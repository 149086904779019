/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Collapse, Grid, Rating } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import Aos from 'aos'
import { ExportNew, FilterNew } from 'assets/images'
import Button from 'components/Button/Index'
import Checkbox from 'components/Checkbox/Index'
import DateFilter from 'components/Dashboard/DateFilter'
import FasTabGrid from 'components/FasTabGrid/FasTabGrid'
import Layout from 'components/Layout/Layout'
import Select from 'components/Select/Index'
import TabDetailsDialog from 'components/TabDetailsDialog/Index'
import Textfield from 'components/Textfield/Index'
import CryptoJS from 'crypto-js'
import { endOfDay, startOfDay } from 'date-fns'
import { selectedEstablishment } from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import {
  setTabPayloadData,
  tabpayloadData,
} from 'features/tabpayload/tabpayload'
import { IDatagrid } from 'interfaces/datagrid'
import { ItabsSearchRequest, ItabsSearchResponse } from 'interfaces/tab'
import React, { useCallback, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import tabService from 'services/tab.service'
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_SECRET_KEY,
} from 'utility/constants'
import { setDefaultDataGridOptions } from 'utility/helper'

Aos.init({
  duration: 600,
  once: true,
})

function Tabs() {
  const [filterToggle, setFilterToggle] = useState(false)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const [totalRecords, setTotalRecords] = useState(0)
  const FILTER_STATUS = { CLOSE: 2, OPEN: 3, DEFAULT: 0 }
  const [tabList, setTabList] = useState([])
  const [posOrderId, setPosOrderId] = useState('')
  const [tableName, setTableName] = useState('')
  const [posOrderName, setPosOrderName] = useState('')
  const [sectionName, setSectionName] = useState('')
  const [includePos, setIncludePos] = useState(false)
  const [changeDataGridCall, setChangeDataGridCall] = useState(true)
  const [tabId, setTabId] = useState(null)
  const [publicTabId, setPublicTabId] = useState(null)
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
  const [openedDate, setOpenedDate] = useState(null)
  const [guestName, setGuestName] = useState('')
  const [guestEmail, setGuestEmail] = useState('')
  const [tabData, setTabData] = useState<ItabsSearchResponse>()
  const startOfToday = startOfDay(new Date())
  const today = [startOfToday, endOfDay(startOfToday)]
  const [isClear, setClear] = useState(false)
  const [seletctedDates, setSelectedDates] = useState(
    localStorage.getItem('fromPaymentTabId') ||
      localStorage.getItem('fromGuestGuestId')
      ? [null, null]
      : today
  )
  const [isSetRestaurant, setRestaurant] = useState(false)
  const [isSetEstablishment, setEstablishment] = useState(false)
  const [displayText, setDisplayText] = useState(null)
  const [userFilterSelectedSplittype, setuserFilterSelectedSplittype] =
    useState(0)
  const [closedDate, setClosedDate] = useState(null)
  const [userFilterSelectedStatus, setuserFilterSelectedStatus] = useState(
    FILTER_STATUS.DEFAULT
  )
  const dispatch = useDispatch()
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'tableName',
        sort: 'asc',
      },
    ],
  })
  const [openTabDetailsDialog, setOpenTabDetailsDialog] = useState(false)
  const IsAdmin = localStorage.getItem('roleId')
    ? Number(
        CryptoJS.AES.decrypt(
          localStorage.getItem('roleId'),
          ROLE_SECRET_KEY
        )?.toString(CryptoJS.enc.Utf8)
      ) === 1
    : false
  const closeTabDetailsDialog = () => {
    setOpenTabDetailsDialog(false)
  }
  const storedTabPayload = useSelector(tabpayloadData)
  const getTabList = useCallback(
    async (payload: ItabsSearchRequest) => {
      try {
        const data = await tabService.getAllTabs(payload)
        setTabList(data.data.data.data)
        setTotalRecords(data.data.data.totalRecords)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId, selectedEstablishmentId]
  )
  const updateDate = (dates: Date[]) => {
    setSelectedDates(dates)
    setOpenedDate(dates[0].toDateString())
    setClosedDate(dates[1].toDateString())
  }
  const generatePayload = (isApplyorClear?: boolean) => {
    if (isApplyorClear) {
      setChangeDataGridCall(false)
      const res = setDefaultDataGridOptions(dataGridOptions)
      setDataGridOptions(res)
    }
    const currentOpenDate =
      seletctedDates[0] !== null
        ? typeof seletctedDates[0] !== 'string'
          ? seletctedDates[0]?.toDateString()
          : seletctedDates[0]
        : null
    const currentCloseDate =
      seletctedDates[1] !== null
        ? typeof seletctedDates[1] !== 'string'
          ? seletctedDates[1]?.toDateString()
          : seletctedDates[1]
        : null
    let tabrequestpayload: ItabsSearchRequest = {
      pageNo: isApplyorClear ? DEFAULT_PAGE_NUMBER : dataGridOptions.pageNumber,
      pageSize: isApplyorClear ? DEFAULT_PAGE_SIZE : dataGridOptions.pageSize,
      restaurantId: selectedRestaurantId === null ? 0 : selectedRestaurantId,
      establishmentId:
        selectedEstablishmentId === null ? 0 : selectedEstablishmentId,
      sortGridModels: dataGridOptions.sortOrder.map((d) => {
        return {
          field: d.field,
          sort: d.sort === 'asc' ? 1 : 0,
        }
      }),
      guestName,
      guestEmail,
    }
    tabrequestpayload.openedAt = currentOpenDate !== '' ? currentOpenDate : ''
    if (userFilterSelectedStatus !== FILTER_STATUS.DEFAULT)
      tabrequestpayload.status =
        userFilterSelectedStatus !== FILTER_STATUS.CLOSE
    tabrequestpayload.tableName = tableName !== '' ? tableName : ''
    tabrequestpayload.posOrderId = posOrderId !== '' ? posOrderId : ''
    tabrequestpayload.posOrderName = posOrderName !== '' ? posOrderName : ''
    tabrequestpayload.publicTabId = publicTabId !== 0 ? publicTabId : 0
    tabrequestpayload.closedAt = currentCloseDate !== '' ? currentCloseDate : ''
    tabrequestpayload.tabId = tabId !== 0 ? tabId : 0
    tabrequestpayload.includePos = includePos
    tabrequestpayload.sectionName = sectionName !== '' ? sectionName : ''
    if (userFilterSelectedSplittype !== 0)
      tabrequestpayload.paymentModeId = userFilterSelectedSplittype

    if (localStorage.getItem('fromPaymentTabId')) {
      tabrequestpayload.publicTabId = Number(
        localStorage.getItem('fromPaymentTabId')
      )
      tabrequestpayload.includePos = true
      tabrequestpayload.openedAt = 'Tue Nov 01 2022'
      tabrequestpayload.closedAt = today[0].toDateString()
    }
    if (localStorage.getItem('fromGuestGuestId')) {
      tabrequestpayload.guestId = Number(
        localStorage.getItem('fromGuestGuestId')
      )
      tabrequestpayload.includePos = true
      tabrequestpayload.openedAt = 'Tue Nov 01 2022'
      tabrequestpayload.closedAt = today[0].toDateString()
    }
    if (
      localStorage.getItem('serverReviewId') &&
      localStorage.getItem('serverReviewDate')
    ) {
      tabrequestpayload.serverId = Number(
        localStorage.getItem('serverReviewId')
      )
      tabrequestpayload.serverReviewDateId = Number(
        localStorage.getItem('serverReviewDate')
      )
      tabrequestpayload.restaurantId = Number(
        localStorage.getItem('restaurantId')
      )
      tabrequestpayload.openedAt = 'Tue Nov 01 2022'
      tabrequestpayload.closedAt = today[0].toDateString()
    }
    if (
      localStorage.getItem('IsFromTabDetails') &&
      Object.keys(storedTabPayload).length !== 0
    ) {
      const tempSortGridModel = storedTabPayload
      tabrequestpayload.sortGridModels = tempSortGridModel.sortGridModels
      tabrequestpayload = storedTabPayload
      const selectedStatusFromBack =
        storedTabPayload.status === null ||
        storedTabPayload.status === undefined
          ? 0
          : storedTabPayload.status === true
          ? 3
          : 2
      setPublicTabId(storedTabPayload.publicTabId)
      setPosOrderId(storedTabPayload.posOrderId)
      setPosOrderName(storedTabPayload.posOrderName)
      setSectionName(storedTabPayload.sectionName)
      setTableName(storedTabPayload.tableName)
      setTabId(storedTabPayload.tabId)
      setOpenedDate(storedTabPayload.openedAt)
      setClosedDate(storedTabPayload.closedAt)
      setIncludePos(storedTabPayload.includePos)
      setuserFilterSelectedStatus(selectedStatusFromBack)
      setFilterToggle(true)
      setDisplayText([
        new Date(storedTabPayload.openedAt),
        new Date(storedTabPayload.closedAt),
      ])
      const newDataGridOptions = { ...dataGridOptions }
      newDataGridOptions.pageNumber = storedTabPayload.pageNo
      newDataGridOptions.pageSize = storedTabPayload.pageSize
      localStorage.removeItem('IsFromTabDetails')
    }
    dispatch(setTabPayloadData(tabrequestpayload))
    return tabrequestpayload
  }

  // if (
  //   selectedRestaurantId === null &&
  //   !isSetRestaurant &&
  //   localStorage.getItem('restaurantIdForDetailRequest') != null
  // ) {
  //   dispatch(
  //     setSelectedRestuarant({
  //       selectedRestaurant: Number(
  //         localStorage.getItem('restaurantIdForDetailRequest')
  //       ),
  //     })
  //   )
  //   setRestaurant(true)
  // }
  // if (
  //   selectedEstablishmentId === null &&
  //   !isSetEstablishment &&
  //   localStorage.getItem('establishmentIdForDetailRequest') != null
  // ) {
  //   dispatch(
  //     setSelectedEstablishment({
  //       selectedEstablishment: Number(
  //         localStorage.getItem('establishmentIdForDetailRequest')
  //       ),
  //     })
  //   )
  //   setEstablishment(true)
  // }
  useEffect(() => {
    const payload = generatePayload()
    if (changeDataGridCall) {
      getTabList(payload)
    }
    setChangeDataGridCall(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions, selectedRestaurantId, selectedEstablishmentId])
  useEffect(() => {
    return () => {
      localStorage.removeItem('fromPaymentTabId')
      localStorage.removeItem('fromGuestGuestId')
      localStorage.removeItem('serverReviewId')
      localStorage.removeItem('serverReviewDate')
      localStorage.removeItem('restaurantId')
    }
  }, [])
  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }
  const onSelectionModelChange = (selectionModelData: GridSelectionModel) => {
    setSelectionModel(selectionModelData)
  }

  const setLocalStorageParams = (fromTabs, orderId, tabIdforDetailRequest?) => {
    localStorage.setItem('fromTabs', fromTabs)
    localStorage.setItem('orderId', orderId)
    localStorage.setItem('tabIdforDetailRequest', tabIdforDetailRequest)
    localStorage.removeItem('fromReview')
    localStorage.removeItem('fromDashboard')
  }

  const getTabColDef = (): GridColDef[] => {
    return [
      {
        field: 'restaurantName',
        headerName: 'Restaurant Name',
        align: 'center',
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<string>) => {
          return (
            <Link
              to="/tab-details"
              onClick={() =>
                setLocalStorageParams(
                  true,
                  params.row.orderId,
                  params.row.tabId
                )
              }
            >
              {params.value ? params.value : '-'}
            </Link>
          )
        },
      },
      {
        field: 'establishmentName',
        headerName: 'Location Name',
        align: 'center',
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<string>) => {
          return (
            <Link
              to="/tab-details"
              onClick={() =>
                setLocalStorageParams(
                  true,
                  params.row.orderId,
                  params.row.tabId
                )
              }
            >
              {params.value ?? '-'}
            </Link>
          )
        },
      },
      {
        field: 'publicTabId',
        headerName: 'Public Tab ID',
        align: 'center',
        flex: 1,
        minWidth: 160,
        renderCell: (params: GridRenderCellParams<string>) => {
          return (
            <Link
              to="/tab-details"
              onClick={() =>
                setLocalStorageParams(
                  true,
                  params.row.orderId,
                  params.row.tabId
                )
              }
            >
              {params.value ? params.value : '-'}
            </Link>
          )
        },
      },
      {
        field: 'tableName',
        headerName: 'Table',
        align: 'center',
        flex: 1,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<string>) => {
          return (
            <Link
              to="/tab-details"
              onClick={() =>
                setLocalStorageParams(
                  true,
                  params.row.orderId,
                  params.row.tabId
                )
              }
            >
              {params.value ? params.value : '-'}
            </Link>
          )
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        align: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          return (
            <Link
              to="/tab-details"
              onClick={() =>
                setLocalStorageParams(
                  true,
                  params.row.orderId,
                  params.row.tabId
                )
              }
            >
              <span>{params.value ? 'Open' : 'Close'}</span>
            </Link>
          )
        },
      },
      {
        field: 'totalPayment',
        headerName: 'Tab Total',
        align: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<number>) => {
          return (
            <Link
              to="/tab-details"
              onClick={() =>
                setLocalStorageParams(
                  true,
                  params.row.orderId,
                  params.row.tabId
                )
              }
            >
              {params.value !== null
                ? `$${(Math.round(Number(params.value) * 100) / 100).toFixed(
                    2
                  )}`
                : '-'}
            </Link>
          )
        },
      },
      {
        field: 'paymentMode',
        headerName: 'Payment Mode',
        align: 'center',
        flex: 1,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<string>) => {
          return (
            <Link
              to="/tab-details"
              onClick={() =>
                setLocalStorageParams(
                  true,
                  params.row.orderId,
                  params.row.tabId
                )
              }
            >
              {params.value ? params.value : '-'}
            </Link>
          )
        },
      },
      {
        field: 'starRating',
        headerName: 'Star Rating',
        align: 'center',
        flex: 1,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<number>) => {
          return (
            <Link
              to="/tab-details"
              onClick={() =>
                setLocalStorageParams(
                  true,
                  params.row.orderId,
                  params.row.tabId
                )
              }
            >
              <Rating
                sx={{ gap: 0, color: '#f48330' }}
                name="read-only"
                value={params.value ? params.value : 0}
                precision={0.1}
                size="small"
                readOnly
              />
            </Link>
          )
        },
      },
      {
        field: 'openedAt',
        headerName: 'Opened At',
        align: 'center',
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<string>) => {
          return (
            <Link
              to="/tab-details"
              onClick={() =>
                setLocalStorageParams(
                  true,
                  params.row.orderId,
                  params.row.tabId
                )
              }
            >
              {params.value ? (
                <Moment format="MM/DD/YYYY hh:mm A">{params.value}</Moment>
              ) : (
                '-'
              )}
            </Link>
          )
        },
      },
    ]
  }

  const onFilterApply = async () => {
    const payload = generatePayload(true)
    getTabList(payload)
  }

  const onFilterClear = () => {
    setPosOrderId('')
    setPosOrderName('')
    setSectionName('')
    setTableName('')
    setTabId(null)
    setPublicTabId(null)
    setOpenedDate(null)
    setClosedDate(null)
    setIncludePos(false)
    setuserFilterSelectedStatus(FILTER_STATUS.DEFAULT)
    setuserFilterSelectedSplittype(0)
    setGuestEmail('')
    setGuestName('')
    const payloadClear = generatePayload(true)
    const onClearPayloadData = { ...payloadClear }
    onClearPayloadData.openedAt = today[0].toDateString()
    onClearPayloadData.posOrderId = ''
    onClearPayloadData.posOrderName = ''
    onClearPayloadData.sectionName = ''
    onClearPayloadData.closedAt = today[1].toDateString()
    onClearPayloadData.tableName = ''
    onClearPayloadData.tabId = null
    onClearPayloadData.publicTabId = null
    onClearPayloadData.status = null
    onClearPayloadData.paymentModeId = 0
    onClearPayloadData.includePos = false
    onClearPayloadData.guestEmail = ''
    onClearPayloadData.guestName = ''
    updateDate(today)
    setDisplayText(today)
    setClear(true)
    getTabList(onClearPayloadData)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exportCSVFile = (
    headers: {
      Name: string // remove commas to avoid errors
      EmailAddress: string
      WebsiteLink: string
      CreatedDate: string
    },
    csv:
      | string
      | Blob
      | import('../../interfaces/restaurant').ICsvDownload
      | ArrayBufferView
      | ArrayBuffer,
    fileTitle: string
  ) => {
    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

    const blob = new Blob([csv as BlobPart], {
      type: 'text/csv;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const downloadFile = async () => {
    const currentOpenDate =
      openedDate !== null
        ? typeof openedDate !== 'string'
          ? openedDate?.toDateString()
          : openedDate
        : null
    const currentCloseDate =
      closedDate !== null
        ? typeof closedDate !== 'string'
          ? closedDate?.toDateString()
          : closedDate
        : null
    const data = await tabService.downloadCsv({
      restaurantId: localStorage.getItem('restaurantId')
        ? Number(localStorage.getItem('restaurantId'))
        : selectedRestaurantId,
      selectedTabs: selectionModel.map((d) => Number(d)),
      publicTabId: publicTabId !== 0 ? publicTabId : 0,
      tabId: tabId !== 0 ? tabId : 0,
      section: sectionName !== '' ? sectionName : '',
      posOrderId,
      tableName: tableName !== '' ? tableName : '',
      openTime: seletctedDates[0].toDateString(),
      closeTime: seletctedDates[1].toDateString(),
      status:
        userFilterSelectedStatus !== FILTER_STATUS.DEFAULT
          ? userFilterSelectedStatus !== FILTER_STATUS.CLOSE
          : null,
      includePos,
      guestName,
      guestEmail,
      establishmentId: selectedEstablishmentId,
      serverId: localStorage.getItem('serverReviewId')
        ? Number(localStorage.getItem('serverReviewId'))
        : 0,
      serverReviewDateId: localStorage.getItem('serverReviewDate')
        ? Number(localStorage.getItem('serverReviewDate'))
        : 0,
    })

    const headers = {
      Name: 'Name'.replace(/,/g, ''), // remove commas to avoid errors
      EmailAddress: 'Email'.replace(/,/g, ''),
      WebsiteLink: 'Website'.replace(/,/g, ''),
      CreatedDate: 'Date'.replace(/,/g, ''),
    }
    const fileTitle = 'Tabs'
    exportCSVFile(headers, data.data.data, fileTitle)
  }

  // convert date to local time
  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    )
    return newDate
  }

  return (
    <>
      <Layout title="Tabs | FasTab">
        <section className="main-content">
          {/* page-top start */}
          <div className="page-top">
            <h3 className="page-title">Tabs</h3>
            <div className="right">
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                size="small"
                title="Export"
                className="btn-h-40"
                onClick={() => downloadFile()}
              >
                <img src={ExportNew} alt="Filter" />
                <span>Export</span>
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                size="small"
                title="Filter"
                className="btn-h-40"
                onClick={() => {
                  setFilterToggle(!filterToggle)
                }}
              >
                <img src={FilterNew} alt="Filter" />
                <span className="icon-btns-span">Filter</span>
              </Button>
            </div>
          </div>
          {/* page-top end */}

          {/* filter-panel start */}
          <Collapse in={filterToggle}>
            <div className="filter-panel">
              <div className="card">
                <Grid container spacing={5}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        label="Public Tab ID"
                        variant="outlined"
                        value={publicTabId ?? ''}
                        onChange={(e) => {
                          setPublicTabId(e.target.value)
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        label="Internal Tab ID"
                        variant="outlined"
                        value={tabId ?? ''}
                        onChange={(e) => {
                          setTabId(e.target.value)
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        label="Pos Order ID"
                        variant="outlined"
                        value={posOrderId}
                        onChange={(e) => {
                          setPosOrderId(e.target.value)
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        label="Table"
                        variant="outlined"
                        value={tableName}
                        onChange={(e) => {
                          setTableName(e.target.value)
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <DateFilter
                      setFilterSelectedDate={updateDate}
                      clear={isClear}
                      displayText={displayText}
                      isDashboard={false}
                      isCompare={false}
                      daysInBetween={null}
                    />
                  </Grid>
                  {/* <Grid item lg={3} sm={6} xs={12}>
                        <div className="form-group">
                          <Datepicker
                            datePickerlabel="Opened At"
                            onChange={(date) => {
                              setOpenedDate(
                                convertUTCDateToLocalDate(date).toISOString()
                              )
                            }}
                            value={openedDate}
                            setFilterSelectedDate={setOpenedDate}
                          />
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={6} xs={12}>
                        <div className="form-group">
                          <Datepicker
                            datePickerlabel="Closed At"
                            onChange={(date) => {
                              setClosedDate(
                                convertUTCDateToLocalDate(date).toISOString()
                              )
                            }}
                            value={closedDate}
                            setFilterSelectedDate={setClosedDate}
                          />
                        </div>
                      </Grid> */}
                  {IsAdmin && (
                    <>
                      <Grid item lg={3} sm={6} xs={12}>
                        <div className="form-group">
                          <Textfield
                            label="Guest Name"
                            variant="outlined"
                            value={guestName}
                            onChange={(e) => {
                              setGuestName(e.target.value)
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={6} xs={12}>
                        <div className="form-group">
                          <Textfield
                            label="Guest Email"
                            variant="outlined"
                            value={guestEmail}
                            onChange={(e) => {
                              setGuestEmail(e.target.value)
                            }}
                          />
                        </div>
                      </Grid>
                    </>
                  )}

                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Status"
                        items={[
                          {
                            key: 'Close',
                            value: FILTER_STATUS.CLOSE,
                          },
                          {
                            key: 'Open',
                            value: FILTER_STATUS.OPEN,
                          },
                        ]}
                        formikValue={userFilterSelectedStatus}
                        handleSelectValue={(value) => {
                          setuserFilterSelectedStatus(value)
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Checkbox
                      name="includePos"
                      label="Include POS Transactions"
                      classes="include-pos-checkbox"
                      checked={includePos}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setIncludePos(event.target.checked)
                      }
                    />
                  </Grid>
                </Grid>
                <div className="btn-list">
                  <Button
                    variant="contained"
                    color="primary"
                    title="Apply"
                    onClick={onFilterApply}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    title="Clear All"
                    onClick={onFilterClear}
                  >
                    Clear All
                  </Button>
                </div>
              </div>
            </div>
          </Collapse>
          {/* filter-panel end */}

          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <FasTabGrid
              uniqueId="tabId"
              columnsData={getTabColDef()}
              checkboxSelection
              gridData={tabList}
              totalRecords={totalRecords}
              onGridPageChange={onPageChange}
              onGridPageSizeChange={onPageSizeChange}
              onGridSortChange={onSortChange}
              onSelectionModelChange={onSelectionModelChange}
            />
          </div>
          {/* data-table end */}
        </section>
        <></>
        {/* main-content end */}
        {/* wrapper end */}
      </Layout>
      <TabDetailsDialog
        open={openTabDetailsDialog}
        onClose={closeTabDetailsDialog}
        tabData={tabData}
      />
    </>
  )
}

export default Tabs
