import { AxiosError, AxiosResponse } from 'axios'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER } from './constants'

export const resolveHTTP = async <T>(
  promise: Promise<AxiosResponse<T>>
): Promise<
  [result: Partial<T> | null, error: Partial<AxiosResponse> | null]
> => {
  try {
    const data = await promise
    return [data?.data, null]
  } catch (e) {
    return [null, (e as Partial<AxiosError>)?.response as AxiosResponse]
  }
}

export const generateRandomString = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  for (let iterator = 0; iterator < length; iterator += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
export const getFileSizeMB = (size: number): number => {
  return size / 1024 / 1024
}

export const formatPhoneNumber = (value: string) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '')

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`
}

export const setDefaultDataGridOptions = (dataGridOptions) => {
  const options = { ...dataGridOptions }
  options.pageSize = DEFAULT_PAGE_SIZE
  options.pageNumber = DEFAULT_PAGE_NUMBER
  return options
}

export const getPrevMonday = () => {
  const prevMonday = new Date()
  prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7))
  prevMonday.setDate(prevMonday.getDate() - 7)
  return prevMonday
}

export const getPrevSunday = () => {
  const prevSunday = new Date()
  prevSunday.setDate(prevSunday.getDate() - ((prevSunday.getDay() + 6) % 7))
  prevSunday.setDate(prevSunday.getDate() - 1)
  return prevSunday
}
