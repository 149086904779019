import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid-pro'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import React from 'react'
import qrService from 'services/qr.service'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  selectedTables: GridSelectionModel
  isPaymentOn?: boolean
  reloadList?: () => void
}

function EnableDisablePaymentDialog(props: dialogProps) {
  const { open, onClose, selectedTables, isPaymentOn, reloadList } = props
  const handleContinueClick = async () => {
    await qrService.EnableDisablePayments({
      tableId: selectedTables.map((d) => Number(d)),
      isPaymentOn,
    })
    reloadList()
  }
  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <p>
            Are you sure you want to {isPaymentOn ? 'enable' : 'disable'}{' '}
            payment for selected table(s)?
          </p>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="OK"
              onClick={(e) => {
                handleContinueClick()
                onClose(e)
              }}
            >
              OK
            </Button>
            <Button
              title="Cancel"
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              <span>Cancel</span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EnableDisablePaymentDialog
