import { RootObject } from 'interfaces/omnivore'
import axios, { AxiosResponse } from 'axios'

const locationApi = `${process.env.REACT_APP_OMNIVORE_API}/locations`

export const getTablesFromPOS = async (
  _apiKey = '',
  _locationId = 'iA7KLzaT'
): Promise<AxiosResponse<RootObject>> =>
  axios.get('', {
    baseURL: `${locationApi}/${_locationId}/tables`,
    headers: {
      'Api-Key': _apiKey,
    },
    hideLoader: false,
  })

export const getTenderTypesFromPOS = async (
  _apiKey = '',
  _locationId = 'iA7KLzaT'
): Promise<AxiosResponse<RootObject>> =>
  axios.get('', {
    baseURL: `${locationApi}/${_locationId}/tender_types`,
    headers: {
      'Api-Key': _apiKey,
    },
    hideLoader: false,
  })
