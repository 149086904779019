/* eslint-disable prefer-destructuring */
import { Delete, FileUploadIcon } from 'assets/images'
import Button from 'components/Button/Index'
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/Index'
import { DialogOptions } from 'enums/Common'
import { useCallback, useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { toast } from 'react-toastify'
import blobService from 'services/blob.service'
import { MAX_FILE_SIZE_FOR_RESTAURANT_LOGO } from 'utility/constants'
import { getFileSizeMB } from 'utility/helper'

interface props {
  classes?: string
  fileTypes: Array<string>
  maxSize?: number
  logoUpload?: boolean
  noItems?: boolean
  filePath?: string
  fileChange?: (uploadedFile: File) => void
  pullName?: (name: string) => void
  deleteUploadedFile?: (uploadedFile: File) => void
}

function FileUpload({
  classes,
  fileTypes,
  maxSize,
  noItems,
  logoUpload = false,
  fileChange,
  pullName,
  filePath,
  deleteUploadedFile,
}: props) {
  const [file, setFile] = useState(null)
  const [token, SetToken] = useState(null)
  const [openDeleteConfirmationFile, setOpenDeleteConfirmationFile] =
    useState(false)
  const getSasToken = async () => {
    if (!token) {
      const azureData = await blobService.getAzureDetails()
      SetToken(azureData.data.data.sasToken)
    }
  }
  const handleChange = (uploadedFile: File) => {
    if (getFileSizeMB(uploadedFile.size) < MAX_FILE_SIZE_FOR_RESTAURANT_LOGO) {
      const reader = new FileReader()
      fileChange(uploadedFile)
      reader.readAsDataURL(uploadedFile)
      reader.onload = () => {
        setFile(reader.result)
      }
    } else {
      setFile(null)
      toast.error('file size exceeded the limit')
    }
  }
  const handleDeleteFile = useCallback(
    async () => {
      const containerName = process.env.REACT_APP_AZURE_CONTAINER
      const publicContainerName = process.env.REACT_APP_AZURE_PUBLIC_CONTAINER
      setFile(null)
      deleteUploadedFile(null)
      if (
        file &&
        (file.includes(containerName) || file.includes(publicContainerName))
      ) {
        let name
        if (file.includes(containerName)) {
          name = file.split(`${containerName}/`)[1].split('?')[0]
        } else {
          name = file.split(`${publicContainerName}/`)[1].split('?')[0]
        }
        pullName(name)
      } else {
        pullName('noFile')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file]
  )
  const toggleDeleteConfirmationDialogforFile = useCallback(
    async (isOpen: boolean, isSubmit: boolean) => {
      setOpenDeleteConfirmationFile(isOpen)
      if (isSubmit && !isOpen) {
        handleDeleteFile()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file]
  )
  useEffect(() => {
    getSasToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (filePath) {
      setFile(filePath)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePath])

  return (
    <>
      {/* no-items file upload start */}
      {noItems && (
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          maxSize={maxSize}
          classes={`file-upload ${classes}`}
        >
          {file == null ? (
            <>
              <img src={FileUploadIcon} alt="File" />
              <p>
                Drag and Drop Files Here <strong>Choose File</strong>
                <span>Files Supported : PDF</span>
              </p>
            </>
          ) : (
            <img src={file} className="uploaded-image" alt="" />
          )}
        </FileUploader>
      )}
      {/* no-items file upload end */}

      {logoUpload && file != null ? (
        <div className="file-preview">
          <Button
            variant="text"
            color="inherit"
            title="Delete"
            onClick={(e) => {
              e.stopPropagation()
              toggleDeleteConfirmationDialogforFile(true, false)
            }}
            className="icon-btn no-border rounded"
          >
            <img src={Delete} alt="Delete" />
          </Button>
          <img src={file} alt="FasTab" />
        </div>
      ) : (
        !noItems && (
          /* if it's not a "logo-upload" or "no-items file upload" 
             then by default this below file upload control will be visible */
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            classes={`file-upload ${classes}`}
          >
            <img src={FileUploadIcon} alt="File" />
            <p>
              Drag and Drop Files Here <strong>Choose File</strong>
              <span>Files Supported : PDF</span>
            </p>
          </FileUploader>
        )
      )}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationFile}
        message="Are you sure you want to delete this file?"
        callBack={(reason: DialogOptions) => {
          toggleDeleteConfirmationDialogforFile(
            false,
            reason === DialogOptions.Yes
          )
        }}
      />
    </>
  )
}

export default FileUpload
