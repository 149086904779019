import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid-pro'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import MarkAsDisbursedDialog from 'components/MarkAsDisbursedDialog/Index'
import React, { useState } from 'react'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  selectedRestaurants: GridSelectionModel
  selectedEstablishments: GridSelectionModel
  paymentDate: string
  getNewList: () => void
  weekStartDate: string
  weekEndDate: string
  isCurrent: boolean
}

function MarkAsDisbursedConfirmation(props: dialogProps) {
  const {
    open,
    onClose,
    selectedRestaurants,
    selectedEstablishments,
    paymentDate,
    getNewList,
    weekStartDate,
    weekEndDate,
    isCurrent,
  } = props
  const [openMarkAsDisbursedDialog, setOpenMarkAsDisbursedDialog] =
    useState(false)
  const closeMarkAsDisbursedDialog = () => {
    setOpenMarkAsDisbursedDialog(false)
  }
  const getFormattedDate = (value) => {
    const date = new Date(
      value || new Date(Date.now() - 86400000).toISOString().split('T')[0]
    )
    const year = date.getFullYear()
    const month = (1 + date.getMonth()).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${month}/${day}/${year}`
  }
  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <p>
            {` Are you sure you want to mark ${
              selectedRestaurants.map((d) => Number(d)).length
            } Restaurant(s) as disbursed for
            ${getFormattedDate(weekStartDate)} to ${getFormattedDate(
              weekEndDate
            )}?`}
          </p>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Yes"
              onClick={(e) => {
                setOpenMarkAsDisbursedDialog(true)
                onClose(e)
              }}
            >
              Yes
            </Button>
            <Button
              title="No"
              variant="outlined"
              color="primary"
              onClick={(e) => {
                onClose(e)
              }}
            >
              <span>No</span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <MarkAsDisbursedDialog
        onClose={closeMarkAsDisbursedDialog}
        closeDialog={closeMarkAsDisbursedDialog}
        getNewList={getNewList}
        open={openMarkAsDisbursedDialog}
        selectedRestaurants={selectedRestaurants}
        selectedEstablishments={selectedEstablishments}
        paymentDate={paymentDate}
        weekStartDate={weekStartDate}
        weekEndDate={weekEndDate}
        isCurrent={isCurrent}
      />
    </>
  )
}

export default MarkAsDisbursedConfirmation
