/* eslint-disable no-nested-ternary */
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import { ItabsSearchResponse } from 'interfaces/tab'
import React from 'react'
import Moment from 'react-moment'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  tabData?: ItabsSearchResponse
}

function TabDetailsDialog(props: dialogProps) {
  const { open, onClose, tabData } = props
  return (
    <>
      <Dialog
        className="primary-dialog details-dialog"
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <h5>Tab Details</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} xs={6}>
              <div className="detail-info">
                <span>Public Tab ID</span>
                <strong>{tabData ? tabData.publicTabId : '-'}</strong>
              </div>
            </Grid>
            <Grid item lg={4} md={4} xs={6}>
              <div className="detail-info">
                <span>Tab ID</span>
                <strong>{tabData ? tabData.tabId : '-'}</strong>
              </div>
            </Grid>
            <Grid item lg={4} md={4} xs={6}>
              <div className="detail-info">
                <span>Table</span>
                <strong>{tabData ? tabData.tableName : '-'}</strong>
              </div>
            </Grid>
            <Grid item lg={4} md={4} xs={6}>
              <div className="detail-info">
                <span>Payment Mode</span>
                <strong>
                  {tabData
                    ? tabData.paymentMode
                      ? tabData.paymentMode
                      : '-'
                    : '-'}
                </strong>
              </div>
            </Grid>
            <Grid item lg={4} md={4} xs={6}>
              <div className="detail-info">
                <span>Opened At</span>
                <strong>
                  {tabData ? (
                    <Moment format="MM/DD/YYYY hh:mm A">
                      {tabData.openedAt}
                    </Moment>
                  ) : (
                    '- '
                  )}
                </strong>
              </div>
            </Grid>

            <Grid item lg={4} md={4} xs={6}>
              <div className="detail-info">
                <span>Tab Total</span>
                <strong>
                  $
                  {tabData
                    ? (
                        Math.round(Number(tabData.totalPayment) * 100) / 100
                      ).toFixed(2)
                    : '-'}
                </strong>
              </div>
            </Grid>

            <Grid item lg={4} md={4} xs={6}>
              <div className="detail-info">
                <span>Status</span>
                <strong>
                  {tabData ? (tabData.status ? 'Open' : 'Close') : '-'}
                </strong>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TabDetailsDialog
