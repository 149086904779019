import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import React, { useMemo } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import Textfield from 'components/Textfield/Index'
import { IMarkDisbursementRequest } from 'interfaces/disbursement'
import { GridSelectionModel } from '@mui/x-data-grid-pro'
import disbursementService from 'services/disbursement.service'
import { toast } from 'react-toastify'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { useSelector } from 'react-redux'
import { selectedEstablishment } from 'features/establishment/establishment'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  selectedRestaurants: GridSelectionModel
  selectedEstablishments: GridSelectionModel
  closeDialog?: (event: boolean) => void
  paymentDate: string
  getNewList: () => void
  weekStartDate: string
  weekEndDate: string
  isCurrent: boolean
}

function MarkAsDisbursedDialog(props: dialogProps) {
  const {
    open,
    onClose,
    selectedRestaurants,
    selectedEstablishments,
    closeDialog,
    paymentDate,
    getNewList,
    weekStartDate,
    weekEndDate,
    isCurrent,
  } = props
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const handleSave = async (formdata: IMarkDisbursementRequest) => {
    formdata.loginId = +localStorage.getItem('loginId')
    formdata.restaurantIds = selectedRestaurants.map((d) => Number(d))
    formdata.establishmentIds = selectedEstablishments.map((d) => Number(d))
    formdata.paymentDate = paymentDate
    formdata.weekStartDate =
      weekStartDate ||
      new Date(Date.now() - 86400000).toISOString().split('T')[0]
    formdata.weekEndDate =
      weekEndDate || new Date(Date.now() - 86400000).toISOString().split('T')[0]
    formdata.globalFilterRestaurantId = selectedRestaurantId
    formdata.globalFilterEstablishmentId = selectedEstablishmentId
    formdata.isCurrent = isCurrent
    const data = await disbursementService.markAsDisbursed(formdata)
    if (data.data.message === 'Payedge order id already exist.') {
      toast.error('PayEdge Order ID already exists')
    } else if (data.data.data) {
      toast.success('Restaurant(s) Marked Successfully as Disbursed')
      getNewList()
      closeDialog(false)
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.resetForm()
    closeDialog(false)
  }
  // eslint-disable-next-line no-unused-expressions
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        payEdgeOrderId: yup
          .string()
          .required('PayEdge Order ID is Required.')
          .max(10, 'Cannot be more than 10 characters'),
      }),
    []
  )
  const formik = useFormik<IMarkDisbursementRequest>({
    initialValues: {
      restaurantIds: [],
      establishmentIds: [],
      payEdgeOrderId: '',
      loginId: null,
      paymentDate: '',
      weekStartDate: '',
      weekEndDate: '',
      isCurrent,
    },
    validationSchema,
    onSubmit: (values) => handleSave(values),
  })
  return (
    <Dialog
      className="primary-dialog"
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
        onClose(e)
        formik.resetForm()
      }}
    >
      <DialogTitle>
        <h5>Payment Details</h5>
        <Button
          variant="text"
          title="Close"
          onClick={(e) => {
            onClose(e)
            formik.resetForm()
          }}
          className="icon-btn rounded"
          color="inherit"
        >
          <img src={Close} alt="Close" />
        </Button>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent className="pb-0">
          <div className="form-group">
            <Textfield
              variant="outlined"
              type="text"
              label="PayEdge Order ID"
              name="payEdgeOrderId"
              handleBlur={formik.handleBlur}
              value={formik.values?.payEdgeOrderId}
              onChange={formik.handleChange}
              error={
                !!formik.errors.payEdgeOrderId && formik.touched.payEdgeOrderId
              }
              helperText={
                !!formik.errors.payEdgeOrderId && formik.touched.payEdgeOrderId
                  ? formik.errors.payEdgeOrderId
                  : ''
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Submit"
              type="submit"
            >
              Submit
            </Button>
            <Button
              onClick={(e) => {
                onClose(e)
                formik.resetForm()
              }}
              title="Cancel"
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default MarkAsDisbursedDialog
