import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IEstablishment,
  IEstablishmentSearchRequest,
  IEstablishmentSearchResponse,
  ISearchResponse,
  IEstablishmentRequest,
  IEstablishmentCityResponse,
  IPOSEstablishment,
  ICloneMenuRequest,
  ILocationIdRequest,
  IEstablishmentCsvRequest,
  IStripeCustomerResponseDTO,
  IStripeCustomerRequestDTO,
} from 'interfaces/establishment'
import { ICsvDownload, IRestaurantState } from 'interfaces/restaurant'
import { RootObject } from 'interfaces/omnivore'
import httpClient from './base-service'

const endPointBaseURL = `/Establishment`

const creatEstablishment = async (
  requestBody: IEstablishment
): Promise<AxiosResponse<IApiSuccessResponse<IEstablishment>>> =>
  httpClient.post<IApiSuccessResponse<IEstablishment>>(
    `${endPointBaseURL}/add-establishment`,
    requestBody
  )

// get filtered establishments
const getFilteredEstablishments = async (
  requestBody: IEstablishmentSearchRequest
): Promise<
  AxiosResponse<
    IApiSuccessResponse<ISearchResponse<IEstablishmentSearchResponse[]>>
  >
> =>
  httpClient.post<
    IApiSuccessResponse<ISearchResponse<IEstablishmentSearchResponse[]>>
  >(`${endPointBaseURL}/get-establishment-list`, requestBody)

const getAllEstablishments = async (
  requestBody: IEstablishmentSearchRequest
): Promise<
  AxiosResponse<
    IApiSuccessResponse<ISearchResponse<IEstablishmentSearchResponse[]>>
  >
> =>
  httpClient.post<
    IApiSuccessResponse<ISearchResponse<IEstablishmentSearchResponse[]>>
  >(`${endPointBaseURL}/get-establishment-list`, requestBody)

// get esablishment by id
const getEstablishmentById = async (
  requestBody: IEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<IEstablishment>>> =>
  httpClient.post<IApiSuccessResponse<IEstablishment>>(
    `${endPointBaseURL}/get-establishment`,
    requestBody
  )

// edit establishment
const updateEstablishment = async (
  requestBody: IEstablishment
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-establishment`,
    requestBody
  )

const deleteEstablishment = async (
  requestBody: IEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.delete<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/delete-establishment`,
    {
      headers: {},
      data: requestBody,
      hideLoader: false,
    }
  )

const getAllCities = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IEstablishmentCityResponse[]>>
> =>
  httpClient.get<IApiSuccessResponse<IEstablishmentCityResponse[]>>(
    `${endPointBaseURL}/get-all-cities`
  )

const getAllStates = async (): Promise<AxiosResponse> =>
  httpClient.get(`${endPointBaseURL}/get-all-state`)

const getConfigurations = async (
  requestBody: IEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPOSEstablishment[]>>> =>
  httpClient.post<IApiSuccessResponse<IPOSEstablishment[]>>(
    `${endPointBaseURL}/get-posconfiguration-by-establishment-id`,
    requestBody
  )

const getTRAYTables = async (
  requestBody: IEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<RootObject>>> =>
  httpClient.post<IApiSuccessResponse<RootObject>>(
    `/table/get-tables-from-tray`,
    requestBody
  )

// download csv
const downloadCsv = async (
  requestBody: IEstablishmentCsvRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICsvDownload>>> =>
  httpClient.post<IApiSuccessResponse<ICsvDownload>>(
    `${endPointBaseURL}/create-csv-file-for-establishment`,
    requestBody
  )
const getEstablishmentsByRestaurantId = async (
  requestBody: IRestaurantState
): Promise<
  AxiosResponse<IApiSuccessResponse<IEstablishmentSearchResponse[]>>
> =>
  httpClient.post<IApiSuccessResponse<IEstablishmentSearchResponse[]>>(
    `${endPointBaseURL}/get-establishments-by-restaurant-id`,
    requestBody
  )

const cloneMenu = async (
  requestBody: ICloneMenuRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/clone-establishment-menu`,
    requestBody
  )

const CheckIsAgentAvailable = async (
  requestBody: ILocationIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<ILocationIdRequest>>> =>
  httpClient.post<IApiSuccessResponse<ILocationIdRequest>>(
    `${endPointBaseURL}/check-is-agent-available`,
    requestBody
  )

const CheckLocationHealths = async (): Promise<
  AxiosResponse<IApiSuccessResponse<string>>
> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/health-checkup`
  )

const getSubscriptionPlan = async (): Promise<AxiosResponse> =>
  httpClient.get(`${endPointBaseURL}/get-subscription-plan`)

const getAllStripeCustomer = async (): Promise<AxiosResponse> =>
  httpClient.get(`${endPointBaseURL}/get-stripe-customer-list`)

const GetStripeCustomerDetails = async (
  requestBody: IStripeCustomerRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<IStripeCustomerResponseDTO>>> =>
  httpClient.post<IApiSuccessResponse<IStripeCustomerResponseDTO>>(
    `${endPointBaseURL}/get-customer-details-by-id`,
    requestBody
  )

export default {
  creatEstablishment,
  getAllEstablishments,
  getEstablishmentById,
  updateEstablishment,
  deleteEstablishment,
  getAllCities,
  getAllStates,
  getFilteredEstablishments,
  getConfigurations,
  downloadCsv,
  getEstablishmentsByRestaurantId,
  cloneMenu,
  CheckIsAgentAvailable,
  CheckLocationHealths,
  getSubscriptionPlan,
  getTRAYTables,
  getAllStripeCustomer,
  GetStripeCustomerDetails,
}
