import Aos from 'aos'
import Layout from 'components/Layout/Layout'
import {
  Checkbox,
  Fade,
  Grid,
  InputAdornment,
  List,
  ListItem,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { BackArrow } from 'assets/images'
import Button from 'components/Button/Index'
// import Select from 'components/Select/Index'
import { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { restaurantList } from 'features/restaurant/restaurant'
import { useSelector } from 'react-redux'
// import { setSelectedEstablishment } from 'features/establishment/establishment'
// import establishmentService from 'services/establishment.service'
import { toast } from 'react-toastify'
// import Datepicker from 'components/Datepicker/Index'
import { IPromocodeAddRequestDTO } from 'interfaces/promocode'
import { resolveHTTP } from 'utility/helper'
import promocodeService from 'services/promocode.service'
import Select from 'components/Select/Index'
import Datepicker from 'components/Datepicker/Index'
import { useNavigate } from 'react-router-dom'
import Switch from 'components/Switch/Index'
import MultiSelect from 'components/MultiSelect/MultiSelect'
import moment from 'moment'

Aos.init({
  duration: 600,
  once: true,
})

function AddEditPromoCode() {
  // const restuarantList = useSelector(restaurantList)
  // const [establishmentLists, setEstablishmentLists] = useState([])
  const [loginLists, setLoginLists] = useState([])
  const restuarantList = useSelector(restaurantList)
  // const [selectedRestraunt] = useState(null)
  const [promoStartDate, setPromoStartDate] = useState(null)
  const [promoEndDate, setPromoEndDate] = useState(null)
  const navigate = useNavigate()
  // console.log(establishmentLists)
  // const getEstablishmentList = useCallback(
  //   async () => {
  //     try {
  //       const data = await establishmentService.getAllEstablishments({
  //         pageNo: 1,
  //         pageSize: 0,
  //         sortGridModels: [{ field: 'restaurantName', sort: 0 }],
  //         restaurantId: selectedRestraunt,
  //       })

  //       const list = data.data.data.data.map((e) => {
  //         return {
  //           key: e.establishmentName,
  //           value: e.establishmentId,
  //         }
  //       })
  //       // setEstablishmentLists(list)
  //     } catch (e) {
  //       if (localStorage.length !== 0) {
  //         toast.error('Failed to retrieve establishment list.')
  //       }
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [selectedRestraunt]
  // )

  // useEffect(() => {
  //   if (selectedRestraunt !== null) {
  //     getEstablishmentList()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedRestraunt])

  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000
    )
    return newDate
  }
  const getNextDay = () => {
    const today = new Date()
    today.setDate(today.getDate() + 1)
    return today
  }

  const handleSave = async (formdata: IPromocodeAddRequestDTO) => {
    const [data] = await resolveHTTP(promocodeService.addPromocode(formdata))
    if (data) {
      toast.success('Promo code added successfully')
      navigate('/promocode')
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }
    // eslint-disable-next-line no-unused-expressions
  }

  const formSchema = useMemo(() => {
    return yup.object().shape({
      codeName: yup
        .string()
        .required('Promo Code name is required')
        .matches(/^[a-zA-Z0-9]+$/, 'No space or special characters'),
      isRestaurantSpecific: yup.boolean(),
      restaurantId: yup.number().when('isRestaurantSpecific', {
        is: true,
        then: yup
          .number()
          .required('Restaurant is required')
          .min(1, `Restaurant is required`),
      }),
      establishmentId: yup
        .number()
        .nullable()
        .when('isRestaurantPromocode', {
          is: true,
          then: yup
            .number()
            .required('Establishment is required')
            .min(1, `Establishment is required`),
        }),
      isFixedAmountPromoCode: yup.boolean(),
      promocodePercentage: yup
        .number()
        .nullable()
        .when('isFixedAmountPromoCode', {
          is: (val: boolean) => val === false,
          then: yup
            .number()
            .typeError('Value must be a number')
            .required('Promocode percentage is required')
            .moreThan(0, `Please Enter Value between 0 to 100`)
            .max(100, `Please Enter Value between 0 to 100`),
        }),
      promocodeAmount: yup
        .number()
        .nullable()
        .when('isFixedAmountPromoCode', {
          is: (val: boolean) => val === true,
          then: yup
            .number()
            .typeError('Value must be a number')
            .required('Promocode fixed amount is required')
            .moreThan(0, `Please enter value greater than 0`),
        }),
      isMinimumOrderRequirement: yup.boolean(),
      minOrderAmount: yup
        .number()
        .nullable()
        .when('isMinimumOrderRequirement', {
          is: true,
          then: yup
            .number()
            .typeError('Value must be a number')
            .required('Minimum amount is required')
            .moreThan(0, `Please enter value greater than 0`),
        }),
      customerEligibility: yup.string(),
      minNoOfVisit: yup
        .number()
        .nullable()
        .typeError('Value must be a number')
        .when('customerEligibility', {
          is: 'specific_customer',
          then: yup
            .number()
            .typeError('Value must be a number')
            .required('Minimum amount is required')
            .min(0, `Please enter value greater than 0`),
        }),
      startDate: yup.date().required('Start date is required'),
      expireDate: yup
        .date()
        .required('Expire date is required')
        .when(
          'startDate',
          (startDate, schem) =>
            startDate &&
            schem.min(startDate, 'Expire time cannot be before start time')
        ),
      isSpecialPromocode: yup.boolean(),
      minNoOfOrder: yup
        .number()
        .nullable()
        .typeError('Value must be a number')
        .when('isSpecialPromocode', {
          is: true,
          then: yup
            .number()
            .typeError('Value must be a number')
            .required('Minimum order value required')
            .moreThan(0, `Please enter value greater than 0`),
        }),
      maxDiscountUses: yup
        .number()
        .typeError('Value must be a number')
        .required('FasTab Promo Dollar Cap is required')
        .moreThan(0, 'Please enter value greater than 0'),
      maxNoOfDiscountUses: yup
        .number()
        .nullable(true)
        .moreThan(0, 'Please enter value greater than 0'),
      maxLimitForUser: yup
        .number()
        .nullable(true)
        .moreThan(0, 'Please enter value greater than 0'),
      maxLimitForNotify: yup
        .number()
        .nullable(true)
        .moreThan(0, 'Please enter value greater than 0')
        .max(
          yup.ref('maxDiscountUses'),
          'maximum value upto FasTab Promo Dollor Cap.'
        ),
      autoApplyAfterPayment: yup
        .number()
        .nullable(true)
        .moreThan(0, 'Please enter value greater than 0'),
      loginIds: yup.array().when('customerEligibility', {
        is: 'specific_customer',
        then: yup.array().min(1, `Please select more than one customer`),
      }),
    })
  }, [])
  const dateToIsoString = (currentDate) => {
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const hours = String(currentDate.getHours()).padStart(2, '0')
    const minutes = String(currentDate.getMinutes()).padStart(2, '0')
    const seconds = String(currentDate.getSeconds()).padStart(2, '0')
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0')

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`
  }

  const formik = useFormik<IPromocodeAddRequestDTO>({
    initialValues: {
      codeName: '',
      isFixedAmountPromoCode: false,
      promocodePercentage: null,
      promocodeAmount: null,
      isMinimumOrderRequirement: false,
      minOrderAmount: null,
      customerEligibility: 'all',
      minNoOfVisit: 0,
      loginIds: [],
      maxDiscountUses: null,
      maxNoOfDiscountUses: null,
      maxLimitForUser: null,
      isActive: true,
      isRestaurantPromocode: false,
      autoApplyAfterPayment: null,
      startDate: promoStartDate || dateToIsoString(new Date()),
      expireDate: promoEndDate || dateToIsoString(getNextDay()),
      maxLimitForNotify: null,
      isRestaurantSpecific: false,
      description: '',
      isSpecialPromocode: false,
      restaurantId: 0,
      establishmentId: null,
    },
    validationSchema: formSchema,
    onSubmit: (values) => handleSave(values),
  })

  const getGuestListByNumberOfVisit = async (noOfVisit) => {
    try {
      if (noOfVisit >= 0) {
        const data = await promocodeService.getGuestByNumberOfVisit({
          noOfVisit,
        })
        const list = data.data.data.loginLists.map((e) => {
          return {
            key: `${e.name} (${e.email})`,
            value: e.loginId,
          }
        })
        setLoginLists(list)
        formik.setFieldValue('loginIds', [])
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getGuestListByNumberOfVisit(formik.values.minNoOfVisit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.minNoOfVisit])

  let content

  switch (formik.values?.customerEligibility) {
    case 'specific_customer':
      content = `Applicable exclusively to customers who have visited ${
        formik.values?.minNoOfVisit || 0
      } times`
      break
    case 'loggedinuser':
      content = 'Applicable exclusively to logged-in customers'
      break
    default:
      content = 'Applicable to all customers'
  }

  return (
    <>
      <Layout title="New Promo Code | FasTab">
        <div className="page-top">
          <h3 className="page-title">New Promo Code</h3>
          <div className="right">
            <Button
              variant="contained"
              color="primary"
              title="Back"
              isTypeLink
              to="/promocode"
              size="small"
            >
              <img src={BackArrow} alt="Back" />
              <span>Back</span>
            </Button>
          </div>
        </div>
        <div className="mb-30" data-aos="fade-up">
          <form onSubmit={formik.handleSubmit}>
            <div className="promo-add-content">
              <div className="left">
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <div className="card margin-10">
                      <div className="tooltip-container">
                        <span className="font-18">Discount Code</span>
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title="This field represents the name of the promo code you are creating. Guests can use this code to receive a discount on their total bill."
                          style={{ marginLeft: 'auto', marginTop: '0px' }}
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                      </div>
                      <Grid item md={8} xs={12}>
                        <div className="form-group">
                          <TextField
                            variant="outlined"
                            type="text"
                            label="Promo Code Name"
                            name="codeName"
                            value={formik.values?.codeName.toUpperCase()}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.codeName &&
                              formik.touched.codeName
                            }
                            helperText={
                              !!formik.errors.codeName &&
                              formik.touched.codeName
                                ? formik.errors.codeName
                                : ''
                            }
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <div className="card margin-10">
                      <div className="tooltip-container">
                        <span className="font-18">Restaurant</span>
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title="If this toggle is ON, the promo code will be restricted to a specific restaurant. You can select the desired restaurant from the dropdown menu. However, if it's OFF, the promo code will be applied to all available restaurants automatically."
                          style={{ marginLeft: 'auto', marginTop: '0px' }}
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                      </div>
                      <Grid item sm={8} xs={12}>
                        <div className="form-group">
                          <span className="enable-two-fa">
                            Restaurant Specific
                          </span>
                          {'  '}
                          <Switch
                            name="isRestaurantSpecific"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'isRestaurantSpecific',
                                e.target.checked
                              )
                            }}
                            defaultChecked={formik.values.isRestaurantSpecific}
                          />
                        </div>
                      </Grid>
                      {formik.values.isRestaurantSpecific && (
                        <Grid item sm={8} xs={12}>
                          <div className="form-group">
                            <Select
                              label="Restaurant"
                              disabled={false}
                              showError={
                                !!formik.errors.restaurantId &&
                                formik.touched.restaurantId
                              }
                              errorText={
                                !!formik.errors.restaurantId &&
                                formik.touched.restaurantId
                                  ? formik.errors.restaurantId
                                  : ''
                              }
                              handleSelectValue={(value) => {
                                formik.setFieldValue('restaurantId', value)
                              }}
                              formikValue={formik.values?.restaurantId}
                              items={restuarantList.map((d) => {
                                return {
                                  key: d.name,
                                  value: d.restaurantId,
                                }
                              })}
                            />
                          </div>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <div className="card margin-10">
                      <div className="tooltip-container">
                        <span className="font-18">Discount</span>
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title="Specify the total dollar amount or percentage that guests will save when using this promo code."
                          style={{ marginLeft: 'auto', marginTop: '0px' }}
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                      </div>
                      <Grid item sm={12} xs={12}>
                        <div className="form-group ">
                          <span className="enable-two-fa">
                            Fixed Amount Promocode
                          </span>
                          {'  '}
                          <Switch
                            name="isFixedAmountPromoCode"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'isFixedAmountPromoCode',
                                e.target.checked
                              )
                            }}
                          />
                        </div>
                      </Grid>

                      {formik.values.isFixedAmountPromoCode && (
                        <Grid item sm={8} xs={12}>
                          <div className="form-group">
                            <TextField
                              variant="outlined"
                              type="number"
                              label="Fixed Amount"
                              name="promocodeAmount"
                              value={formik.values.promocodeAmount}
                              onChange={formik.handleChange}
                              error={!!formik.errors.promocodeAmount}
                              helperText={
                                formik.errors.promocodeAmount
                                  ? formik.errors.promocodeAmount
                                  : ''
                              }
                              onBlur={formik.handleBlur}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ borderRight: '0px' }}
                                  >
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </Grid>
                      )}
                      {!formik.values.isFixedAmountPromoCode && (
                        <Grid item sm={8} xs={12}>
                          <div className="form-group">
                            <TextField
                              variant="outlined"
                              type="number"
                              label="Percentage"
                              name="promocodePercentage"
                              value={formik.values.promocodePercentage}
                              onChange={formik.handleChange}
                              error={
                                !!formik.errors.promocodePercentage &&
                                formik.touched.promocodePercentage
                              }
                              helperText={
                                !!formik.errors.promocodePercentage &&
                                formik.touched.promocodePercentage
                                  ? formik.errors.promocodePercentage
                                  : ''
                              }
                              onBlur={formik.handleBlur}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <div className="card margin-10">
                      <div className="tooltip-container">
                        <span className="font-18">
                          Minimum Purchase Requirements
                        </span>
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title="When enabled, you can set a minimum dollar amount that guests must spend to qualify for the discount code. They will be eligible to use the code only if their total bill meets or exceeds this specified amount."
                          style={{ marginLeft: 'auto', marginTop: '0px' }}
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                      </div>
                      <Grid item sm={8} xs={12}>
                        <div className="form-group">
                          <span className="enable-two-fa">
                            Set Minimum Order Requirement
                          </span>
                          {'  '}
                          <Switch
                            name="isMinimumOrderRequirement"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'isMinimumOrderRequirement',
                                e.target.checked
                              )
                            }}
                            defaultChecked={
                              formik.values.isMinimumOrderRequirement
                            }
                          />
                        </div>
                      </Grid>
                      {formik.values.isMinimumOrderRequirement && (
                        <Grid item sm={8} xs={12}>
                          <div className="form-group">
                            <TextField
                              variant="outlined"
                              type="number"
                              label="Minimum Order Amount"
                              name="minOrderAmount"
                              value={formik.values.minOrderAmount}
                              onChange={formik.handleChange}
                              error={
                                !!formik.errors.minOrderAmount &&
                                formik.touched.minOrderAmount
                              }
                              helperText={
                                !!formik.errors.minOrderAmount &&
                                formik.touched.minOrderAmount
                                  ? formik.errors.minOrderAmount
                                  : ''
                              }
                              onBlur={formik.handleBlur}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ borderRight: '0px' }}
                                  >
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <div className="card margin-10">
                      <div className="tooltip-container">
                        <span className="font-18">Customer Eligibility</span>
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title="This allows you to specify the type of customer base the promo code should apply to."
                          style={{ marginLeft: 'auto', marginTop: '0px' }}
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                      </div>
                      <Grid item sm={8} xs={12}>
                        <div className="form-group radio-group mb-0">
                          <Radio
                            color="primary"
                            value="all"
                            checked={
                              formik.values.customerEligibility === 'all'
                            }
                            onChange={() =>
                              formik.setFieldValue('customerEligibility', 'all')
                            }
                            name="percentage"
                          />
                          <span className="enable-two-fa">All</span>
                        </div>
                      </Grid>
                      {/* <Grid item sm={8} xs={12}>
                        <div className="form-group radio-group mb-0">
                          <Radio
                            color="primary"
                            value="loggedinuser"
                            checked={
                              formik.values.customerEligibility ===
                              'loggedinuser'
                            }
                            onChange={() =>
                              formik.setFieldValue(
                                'customerEligibility',
                                'loggedinuser'
                              )
                            }
                            name="percentage"
                          />
                          <span className="enable-two-fa">Logged In User</span>
                        </div>
                      </Grid> */}
                      <Grid item sm={8} xs={12}>
                        <div className="form-group radio-group">
                          <Radio
                            color="primary"
                            value="specific_customer"
                            checked={
                              formik.values.customerEligibility ===
                              'specific_customer'
                            }
                            onChange={() =>
                              formik.setFieldValue(
                                'customerEligibility',
                                'specific_customer'
                              )
                            }
                            name="percentage"
                          />
                          <span className="enable-two-fa">
                            Specific Customer based on visit
                          </span>
                        </div>
                      </Grid>
                      {formik.values.customerEligibility ===
                        'specific_customer' && (
                        <>
                          <Grid item sm={8} xs={12}>
                            <div className="form-group">
                              <TextField
                                variant="outlined"
                                type="number"
                                label="Number of visit"
                                name="minNoOfVisit"
                                value={formik.values.minNoOfVisit}
                                onChange={formik.handleChange}
                                error={
                                  !!formik.errors.minNoOfVisit &&
                                  formik.touched.minNoOfVisit
                                }
                                helperText={
                                  !!formik.errors.minNoOfVisit &&
                                  formik.touched.minNoOfVisit
                                    ? formik.errors.minNoOfVisit
                                    : ''
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </Grid>
                          <Grid item sm={8} xs={12}>
                            <div className="form-group">
                              <MultiSelect
                                label="Select Guest"
                                searchAble
                                showError={
                                  !!formik.errors.loginIds &&
                                  formik.touched.loginIds
                                }
                                errorText={
                                  !!formik.errors.loginIds &&
                                  formik.touched.loginIds
                                    ? 'Please select guest.'
                                    : ''
                                }
                                handleSelectValue={(value) => {
                                  formik.setFieldValue('loginIds', value)
                                }}
                                items={loginLists}
                              />
                            </div>
                          </Grid>
                        </>
                      )}
                    </div>
                  </Grid>
                  {/* {(formik.values.customerEligibility === 'specific_customer' ||
                    formik.values.customerEligibility === 'loggedinuser') && (
                      <>
                        <Grid item sm={12} xs={12}>
                          <div className="card margin-10">
                            <div className="tooltip-container">
                              <span className="font-18">
                                Auto Apply Promo Codes
                              </span>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="This will automatically applies the promo code to logged-in users or specific customers who have used FasTab a certain number of times. When the condition is met, the promo code will be automatically activated for eligible users without manual intervention."
                                style={{ marginLeft: 'auto', marginTop: '0px' }}
                              >
                                <InfoOutlinedIcon />
                              </Tooltip>
                            </div>

                            <Grid item sm={12} xs={12}>
                              <Grid item sm={8} xs={12}>
                                <div className="form-group">
                                  <TextField
                                    variant="outlined"
                                    type="number"
                                    label="Auto apply after certain number of payments"
                                    name="autoApplyAfterPayment"
                                    value={formik.values.autoApplyAfterPayment}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                      !!formik.errors.autoApplyAfterPayment &&
                                      formik.touched.autoApplyAfterPayment
                                    }
                                    helperText={
                                      !!formik.errors.autoApplyAfterPayment &&
                                        formik.touched.autoApplyAfterPayment
                                        ? formik.errors.autoApplyAfterPayment
                                        : ''
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </>
                    )} */}
                  <Grid item sm={12} xs={12}>
                    <div className="card margin-10">
                      <span className="font-18">Details</span>
                      <Grid item sm={8} xs={12}>
                        <div
                          className="form-group"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <TextField
                            variant="outlined"
                            type="number"
                            label="FasTab Promo Dollar Cap"
                            name="maxDiscountUses"
                            value={formik.values.maxDiscountUses}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.maxDiscountUses &&
                              formik.touched.maxDiscountUses
                            }
                            helperText={
                              !!formik.errors.maxDiscountUses &&
                              formik.touched.maxDiscountUses
                                ? formik.errors.maxDiscountUses
                                : ''
                            }
                            onBlur={formik.handleBlur}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ borderRight: '0px' }}
                                >
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="This is the maximum amount of discount that can be applied using this promo code. Once the total discount reaches this cap, it won't apply any further."
                          >
                            <InfoOutlinedIcon style={{ marginLeft: '5px' }} />
                          </Tooltip>
                        </div>
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <div
                          className="form-group"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <TextField
                            variant="outlined"
                            type="number"
                            label="FasTab Promo Use Cap"
                            name="maxNoOfDiscountUses"
                            value={formik.values.maxNoOfDiscountUses}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.maxNoOfDiscountUses &&
                              formik.touched.maxNoOfDiscountUses
                            }
                            helperText={
                              !!formik.errors.maxNoOfDiscountUses &&
                              formik.touched.maxNoOfDiscountUses
                                ? formik.errors.maxNoOfDiscountUses
                                : ''
                            }
                            onBlur={formik.handleBlur}
                          />
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="This is the maximum time this promo code can be used overall. Once the limit reaches this cap, it won't apply any further."
                          >
                            <InfoOutlinedIcon style={{ marginLeft: '5px' }} />
                          </Tooltip>
                        </div>
                      </Grid>
                      {(formik.values.customerEligibility ===
                        'specific_customer' ||
                        formik.values.customerEligibility ===
                          'loggedinuser') && (
                        <>
                          <Grid item sm={8} xs={12}>
                            <div
                              className="form-group"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                              }}
                            >
                              <TextField
                                variant="outlined"
                                type="number"
                                label="Maximum Uses per Guest"
                                name="maxLimitForUser"
                                value={formik.values.maxLimitForUser}
                                onChange={formik.handleChange}
                                error={
                                  !!formik.errors.maxLimitForUser &&
                                  formik.touched.maxLimitForUser
                                }
                                helperText={
                                  !!formik.errors.maxLimitForUser &&
                                  formik.touched.maxLimitForUser
                                    ? formik.errors.maxLimitForUser
                                    : ''
                                }
                                onBlur={formik.handleBlur}
                              />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="This identifies the total number of times an individual guest can use the promo code."
                              >
                                <InfoOutlinedIcon
                                  style={{ marginLeft: '5px' }}
                                />
                              </Tooltip>
                            </div>
                          </Grid>
                        </>
                      )}
                      <Grid item sm={12} xs={12}>
                        <Grid item sm={8} xs={12}>
                          <div
                            className="form-group"
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            <TextField
                              variant="outlined"
                              type="number"
                              label="Notification Threshold"
                              name="maxLimitForNotify"
                              value={formik.values.maxLimitForNotify}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                !!formik.errors.maxLimitForNotify &&
                                formik.touched.maxLimitForNotify
                              }
                              helperText={
                                !!formik.errors.maxLimitForNotify &&
                                formik.touched.maxLimitForNotify
                                  ? formik.errors.maxLimitForNotify
                                  : ''
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ borderRight: '0px' }}
                                  >
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="This enables you to set a specific dollar amount that, when reached, will trigger a symbol on the promo code dashboard. This alert informs you that a certain amount of dollars has been spent using the promo code."
                            >
                              <InfoOutlinedIcon style={{ marginLeft: '5px' }} />
                            </Tooltip>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4}>
                        <Grid item sm={4} md={4} xs={6}>
                          <div className="form-group">
                            <Datepicker
                              datePickerlabel="Start Date"
                              isPromoCodePage
                              value={promoStartDate || new Date()}
                              minimumDate={new Date()}
                              onChange={(date) => {
                                if (date) {
                                  formik.setFieldValue(
                                    'startDate',
                                    dateToIsoString(
                                      convertUTCDateToLocalDate(date)
                                    )
                                  )
                                  setPromoStartDate(
                                    convertUTCDateToLocalDate(
                                      date
                                    ).toISOString()
                                  )
                                  console.log(
                                    convertUTCDateToLocalDate(
                                      date
                                    ).toISOString()
                                  )
                                  console.log(
                                    dateToIsoString(
                                      convertUTCDateToLocalDate(date)
                                    )
                                  )
                                } else {
                                  formik.setFieldValue('startDate', null)
                                  setPromoStartDate(null)
                                }
                              }}
                              setFilterSelectedDate={setPromoStartDate}
                            />
                            {formik.values.startDate === null && (
                              <p className="error-css">
                                Please enter valid date.
                              </p>
                            )}
                          </div>
                        </Grid>
                        <Grid item sm={4} md={4} xs={6}>
                          <div className="form-group">
                            <Datepicker
                              datePickerlabel="Expire Date"
                              isPromoCodePage
                              value={
                                promoEndDate ||
                                dateToIsoString(
                                  convertUTCDateToLocalDate(getNextDay())
                                )
                              }
                              onChange={(date) => {
                                if (date) {
                                  formik.setFieldValue(
                                    'expireDate',
                                    dateToIsoString(
                                      convertUTCDateToLocalDate(date)
                                    )
                                  )
                                  setPromoEndDate(
                                    convertUTCDateToLocalDate(
                                      date
                                    ).toISOString()
                                  )
                                } else {
                                  formik.setFieldValue('expireDate', null)
                                  setPromoEndDate(null)
                                }
                              }}
                              setFilterSelectedDate={setPromoEndDate}
                            />
                            {formik.values.expireDate === null && (
                              <p className="error-css">
                                Please enter valid date.
                              </p>
                            )}
                            {formik.values.expireDate !== null &&
                              formik.values.startDate !== null &&
                              Date.parse(formik.values.expireDate) <=
                                Date.parse(formik.values.startDate) && (
                                <p className="error-css">
                                  Expire time cannot be before start time.
                                </p>
                              )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <div
                          className="form-group"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <TextField
                            variant="outlined"
                            multiline
                            rows={3}
                            type="text"
                            label="Description"
                            name="description"
                            value={formik.values?.description}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.description &&
                              formik.touched.description
                            }
                            helperText={
                              !!formik.errors.description &&
                              formik.touched.description
                                ? formik.errors.description
                                : ''
                            }
                            onBlur={formik.handleBlur}
                          />
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="This description will be visible to guests when the promo code is applied. It must precisely match the discount they will receive after the promo code is successfully applied. The eligibility for using the promo code will be determined based on the rules selected above, ensuring a seamless experience for the guests."
                          >
                            <InfoOutlinedIcon style={{ marginLeft: '5px' }} />
                          </Tooltip>
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <div className="card margin-10">
                      <span className="font-18">Performance</span>
                      <Grid item sm={6} xs={12}>
                        <div className="form-group">
                          <Checkbox
                            color="primary"
                            checked={formik.values.isActive}
                            onChange={formik.handleChange}
                            name="isActive"
                          />{' '}
                          <span className="enable-two-fa">Is Active</span>
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <div className="btn-list ml-10">
                  <Button
                    variant="contained"
                    color="primary"
                    title="Add"
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    title="Cancel"
                    variant="outlined"
                    color="primary"
                    isTypeLink
                    to="/promocode"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <div className="right" style={{ marginTop: '10px' }}>
                <Grid className="card" container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Grid item md={8} xs={12}>
                      <div>
                        <Typography variant="h5" fontWeight="bold">
                          Summary
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <div>
                        <Typography variant="h6">
                          <span className="text-blue">
                            {formik.values.codeName.trim().toUpperCase() ||
                              'No promo code yet'}
                          </span>
                        </Typography>
                      </div>
                    </Grid>
                    <hr />
                    <Grid item md={12} xs={12}>
                      <div className="promo-list-details">
                        <Typography variant="h6">Details</Typography>
                        <List
                          sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            '& .MuiListItem-root': {
                              display: 'list-item',
                            },
                          }}
                        >
                          {formik.values.isRestaurantSpecific ? (
                            <ListItem>
                              {`Promo Code is applicable at `}
                              <span className="text-blue">
                                {restuarantList.find(
                                  (d) =>
                                    d.restaurantId ===
                                    formik.values?.restaurantId
                                )?.name || '-'}
                              </span>
                            </ListItem>
                          ) : (
                            <ListItem>
                              Promo Code is applicable at{` `}
                              <span className="text-blue">all restaurants</span>
                            </ListItem>
                          )}
                          {formik.values.isFixedAmountPromoCode ? (
                            <ListItem>
                              <span className="text-blue">
                                {`$${formik.values?.promocodeAmount ?? 0} `}
                              </span>
                              off entire order
                            </ListItem>
                          ) : (
                            <ListItem>
                              <span className="text-blue">
                                {formik.values?.promocodePercentage ?? 0}%
                              </span>
                              {` off entire order`}
                            </ListItem>
                          )}
                          {formik.values.isMinimumOrderRequirement ? (
                            <ListItem>
                              {`Minimum purchase of `}{' '}
                              <span className="text-blue">
                                ${formik.values?.minOrderAmount ?? 0}
                              </span>
                            </ListItem>
                          ) : (
                            <ListItem>No minimum purchase requirement</ListItem>
                          )}
                          <ListItem>
                            <span className="text-blue">{content}</span>
                          </ListItem>
                          {/* <ListItem>
                            Promo code will be auto-applied after
                            <span className="text-blue">
                              {` `}
                              {formik.values?.autoApplyAfterPayment ?? 0}{' '}
                            </span>
                            {` payments`}
                          </ListItem> */}
                          {formik.values?.maxDiscountUses != null && (
                            <ListItem>
                              {`Maximum discount usage amount is `}
                              <span className="text-blue">
                                ${formik.values?.maxDiscountUses ?? 0}
                              </span>
                            </ListItem>
                          )}
                          {formik.values?.maxNoOfDiscountUses != null && (
                            <ListItem>
                              {`Maximum number of discount usage is `}
                              <span className="text-blue">
                                {formik.values?.maxNoOfDiscountUses ?? 0}
                              </span>
                              {` times`}
                            </ListItem>
                          )}
                          {formik.values?.maxLimitForUser != null && (
                            <ListItem>
                              Applicable to use{` `}
                              <span className="text-blue">
                                {formik.values?.maxLimitForUser ?? 0}
                              </span>
                              {` time per customer (only applicable if promo code is customer specific)`}
                            </ListItem>
                          )}
                          {formik.values?.maxLimitForNotify != null && (
                            <ListItem>
                              FasTab will get notified after{` `}
                              <span className="text-blue">
                                ${formik.values?.maxLimitForNotify ?? 0}
                              </span>
                              {` has been spent on this promo code`}
                            </ListItem>
                          )}
                          <ListItem>
                            {`Promo code will be valid from `}
                            <span className="text-blue">
                              {moment(new Date(formik.values?.startDate))
                                .utc()
                                .format('D MMMM YYYY')}
                            </span>
                            {`  to `}
                            <span className="text-blue">
                              {moment(new Date(formik.values?.expireDate))
                                .utc()
                                .format('D MMMM YYYY')}
                            </span>
                            {` payments`}
                          </ListItem>
                        </List>
                      </div>
                    </Grid>
                    <hr />
                    <Grid item md={12} xs={12}>
                      <div className="promo-list-details">
                        <Typography variant="h6">Description</Typography>
                        <List>
                          <ListItem>
                            <span className="text-blue">{`${
                              formik.values?.description.trim() ||
                              'No description yet'
                            }`}</span>
                          </ListItem>
                        </List>
                      </div>
                    </Grid>
                    <hr />
                    <Grid item md={12} xs={12}>
                      <div className="promo-list-details">
                        <Typography variant="h6">Performance</Typography>
                        <List>
                          {formik.values?.isActive ? (
                            <ListItem>
                              Discount is&nbsp;
                              <span className="text-blue">Active</span>
                            </ListItem>
                          ) : (
                            <ListItem>
                              Discount is&nbsp;
                              <span className="text-blue">
                                not active&nbsp;
                              </span>
                              {` yet`}
                            </ListItem>
                          )}
                        </List>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </form>
        </div>
      </Layout>
    </>
  )
}

export default AddEditPromoCode
