import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { resolveHTTP } from 'utility/helper'
import Textfield from 'components/Textfield/Index'
import { IDisbursementFileRequest } from 'interfaces/disbursement'
import { GridSelectionModel } from '@mui/x-data-grid-pro'
import Datepicker from 'components/Datepicker/Index'
import disbursementService from 'services/disbursement.service'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { selectedEstablishment } from 'features/establishment/establishment'
import { useSelector } from 'react-redux'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  selectedRestaurants: GridSelectionModel
  selectedEstablishmets: GridSelectionModel
  closeDialog?: (event: boolean) => void
  weekStartDate?: string
  weekEndDate?: string
  isCurrent: boolean
}

function AddNoteDialog(props: dialogProps) {
  const {
    open,
    onClose,
    selectedRestaurants,
    selectedEstablishmets,
    closeDialog,
    weekStartDate,
    weekEndDate,
    isCurrent,
  } = props
  const [disbursementFilterSelectedDate, setDisbursementFilterSelectedDate] =
    useState(null)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const exportCSVFile = (
    headers: {
      Name: string // remove commas to avoid errors
      EmailAddress: string
      WebsiteLink: string
      CreatedDate: string
    },
    csv:
      | string
      | Blob
      | import('../../interfaces/restaurant').ICsvDownload
      | ArrayBufferView
      | ArrayBuffer,
    fileTitle: string
  ) => {
    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

    const blob = new Blob([csv as BlobPart], {
      type: 'text/csv;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
    closeDialog(false)
  }

  const downloadFile = async (values: IDisbursementFileRequest) => {
    const data = await disbursementService.generateDisbursementFile({
      restaurantIds: selectedRestaurants.map((d) => Number(d)),
      establishmentIds: selectedEstablishmets.map((d) => Number(d)),
      paymentDate: values.paymentDate,
      paymentDetails: values.paymentDetails,
      weekStartDate: new Date(weekStartDate).toDateString(),
      weekEndDate: new Date(weekEndDate).toDateString(),
      globalFilterRestaurantId: selectedRestaurantId,
      globalFilterEstablishmentId: selectedEstablishmentId,
      isCurrent,
    })
    const date = new Date(values.paymentDate)
    const year = date.getFullYear()
    const month = (1 + date.getMonth()).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    if (data.data.data) {
      await disbursementService.saveFileDownloadHistory({
        fileName: `FT_Disbursement_${month}${day}${year}_${date.getHours()}${date.getMinutes()}`,
        loginId: +localStorage.getItem('loginId'),
      })
    }

    const headers = {
      Name: 'Name'.replace(/,/g, ''), // remove commas to avoid errors
      EmailAddress: 'Email'.replace(/,/g, ''),
      WebsiteLink: 'Website'.replace(/,/g, ''),
      CreatedDate: 'Date'.replace(/,/g, ''),
    }

    const fileTitle = `FT_Disbursement_${month}${day}${year}_${date.getHours()}${date.getMinutes()}`
    exportCSVFile(headers, data.data.data, fileTitle)
  }
  const getnextBusinessDay = (date: Date) => {
    const tomorrow = new Date(date.setDate(date.getDate() + 1))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return tomorrow.getDay() % 6 ? tomorrow : getnextBusinessDay(tomorrow)
  }
  const formik = useFormik<IDisbursementFileRequest>({
    initialValues: {
      restaurantIds: [],
      establishmentIds: [],
      paymentDate:
        disbursementFilterSelectedDate || getnextBusinessDay(new Date()),
      paymentDetails: '',
      weekStartDate: '',
      weekEndDate: '',
      isCurrent,
    },
    onSubmit: (values) => downloadFile(values),
  })

  const getWednesdayOfCurrentWeek = (defaultNote) => {
    const curr = new Date() // get current date
    const final = new Date(curr.setDate(curr.getDate() - curr.getDay()))
    final.setDate(final.getDate() + 3)
    const year = final.getFullYear()
    const month = (1 + final.getMonth()).toString().padStart(2, '0')
    const day = final.getDate().toString().padStart(2, '0')
    defaultNote = defaultNote.replace('MM', month)
    defaultNote = defaultNote.replace('DD', day)
    defaultNote = defaultNote.replace('YYYY', year)
    formik.setFieldValue('paymentDetails', defaultNote)
  }

  const getDefaultNote = async () => {
    const [data] = await resolveHTTP(
      disbursementService.getDefaultNoteForPayment()
    )
    if (data) {
      getWednesdayOfCurrentWeek(data.data.paymentNotes)
    }
  }

  useEffect(() => {
    getDefaultNote()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // convert date to local time
  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60 * 1000
    )
    return newDate
  }

  return (
    <Dialog
      className="primary-dialog"
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
        onClose(e)
        formik.resetForm()
        getDefaultNote()
      }}
    >
      <DialogTitle>
        <h5>Payment Details</h5>
        <Button
          variant="text"
          title="Close"
          onClick={(e) => {
            onClose(e)
            formik.resetForm()
            getDefaultNote()
          }}
          className="icon-btn rounded"
          color="inherit"
        >
          <img src={Close} alt="Close" />
        </Button>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent className="pb-0">
          <div className="form-group">
            <Textfield
              variant="outlined"
              type="text"
              label="Note"
              name="paymentDetails"
              handleBlur={formik.handleBlur}
              value={formik.values?.paymentDetails}
              onChange={formik.handleChange}
              error={
                !!formik.errors.paymentDetails && formik.touched.paymentDetails
              }
              helperText={
                !!formik.errors.paymentDetails && formik.touched.paymentDetails
                  ? formik.errors.paymentDetails
                  : ''
              }
            />
          </div>
          <div className="form-group">
            <Datepicker
              datePickerlabel="Date"
              value={
                disbursementFilterSelectedDate || getnextBusinessDay(new Date())
              }
              onChange={(date) => {
                formik.setFieldValue(
                  'paymentDate',
                  convertUTCDateToLocalDate(date).toISOString()
                )
                setDisbursementFilterSelectedDate(
                  convertUTCDateToLocalDate(date).toISOString()
                )
              }}
              setFilterSelectedDate={setDisbursementFilterSelectedDate}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Download"
              type="submit"
            >
              Download
            </Button>
            <Button
              onClick={(e) => {
                onClose(e)
                formik.resetForm()
                setDisbursementFilterSelectedDate(null)
                getDefaultNote()
              }}
              title="Cancel"
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddNoteDialog
