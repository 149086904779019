import { useState, ReactNode } from 'react'
import ComingSoon from './ComingSoon'

interface StatDetailProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title: string
  description: ReactNode
  content: ReactNode
  comingSoon?: boolean
}

function StatDetail({
  title,
  description,
  content,
  comingSoon,
}: StatDetailProps) {
  const [showDescription, setShowDescription] = useState<boolean>(false)

  const toggleEarnings = () => {
    setShowDescription(!showDescription)
  }

  if (comingSoon) {
    return (
      <div className="stats-info">
        <div className="stat-title">
          <h3>{title}</h3>
        </div>
        <ComingSoon />
      </div>
    )
  }

  return (
    <div className="stats-info">
      <div className="stat-title">
        <h3>{title}</h3>
        <button
          type="button"
          className="stat-more-info"
          onClick={() => toggleEarnings()}
        >
          i
        </button>
      </div>
      {showDescription ? <>{description}</> : <>{content}</>}
    </div>
  )
}

export default StatDetail
