/* eslint-disable no-nested-ternary */
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import {
  IPaymentDetailRequest,
  IPaymentDetailResponse,
} from 'interfaces/payments'
import React, { useCallback, useEffect, useState } from 'react'
import Moment from 'react-moment'
import paymentService from 'services/payment.service'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { useSelector } from 'react-redux'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  paymentData?: { paymentId: number; posOrderId?: string }
}

function PaymentDetailsDialog(props: dialogProps) {
  const { open, onClose, paymentData } = props
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const [paymentDetail, setPaymentDetail] =
    useState<IPaymentDetailResponse>(null)

  const getPaymentDetail = useCallback(
    async (payload: IPaymentDetailRequest) => {
      try {
        const data = await paymentService.getPaymentDetail(payload)
        setPaymentDetail(data.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getPaymentDetailByOrder = useCallback(
    async (payload: IPaymentDetailRequest) => {
      try {
        const data = await paymentService.getPaymentDetailByOrder(payload)
        setPaymentDetail(data.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (paymentData) {
      if (paymentData.posOrderId) {
        getPaymentDetailByOrder({
          tabPaymentId: paymentData.paymentId,
          posOrderId: paymentData.posOrderId,
          globalFilterRestaurantId: selectedRestaurantId,
        })
      } else {
        getPaymentDetail({
          tabPaymentId: paymentData.paymentId,
          posOrderId: paymentData.posOrderId,
          globalFilterRestaurantId: selectedRestaurantId,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData])

  // const setLocalStorageParamsForPayouts = () => {
  //   localStorage.setItem('isfromPayment', 'true')
  //   localStorage.setItem('createdDate', startDate)
  //   localStorage.setItem('createdEndDate', endDate)
  //   // test
  //   localStorage.setItem('payEdgeId', paymentDetail.payEdgeOrderId.toString())
  // }
  return (
    <>
      {paymentDetail && (
        <Dialog
          className="primary-dialog details-dialog"
          maxWidth="xl"
          fullWidth
          open={open}
          onClose={onClose}
        >
          <DialogTitle>
            <h5>Payment Details</h5>
            <Button
              variant="text"
              title="Close"
              onClick={onClose}
              className="icon-btn rounded"
              color="inherit"
            >
              <img src={Close} alt="Close" />
            </Button>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item lg={2} md={4} xs={6}>
                <div className="detail-info">
                  <span>Tab Id</span>

                  <strong>{paymentDetail.tabId || '-'}</strong>
                </div>
              </Grid>
              <Grid item lg={2} md={4} xs={6}>
                <div className="detail-info">
                  <span>Subtotal</span>
                  <strong>
                    {paymentDetail.subTotal
                      ? `$${(
                          Math.round(Number(paymentDetail.subTotal) * 100) / 100
                        ).toFixed(2)}`
                      : `$0.00`}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={2} md={4} xs={6}>
                <div className="detail-info">
                  <span>Tip</span>
                  <strong>
                    {paymentDetail.totalTip
                      ? `$${(
                          Math.round(Number(paymentDetail.totalTip) * 100) / 100
                        ).toFixed(2)}`
                      : `$0.00`}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={2} md={4} xs={6}>
                <div className="detail-info">
                  <span>Tax</span>
                  <strong>
                    {paymentDetail.totalTax
                      ? `$${(
                          Math.round(Number(paymentDetail.totalTax) * 100) / 100
                        ).toFixed(2)}`
                      : `$0.00`}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={2} md={4} xs={6}>
                <div className="detail-info">
                  <span>Service Charge</span>
                  <strong>
                    {paymentDetail.totalServiceCharge
                      ? `$${(
                          Math.round(
                            Number(paymentDetail.totalServiceCharge) * 100
                          ) / 100
                        ).toFixed(2)}`
                      : `$0.00`}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={3} md={4} xs={6}>
                <div className="detail-info">
                  <span>Total</span>
                  <strong>
                    {paymentDetail.amount
                      ? `$${(
                          Math.round(Number(paymentDetail.amount) * 100) / 100
                        ).toFixed(2)}`
                      : '$0.00'}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={2.5} md={4} xs={6}>
                <div className="detail-info">
                  <span>Payment Type</span>
                  <strong>
                    {paymentDetail.paymentTypeName
                      ? paymentDetail.paymentTypeName
                      : '-'}
                  </strong>
                </div>
              </Grid>

              <Grid item lg={2.5} md={4} xs={6}>
                <div className="detail-info">
                  <span>Card Type</span>
                  <strong>
                    {paymentDetail.cardType ? paymentDetail.cardType : '-'}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={4} md={4} xs={6}>
                <div className="detail-info">
                  <span>Date & Time</span>
                  <strong>
                    {paymentDetail.createdDate ? (
                      <Moment format="MM/DD/YYYY hh:mm A">
                        {paymentDetail.createdDate}
                      </Moment>
                    ) : (
                      '-'
                    )}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={3} md={4} xs={6}>
                <div className="detail-info">
                  <span>PayEdge Order Id</span>
                  <strong>
                    {paymentDetail.payEdgeOrderId
                      ? // <Link
                        //   to="/payouts"
                        //   onClick={() => setLocalStorageParamsForPayouts()}
                        //   style={{
                        //     color: '#008cff',
                        //   }}
                        //   target="_blank"
                        // >
                        paymentDetail.payEdgeOrderId
                        ? paymentDetail.payEdgeOrderId
                        : '-'
                      : // {/* </Link> */}
                        '-'}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={2.5} md={6} xs={6}>
                <div className="detail-info">
                  <span>First Name</span>
                  <strong>
                    {paymentDetail
                      ? paymentDetail.paymentSource === 'POS'
                        ? 'POS'
                        : paymentDetail.firstName
                        ? paymentDetail.firstName
                        : '-'
                      : '-'}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={2.5} md={6} xs={6}>
                <div className="detail-info">
                  <span>Last Name</span>
                  <strong>
                    {paymentDetail
                      ? paymentDetail.paymentSource === 'POS'
                        ? 'POS'
                        : paymentDetail.lastName
                        ? paymentDetail.lastName
                        : '-'
                      : '-'}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={4} md={6} xs={6}>
                <div className="detail-info">
                  <span>Email Id</span>
                  <strong>
                    {paymentDetail
                      ? paymentDetail.paymentSource === 'POS'
                        ? `pos-${paymentDetail.restaurantName}@fastabpayments.com`
                        : paymentDetail.emailAddress
                        ? paymentDetail.emailAddress
                        : '-'
                      : '-'}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={3} md={6} xs={6}>
                <div className="detail-info">
                  <span>Registered Guest?</span>
                  <strong>
                    {paymentDetail.isRegisteredUser ? 'Yes' : 'No'}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={5} md={6} xs={6}>
                <div className="detail-info">
                  <span>Transaction Id</span>
                  <strong>
                    {paymentDetail
                      ? paymentDetail.transactionId
                        ? paymentDetail.transactionId
                        : '-'
                      : '-'}
                  </strong>
                </div>
              </Grid>
              <Grid item lg={6} md={6} xs={6}>
                <div className="detail-info">
                  <span>Stripe Payout Id</span>
                  <strong>
                    {paymentDetail
                      ? paymentDetail.stripePayoutId
                        ? paymentDetail.stripePayoutId
                        : '-'
                      : '-'}
                  </strong>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default PaymentDetailsDialog
