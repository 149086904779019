/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Switch from 'components/Switch/Index'
import Textfield from 'components/Textfield/Index'
import React, { useState, useEffect, useCallback } from 'react'
import menuService from 'services/menu.service'
import { ICategoryItemUpdate, IMenuSearchResponse } from 'interfaces/menu'
import { useDispatch } from 'react-redux'
import { addMenuItem, updateMenuItem } from 'features/menu/menu'
import { toast } from 'react-toastify'

interface dialogProps {
  open: boolean
  categoryId?: number
  categoryList?: ICategoryItemUpdate[]
  closeDialog?: (event: boolean) => void
  isEdit?: boolean
  menuId?: number
  currentMenuId?: number
  getNewList?: () => void
  menu?: IMenuSearchResponse[]
}

function AddEditCategoryItemDialog(props: dialogProps) {
  const {
    open,
    categoryId,
    categoryList,
    closeDialog,
    isEdit,
    menuId,
    getNewList,
    menu,
    currentMenuId,
  } = props
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [price, setPrice] = useState(null)
  const [description, setDescription] = useState('')
  const [activeCat, setActive] = useState(true)
  const [nameError, setNameError] = useState(false)
  const [lengthError, setLengthError] = useState(false)
  const [priceError, setPriceError] = useState(false)
  const [descError, setDescError] = useState(false)
  const [data, setData] = useState<ICategoryItemUpdate>()
  const getMenuLists = useCallback(
    async (menuItemId: number) => {
      try {
        const getData = await menuService.getItembyItemID({
          menuItemId,
        })
        setData(getData.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuId]
  )

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    menuId != null && open === true && getMenuLists(menuId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const SaveItem = async () => {
    let currentAddedItem
    if (isEdit) {
      if (
        data.name !== '' &&
        data.price !== null &&
        data.description !== '' &&
        data.name.length < 100
      ) {
        await menuService.updateItemMenu(data)
        toast.success('Menu item updated successfully')
        setNameError(false)
        setDescError(false)
        setLengthError(false)
        setPriceError(false)
        if (!isEdit) {
          setData({})
        }
        closeDialog(false)
      } else {
        if (data.name === '') {
          setNameError(true)
        } else if (data.name.length > 100) {
          setLengthError(true)
        } else if (data.price === null) {
          setPriceError(true)
        } else if (data.description === '') {
          setDescError(true)
        }
      }
      getNewList()
    } else {
      if (
        name !== '' &&
        price !== null &&
        price !== '' &&
        description !== '' &&
        name.length < 100
      ) {
        currentAddedItem = await menuService.creatCatItem({
          menuCategoryId: categoryId,
          name,
          position: categoryList.length + 1,
          isActive: activeCat,
          price,
          description,
        })
        toast.success('Menu item added successfully')
        setNameError(false)
        setDescError(false)
        setLengthError(false)
        setPriceError(false)
        setName('')
        setPrice('')
        setDescription('')
        closeDialog(false)
        getNewList()
      } else {
        if (name === '') {
          setNameError(true)
        } else if (name.length > 100) {
          setLengthError(true)
        } else if (price === null || price === '') {
          setPriceError(true)
        } else if (description === '') {
          setDescError(true)
        }
      }
    }
    const currentMenuIndex = menu.findIndex((d) => d.menuId === currentMenuId)
    // eslint-disable-next-line prefer-const
    let currentMenuData = menu[currentMenuIndex]
    const categoryIndex = currentMenuData?.menuCategories?.findIndex(
      (d) => d.menuCategoryId === categoryId
    )
    const currentMenuCategoryData =
      currentMenuData?.menuCategories[categoryIndex]
    // eslint-disable-next-line prefer-const
    if (!isEdit) {
      dispatch(
        addMenuItem({
          menuid: currentMenuId,
          categoryIndex,
          categoryItemDetails: {
            menuItemId: currentAddedItem.data.data.menuItemId,
            menuCategoryId: categoryId,
            name,
            position: categoryList.length + 1,
            isActive: activeCat,
            price: Number(price),
            description,
          },
        })
      )
    }

    // eslint-disable-next-line prefer-const

    if (isEdit) {
      const itemIndex = currentMenuCategoryData.menuItems.findIndex(
        (d) => d.menuItemId === data.menuItemId
      )
      dispatch(
        updateMenuItem({
          menuid: currentMenuId,
          categoryIndex,
          itemIndex,
          isActive: data.isActive,
          itemName: data.name,
          price: data.price,
          description: data.description,
        })
      )
    }
  }

  const handelChangeEvent = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (isEdit) {
      if (e.target.name === 'name') {
        data.name = e.target.value
        // eslint-disable-next-line no-lone-blocks
        {
          // eslint-disable-next-line no-unused-expressions
          e.target.value && setNameError(false)
        }
      } else if (e.target.name === 'price') {
        let priceValue = null
        if (e.target.value !== '') {
          priceValue = Number(e.target.value)
        }
        data.price = priceValue
        // eslint-disable-next-line no-lone-blocks
        {
          // eslint-disable-next-line no-unused-expressions
          e.target.value && setPriceError(false)
        }
      } else if (e.target.name === 'description') {
        data.description = e.target.value
        // eslint-disable-next-line no-lone-blocks
        {
          // eslint-disable-next-line no-unused-expressions
          e.target.value && setDescError(false)
        }
      }
    } else if (e.target.name === 'name') {
      setName(e.target.value)
      // eslint-disable-next-line no-lone-blocks
      {
        // eslint-disable-next-line no-unused-expressions
        e.target.value && setNameError(false)
      }
    } else if (e.target.name === 'price') {
      setPrice(e.target.value)
      // eslint-disable-next-line no-lone-blocks
      {
        // eslint-disable-next-line no-unused-expressions
        e.target.value && setPriceError(false)
      }
    } else if (e.target.name === 'description') {
      setDescription(e.target.value)
      // eslint-disable-next-line no-lone-blocks
      {
        // eslint-disable-next-line no-unused-expressions
        e.target.value && setDescError(false)
      }
    }
  }

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActive(e.target.checked)
  }

  const closeDialogBox = () => {
    setNameError(false)
    setLengthError(false)
    setDescError(false)
    setPriceError(false)
    closeDialog(false)
  }
  return (
    <>
      <Dialog
        className="primary-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={closeDialogBox}
      >
        <DialogTitle>
          <h5>{isEdit ? 'Save' : 'Add'} Item</h5>
          <Button
            variant="text"
            title="Close"
            onClick={closeDialogBox}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        {/* dialog-content start */}
        <DialogContent>
          <div className="form-group">
            <Textfield
              variant="outlined"
              label="Item Name"
              name="name"
              key={isEdit ? data?.name : ''}
              defaultValue={isEdit ? data?.name : ''}
              onChange={(e) => handelChangeEvent(e)}
              error={nameError || lengthError}
              helperText={
                nameError
                  ? 'Item name is required'
                  : lengthError
                  ? 'Item name length should be less than 100'
                  : ''
              }
            />
          </div>
          <div className="form-group">
            <Textfield
              variant="outlined"
              label="Price"
              name="price"
              type="number"
              key={isEdit ? data?.price?.toFixed(2) : ''}
              defaultValue={isEdit ? data?.price?.toFixed(2) : ''}
              onChange={(e) => handelChangeEvent(e)}
              error={priceError}
              helperText={priceError ? 'Item price is required' : ''}
            />
          </div>
          <div className="form-group">
            <Textfield
              variant="outlined"
              multiline
              rows={3}
              label="Item Description"
              name="description"
              key={isEdit ? data?.description : ''}
              defaultValue={isEdit ? data?.description : ''}
              onChange={(e) => handelChangeEvent(e)}
              error={descError}
              helperText={descError ? 'Item description is required' : ''}
            />
          </div>
          {!isEdit && (
            <Switch
              label="Visibility"
              placement="start"
              name="active"
              onChange={(e) => handleSwitchChange(e)}
              defaultChecked
              key={isEdit ? data?.description : ''}
            />
          )}
        </DialogContent>
        {/* dialog-content end */}
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title={isEdit ? 'Save' : 'Add'}
              onClick={SaveItem}
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
            <Button
              onClick={closeDialogBox}
              title="Cancel"
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddEditCategoryItemDialog
