/* eslint-disable no-nested-ternary */
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro'
import { Close, View } from 'assets/images'
import Button from 'components/Button/Index'
import DataTable from 'components/DataTable/Index'
import PaymentDetailsDialog from 'components/PaymentDetailsDialog/Index'
import { IDatagrid } from 'interfaces/datagrid'
import { IMultiPaymentsSearchRequest } from 'interfaces/payments'
import React, { useCallback, useEffect, useState } from 'react'
import Moment from 'react-moment'
import paymentService from 'services/payment.service'
import CryptoJS from 'crypto-js'
import { ROLE_SECRET_KEY } from 'utility/constants'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { useSelector } from 'react-redux'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  paymentId?: number
}

function MultiPaymentDetailsDialog(props: dialogProps) {
  const { open, onClose, paymentId } = props
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: '',
        sort: 'asc',
      },
    ],
  })
  const [multiPaymentList, setMultiPaymentList] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [paymentData, setPaymentData] =
    useState<{ paymentId: number; posOrderId: string }>()
  const [openPaymentDetailsDialog, setOpenPaymentDetailsDialog] =
    useState(false)
  const [roleId, setRoleId] = useState(0)

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }
  const getMultiPaymentList = useCallback(
    async (payload: IMultiPaymentsSearchRequest) => {
      try {
        const data = await paymentService.getMultiPaymentsList(payload)
        setMultiPaymentList(data.data.data.data)
        setTotalRecords(data.data.data.totalRecords)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const closePaymentDetailsDialog = () => {
    setOpenPaymentDetailsDialog(false)
  }
  useEffect(() => {
    if (open) {
      getMultiPaymentList({
        paymentId,
        pageSize: dataGridOptions.pageSize,
        pageNo: dataGridOptions.pageNumber,
        globalFilterRestaurantId: selectedRestaurantId,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions, open])
  useEffect(() => {
    if (localStorage.getItem('roleId')) {
      setRoleId(
        +CryptoJS.AES.decrypt(
          localStorage.getItem('roleId'),
          ROLE_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      )
    }
  }, [])

  return (
    <>
      <Dialog
        className="primary-dialog details-dialog"
        maxWidth={false}
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <h5>Payments</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent>
          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="omnivoreOrderId"
              columnsData={[
                {
                  field: 'paymentId',
                  headerName: 'Payment Id',
                  flex: 1,
                  minWidth: 120,
                },
                {
                  field: 'omnivoreOrderId',
                  headerName: 'POS Ordrer Id',
                  flex: 1,
                  minWidth: 120,
                  renderCell: (d: GridRenderCellParams<string>) => {
                    return d.value === 'Multi' ? (
                      <Button
                        className="icon-btn no-border rounded"
                        variant="text"
                        color="primary"
                        // onClick={() => {
                        //   setOpenMultiPaymentDetailsDialog(true)
                        // }}
                      >
                        Multi
                      </Button>
                    ) : (
                      d.value
                    )
                  },
                },
                {
                  field: 'publicTabId',
                  headerName: 'Tab Id',
                  flex: 1,
                  minWidth: 100,
                },
                {
                  field: 'createdDate',
                  headerName: 'Date & Time',
                  flex: 1,
                  minWidth: 190,
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <Moment format="MM/DD/YYYY hh:mm A">
                        {params.value}
                      </Moment>
                    )
                  },
                },
                {
                  field: 'subTotal',
                  headerName: 'Subtotal',
                  flex: 1,
                  minWidth: 120,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return `$${(
                      Math.round(Number(params.value) * 100) / 100
                    ).toFixed(2)}`
                  },
                },
                {
                  field: 'totalTip',
                  headerName: 'Tip',
                  flex: 1,
                  minWidth: 70,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return `$${(
                      Math.round(Number(params.value) * 100) / 100
                    ).toFixed(2)}`
                  },
                },
                {
                  field: 'totalTax',
                  headerName: 'Tax',
                  flex: 1,
                  minWidth: 70,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return `$${(
                      Math.round(Number(params.value) * 100) / 100
                    ).toFixed(2)}`
                  },
                },
                {
                  field: 'totalServiceCharge',
                  headerName: 'Service Charge',
                  flex: 1,
                  minWidth: 70,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return params.value
                      ? `$${(
                          Math.round(Number(params.value) * 100) / 100
                        ).toFixed(2)}`
                      : '$0.00'
                  },
                },
                {
                  field: 'amount',
                  headerName: 'Total',
                  flex: 1,
                  minWidth: 80,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return `$${(
                      Math.round(Number(params.value) * 100) / 100
                    ).toFixed(2)}`
                  },
                },
                Number(roleId) &&
                  Number(roleId) === 1 && {
                    align: 'center',
                    headerAlign: 'center',
                    field: 'action',
                    disableColumnMenu: true,
                    headerName: 'Action',
                    sortable: false,
                    renderCell: (d: GridRenderCellParams<number>) => {
                      return (
                        <Button
                          className="icon-btn no-border rounded"
                          variant="text"
                          color="inherit"
                          onClick={() => {
                            setOpenPaymentDetailsDialog(true)
                            setPaymentData({
                              paymentId: d.row.tabPaymentId,
                              posOrderId: d.row.omnivoreOrderId,
                            })
                          }}
                        >
                          <img src={View} alt="More" /> {d.value}
                        </Button>
                      )
                    },
                    flex: 1,
                    minWidth: 100,
                  },
              ]}
              rowsData={multiPaymentList}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortChange}
              totalRecords={totalRecords}
            />
          </div>
        </DialogContent>
      </Dialog>
      <PaymentDetailsDialog
        open={openPaymentDetailsDialog}
        onClose={closePaymentDetailsDialog}
        paymentData={paymentData}
        // startDate={startDate}
        // endDate={endDate}
      />
    </>
  )
}

export default MultiPaymentDetailsDialog
