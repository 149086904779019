import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { restaurantList } from 'features/restaurant/restaurant'
import { useSelector } from 'react-redux'
import { setSelectedEstablishment } from 'features/establishment/establishment'
import establishmentService from 'services/establishment.service'
import { toast } from 'react-toastify'
import Datepicker from 'components/Datepicker/Index'
import { IPromocodeAddRequestDTO } from 'interfaces/promocode'
import { resolveHTTP } from 'utility/helper'
import promocodeService from 'services/promocode.service'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  closeDialog?: (event: boolean) => void
  isRestEstShow: boolean
  updatePromocodeList?: () => void
}

function AddPromoCode(props: dialogProps) {
  const { open, onClose, closeDialog, isRestEstShow, updatePromocodeList } =
    props
  const restuarantList = useSelector(restaurantList)
  const [establishmentLists, setEstablishmentLists] = useState([])
  const [selectedRestraunt, setSelectedRestraunt] = useState(null)
  const [promoStartDate, setPromoStartDate] = useState(null)
  const [promoEndDate, setPromoEndDate] = useState(null)

  const getEstablishmentList = useCallback(
    async () => {
      try {
        const data = await establishmentService.getAllEstablishments({
          pageNo: 1,
          pageSize: 0,
          sortGridModels: [{ field: 'restaurantName', sort: 0 }],
          restaurantId: selectedRestraunt,
        })

        const list = data.data.data.data.map((e) => {
          return {
            key: e.establishmentName,
            value: e.establishmentId,
          }
        })
        setEstablishmentLists(list)
      } catch (e) {
        if (localStorage.length !== 0) {
          toast.error('Failed to retrieve establishment list.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestraunt]
  )

  useEffect(() => {
    if (selectedRestraunt !== null) {
      getEstablishmentList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestraunt])
  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60 * 1000
    )
    return newDate
  }
  const formSchema = useMemo(() => {
    return yup.object().shape({
      codeName: yup.string().required('Promo Code name is required'),
      isRestaurantPromocode: yup.boolean(),
      restaurantId: yup.number().when('isRestaurantPromocode', {
        is: true,
        then: yup
          .number()
          .required('Restaurant is required')
          .min(1, `Restaurant is required`),
      }),
      establishmentId: yup.number().when('isRestaurantPromocode', {
        is: true,
        then: yup
          .number()
          .required('Establishment is required')
          .min(1, `Establishment is required`),
      }),
      promocodePercentage: yup.number().when('promocodeAmount', {
        is: (promocodeAmount) =>
          promocodeAmount === null ||
          promocodeAmount === '' ||
          promocodeAmount <= 0,
        then: yup
          .number()
          .required('Promocode percentage is required')
          .min(0.1, `Please Enter Value between 0 to 100`)
          .max(100, `Please Enter Value between 0 to 100`),
      }),
      promocodeAmount: yup.number(),
      startDate: yup.date().required('Start date is required'),
      expireDate: yup
        .date()
        .required('Expire date is required')
        .when(
          'startDate',
          (startDate, schem) =>
            startDate &&
            schem.min(startDate, 'Expire time cannot be before start time')
        ),
      isSpecialPromocode: yup.boolean(),
      minNoOfOrder: yup.number().when('isSpecialPromocode', {
        is: true,
        then: yup
          .number()
          .required('Minimum order value required')
          .min(1, `Please enter value greter than 0`),
      }),
    })
  }, [])

  const handleSave = async (formdata: IPromocodeAddRequestDTO) => {
    const [data] = await resolveHTTP(promocodeService.addPromocode(formdata))
    if (data) {
      toast.success('Promo code added successfully')
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      formik.resetForm()
      closeDialog(false)
      updatePromocodeList()
    }
    // eslint-disable-next-line no-unused-expressions
  }
  const getNextDay = () => {
    const today = new Date()
    today.setDate(today.getDate() + 1)
    return today
  }
  const formik = useFormik<IPromocodeAddRequestDTO>({
    initialValues: {
      codeName: '',
      isFixedAmountPromoCode: false,
      promocodePercentage: 0,
      promocodeAmount: 0,
      isMinimumOrderRequirement: false,
      minOrderAmount: 0,
      customerEligibility: '',
      minNoOfVisit: 0,
      loginIds: [],
      maxDiscountUses: 0,
      maxLimitForUser: 0,
      isActive: true,
      isRestaurantPromocode: false,
      autoApplyAfterPayment: 0,
      startDate: promoStartDate || new Date(),
      expireDate:
        promoEndDate || convertUTCDateToLocalDate(getNextDay()).toISOString(),
      maxLimitForNotify: 0,
      isRestaurantSpecific: false,
      description: '',
      isSpecialPromocode: false,
      restaurantId: 0,
      establishmentId: 0,
    },
    validationSchema: formSchema,
    onSubmit: (values) => handleSave(values),
  })

  formik.values.isRestaurantPromocode = isRestEstShow

  const handleClose = () => {
    formik.resetForm()
  }

  return (
    <Dialog
      className="primary-dialog user-dialog"
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
        handleClose()
        onClose(e)
      }}
    >
      <DialogTitle>
        <h5 className="bold">Add Promo Code</h5>
        <Button
          variant="text"
          title="Close"
          onClick={(e) => {
            onClose(e)
            handleClose()
          }}
          className="icon-btn rounded"
          color="inherit"
        >
          <img src={Close} alt="Close" />
        </Button>
      </DialogTitle>
      {/* dialog-content start */}
      <form onSubmit={formik.handleSubmit}>
        <DialogContent className="pb-0">
          {isRestEstShow && (
            <>
              <div className="form-group">
                <Select
                  label="Restaurant"
                  disabled={false}
                  showError={
                    !!formik.errors.restaurantId && formik.touched.restaurantId
                  }
                  errorText={
                    !!formik.errors.restaurantId && formik.touched.restaurantId
                      ? 'Restaurant is required'
                      : ''
                  }
                  handleSelectValue={(value) => {
                    formik.setFieldValue('restaurantId', value)
                    setSelectedRestraunt(value)
                  }}
                  formikValue={formik.values?.restaurantId}
                  items={restuarantList.map((d) => {
                    return {
                      key: d.name,
                      value: d.restaurantId,
                    }
                  })}
                />
              </div>
              <div className="form-group">
                <Select
                  label="Location"
                  disabled={false}
                  showError={
                    !!formik.errors.establishmentId &&
                    formik.touched.establishmentId
                  }
                  errorText={
                    !!formik.errors.establishmentId &&
                    formik.touched.establishmentId
                      ? 'Location is required'
                      : ''
                  }
                  formikValue={formik.values?.establishmentId}
                  items={establishmentLists}
                  handleSelectValue={(value) => {
                    formik.setFieldValue('establishmentId', value)
                    setSelectedEstablishment(value)
                  }}
                />
              </div>
            </>
          )}

          <div className="form-group">
            <Textfield
              variant="outlined"
              type="text"
              label="Promo Code Name"
              name="codeName"
              value={formik.values?.codeName}
              onChange={formik.handleChange}
              error={!!formik.errors.codeName && formik.touched.codeName}
              helperText={
                !!formik.errors.codeName && formik.touched.codeName
                  ? formik.errors.codeName
                  : ''
              }
              handleBlur={formik.handleBlur}
            />
          </div>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <div className="form-group">
                <Datepicker
                  datePickerlabel="Start Date"
                  isPromoCodePage
                  value={promoStartDate || new Date()}
                  onChange={(date) => {
                    if (date) {
                      formik.setFieldValue(
                        'startDate',
                        convertUTCDateToLocalDate(date).toISOString()
                      )
                      setPromoStartDate(
                        convertUTCDateToLocalDate(date).toISOString()
                      )
                    } else {
                      formik.setFieldValue('startDate', null)
                      setPromoStartDate(null)
                    }
                  }}
                  setFilterSelectedDate={setPromoStartDate}
                />
                {formik.values.startDate === null && (
                  <p className="error-css">Please enter valid date.</p>
                )}
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className="form-group">
                <Datepicker
                  datePickerlabel="Expire Date"
                  isPromoCodePage
                  value={
                    promoEndDate ||
                    convertUTCDateToLocalDate(getNextDay()).toISOString()
                  }
                  onChange={(date) => {
                    if (date) {
                      formik.setFieldValue(
                        'expireDate',
                        convertUTCDateToLocalDate(date).toISOString()
                      )
                      setPromoEndDate(
                        convertUTCDateToLocalDate(date).toISOString()
                      )
                    } else {
                      formik.setFieldValue('expireDate', null)
                      setPromoEndDate(null)
                    }
                  }}
                  setFilterSelectedDate={setPromoEndDate}
                />
                {formik.values.expireDate === null && (
                  <p className="error-css">Please enter valid date.</p>
                )}
                {formik.values.expireDate !== null &&
                  formik.values.startDate !== null &&
                  Date.parse(formik.values.expireDate) <=
                    Date.parse(formik.values.startDate) && (
                    <p className="error-css">
                      Expire time cannot be before start time.
                    </p>
                  )}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <div className="form-group">
                <Textfield
                  variant="outlined"
                  type="number"
                  label="Promo Code Percentage(%)"
                  name="promocodePercentage"
                  value={formik.values?.promocodePercentage}
                  onChange={formik.handleChange}
                  error={
                    !!formik.errors.promocodePercentage &&
                    formik.touched.promocodePercentage
                  }
                  helperText={
                    !!formik.errors.promocodePercentage &&
                    formik.touched.promocodePercentage
                      ? formik.errors.promocodePercentage
                      : ''
                  }
                  handleBlur={formik.handleBlur}
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className="form-group">
                <Textfield
                  variant="outlined"
                  type="number"
                  label="Promo Code Amount"
                  name="promocodeAmount"
                  value={formik.values?.promocodeAmount}
                  onChange={formik.handleChange}
                  error={
                    !!formik.errors.promocodeAmount &&
                    formik.touched.promocodeAmount
                  }
                  helperText={
                    !!formik.errors.promocodeAmount &&
                    formik.touched.promocodeAmount
                      ? formik.errors.promocodeAmount
                      : ''
                  }
                  handleBlur={formik.handleBlur}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <div className="form-group">
                <Textfield
                  variant="outlined"
                  type="number"
                  label="Minimum Order Amount"
                  name="minOrderAmount"
                  value={formik.values?.minOrderAmount}
                  onChange={formik.handleChange}
                  error={
                    !!formik.errors.minOrderAmount &&
                    formik.touched.minOrderAmount
                  }
                  helperText={
                    !!formik.errors.minOrderAmount &&
                    formik.touched.minOrderAmount
                      ? formik.errors.minOrderAmount
                      : ''
                  }
                  handleBlur={formik.handleBlur}
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className="form-group">
                <Textfield
                  variant="outlined"
                  type="number"
                  label="Auto Apply After Payment"
                  name="autoApplyAfterPayment"
                  value={formik.values?.autoApplyAfterPayment}
                  onChange={formik.handleChange}
                  error={
                    !!formik.errors.autoApplyAfterPayment &&
                    formik.touched.autoApplyAfterPayment
                  }
                  helperText={
                    !!formik.errors.autoApplyAfterPayment &&
                    formik.touched.autoApplyAfterPayment
                      ? formik.errors.autoApplyAfterPayment
                      : ''
                  }
                  handleBlur={formik.handleBlur}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <div className="form-group">
                <Textfield
                  variant="outlined"
                  type="number"
                  label="How many payment after notify?"
                  name="maxLimitForNotify"
                  value={formik.values?.maxLimitForNotify}
                  onChange={formik.handleChange}
                  error={
                    !!formik.errors.maxLimitForNotify &&
                    formik.touched.maxLimitForNotify
                  }
                  helperText={
                    !!formik.errors.maxLimitForNotify &&
                    formik.touched.maxLimitForNotify
                      ? formik.errors.maxLimitForNotify
                      : ''
                  }
                  handleBlur={formik.handleBlur}
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className="form-group">
                <Textfield
                  variant="outlined"
                  type="number"
                  label="Max Limit For User"
                  name="maxLimitForUser"
                  value={formik.values?.maxLimitForUser}
                  onChange={formik.handleChange}
                  error={
                    !!formik.errors.maxLimitForUser &&
                    formik.touched.maxLimitForUser
                  }
                  helperText={
                    !!formik.errors.maxLimitForUser &&
                    formik.touched.maxLimitForUser
                      ? formik.errors.maxLimitForUser
                      : ''
                  }
                  handleBlur={formik.handleBlur}
                />
              </div>
            </Grid>
          </Grid>

          <div className="form-group">
            <Textfield
              variant="outlined"
              multiline
              rows={3}
              type="text"
              label="Description"
              name="description"
              value={formik.values?.description}
              onChange={formik.handleChange}
              error={!!formik.errors.description && formik.touched.description}
              helperText={
                !!formik.errors.description && formik.touched.description
                  ? formik.errors.description
                  : ''
              }
              handleBlur={formik.handleBlur}
            />
          </div>
          <Grid container spacing={4}>
            <Grid item sm={4} xs={12}>
              <div className="form-group">
                <Checkbox
                  color="primary"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  name="isActive"
                />{' '}
                <span className="enable-two-fa">Is Active?</span>
              </div>
            </Grid>
            <Grid item sm={8} xs={12}>
              <div className="form-group">
                <Checkbox
                  color="primary"
                  checked={formik.values.isSpecialPromocode}
                  onChange={formik.handleChange}
                  name="isSpecialPromocode"
                />{' '}
                <span className="enable-two-fa">Is Special Promocode?</span>
              </div>
            </Grid>
          </Grid>
          <div className="form-group" style={{ display: 'none' }}>
            <Checkbox
              color="primary"
              checked={formik.values.isRestaurantPromocode}
              onChange={formik.handleChange}
              name="isRestaurantPromocode"
            />{' '}
            <span className="enable-two-fa">Is isRestaurantPromocode?</span>
          </div>
        </DialogContent>
        {/* dialog-content end */}
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Add"
              type="submit"
            >
              Add
            </Button>
            <Button
              onClick={(e) => {
                onClose(e)
                handleClose()
              }}
              title="Cancel"
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export default AddPromoCode
