import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IReportingServersResponse,
  IReportingRequest,
  ILiveMetricsRequest,
  ILMTodaysEarningResponse,
  ILMLastHourEarningsResponse,
  ILMTodaysReviewsResponse,
  ILMServerActionResponse,
  ILiveMetricsServerActionRequest,
  ILiveMetricsAllTimeRequest,
  ILMAllTimeResponse,
  ILMTodayFifteenMinsResponse,
  IDashboardOverViewResponse,
  IDashboardFinanceDetailsResponse,
  IDashboardOperationsResponse,
} from 'interfaces/reporting'
import {
  IRestuarantDashboardConfig,
  IServerPillerResponse,
} from 'interfaces/restaurant'
import httpClient from './base-service'

const endPointBaseURL = `/Common`

const getDashboardInfo = async (
  requestBody: IReportingRequest
): Promise<AxiosResponse<IApiSuccessResponse<IDashboardOverViewResponse>>> =>
  httpClient.post<IApiSuccessResponse<IDashboardOverViewResponse>>(
    `${endPointBaseURL}/get-overview-dashboard`,
    requestBody
  )

// const getDashboardDailyInfo = async (
//   requestBody: IReportingRequest
// ): Promise<
//   AxiosResponse<IApiSuccessResponse<IReportingDashboardDailyResponse>>
// > =>
//   httpClient.post<IApiSuccessResponse<IReportingDashboardDailyResponse>>(
//     `${endPointBaseURL}/get-dashboard-overview-daily-details`,
//     requestBody
//   )

const getOperationsInfo = async (
  requestBody: IReportingRequest
): Promise<AxiosResponse<IApiSuccessResponse<IDashboardOperationsResponse>>> =>
  httpClient.post<IApiSuccessResponse<IDashboardOperationsResponse>>(
    `${endPointBaseURL}/get-operations-dashboard`,
    requestBody
  )

const getServersInfo = async (
  requestBody: IReportingRequest
): Promise<AxiosResponse<IApiSuccessResponse<IReportingServersResponse>>> =>
  httpClient.post<IApiSuccessResponse<IReportingServersResponse>>(
    `${endPointBaseURL}/get-dashboard-server-details`,
    requestBody
  )

const getFinanceInfo = async (
  requestBody: IReportingRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<IDashboardFinanceDetailsResponse[]>>
> =>
  httpClient.post<IApiSuccessResponse<IDashboardFinanceDetailsResponse[]>>(
    `${endPointBaseURL}/get-finance-dashboard`,
    requestBody
  )

const getTabsInfo = async (
  requestBody: IRestuarantDashboardConfig
): Promise<AxiosResponse<IApiSuccessResponse<IRestuarantDashboardConfig>>> =>
  httpClient.post<IApiSuccessResponse<IRestuarantDashboardConfig>>(
    `${endPointBaseURL}/get-restaurant-dashboard-tabs-details`,
    requestBody
  )

const GetServerPillerInfo = async (
  requestBody: IReportingRequest
): Promise<AxiosResponse<IApiSuccessResponse<IServerPillerResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<IServerPillerResponse[]>>(
    `${endPointBaseURL}/get-server-pillar`,
    requestBody
  )

const getLMTodaysEarning = async (
  requestBody: ILiveMetricsRequest,
  isHideLoader: boolean
): Promise<AxiosResponse<IApiSuccessResponse<ILMTodaysEarningResponse>>> =>
  httpClient.post<IApiSuccessResponse<ILMTodaysEarningResponse>>(
    `${endPointBaseURL}/get-live-metrics-todays-earnings`,
    requestBody,
    { hideLoader: isHideLoader }
  )

const getLMLastHourEarnings = async (
  requestBody: ILiveMetricsRequest,
  isHideLoader: boolean
): Promise<AxiosResponse<IApiSuccessResponse<ILMLastHourEarningsResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<ILMLastHourEarningsResponse[]>>(
    `${endPointBaseURL}/get-live-metrics-last-hour-earnings`,
    requestBody,
    { hideLoader: isHideLoader }
  )

const getLMTodaysReviews = async (
  requestBody: ILiveMetricsRequest,
  isHideLoader: boolean
): Promise<AxiosResponse<IApiSuccessResponse<ILMTodaysReviewsResponse>>> =>
  httpClient.post<IApiSuccessResponse<ILMTodaysReviewsResponse>>(
    `${endPointBaseURL}/get-live-metrics-todays-reviews`,
    requestBody,
    { hideLoader: isHideLoader }
  )

const getLMServerActions = async (
  requestBody: ILiveMetricsServerActionRequest,
  isHideLoader: boolean
): Promise<AxiosResponse<IApiSuccessResponse<ILMServerActionResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<ILMServerActionResponse[]>>(
    `${endPointBaseURL}/get-live-metrics-servers-actions`,
    requestBody,
    { hideLoader: isHideLoader }
  )

const getLMTodayFifteenMinsEarnings = async (
  requestBody: ILiveMetricsRequest,
  isHideLoader: boolean
): Promise<AxiosResponse<IApiSuccessResponse<ILMTodayFifteenMinsResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<ILMTodayFifteenMinsResponse[]>>(
    `${endPointBaseURL}/get-live-metrics-todays-15-mins-earnings`,
    requestBody,
    { hideLoader: isHideLoader }
  )

const getLMAllTimeEarnings = async (
  requestBody: ILiveMetricsAllTimeRequest,
  isHideLoader: boolean
): Promise<AxiosResponse<IApiSuccessResponse<ILMAllTimeResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<ILMAllTimeResponse[]>>(
    `${endPointBaseURL}/get-live-metrics-all-time-earnings`,
    requestBody,
    { hideLoader: isHideLoader }
  )

export default {
  getDashboardInfo,
  getOperationsInfo,
  getServersInfo,
  getFinanceInfo,
  getTabsInfo,
  GetServerPillerInfo,
  getLMTodaysEarning,
  getLMLastHourEarnings,
  getLMTodaysReviews,
  getLMServerActions,
  getLMAllTimeEarnings,
  getLMTodayFifteenMinsEarnings,
}
