/* eslint-disable no-nested-ternary */
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid-pro'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import DataTable from 'components/DataTable/Index'
import { IDatagrid } from 'interfaces/datagrid'
// import { IRestaurantNames } from 'interfaces/guest'
import React, { useCallback, useEffect, useState } from 'react'
import guestService from 'services/guest.service'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  guestId?: number
}

function GuestRestaurant(props: dialogProps) {
  const { open, onClose, guestId } = props
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: '',
        sort: 'asc',
      },
    ],
  })
  const [restaurantList, setRestaurantList] = useState([])

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }
  const getRestaurantList = useCallback(
    async (guestIdVal: number) => {
      try {
        const guestData = await guestService.getGuestRestaurant({
          guestId: guestIdVal,
        })
        const listRestaurant = guestData.data.data.data.map((user, index) => {
          return { id: index + 1, ...user }
        })
        setRestaurantList(listRestaurant)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (open) {
      getRestaurantList(guestId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions, open])

  return (
    <>
      <Dialog
        className="primary-dialog details-dialog"
        maxWidth={false}
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <h5>Restaurant</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent>
          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="id"
              columnsData={[
                {
                  field: 'restaurantName',
                  headerName: 'Restaurant Name',
                  flex: 1,
                  minWidth: 120,
                },
              ]}
              rowsData={restaurantList}
              onSortModelChange={onSortChange}
              sortingMode
              bottomPagination
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default GuestRestaurant
