import React from 'react'
import {
  GridColDef,
  GridSelectionModel,
  GridSortModel,
  DataGridPro,
} from '@mui/x-data-grid-pro'
// import {  } from '@mui/x-data-grid-pro'
import { SortAscending, SortDescending } from 'assets/images'
import Pagination from 'components/Pagination/Index'

interface dataTableProps {
  columnsData: GridColDef[]
  rowsData: Array<Object>
  checkboxSelection?: boolean
  topPaginatiom?: boolean
  bottomPagination?: boolean
  classes?: string
  uniqueId?: string
  totalRecords?: number
  sortingMode?: boolean
  onPageChange?: (value: number) => void
  onPageSizeChange?: (value: number) => void
  onSortModelChange?: (sortModel: GridSortModel) => void
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void
}

function DataTable({
  columnsData,
  checkboxSelection = false,
  rowsData,
  classes,
  bottomPagination,
  topPaginatiom = false,
  uniqueId,
  totalRecords,
  sortingMode,
  onPageChange,
  onPageSizeChange,
  onSortModelChange,
  onSelectionModelChange,
}: dataTableProps) {
  const sortDescendingIcon = () => {
    return <img src={SortDescending} alt="SortDescending" />
  }
  const sortAscendingIcon = () => {
    return <img src={SortAscending} alt="SortAscending" />
  }
  return (
    <>
      {topPaginatiom && (
        <Pagination
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      )}
      <DataGridPro
        getRowId={(row) => row[uniqueId]}
        getRowHeight={() => 'auto'}
        autoHeight
        rows={rowsData}
        columns={columnsData}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        checkboxSelection={checkboxSelection}
        hideFooterPagination
        classes={{
          root: `primary-grid ${classes}`,
        }}
        disableSelectionOnClick
        hideFooter
        rowHeight={48}
        headerHeight={48}
        components={{
          ColumnSortedAscendingIcon: sortAscendingIcon,
          ColumnSortedDescendingIcon: sortDescendingIcon,
        }}
        sortingMode={sortingMode ? 'client' : 'server'}
        onSortModelChange={onSortModelChange}
        onSelectionModelChange={onSelectionModelChange}
      />
      {!bottomPagination && (
        <Pagination
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          noOfRecords={totalRecords || rowsData.length}
        />
      )}
    </>
  )
}

export default DataTable
