import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import AddEditUserDialog from 'components/AddEditUserDialog/Index'
import Button from 'components/Button/Index'
import ExistingUserDialog from 'components/ExistingUserDialog/Index'
import React, { useState } from 'react'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  restaurantId?: number
  locationId?: number[]
  isFromLocation?: boolean
  isFromRestaurant?: boolean
  restaurantIdForEstUser?: number
}

function AlertDialog(props: dialogProps) {
  const {
    open,
    onClose,
    restaurantId,
    locationId,
    isFromLocation,
    isFromRestaurant,
    restaurantIdForEstUser,
  } = props

  // Add/Edit User Dialog
  const [openAddEditUserDialog, setOpenAddEditUserDialog] = useState(false)
  const closeAddEditUserDialog = () => {
    setOpenAddEditUserDialog(false)
  }

  // Existing User Dialog
  const [openExistingUserDialog, setOpenExistingUserDialog] = useState(false)
  const closeExistingUserDialog = () => {
    setOpenExistingUserDialog(false)
  }
  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <p>Do you want to add existing user?</p>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Yes"
              onClick={(e) => {
                setOpenExistingUserDialog(true)
                onClose(e)
              }}
            >
              Yes
            </Button>
            <Button
              title="No"
              variant="outlined"
              color="primary"
              onClick={(e) => {
                setOpenAddEditUserDialog(true)
                onClose(e)
              }}
            >
              <span>No</span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* add-edit-user-dialog */}
      <AddEditUserDialog
        open={openAddEditUserDialog}
        onClose={closeAddEditUserDialog}
        restId={restaurantId}
        estId={locationId}
        isFromRestaurant={isFromRestaurant}
        isFromLocation={isFromLocation}
        restaurantIdForEstUser={restaurantIdForEstUser}
        closeDialog={closeAddEditUserDialog}
      />

      {/* Existing-user-dialog */}
      <ExistingUserDialog
        open={openExistingUserDialog}
        onClose={closeExistingUserDialog}
        isFromRestaurant={isFromRestaurant}
        isFromLocation={isFromLocation}
        restaurantId={restaurantId}
        locationId={locationId}
        restaurantIdForEstUser={restaurantIdForEstUser}
        closeDialog={closeExistingUserDialog}
      />
    </>
  )
}

export default AlertDialog
