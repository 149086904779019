/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Tab,
  Tabs,
} from '@mui/material'
import {
  AddSmall,
  Close,
  Delete,
  DeleteGrey,
  Edit,
  Move,
  PDF,
} from 'assets/images'
import AddEditCategoryDialog from 'components/AddEditCategoryDialog/Index'
import AddEditCategoryItemDialog from 'components/AddEditCategoryItemDialog.tsx/Index'
// import AddEditSubCategoryDialog from 'components/AddEditSubCategoryDialog/Index'
import Button from 'components/Button/Index'
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/Index'
import FileUpload from 'components/FileUpload/Index'
import Switch from 'components/Switch/Index'
import { DialogOptions } from 'enums/Common'
import {
  deleteMenuCategory,
  deleteMenuItem,
  menuList,
  setMenuCategory,
  setMenuItem,
  updateMenu,
  updateMenuCategory,
  updateMenuItem,
} from 'features/menu/menu'
import {
  ICategoryItemUpdate,
  IMenuCategorySearchResponse,
  IMenuSearchResponse,
} from 'interfaces/menu'
import React, { useCallback, useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import blobService from 'services/blob.service'
import menuService from 'services/menu.service'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  menuId?: number
  menu?: IMenuSearchResponse[]
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  // eslint-disable @typescript-eslint/no-unused-vars /
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function MenuItemsDialog(props: dialogProps) {
  const { open, onClose, menuId, menu } = props
  const dispatch = useDispatch()
  const [category, setCategory] = useState<IMenuCategorySearchResponse[]>([])
  const [currentMenu, setMenu] = useState<IMenuSearchResponse>(null)
  const [categoryItem, setCategoryItem] = useState<ICategoryItemUpdate[]>([])
  const [MenuId, setMenuId] = useState(null)
  const [tableIdForDelete, setTableIdForDelete] = useState(0)
  const [file, setFile] = useState<File>(null)
  const [parentIdForUpdate, setParentIdForUpdate] = useState(0)
  const [fileData, setFileData] = useState({
    name: '',
    path: '',
  })
  const [token, SetToken] = useState(null)
  const [storgaeName, SetStorageName] = useState(null)
  const [value, setValue] = React.useState(0)
  const [catId, setCatId] = React.useState(0)
  const [subCatId, setSubCatId] = React.useState(0)
  const [parentId, setParentId] = React.useState(0)
  const [editPosition, setEditPosition] = React.useState(0)
  const [visibility, setVisibility] = React.useState(true)
  const [isFileSaved, setIsFileSaved] = React.useState(true)
  const [editName, setEditName] = React.useState('')
  const menus = useSelector(menuList)
  const getMenuItemLists = useCallback(
    async (menuCategoryId: number) => {
      try {
        const data = await menuService.getItemByCatId(menuCategoryId)
        setCategoryItem(
          data.data.data.sort((a, b) => {
            return a.position - b.position
          })
        )
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getMenuCategoryLists = useCallback(
    async (currentMenuId: number) => {
      try {
        const data = await menuService.getMenuCategoryById(currentMenuId)
        const sortedArray = data.data.data.sort((a, b) => {
          return a.position - b.position
        })
        setCategory(sortedArray)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setCatId(sortedArray[0]?.menuCategoryId)
        setParentId(sortedArray[0]?.menuCategoryId)
        // to get items of first selected categorysetCategoryItem
        if (sortedArray.length === 0) {
          setCategoryItem([])
        } else {
          getMenuItemLists(sortedArray[0].menuCategoryId)
        }
      } catch (e) {
        console.log(e)
        setCategoryItem([])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // add-edit category item dialog
  const [openAddEditCategoryItemDialog, setOpenAddEditCategoryItemDialog] =
    useState(false)

  const getMenuData = useCallback(
    async (currentMenuId: number) => {
      try {
        const data = await menuService.geMenubyMenuId({
          menuId: currentMenuId,
          includeAllFields: false,
        })
        const menuData = data.data.data
        setMenu(data.data.data)
        if (menuData.isPdfMenu) {
          const containerName = process.env.REACT_APP_AZURE_CONTAINER
          const name = menuData.menuPdfPath
            .split(`${containerName}/`)[1]
            .split('?')[0]
          setFileData({ name, path: menuData.menuPdfPath })
        } else {
          setFileData(null)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuId]
  )

  // switch tabs
  useEffect(() => {
    if (open) {
      getMenuData(menuId)
      getMenuCategoryLists(menuId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId, open])

  const getNewItems = () => {
    getMenuItemLists(catId)
  }

  const getNewCategories = () => {
    getMenuCategoryLists(menuId)
  }

  const closeAddEditCategoryItemDialog = () => {
    setOpenAddEditCategoryItemDialog(false)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  // category and category items drag and drop

  const handleFileChange = (fileObj: File) => {
    if (fileData?.name) {
      toast.error('Please delete the existing file.')
    } else {
      setFile(fileObj)
      const containerName = process.env.REACT_APP_AZURE_CONTAINER
      const menuPdfPath = `${containerName}/${fileObj.name}`
      setIsFileSaved(false)
      setFileData({
        name: fileObj.name,
        path: `https://${storgaeName}.blob.core.windows.net/${menuPdfPath}?${token}`,
      })
    }
  }

  const getSasToken = async () => {
    if (!token) {
      const azureData = await blobService.getAzureDetails()
      SetToken(azureData.data.data.sasToken)
      SetStorageName(azureData.data.data.azureStorageAccountName)
    }
  }

  useEffect(() => {
    getSasToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async () => {
    const menuIndex = menus.findIndex((d) => d.menuId === menuId)
    if (category && category?.length === 0) {
      setIsFileSaved(true)
      const containerName = process.env.REACT_APP_AZURE_CONTAINER
      // upload file logic
      if (file) {
        await blobService.uploadFileToBlob(
          file,
          file.name,
          containerName,
          storgaeName || '',
          token || ''
        )
        currentMenu.menuPdfPath = `${containerName}/${file.name}`
        currentMenu.isPdfMenu = true
        setFileData({
          name: file.name,
          path: `https://${storgaeName}.blob.core.windows.net/${currentMenu.menuPdfPath}?${token}`,
        })
        await menuService.updateMenu(currentMenu)
        dispatch(updateMenu({ menu: currentMenu }))
      } else {
        toast.error('Please select a file to upload.')
      }
    } else {
      if (!menus[menuIndex]?.isPdfMenu)
        toast.error(
          'There is already a category added for this menu. Please remove category to upload file'
        )
    }
  }

  const handleDeleteFile = useCallback(
    async () => {
      const containerName = process.env.REACT_APP_AZURE_CONTAINER
      if (!isFileSaved) {
        setFile(null)
        setFileData(null)
      } else if (fileData.name) {
        await blobService.deleteFile(
          fileData.name,
          containerName,
          storgaeName,
          token
        )

        const menuItems = { ...currentMenu }
        menuItems.menuPdfPath = ''
        menuItems.isPdfMenu = false
        setFile(null)
        setFileData(null)
        await menuService.updateMenu(menuItems)
        dispatch(updateMenu({ menu: menuItems }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileData, currentMenu]
  )

  const handleOnDragEnd = async (result: DropResult) => {
    const newCategory = Array.from(category)
    const newCategoryItem = Array.from(categoryItem)

    if (!result.destination) return

    // category drag and drop
    if (
      (result.destination.droppableId || result.source.droppableId) ===
      'category-list'
    ) {
      const [reorderedCategory] = newCategory.splice(result.source.index, 1)
      newCategory.splice(result.destination.index, 0, reorderedCategory)
      setCategory(newCategory)
      const sortedArray = newCategory.map((a) => a.menuCategoryId)
      // ReorderMenu
      const newsortedArray = newCategory.map((element, index) => {
        return { ...element, position: index }
      })
      await menuService.ReorderMenuCategory(sortedArray)
      dispatch(setMenuCategory({ menuCategory: newsortedArray, menuId }))
    }

    // category items drag and drop
    if (
      (result.destination.droppableId || result.source.droppableId) ===
      'category-item-list'
    ) {
      const [reorderedCategoryItem] = newCategoryItem.splice(
        result.source.index,
        1
      )
      newCategoryItem.splice(result.destination.index, 0, reorderedCategoryItem)
      setCategoryItem(newCategoryItem)
      const sortedArray = newCategoryItem.map((a) => a.menuItemId)
      const newsortedItemArray = newCategoryItem.map((element, index) => {
        return { ...element, position: index }
      })
      // ReorderItems
      await menuService.ReorderItems(sortedArray)
      dispatch(setMenuItem({ menuItem: newsortedItemArray, menuId }))
    }

    // subcategory drag and drop
    if (
      (result.destination.droppableId || result.source.droppableId) ===
      'submenucat'
    ) {
      const [reorderedCategory] = newCategory.splice(result.source.index, 1)
      newCategory.splice(result.destination.index, 0, reorderedCategory)
      setCategory(newCategory)
      const sortedArray = newCategory.map((a) => a.menuCategoryId)
      // ReorderMenu
      const newsortedArray = newCategory.map((element, index) => {
        return { ...element, position: index }
      })
      await menuService.ReorderMenuCategory(sortedArray)
      dispatch(setMenuCategory({ menuCategory: newsortedArray, menuId }))
    }
  }

  // DeleteConfirmation Dialog
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [openDeleteConfirmationItem, setOpenDeleteConfirmationItem] =
    useState(false)
  const [openDeleteConfirmationFile, setOpenDeleteConfirmationFile] =
    useState(false)
  const toggleDeleteConfirmationDialog = useCallback(
    async (
      isOpen: boolean,
      isSubmit = false,
      id: number,
      menuIdProp: number,
      resmenu: IMenuSearchResponse[],
      parentCatId?: number
    ) => {
      console.log(id)
      const currentMenuIndex = resmenu.findIndex((d) => d.menuId === menuIdProp)
      setOpenDeleteConfirmation(isOpen)
      setTableIdForDelete(id)
      if (isSubmit && !isOpen && id > 0) {
        try {
          await menuService.deleteCategoryMenu(Number(id))
          // eslint-disable-next-line prefer-const
          const currentMenuData = resmenu[currentMenuIndex]
          let categoryIndex = null
          categoryIndex = currentMenuData?.menuCategories?.findIndex(
            (d) => d.menuCategoryId === id
          )

          dispatch(
            deleteMenuCategory({
              menuId: menuIdProp,
              categoryIndex,
            })
          )
          getMenuCategoryLists(menuIdProp)
          toast.success('Category deleted successfully.')
        } catch (error) {
          console.log(error)
          toast.error('Something wrong with deleting the records.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const toggleDeleteConfirmationDialogforItems = useCallback(
    async (
      isOpen: boolean,
      isSubmit = false,
      id: number,
      catIdProps: number,
      resmenu: IMenuSearchResponse[],
      resMenuId: number,
      parentCategoryIdForItems?: number
    ) => {
      setOpenDeleteConfirmationItem(isOpen)
      setTableIdForDelete(id)
      if (isSubmit && !isOpen && id > 0) {
        try {
          const currentMenuIndex = resmenu.findIndex(
            (d) => d.menuId === resMenuId
          )
          // eslint-disable-next-line prefer-const
          let currentMenuData = resmenu[currentMenuIndex]
          let categoryIndex = null
          let currentMenuCategoryData = null
          categoryIndex = currentMenuData?.menuCategories?.findIndex(
            (d) => d.menuCategoryId === catIdProps
          )
          currentMenuCategoryData =
            currentMenuData?.menuCategories[categoryIndex]
          // eslint-disable-next-line prefer-const
          const itemIndex = currentMenuCategoryData?.menuItems?.findIndex(
            (d) => d.menuItemId === id
          )
          await menuService.deleteItemMenu(id)
          dispatch(
            deleteMenuItem({
              menuId: resMenuId,
              categoryIndex,
              itemIndex,
            })
          )
          getMenuItemLists(catIdProps)
          toast.success('Item deleted successfully.')
        } catch (error) {
          console.log(error)
          toast.error('Something wrong with deleting the records.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const toggleDeleteConfirmationDialogforFile = useCallback(
    async (isOpen: boolean, isSubmit: boolean) => {
      setOpenDeleteConfirmationFile(isOpen)
      if (isSubmit && !isOpen) {
        handleDeleteFile()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileData]
  )

  // add-edit category dialog
  const [openAddEditCategoryDialog, setOpenAddEditCategoryDialog] =
    useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const closeAddEditCategoryDialog = () => {
    setOpenAddEditCategoryDialog(false)
  }

  const [parentCategoryId, setParentCategoryId] = useState(0)
  const [parentCatForItems, setParentCatForItems] = useState(0)
  // const [openAddEditSubCategoryDialog, setOpenAddEditSubCategoryDialog] =
  //   useState(false)
  // const closeAddEditSubCategoryDialog = () => {
  //   setOpenAddEditSubCategoryDialog(false)
  // }
  const [emptyCategory] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCategoryClick = (e, menuCategoryId: number, pid: number) => {
    e.preventDefault()
    setCatId(menuCategoryId)
    // setSubCatId(menuCategoryId)
    setParentId(pid)
    getMenuItemLists(menuCategoryId)
  }
  const updateCategory = async (
    name: string,
    menuid: number,
    position: number,
    isActive: boolean,
    menuCategoryId: number,
    parentCatId?: number
  ) => {
    await menuService.updateCategoryMenu({
      menuId: menuid,
      name,
      position,
      isActive,
      menuCategoryId,
      parentCategoryId: parentCatId,
    })
    const currentMenuIndex = menu.findIndex((d) => d.menuId === menuid)
    // eslint-disable-next-line prefer-const
    let currentMenuData = menu[currentMenuIndex]
    let categoryIndex = null
    categoryIndex = currentMenuData?.menuCategories?.findIndex(
      (d) => d.menuCategoryId === menuCategoryId
    )
    // const categoryIndex = currentMenuData.menuCategories.findIndex(
    //   (d) => d.menuCategoryId === menuCategoryId
    // )
    // const subCategoryIndex = currentMenuData?.menuCategories[
    //   categoryIndex
    // ]?.menuSubCategory?.findIndex((d) => d.menuCategoryId === categoryId)
    dispatch(
      updateMenuCategory({
        menuId: menuid,
        categoryIndex,
        isActive,
        categoryName: name,
        position,
      })
    )
    getNewCategories()
  }
  const handleSwitchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    elementName?: IMenuCategorySearchResponse
  ) => {
    setVisibility(e.target.checked)
    updateCategory(
      elementName.name,
      elementName.menuId,
      elementName.position,
      e.target.checked,
      elementName.menuCategoryId,
      elementName.parentCategoryId
    )
  }
  const handleSwitchChangeForItems = async (
    e: React.ChangeEvent<HTMLInputElement>,
    elementName?: ICategoryItemUpdate
  ) => {
    setVisibility(e.target.checked)
    await menuService.updateItemMenu({
      name: elementName.name,
      position: elementName.position,
      isActive: e.target.checked,
      menuCategoryId: elementName.menuCategoryId,
      price: elementName.price,
      description: elementName.description,
      menuItemId: elementName.menuItemId,
    })
    const currentMenuIndex = menu.findIndex((d) => d.menuId === menuId)
    // eslint-disable-next-line prefer-const
    let currentMenuData = menu[currentMenuIndex]
    // const categoryIndex = currentMenuData.menuCategories.findIndex(
    //   (d) => d.menuCategoryId === elementName.menuCategoryId
    // )
    // eslint-disable-next-line prefer-const
    let categoryIndex = null
    let currentMenuCategoryData = null

    categoryIndex = currentMenuData?.menuCategories?.findIndex(
      (d) => d.menuCategoryId === elementName.menuCategoryId
    )
    currentMenuCategoryData = currentMenuData?.menuCategories[categoryIndex]
    // let currentMenuCategoryData = currentMenuData.menuCategories[categoryIndex]
    const itemIndex = currentMenuCategoryData.menuItems.findIndex(
      (d) => d.menuItemId === elementName.menuItemId
    )
    dispatch(
      updateMenuItem({
        menuid: menuId,
        categoryIndex,
        itemIndex,
        isActive: e.target.checked,
        itemName: elementName.name,
        price: elementName.price,
        description: elementName.description,
      })
    )
    getNewItems()
  }

  const openAddEditSubCatDialog = (ele: IMenuCategorySearchResponse) => {
    if (categoryItem.length === 0) {
      // setOpenAddEditSubCategoryDialog(true)
      setIsEdit(false)
      setParentCategoryId(ele.menuCategoryId)
    } else {
      toast.error(
        'There are some items in this category, remove them to add sub category.'
      )
    }
  }
  return (
    <>
      <Dialog
        className="primary-dialog menu-items-dialog"
        maxWidth="xl"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <h5 className="bold">Menu Category/Items</h5>
          <Button
            variant="text"
            title="Close"
            onClick={(e) => {
              setFileData(null)
              setFile(null)
              setIsFileSaved(true)
              onClose(e)
            }}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        {/* dialog-content start */}
        <DialogContent>
          <Tabs value={value} onChange={handleChange} className="switch-tabs">
            <Tab label="Upload PDF" />
            <Tab label={menuId ? 'Menu Detail' : 'Create menu'} />
          </Tabs>
          <TabPanel value={value} index={0}>
            {/* uploaded-files start */}
            <ul className="uploaded-files">
              {fileData && (
                <li>
                  {isFileSaved ? (
                    <a
                      href={fileData.path}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={PDF} alt="PDF" />
                      <span className="file-info">
                        <span>{fileData.name}</span>
                      </span>
                    </a>
                  ) : (
                    <span>
                      <img src={PDF} alt="PDF" />
                      <span className="file-info">
                        <span>{fileData.name}</span>
                      </span>
                    </span>
                  )}
                  <Button
                    variant="text"
                    color="inherit"
                    title="Delete"
                    className="icon-btn rounded"
                    onClick={() => {
                      toggleDeleteConfirmationDialogforFile(true, false)
                    }}
                  >
                    <img src={DeleteGrey} alt="Delete" />
                  </Button>
                </li>
              )}
            </ul>
            <FileUpload
              fileChange={handleFileChange}
              classes="vertical mb-30"
              fileTypes={['pdf']}
            />
            <span className="btn-list">
              <Button
                onClick={(e) => {
                  handleSave()
                  onClose(e)
                }}
                variant="contained"
                color="primary"
                title="Save"
              >
                Save
              </Button>
              <Button
                onClick={(e) => {
                  setFileData(null)
                  setFile(null)
                  setIsFileSaved(true)
                  onClose(e)
                }}
                variant="outlined"
                color="primary"
                title="Cancel"
              >
                Cancel
              </Button>
            </span>
            {/* uploaded-files end */}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div
              className={`menu-items-wrapper ${emptyCategory ? 'empty' : ''}`}
            >
              {emptyCategory ? (
                <>
                  <span>
                    <strong>There is no category!</strong>Please create at least
                    one category to add menu item.{' '}
                  </span>
                  <Link
                    href=" "
                    onClick={() => setOpenAddEditCategoryDialog(true)}
                    className="primary-link"
                  >
                    Create Category
                  </Link>
                </>
              ) : (
                <>
                  {/* category list start */}
                  <div className="left">
                    <div className="items-top">
                      <p>Category</p>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setOpenAddEditCategoryDialog(true)
                          setIsEdit(false)
                        }}
                        title="Add"
                        color="secondary"
                      >
                        <img src={AddSmall} alt="Add" />
                        <span>Add</span>
                      </Button>
                    </div>

                    <div>
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="category-list">
                          {(provided) => (
                            <ul
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="draggable-list category-list"
                            >
                              {category.map((ele, i) => {
                                return (
                                  <Draggable
                                    key={ele.menuCategoryId}
                                    draggableId={ele.name}
                                    index={i}
                                  >
                                    {(providedDraggable, snapshot) => (
                                      <li
                                        className={`draggable-item ${
                                          snapshot.isDragging ? 'dragging' : ''
                                        }${
                                          ele.menuCategoryId === catId
                                            ? 'active'
                                            : ''
                                        } ${!ele.isActive ? 'inactive' : ''}`}
                                        {...providedDraggable.draggableProps}
                                        ref={providedDraggable.innerRef}
                                      >
                                        <Link
                                          href="#/"
                                          title={ele.name}
                                          onClick={(
                                            e: React.MouseEvent<
                                              HTMLAnchorElement,
                                              MouseEvent
                                            >
                                          ) =>
                                            handleCategoryClick(
                                              e,
                                              ele.menuCategoryId,
                                              ele.parentCategoryId
                                            )
                                          }
                                        >
                                          <span
                                            className="drag-handler"
                                            {...providedDraggable.dragHandleProps}
                                          >
                                            <img src={Move} alt="Move" />
                                          </span>
                                          <span className="item-label wrap-content ">
                                            <span>{ele.name}</span>
                                          </span>
                                        </Link>
                                        <span className="right">
                                          <Switch
                                            placement="start"
                                            defaultChecked={ele.isActive}
                                            onChange={(e) =>
                                              handleSwitchChange(e, ele)
                                            }
                                            className="small-switch"
                                          />
                                          <Button
                                            variant="text"
                                            title="Edit"
                                            className="icon-btn rounded light-bordered"
                                            color="inherit"
                                            onClick={() => {
                                              setOpenAddEditCategoryDialog(true)
                                              setIsEdit(true)
                                              setCatId(ele.menuCategoryId)
                                              setParentId(ele.parentCategoryId)
                                              setEditPosition(ele.position)
                                              setVisibility(ele.isActive)
                                              setEditName(ele.name)
                                            }}
                                          >
                                            <img src={Edit} alt="Edit" />
                                          </Button>
                                          <Button
                                            variant="text"
                                            title="Delete"
                                            className="icon-btn rounded light-bordered"
                                            color="inherit"
                                            onClick={() => {
                                              toggleDeleteConfirmationDialog(
                                                true,
                                                false,
                                                ele.menuCategoryId,
                                                menuId,
                                                menu
                                              )
                                            }}
                                          >
                                            <img src={Delete} alt="Delete" />
                                          </Button>
                                          {/* <Button
                                            variant="text"
                                            title="Add"
                                            className="icon-btn rounded light-bordered"
                                            color="inherit"
                                            onClick={() => {
                                              openAddEditSubCatDialog(ele)
                                            }}
                                          >
                                            <img src={AddSmall} alt="Add" />
                                          </Button> */}
                                        </span>
                                      </li>
                                    )}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                  {/* category list end */}

                  {/* category items list start */}
                  <div className="right">
                    <div className="items-top">
                      <p>Items</p>
                      <Button
                        variant="outlined"
                        title="Add"
                        onClick={() => {
                          if (category?.length > 0) {
                            setOpenAddEditCategoryItemDialog(true)
                            setIsEdit(false)
                          }
                        }}
                        color="secondary"
                        disabled={category?.length <= 0}
                      >
                        <img src={AddSmall} alt="Add" />
                        <span>Add</span>
                      </Button>
                    </div>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="category-item-list">
                        {(provided) => (
                          <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="draggable-list category-item-list"
                          >
                            {categoryItem &&
                              categoryItem.map((element, index) => {
                                return (
                                  <Draggable
                                    key={element.menuItemId + index}
                                    draggableId={element.name}
                                    index={index}
                                  >
                                    {(providedDraggable, snapshot) => (
                                      <li
                                        className={`draggable-item ${
                                          snapshot.isDragging ? 'dragging' : ''
                                        } ${
                                          !element.isActive ? 'inactive' : ''
                                        }`}
                                        {...providedDraggable.draggableProps}
                                        ref={providedDraggable.innerRef}
                                        title={element.name}
                                      >
                                        <span
                                          className="drag-handler"
                                          {...providedDraggable.dragHandleProps}
                                        >
                                          <img src={Move} alt="Move" />
                                        </span>
                                        <span className="item-label  wrap-content">
                                          <span>{element.name}</span>
                                          <small>
                                            ${element?.price?.toFixed(2)}
                                          </small>
                                        </span>
                                        <span className="right">
                                          <Switch
                                            // label="Visibility"
                                            placement="start"
                                            defaultChecked={element.isActive}
                                            onChange={(e) =>
                                              handleSwitchChangeForItems(
                                                e,
                                                element
                                              )
                                            }
                                            className="small-switch"
                                          />
                                          <Button
                                            variant="text"
                                            title="Edit"
                                            className="icon-btn rounded light-bordered"
                                            color="inherit"
                                            onClick={() => {
                                              setOpenAddEditCategoryItemDialog(
                                                true
                                              )
                                              setIsEdit(true)
                                              setMenuId(element.menuItemId)
                                              setParentIdForUpdate(
                                                element.parentCategoryId
                                              )
                                            }}
                                          >
                                            <img src={Edit} alt="Edit" />
                                          </Button>
                                          <Button
                                            variant="text"
                                            title="Delete"
                                            className="icon-btn rounded light-bordered"
                                            color="inherit"
                                            onClick={() => {
                                              setParentCatForItems(
                                                element.parentCategoryId
                                              )
                                              toggleDeleteConfirmationDialogforItems(
                                                true,
                                                false,
                                                element.menuItemId,
                                                element.menuCategoryId,
                                                menu,
                                                menuId,
                                                element.parentCategoryId
                                              )
                                            }}
                                          >
                                            <img src={Delete} alt="Delete" />
                                          </Button>
                                        </span>
                                      </li>
                                    )}
                                  </Draggable>
                                )
                              })}
                            {provided.placeholder}
                            {categoryItem.length === 0 && (
                              <li>No Items available.</li>
                            )}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                  {/* category items list end */}
                </>
              )}
            </div>
          </TabPanel>
        </DialogContent>
        {/* dialog-content end */}
      </Dialog>

      {/* Delete Confirmation Dialog start */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmation}
        idFieldValue={tableIdForDelete}
        message="Are you sure you want to delete this Category?"
        callBack={(reason: DialogOptions, idFieldValue: number) => {
          toggleDeleteConfirmationDialog(
            false,
            reason === DialogOptions.Yes,
            idFieldValue,
            menuId,
            menu,
            parentCategoryId
          )
        }}
      />

      <DeleteConfirmationDialog
        open={openDeleteConfirmationItem}
        idFieldValue={tableIdForDelete}
        message="Are you sure you want to delete this Item?"
        callBack={(reason: DialogOptions, idFieldValue: number) => {
          toggleDeleteConfirmationDialogforItems(
            false,
            reason === DialogOptions.Yes,
            idFieldValue,
            catId,
            menu,
            menuId,
            parentCatForItems
          )
        }}
      />
      {/* Delete Confirmation Dialog end */}

      <DeleteConfirmationDialog
        open={openDeleteConfirmationFile}
        message="Are you sure you want to delete this file?"
        callBack={(reason: DialogOptions) => {
          toggleDeleteConfirmationDialogforFile(
            false,
            reason === DialogOptions.Yes
          )
        }}
      />

      {/* add-edit-category-dialog */}
      <AddEditCategoryDialog
        open={openAddEditCategoryDialog}
        menuId={menuId}
        isEdit={isEdit}
        totalCategory={category}
        categoryId={catId}
        editPosition={editPosition}
        editVisibility={visibility}
        closeDialog={closeAddEditCategoryDialog}
        editName={editName}
        getNewCatList={getNewCategories}
        menu={menu}
      />
      {/* <AddEditSubCategoryDialog
        open={openAddEditSubCategoryDialog}
        menuId={menuId}
        isEdit={isEdit}
        totalCategory={category}
        categoryId={catId}
        editPosition={editPosition}
        editVisibility={visibility}
        closeDialog={closeAddEditSubCategoryDialog}
        editName={editName}
        getNewCatList={getNewCategories}
        menu={menu}
        parentCategoryId={parentCategoryId}
      /> */}
      {/* add-edit-category-item-dialog */}
      <AddEditCategoryItemDialog
        open={openAddEditCategoryItemDialog}
        categoryId={catId}
        categoryList={categoryItem}
        closeDialog={closeAddEditCategoryItemDialog}
        isEdit={isEdit}
        menuId={MenuId}
        getNewList={getNewItems}
        menu={menu}
        currentMenuId={menuId}
      />
    </>
  )
}

export default MenuItemsDialog
