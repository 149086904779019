import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isloading } from 'features/loader/loader'

function Loader() {
  const isLoading = useSelector(isloading)

  useEffect(() => {
    if (isLoading) document.body.classList.add('show-loader')
    else document.body.classList.remove('show-loader')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <>
      {isLoading && (
        <div className="loader-wrapper">
          <div className="loader" />
        </div>
      )}
    </>
  )
}

export default Loader
