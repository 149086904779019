import React, { useEffect, useState } from 'react'
import { Pagination as MaterialPagination } from '@mui/material'
import Select from 'components/Select/Index'

interface Pagination {
  noOfRecords?: number
  onPageChange?: (value: number) => void
  onPageSizeChange?: (value: number) => void
}

function Pagination({
  noOfRecords = 1,
  onPageChange,
  onPageSizeChange,
}: Pagination) {
  const [paginationPageSize, setPaginationPageSize] = useState(50)
  const [noOfPage, setNoOfPage] = useState(1)

  useEffect(() => {
    let totalPages
    if (paginationPageSize === 0) {
      totalPages = 1
    } else {
      totalPages = Math.floor(
        noOfRecords / paginationPageSize +
          (noOfRecords % paginationPageSize > 0 ? 1 : 0)
      )
    }

    setNoOfPage(Number(totalPages))
  }, [noOfRecords, paginationPageSize])

  return (
    <>
      <div className="pagination-wrapper">
        <div className="total-pages">
          <span>Show</span>
          <div className="form-group">
            <Select
              items={[
                {
                  key: 50,
                  value: 50,
                },
                {
                  key: 100,
                  value: 100,
                },
                {
                  key: 'All',
                  value: 0,
                },
              ]}
              formikValue={paginationPageSize}
              handleSelectValue={(item: number) => {
                setPaginationPageSize(Number(item))
                onPageSizeChange(Number(item))
              }}
            />
          </div>
          <span>Entries</span>
        </div>
        <MaterialPagination
          count={Number(noOfPage)}
          shape="rounded"
          onChange={(event, value) => onPageChange(value)}
        />
      </div>
    </>
  )
}

export default Pagination
