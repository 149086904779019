import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import Aos from 'aos'
import { BackArrow } from 'assets/images'
import Button from 'components/Button/Index'
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/Index'
import Layout from 'components/Layout/Layout'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import { DialogOptions } from 'enums/Common'

import {
  selectedRestaurant,
  setSelectedRestuarant,
} from 'features/restaurant/restaurant'
import { useFormik } from 'formik'
import { IRestaurant, IRestaurantState } from 'interfaces/restaurant'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import restaurantService from 'services/restaurant.service'
import { resolveHTTP } from 'utility/helper'
import * as yup from 'yup'

Aos.init({
  duration: 600,
  once: true,
})

function AddEditRestaurant() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [currentID] = useState(
    location.state ? (location.state as IRestaurantState).restaurantId : null
  )
  const navigate = useNavigate()
  const [isEditMode] = useState(Number.isInteger(currentID))
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [restaurantIdForDelete, setRestaurantIdForDelete] = useState(0)
  const [disableSaveButton, setDisableSaveButton] = useState(false)
  const selectedRestraunt = useSelector(selectedRestaurant)
  const handleSave = async (formData: IRestaurant) => {
    setDisableSaveButton(true)
    formData.isActive = formData.isActiveNumber === 1
    if (isEditMode) {
      const [data] = await resolveHTTP(
        restaurantService.updateRestaurant(formData)
      )
      if (data) {
        toast.success('Restaurant updated successfully')
        navigate('/restaurants')
      }
    } else {
      const [data] = await resolveHTTP(
        restaurantService.createRestaurent(formData)
      )
      if (data) {
        toast.success('Restaurant created successfully')
        navigate('/restaurants')
      }
    }
  }

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Restaurant Name is required'),
      }),
    []
  )

  const formik = useFormik<IRestaurant>({
    initialValues: {
      isActiveNumber: 1,
      name: '',
      restaurantId: 0,
      financeTabVisible: true,
      operationsTabVisible: true,
      overviewTabVisible: true,
      serversTabVisible: true,
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => handleSave(values),
  })

  const getRestaurantDetail = useCallback(
    async (restaurantId: number) => {
      try {
        const data = await restaurantService.getResturantById({
          restaurantId,
        })
        const restaurantDetailData = data.data.data

        formik.setValues(restaurantDetailData.restaurant)
        formik.setFieldValue(
          'financeTabVisible',
          restaurantDetailData.restaurantDashboardViewResponseDTO
            .financeTabVisible
        )
        formik.setFieldValue(
          'operationsTabVisible',
          restaurantDetailData.restaurantDashboardViewResponseDTO
            .operationsTabVisible
        )
        formik.setFieldValue(
          'overviewTabVisible',
          restaurantDetailData.restaurantDashboardViewResponseDTO
            .overviewTabVisible
        )
        formik.setFieldValue(
          'serversTabVisible',
          restaurantDetailData.restaurantDashboardViewResponseDTO
            .serversTabVisible
        )
        formik.setFieldValue(
          'isActiveNumber',
          restaurantDetailData.restaurant.isActive ? 1 : 2
        )
      } catch (e) {
        toast.error('Error occured fetching details')
      }
    },
    [formik]
  )

  const toggleDeleteConfirmationDialog = useCallback(
    async (isOpen: boolean, isSubmit = false, id: number) => {
      setOpenDeleteConfirmation(isOpen)
      setRestaurantIdForDelete(id)
      if (isSubmit && !isOpen && id > 0) {
        const [result] = await resolveHTTP(
          restaurantService.deleteRestaurant({
            restaurantId: Number(currentID),
          })
        )
        if (result) {
          if (currentID === selectedRestraunt) {
            dispatch(setSelectedRestuarant({ selectedRestaurant: 0 }))
          }
          toast.success('Restaurant deleted successfully')
          navigate('/restaurants')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (Number.isInteger(currentID) && currentID > 0) {
      getRestaurantDetail(Number(currentID))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Layout
        title={
          isEditMode ? 'Edit  Restaurant | FasTab' : 'New Restaurant | FasTab'
        }
      >
        {/* page-top start */}
        <div className="page-top">
          <h3 className="page-title">
            {isEditMode ? 'Edit' : 'New'} Restaurant
          </h3>
          <div className="right">
            <Button
              variant="contained"
              color="primary"
              title="Back"
              isTypeLink
              to="/restaurants"
              size="small"
            >
              <img src={BackArrow} alt="Back" />
              <span>Back</span>
            </Button>
          </div>
        </div>
        {/* page-top end */}
        <div className="card mb-30" data-aos="fade-up">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={6}>
              <Grid item lg={8} xs={12}>
                <Grid container spacing={6}>
                  <Grid item sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        name="name"
                        value={formik.values?.name}
                        onChange={formik.handleChange}
                        error={!!formik.errors.name && formik.touched.name}
                        helperText={
                          !!formik.errors.name && formik.touched.name
                            ? formik.errors.name
                            : ''
                        }
                        label="Restaurant Name"
                        handleBlur={formik.handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Select
                        name="isActiveNumber"
                        formikValue={formik.values?.isActiveNumber}
                        handleSelectValue={(value) => {
                          formik.setFieldValue('isActiveNumber', value)
                        }}
                        label="Is Active"
                        items={[
                          {
                            key: 'Active',
                            value: 1,
                          },
                          {
                            key: 'Inactive',
                            value: 2,
                          },
                        ]}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <h6 className="section-title">Dashboard configuration</h6>
            <Grid container spacing={6}>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="overviewTabVisible"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.overviewTabVisible}
                        onChange={formik.handleChange}
                      />
                    }
                    label={<span style={{ color: '#737373' }}>Overview</span>}
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="financeTabVisible"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.financeTabVisible}
                        onChange={formik.handleChange}
                      />
                    }
                    label={<span style={{ color: '#737373' }}>Finance</span>}
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="operationsTabVisible"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.operationsTabVisible}
                        onChange={formik.handleChange}
                      />
                    }
                    label={<span style={{ color: '#737373' }}>Operations</span>}
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="serversTabVisible"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.serversTabVisible}
                        onChange={formik.handleChange}
                      />
                    }
                    label={<span style={{ color: '#737373' }}>Servers</span>}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="btn-list">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                title="Save"
                disabled={disableSaveButton}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="primary"
                isTypeLink
                to="/restaurants"
                title="Cancel"
              >
                Cancel
              </Button>
              {isEditMode && (
                <Button
                  variant="outlined"
                  color="primary"
                  isTypeLink
                  onClick={() =>
                    toggleDeleteConfirmationDialog(
                      true,
                      false,
                      Number(currentID)
                    )
                  }
                  title="Delete"
                >
                  Delete
                </Button>
              )}

              {isEditMode && (
                <div className="right">
                  <Button
                    variant="outlined"
                    color="primary"
                    isTypeLink
                    to="/restaurants/locations/add"
                    title="Add Location"
                    linkState={{
                      fromAddRestaurant: true,
                      restaurantId: +currentID,
                    }}
                  >
                    Add Location
                  </Button>
                </div>
              )}
            </div>
          </form>
        </div>
      </Layout>
      <DeleteConfirmationDialog
        open={openDeleteConfirmation}
        idFieldValue={restaurantIdForDelete}
        message="Are you sure you want to delete this Restaurant?"
        callBack={(reason: DialogOptions, idFieldValue: number) => {
          toggleDeleteConfirmationDialog(
            false,
            reason === DialogOptions.Yes,
            idFieldValue
          )
        }}
      />
      {/* wrapper end */}
    </>
  )
}

export default AddEditRestaurant
