/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */

import { Button, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { BackArrow, StarFilled, StarNormal, View } from 'assets/images'
import Footer from 'components/Footer/Index'
import Header from 'components/Header/Index'
import Sidebar from 'components/Sidebar/Index'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Aos from 'aos'
import DataTable from 'components/DataTable/Index'
import {
  GridRenderCellParams,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import { IDatagrid } from 'interfaces/datagrid'
import tabService from 'services/tab.service'
import {
  IGuestListDetails,
  IItemDetails,
  IPaymentDetails,
  ITabDetailsResponse,
} from 'interfaces/tab'
import moment from 'moment'
import CryptoJS from 'crypto-js'
import { useNavigate } from 'react-router-dom'
import { ROLE_SECRET_KEY } from 'utility/constants'
import { useDispatch } from 'react-redux'
import { setSelectedEstablishment } from 'features/establishment/establishment'
import { setSelectedRestuarant } from 'features/restaurant/restaurant'

Aos.init({
  duration: 600,
  once: true,
})

function TabDetails() {
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'checkNumber',
        sort: 'asc',
      },
    ],
  })
  const [totalRecords, setTotalRecords] = useState(5)
  const IsAdmin =
    Number(
      CryptoJS.AES.decrypt(
        localStorage.getItem('roleId'),
        ROLE_SECRET_KEY
      )?.toString(CryptoJS.enc.Utf8)
    ) === 1
  const PAYMENT_MODE = {
    5: 'Split by seat',
    6: 'Split by Guest',
    7: 'Split by Item',
    8: 'Pay in Full',
    9: 'Pay by Check',
  }
  const [tabdetails, setTabDetailResult] = useState<ITabDetailsResponse>()
  const [stepperLength, setStepperLength] = useState(0)
  const [paymentForTabDetails, setPaymentForTabDetails] =
    useState<IPaymentDetails[]>()
  const [guestForTabDetails, setGuestForTabDetails] =
    useState<IGuestListDetails[]>()
  const [itemForTabDetails, setItemForTabDetails] = useState<IItemDetails[]>()
  const [steps, setSteps] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  const getTabDetailsData = async () => {
    const tabDetailResult = await tabService.getTabDetailsData({
      tabId: Number(localStorage.getItem('tabIdforDetailRequest')),
    })
    setStepperLength(tabDetailResult?.data?.data?.trackingDetails.length)
    setTabDetailResult(tabDetailResult.data.data)
    if (tabDetailResult?.data.data) {
      tabDetailResult?.data.data.trackingDetails.forEach((element) => {
        if (element.actionName === 'PaymentModeSelected') {
          element.actionName = 'Payment Mode Selected'
        }
        if (element.actionName === 'TabClosed') {
          element.actionName = 'Tab Closed'
        }
        if (element.actionName === 'TabUpdate') {
          element.actionName = 'Tab Update'
        }
        if (element.actionName === 'TabAdd') {
          element.actionName = 'Tab Add'
        }
        if (element.actionName === 'FTPayment') {
          element.actionName = 'FT Payment'
        }
        if (element.actionName === 'POSPayment') {
          element.actionName = 'POS Payment'
        }
      })
    }
    setSteps(tabDetailResult?.data?.data?.trackingDetails)
  }
  const getPaymentForTabDetails = async () => {
    const paymentforTabDetail = await tabService.getPaymentForTabDetails({
      tabId: Number(localStorage.getItem('tabIdforDetailRequest')),
    })
    setPaymentForTabDetails(paymentforTabDetail.data.data)
  }
  const getGuestForTabDetailss = async () => {
    const guestDetailResult = await tabService.getGuestForTabDetails({
      tabId: Number(localStorage.getItem('tabIdforDetailRequest')),
    })
    setGuestForTabDetails(guestDetailResult.data.data)
  }
  const getItemForTabDetails = async () => {
    const itemDetailResult = await tabService.getItemForTabDetails({
      tabId: Number(localStorage.getItem('tabIdforDetailRequest')),
    })
    setItemForTabDetails(itemDetailResult.data.data)
  }
  const getTimedifference = () => {
    let date = new Date()
    date = new Date(
      date.toLocaleString('en-US', {
        timeZone: 'CST',
      })
    )
    const timeDiff =
      new Date(tabdetails?.closeTime ? tabdetails?.closeTime : date).getTime() -
      new Date(tabdetails?.openTime).getTime()
    // return new Date(timeDiff).toISOString().slice(11, 19)
    let seconds = timeDiff / 1000
    const hours = Math.trunc(seconds / 3600)
    seconds %= 3600
    const minutes = Math.trunc(seconds / 60)
    seconds %= 60
    seconds = Number(Math.round(seconds))
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )}:${String(seconds).padStart(2, '0')}`
  }
  const getPaymentMode = (paymentMode: number) => {
    if (!paymentMode) return '-'
    return PAYMENT_MODE[paymentMode]
  }
  const handleBackButtonClick = () => {
    localStorage.setItem('IsFromTabDetails', 'true')
    if (Boolean(localStorage.getItem('fromReview')) === true) {
      localStorage.removeItem('fromReview')
      navigate('/reviews')
    } else if (Boolean(localStorage.getItem('fromDashboard')) === true) {
      localStorage.removeItem('fromDashboard')
      navigate('/dashboard')
    } else {
      navigate('/tabs')
    }
  }
  useEffect(() => {
    if (
      localStorage.getItem('fromReview') !== null &&
      localStorage.getItem('fromReview') !== undefined &&
      Boolean(localStorage.getItem('fromReview')) === true
    ) {
      if (localStorage.getItem('establishmentIdForDetailRequest') != null) {
        dispatch(
          setSelectedRestuarant({
            selectedRestaurant: localStorage.getItem(
              'restaurantIdForDetailRequest'
            ),
          })
        )
      }
      if (localStorage.getItem('establishmentIdForDetailRequest') != null) {
        dispatch(
          setSelectedEstablishment({
            selectedEstablishment: localStorage.getItem(
              'establishmentIdForDetailRequest'
            ),
          })
        )
      }
    }

    getTabDetailsData()
    getPaymentForTabDetails()
    getItemForTabDetails()
    getGuestForTabDetailss()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Helmet>
        <title>TabDetails | FasTab</title>
      </Helmet>
      <Sidebar />
      {/* wrapper start */}
      <section className="wrapper">
        {/* header */}
        <Header />
        {/* main-content start */}
        <section className="main-content">
          {/* page-top start */}
          <div className="page-top">
            <h3 className="page-title">Tab Details</h3>
            <div className="right">
              <Button
                variant="contained"
                color="primary"
                title="Back"
                size="small"
                onClick={handleBackButtonClick}
              >
                <img src={BackArrow} alt="ADD" />
                <span>Back</span>
              </Button>
            </div>
          </div>
          {/* page-top end */}

          {/* Tab Detail Card start */}
          <div className="card tab-detail-summary tab-detail-card">
            <h1>
              ${tabdetails?.totalAmountPaid?.toFixed(2)}
              <em>
                {tabdetails?.closeTime ? (
                  <span className="badge success">Paid</span>
                ) : (
                  <span className="badge default">Open</span>
                )}
              </em>
            </h1>
            <ul>
              <li>
                <p className="tab-detail-summary-data">
                  Table
                  <span>{tabdetails?.tableName}</span>
                </p>
              </li>
              <li>
                <p className="tab-detail-summary-data">
                  Section
                  <span>
                    {tabdetails?.sectionName ? tabdetails?.sectionName : '-'}
                  </span>
                </p>
              </li>
              <li>
                <p className="tab-detail-summary-data">
                  Server
                  <span>
                    {tabdetails?.serverName ? tabdetails?.serverName : '-'}
                  </span>
                </p>
              </li>
            </ul>

            <div className="tab-detail-summary-right">
              <div>
                <h2>
                  Tab Id :&nbsp;<span> #{tabdetails?.tabIdExternal}</span>
                </h2>
                <h5>
                  Tab Id (Internal) :&nbsp;
                  <span> #{tabdetails?.tabIdInternal}</span>
                </h5>
              </div>
            </div>
          </div>
          {/* Tab Detail Card end */}

          {/* Tab Detail Card start */}
          <div className="card tab-detail-card">
            <div className="tab-detail-card-header">
              <h6>Payments</h6>
              <p className="tab-detail-summary-data tab-details-count-ele">
                <small> Number of Payments :</small>
                <p className="grid-length-count">
                  &nbsp;{tabdetails?.paymentCount}
                </p>
              </p>
              <p className="tab-detail-summary-data tab-details-count-ele">
                Number of Checks :
                <p className="grid-length-count">
                  &nbsp;{tabdetails?.checkCount}
                </p>
              </p>
            </div>
            <div className="card mb-30 p-0">
              <DataTable
                uniqueId="paymentOrderId"
                columnsData={[
                  {
                    field: 'checkNumber',
                    headerName: 'Check Number',
                    flex: 1,
                    minWidth: 90,
                  },
                  {
                    field: 'foodSubTotal',
                    headerName: 'Food Sub Total',
                    flex: 1,
                    minWidth: 90,
                    renderCell: (params: GridRenderCellParams<number>) => {
                      return `$${(
                        Math.round(Number(params.value) * 100) / 100
                      ).toFixed(2)}`
                    },
                  },
                  {
                    field: 'tax',
                    headerName: 'Tax',
                    flex: 1,
                    minWidth: 90,
                    renderCell: (params: GridRenderCellParams<number>) => {
                      return `$${(
                        Math.round(Number(params.value) * 100) / 100
                      ).toFixed(2)}`
                    },
                  },
                  {
                    field: 'serviceCharge',
                    headerName: 'Service Charge',
                    flex: 1,
                    minWidth: 90,
                    renderCell: (params: GridRenderCellParams<number>) => {
                      return `$${(
                        Math.round(Number(params.value) * 100) / 100
                      ).toFixed(2)}`
                    },
                  },
                  {
                    field: 'foodAndTotalTax',
                    headerName: 'Food + Tax Total',
                    flex: 1,
                    minWidth: 90,
                    renderCell: (params: GridRenderCellParams<number>) => {
                      return `$${(
                        Math.round(Number(params.value) * 100) / 100
                      ).toFixed(2)}`
                    },
                  },
                  {
                    field: 'tip',
                    headerName: 'Tip',
                    flex: 1,
                    minWidth: 90,
                    renderCell: (params: GridRenderCellParams<number>) => {
                      return `$${(
                        Math.round(Number(params.value) * 100) / 100
                      ).toFixed(2)}`
                    },
                  },
                  {
                    field: 'total',
                    headerName: 'Total',
                    flex: 1,
                    minWidth: 90,
                    renderCell: (params: GridRenderCellParams<number>) => {
                      return `$${(
                        Math.round(Number(params.value) * 100) / 100
                      ).toFixed(2)}`
                    },
                  },
                  {
                    field: 'status',
                    headerName: 'Status',
                    flex: 1,
                    minWidth: 100,
                    renderCell: (params: GridRenderCellParams<string>) => {
                      return params?.value === 'Paid' ? (
                        <span className="badge success">Paid</span>
                      ) : (
                        <span className="badge default"> Open </span>
                      )
                    },
                  },
                  {
                    field: 'paymentMode',
                    headerName: 'Payment Mode',
                    flex: 1,
                    minWidth: 140,
                    renderCell: (params: GridRenderCellParams<string>) => {
                      return getPaymentMode(Number(params.value))
                    },
                  },
                  {
                    field: 'paymentSource',
                    headerName: 'Payment Source',
                    flex: 1,
                    minWidth: 120,
                  },
                  {
                    field: 'ftOrderId',
                    headerName: 'FT Order ID',
                    flex: 1,
                    minWidth: 80,
                  },
                  {
                    field: 'posOrderId',
                    headerName: 'POS Order ID',
                    flex: 1,
                    minWidth: 80,
                  },
                ]}
                rowsData={paymentForTabDetails || []}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onSortModelChange={onSortChange}
                totalRecords={paymentForTabDetails?.length}
                sortingMode
                bottomPagination
              />
            </div>
          </div>
          {/* Tab Detail Card end */}

          {/* Tab Detail Card start */}
          <div className="card tab-detail-card">
            <div className="tab-detail-card-header">
              <h6>Items Ordered</h6>
              <p className="tab-detail-summary-data tab-details-count-ele">
                Number of Items Ordered :
                <p className="grid-length-count">
                  {' '}
                  &nbsp;{itemForTabDetails?.length}
                </p>
              </p>
            </div>
            <div className="card mb-30 p-0">
              <DataTable
                uniqueId="orderItemsId"
                columnsData={[
                  {
                    field: 'itemName',
                    headerName: 'Item Name',
                    flex: 1,
                    minWidth: 120,
                    renderCell: (params: GridRenderCellParams<string>) => {
                      return params.row.quantity > 1
                        ? `${params.value}(${params.row.quantity})`
                        : params.value
                    },
                  },
                  {
                    field: 'itemCost',
                    headerName: 'Item Cost',
                    flex: 1,
                    minWidth: 70,
                    renderCell: (params: GridRenderCellParams<number>) => {
                      return `$${(
                        Math.round(Number(params.value) * 100) / 100
                      ).toFixed(2)}`
                    },
                  },
                  {
                    field: 'checkNumber',
                    headerName: 'Check Number',
                    flex: 1,
                    minWidth: 70,
                  },
                ]}
                rowsData={itemForTabDetails || []}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onSortModelChange={onSortChange}
                totalRecords={totalRecords}
                sortingMode
                bottomPagination
              />
            </div>
          </div>
          {/* Tab Detail Card end */}

          {/* Tab Detail Card start */}
          <div className="card tab-detail-card">
            <div className="tab-detail-card-header">
              <h6>Feedback</h6>
              {guestForTabDetails?.length !== 0 && (
                <p className="tab-detail-summary-data">
                  Average Rating
                  <span className="star-rating">
                    {[...Array(5)].map((e, i) => {
                      if (
                        i <
                        Number(
                          guestForTabDetails
                            ? Math.round(guestForTabDetails[0]?.averageStars)
                            : 0
                        )
                      ) {
                        return (
                          <span key={i} className="star-rating">
                            <img src={StarFilled} alt="" />
                          </span>
                        )
                      }

                      return (
                        <span key={i} className="star-rating">
                          <img src={StarNormal} alt="" />
                        </span>
                      )
                    })}
                  </span>
                </p>
              )}
            </div>
            {guestForTabDetails?.length !== 0 ? (
              <div className="card mb-30 p-0">
                <DataTable
                  uniqueId="guestRatingId"
                  columnsData={[
                    {
                      field: 'guestName',
                      headerName: 'Guest Name',
                      flex: 1,
                      minWidth: 120,
                      // cellClassName: IsAdmin ? `` : `hide-column-name`,
                      renderCell: (params: GridRenderCellParams<string>) => {
                        return (
                          <div className="MuiDataGrid-cellContent">
                            {params.row.isReachOutExp || IsAdmin
                              ? params.value
                              : `Restaurant Guest`}
                          </div>
                        )
                      },
                    },
                    {
                      field: 'email',
                      headerName: 'Email',
                      flex: 1,
                      minWidth: 120,
                      // cellClassName: IsAdmin ? `` : `hide-column-name`,
                      renderCell: (params: GridRenderCellParams<string>) => {
                        return (
                          <div className="MuiDataGrid-cellContent">
                            {params.row.isReachOutExp || IsAdmin
                              ? params.value
                              : `Restaurant Guest`}
                          </div>
                        )
                      },
                    },
                    {
                      field: 'guestType',
                      headerName: 'Guest Type',
                      flex: 1,
                      minWidth: 100,
                    },
                    {
                      field: 'rating',
                      headerName: 'Rating',
                      flex: 1,
                      minWidth: 190,
                      renderCell: (params: GridRenderCellParams<number>) => {
                        return [...Array(5)].map((e, i) => {
                          if (i < Number(params.value)) {
                            return (
                              <span key={i} className="star-rating">
                                <img src={StarFilled} alt="" />
                              </span>
                            )
                          }
                          return (
                            <span key={i} className="star-rating">
                              <img src={StarNormal} alt="" />
                            </span>
                          )
                        })
                      },
                    },
                    {
                      field: 'feedbackCategories',
                      cellClassName: 'now-wrap-categories',
                      headerName: 'Feedback Categories',
                      flex: 1,
                      minWidth: 120,
                      renderCell: (params: GridRenderCellParams<string>) => {
                        return params.value
                          ? params.value
                          : 'No Feedback Received'
                      },
                    },
                    {
                      field: 'comments',
                      headerName: 'Comments',
                      flex: 1,
                      minWidth: 120,
                      renderCell: (params: GridRenderCellParams<string>) => {
                        return params.value ? (
                          <span className="wrap-comments">{params.value}</span>
                        ) : (
                          'No Comments Received'
                        )
                      },
                    },
                  ]}
                  rowsData={guestForTabDetails || []}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  onSortModelChange={onSortChange}
                  totalRecords={guestForTabDetails?.length}
                  sortingMode
                  bottomPagination
                />
              </div>
            ) : (
              <span className="no-feedback-data">No Feedback Received</span>
            )}
          </div>
          {/* Tab Detail Card end */}

          {/* Tab Detail Card start */}
          <div className="card tab-detail-card">
            <div className="tab-detail-card-header">
              <h6>Timeline</h6>
              <ul>
                <li>
                  <p className="tab-detail-summary-data">
                    Open Time(CT)
                    <span>
                      {moment(tabdetails?.openTime).format('hh:mm:ss A')}
                    </span>
                  </p>
                </li>
                <li>
                  <p className="tab-detail-summary-data">
                    Closed Time(CT)
                    <span>
                      {tabdetails?.closeTime
                        ? moment(tabdetails?.closeTime).format('hh:mm:ss A')
                        : '-'}
                    </span>
                  </p>
                </li>
                <li>
                  <p className="tab-detail-summary-data">
                    Visit Duration
                    <span>{tabdetails ? getTimedifference() : '00:00:00'}</span>
                  </p>
                </li>
              </ul>
            </div>
            <Stepper activeStep={stepperLength} orientation="vertical">
              {steps.map((step) => (
                <Step key={step?.trackingId}>
                  <StepLabel
                    optional={
                      <Typography variant="caption">
                        {step?.actionTime !== ''
                          ? moment(step?.actionTime).format(
                              'MMM D,YYYY, hh:mm:ss a'
                            )
                          : ''}
                      </Typography>
                    }
                  >
                    {step.actionName}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {/* Tab Detail Card end */}
        </section>
        {/* main-content end */}
        {/* footer */}
        <Footer />
      </section>
      {/* wrapper end */}
    </>
  )
}

export default TabDetails
