import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import React from 'react'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  message?: string
}

function AlertDialog(props: dialogProps) {
  const { open, onClose, message } = props

  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog"
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <p>{message}</p>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Ok"
              onClick={(e) => {
                onClose(e)
              }}
            >
              Ok
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AlertDialog
