export const EMPTY_STRING = ''
export const EMAIL_REGEX =
  /^(?=.{1,100}@)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
export const RANDOM_STRING_LENGTH = 5
export const PHONE_NUMBER_REGEX =
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
export const MAX_FILE_SIZE_FOR_RESTAURANT_LOGO = 1
export const PASSWORD_REGEX =
  // eslint-disable-next-line no-useless-escape
  // Old Regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*?])(?=.{8,})/
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
export const ROLE_SECRET_KEY = 'roleId&SecretKey'
export const QR_CODE_STICKER = 1
export const QR_CODE_TENT = 2
export const DEFAULT_PAGE_SIZE = 50
export const DEFAULT_PAGE_NUMBER = 1
export const NEW_QR_CODE_TENT = 3
export const ESTABLISHMENT_ID_FOR_NEW_TEMPLATE = [72, 2]
export const ESTABLISHMENT_ID_FOR_HT_LOCATION = [73, 3]
export const SIGNAL_R_HUB = {
  HUB_NAME: 'notify-client-connect',
  REQUEST_LIST_OF_TABLES_METHOD: 'requesttablelist',
  ON_RECIEVE_TABLE_LIST: 'onreceivetablelist',
  SYNC_PAYMENT_METHOD_LIST_FOR_PIXEL: 'SyncPaymentMethodListForPixel',
  ON_RECEIVE_PAYMENT_METHOD_LIST: 'OnReceivePaymentMethodList',
  CHECK_API_CONNECTIVITY: 'CheckApiConnectivity',
  ON_RECEIVE_RESPONSE_FROM_API: 'OnReceiveResponseFromAPI',
}
export const ESTABLISHMENT_IS_FOR_PIXEL_POINT = 74
export const POS_TENDER_TYPE = 'Tender-Type'
export const TENDER_TYPE_NAME_OPTION1 = 'FasTab Payment'
export const TENDER_TYPE_NAME_OPTION2 = 'fasttab'
export const TENDER_TYPE_NAME_OPTION3 = 'Fastab'
export const POS_CONFIG_KEYS = {
  API_KEY: 'Api-Key',
  LOCATION_ID: 'locationId',
}
export const PIXELPOINT_POS_ID = 2
export const POS_CONFIG_KEYS_LIST = [
  'Api-Key',
  'locationId',
  'Location-Email',
  'Tender-Type',
]
export const SQUIRREL_POS_ID = 4
export const ADMIN_ROLE_ID = 1
export const TRAY_POS_ID = 8
