/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputAdornment, TextField } from '@mui/material'
import React, { FocusEventHandler } from 'react'

interface props {
  label: string
  withAdornment?: boolean
  variant: 'standard' | 'filled' | 'outlined'
  adormentComponent?: React.ReactNode
  type?: string
  multiline?: boolean
  rows?: number
  autofocus?: boolean
  value?: string | number
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  error?: boolean
  helperText?: string
  name?: string
  handleBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  defaultValue?: string | number
  disable?: boolean
  inputLabelProps?: { shrink: boolean }
}

function Textfield({
  label,
  withAdornment = false,
  variant,
  autofocus,
  adormentComponent,
  type,
  multiline,
  rows,
  error,
  helperText,
  value,
  name,
  onChange,
  handleBlur,
  disable,
  defaultValue,
  inputLabelProps,
}: props) {
  return (
    <>
      <TextField
        label={label}
        variant={variant}
        type={type}
        disabled={disable}
        autoFocus={autofocus}
        value={value}
        error={error}
        onBlur={handleBlur}
        multiline={multiline}
        helperText={helperText}
        rows={rows}
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
        InputProps={{
          autoComplete: 'off',
          endAdornment: withAdornment ? (
            <InputAdornment position="end">{adormentComponent}</InputAdornment>
          ) : (
            <></>
          ),
        }}
        InputLabelProps={inputLabelProps}
      />
    </>
  )
}

export default Textfield
