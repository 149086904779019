import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'

export type Option = {
  label: string
  value: string
}

interface IReactSelectProp {
  items: Array<Option>
  label?: string
  placeholderValue?: string
  disabled?: boolean
  name?: string
  value?: string
  handleSelectValue?: (newValue: Option) => void
  handleCreate?: (inputValue: string) => void
}

function ReactSelect(props: IReactSelectProp) {
  const {
    label,
    items,
    name,
    placeholderValue,
    disabled,
    value,
    handleSelectValue,
    handleCreate,
  } = props
  const [focused, setFocused] = useState(false)
  const onHandleFocus = () => {
    setFocused(true)
  }
  const onHandleBlur = () => {
    setFocused(false)
  }
  const onSelectedValueChange = (newValue: Option) => {
    handleSelectValue(newValue)
  }
  const handleOptionCreate = (inputValue: string) => {
    handleCreate(inputValue)
  }
  return (
    <>
      <CreatableSelect
        name={name}
        options={items}
        classNamePrefix="react-select"
        onFocus={onHandleFocus}
        onBlur={onHandleBlur}
        onChange={onSelectedValueChange}
        placeholder={placeholderValue}
        isDisabled={disabled}
        onCreateOption={handleOptionCreate}
        value={items.find((x) => x.value === value)}
      />
      <span className={`react-select-label ${focused ? 'focused' : ''}`}>
        {label}
      </span>
    </>
  )
}

export default ReactSelect
