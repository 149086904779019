import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import Aos from 'aos'
import { ExportNew } from 'assets/images'
import Button from 'components/Button/Index'
import FasTabGrid from 'components/FasTabGrid/FasTabGrid'
import Layout from 'components/Layout/Layout'
import Select from 'components/Select/Index'
import {
  establishmentList,
  selectedEstablishment,
} from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IDatagrid } from 'interfaces/datagrid'
import { IMonthlyStatementsResponse } from 'interfaces/disbursement'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import monthlyStatementService from 'services/disbursement.service'
import paymentService from 'services/payment.service'

Aos.init({
  duration: 600,
  once: true,
})

function MonthlyStatements() {
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const [statementList, setStatementList] = useState<
    IMonthlyStatementsResponse[]
  >([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [startDate, setStartDate] = useState(null)
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 20,
    sortOrder: [
      {
        field: 'transactionmonth',
        sort: 'desc',
      },
    ],
  })
  const FILTER_STATEMENTS_STATUS = { MONTHLY: 1, SUBSCRIPTION: 0 }
  const [statementType, setStatementType] = useState<boolean>(true)
  const [invoiceId, setInvoiceId] = useState<string[]>([])
  const establishmentLists = useSelector(establishmentList)
  const currentEstablishment = establishmentLists.find(
    (x) => x.establishmentId === selectedEstablishmentId
  )
  const downLoadReport = async () => {
    if (selectionModel.map((d) => Number(d)).length === 0) {
      toast.error('Please make a selection to generate PDF.')
    } else {
      const promises = startDate.map(async (element, index) => {
        const currentDate = new Date(element)
        const pdfByte = await paymentService.getMonthlyReportDetails({
          isMonthlyStatement: currentEstablishment?.isSubscriptionActive
            ? statementType
            : true,
          restaurantId: selectedRestaurantId,
          establishmentId: selectedEstablishmentId,
          startDate: element,
          endDate: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            currentDate.getDate() - 1
          ).toISOString(),
          invoiceId: invoiceId.length ? invoiceId[index] : '',
        })
        const downloadLink = document.createElement('a')
        downloadLink.href = `data:application/pdf;base64,${pdfByte.data.data}`
        downloadLink.download = `${moment(element).format(
          'MMMYYYY'
        )}-statement.pdf`
        downloadLink.click()
      })
      await Promise.all(promises)
    }
  }

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  const onSelectionModelChange = (selectionModelData: GridSelectionModel) => {
    setSelectionModel(selectionModelData)
    setStartDate(
      selectionModelData.map((d) => statementList[d].transactionMonth)
    )
    setInvoiceId(selectionModelData.map((i) => statementList[i].invoiceId))
  }

  const getStatementListColumnDef = (): GridColDef[] => {
    return [
      {
        field: 'restaurantName',
        headerName: 'Restaurant Name',
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'locationName',
        headerName: 'Location Name',
        flex: 1,
        minWidth: 230,
      },
      {
        field: 'transactionMonth',
        headerName: 'Transaction Month',
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<Date>) => {
          return `${moment(params.value).format('MMM YYYY')}`
        },
      },
      {
        field: 'totalDollarProcessed',
        headerName: 'Total Amount Processed',
        flex: 1,
        minWidth: 180,
        hide: currentEstablishment?.isSubscriptionActive && !statementType,
        renderCell: (params: GridRenderCellParams<number>) => {
          return `$${params.value.toFixed(2)}`
        },
      },
      {
        field: 'totalProcessFees',
        headerName: 'Interchange Fees',
        flex: 1,
        minWidth: 180,
        hide: currentEstablishment?.isSubscriptionActive && !statementType,
        renderCell: (params: GridRenderCellParams<number>) => {
          return `$${params.value.toFixed(2)}`
        },
      },
      {
        field: 'totalAmountDisbursed',
        headerName:
          currentEstablishment?.isSubscriptionActive && !statementType
            ? 'Total Amount With Tax'
            : 'Total Amount Disbursed',
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<number>) => {
          return `$${params.value.toFixed(2)}`
        },
      },
    ]
  }

  const getStatementList = useCallback(
    async (
      sDate: string,
      eDate: string,
      restId,
      estId,
      pageNo: number,
      pageSize: number,
      sortOrder: GridSortModel,
      isMonthlyStatement: boolean
    ) => {
      const data = await monthlyStatementService.getMonthlyStatementList({
        isMonthlyStatement: currentEstablishment?.isSubscriptionActive
          ? isMonthlyStatement
          : true,
        startDate: sDate,
        endDate: eDate,
        restaurantId: restId,
        establishmentId: estId,
        pageNo,
        pageSize,
        sortGridModels: sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
      })
      if (data.data.data.data) {
        data.data.data.data.map((element, index) => {
          element.uniqueId = index
          return element
        })
        setStatementList(data.data.data.data)
      }
      setTotalRecords(data.data.data.totalRecords)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId, selectedEstablishmentId]
  )

  useEffect(() => {
    getStatementList(
      '2022-11-01',
      new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        .toISOString()
        .split('T')[0],
      selectedRestaurantId,
      selectedEstablishmentId,
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      statementType
    )
  }, [
    selectedRestaurantId,
    selectedEstablishmentId,
    dataGridOptions,
    getStatementList,
    statementType,
  ])

  return (
    <Layout title="Statements | FasTab">
      <></>
      {selectedRestaurantId !== 0 &&
      selectedRestaurantId !== null &&
      selectedEstablishmentId !== 0 &&
      selectedEstablishmentId !== null ? (
        <>
          <div className="page-top">
            <h3 className="page-title">Monthly Statements</h3>
            <div className="right">
              {currentEstablishment?.isSubscriptionActive && (
                <div className="form-group" style={{ marginBottom: '0px' }}>
                  <Select
                    label="Statement Type"
                    items={[
                      {
                        key: 'Transaction',
                        value: FILTER_STATEMENTS_STATUS.MONTHLY,
                      },
                      {
                        key: 'Subscription',
                        value: FILTER_STATEMENTS_STATUS.SUBSCRIPTION,
                      },
                    ]}
                    handleSelectValue={(item) => {
                      if (item) {
                        setStatementType(true)
                      } else {
                        setStatementType(false)
                      }
                    }}
                    formikValue={statementType ? 1 : 0}
                  />
                </div>
              )}

              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                title="Download"
                size="small"
                className="btn-h-40"
                onClick={downLoadReport}
              >
                <img src={ExportNew} alt="File" />
                <span>Export Pdf</span>
              </Button>
            </div>
          </div>
          <div className="card mb-30 p-0" data-aos="fade-up">
            <FasTabGrid
              checkboxSelection
              uniqueId="uniqueId"
              columnsData={getStatementListColumnDef()}
              gridData={statementList}
              totalRecords={totalRecords}
              onGridPageChange={onPageChange}
              onGridPageSizeChange={onPageSizeChange}
              onGridSortChange={onSortChange}
              onSelectionModelChange={onSelectionModelChange}
            />
          </div>
        </>
      ) : (
        <section className="main-content no-data">
          <p>Please Select Restaurant And Establishment To View Statements</p>
        </section>
      )}
    </Layout>
  )
}

export default MonthlyStatements
