/* eslint-disable no-nested-ternary */
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro'
import { Close, ExportNew, StarFilled, StarNormal } from 'assets/images'
import Button from 'components/Button/Index'
import DataTable from 'components/DataTable/Index'
import { IDatagrid } from 'interfaces/datagrid'
import React, { useCallback, useEffect, useState } from 'react'
import guestService from 'services/guest.service'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  guestId?: number
}

function GuestReview(props: dialogProps) {
  const { open, onClose, guestId } = props
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: '',
        sort: 'asc',
      },
    ],
  })
  const [guestReviewList, setguestReviewList] = useState([])

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }
  const getStarHtml = (value: number) => {
    return [...Array(5)].map((e, i) => {
      const keyVal = i
      if (keyVal < Number(value)) {
        return (
          <span key={keyVal} className="star-rating">
            <img src={StarFilled} alt="" />
          </span>
        )
      }
      return (
        <span key={keyVal} className="star-rating">
          <img src={StarNormal} alt="" />
        </span>
      )
    })
  }
  const getguestReviewList = useCallback(
    async (guestIdVal: number) => {
      try {
        const guestData = await guestService.getGuestReview({
          guestId: guestIdVal,
        })
        setguestReviewList(guestData.data.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exportCSVFile = (
    headers: {
      GuestName: string
      Rating: string
      FeedbackCategory: string
      Comment: string
      RestaurantName: string
    },
    csv:
      | string
      | Blob
      | import('../../interfaces/restaurant').ICsvDownload
      | ArrayBufferView
      | ArrayBuffer,
    fileTitle: string
  ) => {
    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

    const blob = new Blob([csv as BlobPart], {
      type: 'text/csv;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
  const downloadFile = async () => {
    const data = await guestService.downloadRatingCsv({
      guestId,
    })

    const headers = {
      GuestName: 'GuestName'.replace(/,/g, ''),
      Rating: 'Rating'.replace(/,/g, ''), // remove commas to avoid errors
      FeedbackCategory: 'FeedbackCategory'.replace(/,/g, ''),
      Comment: 'Comment'.replace(/,/g, ''),
      RestaurantName: 'RestaurantName'.replace(/,/g, ''),
    }
    const fileTitle = 'Guests'
    exportCSVFile(headers, data.data.data, fileTitle)
  }
  useEffect(() => {
    if (open) {
      getguestReviewList(guestId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions, open])

  return (
    <>
      <Dialog
        className="primary-dialog details-dialog"
        maxWidth={false}
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <h5>Guest Ratings</h5>
          <div className="right right-button">
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              size="small"
              title="Export"
              className="btn-h-40 export-button"
              onClick={() => downloadFile()}
            >
              <img src={ExportNew} alt="File" />
              <span>Export</span>
            </Button>
          </div>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent>
          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="guestRatingid"
              columnsData={[
                {
                  field: 'fullName',
                  headerName: 'Guest Name',
                  flex: 1,
                  minWidth: 100,
                },
                {
                  field: 'starRating',
                  headerName: 'Rating',
                  flex: 1,
                  minWidth: 190,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return getStarHtml(params.value)
                  },
                },
                {
                  field: 'feedbackCategories',
                  cellClassName: 'now-wrap-categories',
                  headerName: 'Feedback Categories',
                  flex: 1,
                  minWidth: 120,
                  renderCell: (params: GridRenderCellParams<string>) => {
                    let feedbackCategoryString = ''
                    if (params.row.isAtmosphereGood)
                      feedbackCategoryString += 'Atmosphere |'
                    if (params.row.isFoodGood)
                      feedbackCategoryString += ' Food |'
                    if (params.row.isHygieneGood)
                      feedbackCategoryString += ' Hygiene |'
                    if (params.row.isPaymentGood)
                      feedbackCategoryString += ' Payment |'
                    if (params.row.isServiceGood)
                      feedbackCategoryString += ' Service |'
                    if (params.row.isValueGood)
                      feedbackCategoryString += ' Value |'
                    if (params.row.isWaitTimeGood)
                      feedbackCategoryString += ' Wait Time |'
                    if (feedbackCategoryString !== '') {
                      feedbackCategoryString = feedbackCategoryString.substr(
                        0,
                        feedbackCategoryString.length - 1
                      )
                    }
                    return feedbackCategoryString === ''
                      ? 'No Feedback Received'
                      : feedbackCategoryString
                  },
                },
                {
                  field: 'additionalFeedback',
                  headerName: 'Comments',
                  flex: 1,
                  minWidth: 120,
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return params.value ? (
                      <span className="wrap-comments">{params.value}</span>
                    ) : (
                      'No Comments Received'
                    )
                  },
                },
                {
                  field: 'restaurantName',
                  headerName: 'Restaurant Name',
                  flex: 1,
                  minWidth: 120,
                },
              ]}
              rowsData={guestReviewList}
              onSortModelChange={onSortChange}
              sortingMode
              bottomPagination
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default GuestReview
