import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Select from 'components/Select/Index'
import { useFormik } from 'formik'
import { ICloneMenuRequest } from 'interfaces/establishment'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import establishmentService from 'services/establishment.service'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  closeDialog?: (event: boolean) => void
  fromLocationId?: number
  fromRestaurantId?: number
}
function CloneMenuDialog(props: dialogProps) {
  const { open, onClose, fromLocationId, fromRestaurantId, closeDialog } = props
  const handleSave = async (formdata: ICloneMenuRequest) => {
    const data = await establishmentService.cloneMenu(formdata)
    if (data.data.message) {
      closeDialog(false)
      toast.success('Menu cloned successfully')
    }
  }
  const [establishmentList, setEstablishmentList] = useState([])

  const getEstablishmentListByRestaurantId = useCallback(
    async (id: number, locationId: number) => {
      try {
        const data = await establishmentService.getEstablishmentsByRestaurantId(
          {
            restaurantId: id,
          }
        )
        let filtered
        if (data) {
          filtered = data.data.data.filter(
            (obj: { establishmentId: number }) =>
              obj.establishmentId !== locationId
          )
          const list = filtered.map((e) => {
            return {
              key: e.establishmentName,
              value: e.establishmentId,
            }
          })
          setEstablishmentList(list)
        }
      } catch (e) {
        if (localStorage.length !== 0) {
          toast.error('Failed to retrieve establishment list.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const formik = useFormik({
    initialValues: {
      fromEstablishmentId: 0,
      toEstablishmentId: 0,
    },
    onSubmit: (values) => handleSave(values),
  })

  useEffect(() => {
    if (fromLocationId !== 0) {
      getEstablishmentListByRestaurantId(fromRestaurantId, fromLocationId)
      formik.setFieldValue('fromEstablishmentId', fromLocationId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      <Dialog
        className="primary-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <h5>Clone Menu</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent className="pb-0">
            <div className="form-group">
              <Select
                label="Establishment"
                items={establishmentList}
                formikValue={formik.values?.toEstablishmentId}
                handleSelectValue={(value) => {
                  formik.setFieldValue('toEstablishmentId', value)
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="btn-list">
              <Button
                variant="contained"
                color="primary"
                title="Save"
                type="submit"
              >
                Save
              </Button>
              <Button
                onClick={onClose}
                title="Cancel"
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default CloneMenuDialog
