import { FormControlLabel, Checkbox as MaterialCheckbox } from '@mui/material'
import { Checked, Unchecked } from 'assets/images'
import React from 'react'

interface props {
  label?: string
  classes?: string
  onChange?: React.ChangeEventHandler<Element>
  checked?: boolean
  name?: string
}

function Checkbox({ label, classes, onChange, checked, name }: props) {
  return (
    <>
      <FormControlLabel
        className={`primary-checkbox ${classes}`}
        control={
          <MaterialCheckbox
            name={name}
            onChange={onChange}
            icon={<img src={Unchecked} alt="Check" />}
            checkedIcon={<img src={Checked} alt="Checked" />}
            checked={checked}
          />
        }
        label={label}
      />
    </>
  )
}

export default Checkbox
