import { createSlice } from '@reduxjs/toolkit'
import { IEstablishmentSearchResponse } from 'interfaces/establishment'

const establishmentSlice = createSlice({
  name: 'establishment',
  initialState: {
    selectedEstablishment: null,
    establishmentList: [],
    currentEstablishment: null,
  },
  reducers: {
    setSelectedEstablishment: (state, action) => {
      const { selectedEstablishment } = action.payload
      state.selectedEstablishment = selectedEstablishment
    },
    setEstablishments: (state, action) => {
      const { establishmentList } = action.payload
      state.establishmentList = establishmentList
    },
    setCurrentEstablishment: (state, action) => {
      const { currentEstablishment } = action.payload
      state.currentEstablishment = currentEstablishment
    },
  },
})

export const {
  setSelectedEstablishment,
  setEstablishments,
  setCurrentEstablishment,
} = establishmentSlice.actions

export default establishmentSlice.reducer

export const establishmentList = (state: {
  establishment: { establishmentList: IEstablishmentSearchResponse[] }
}) => state.establishment.establishmentList

export const selectedEstablishment = (state: {
  establishment: { selectedEstablishment: number }
}) => state.establishment.selectedEstablishment

export const currentEstablishment = (state: {
  establishment: { currentEstablishment: IEstablishmentSearchResponse }
}) => state.establishment.currentEstablishment
