/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import reportingService from 'services/reporting.service'
import CryptoJS from 'crypto-js'
import { ADMIN_ROLE_ID, ROLE_SECRET_KEY } from 'utility/constants'
import OverviewView from './OverviewView'
import FinanceView from './FinanceView'
import OperationsView from './OperationsView'
import ServerView from './ServerView'
import GuestView from './GuestView'
import LiveMetricsDashboard from './LiveMetricsDashboard'

function Dashboard({
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
  impersonateOn,
  showsDate,
}) {
  const [activeTab, setActiveTab] = useState()
  const selectedRestraunt = useSelector(selectedRestaurant)
  const [tabsToShow, setTabsToShow] = useState({
    financeTabVisible: false,
    operationsTabVisible: false,
    overviewTabVisible: false,
    serversTabVisible: false,
  })

  //  Functions to handle Tab Switching
  const handleTab = (tab, showDate) => {
    // update the state to tab1
    setActiveTab(tab)
    localStorage.setItem('ShowDateCalender', showDate)
    showsDate()
  }

  // const getFirstTruthyItem = (obj) =>
  //   Object.keys(obj).find((i) => obj[i] === true)

  const getTabsDetail = useCallback(
    async (paramimpersonateOn) => {
      try {
        const data = await reportingService.getTabsInfo({
          restaurantId: selectedRestraunt,
        })
        const tabDetails = data.data.data
        if (
          Number(
            CryptoJS.AES.decrypt(
              localStorage.getItem('roleId'),
              ROLE_SECRET_KEY
            )?.toString(CryptoJS.enc.Utf8)
          ) === ADMIN_ROLE_ID &&
          !paramimpersonateOn
        ) {
          setTabsToShow({
            financeTabVisible: true,
            operationsTabVisible: true,
            overviewTabVisible: true,
            serversTabVisible: true,
          })
        } else {
          setTabsToShow(tabDetails)
        }
        setActiveTab('tab6')
        // const active = getFirstTruthyItem(tabDetails)
        // if (active === 'overviewTabVisible') {
        //   setActiveTab('tab1')
        // } else if (active === 'financeTabVisible') {
        //   setActiveTab('tab2')
        // } else if (active === 'operationsTabVisible') {
        //   setActiveTab('tab3')
        // } else if (active === 'serversTabVisible') {
        //   setActiveTab('tab4')
        // } else {
        //   setActiveTab()
        // }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },
    [selectedRestraunt]
  )

  useEffect(() => {
    if (selectedRestraunt !== null) {
      getTabsDetail(impersonateOn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestraunt, impersonateOn])

  return (
    <div className="dashboard-tabs">
      {/* Tab nav */}
      <ul className="nav">
        <li
          style={{ cursor: 'pointer' }}
          className={activeTab === 'tab6' ? 'active' : ''}
          onClick={() => handleTab('tab6', 'false')}
        >
          Live Metrics
        </li>
        {tabsToShow.overviewTabVisible && (
          <li
            style={{ cursor: 'pointer' }}
            className={activeTab === 'tab1' ? 'active' : ''}
            onClick={() => handleTab('tab1', 'true')}
          >
            Overview
          </li>
        )}
        {tabsToShow.financeTabVisible && (
          <li
            style={{ cursor: 'pointer' }}
            className={activeTab === 'tab2' ? 'active' : ''}
            onClick={() => handleTab('tab2', 'true')}
          >
            Finance
          </li>
        )}
        {tabsToShow.operationsTabVisible && (
          <li
            style={{ cursor: 'pointer' }}
            className={activeTab === 'tab3' ? 'active' : ''}
            onClick={() => handleTab('tab3', 'true')}
          >
            Operations
          </li>
        )}
        {tabsToShow.serversTabVisible && (
          <li
            style={{ cursor: 'pointer' }}
            className={activeTab === 'tab4' ? 'active' : ''}
            onClick={() => handleTab('tab4', 'true')}
          >
            Servers
          </li>
        )}
        {/* <li
          className={activeTab === 'tab5' ? 'active' : ''}
          onClick={() => handleTab('tab5')}
        >
          Guests
        </li> */}
      </ul>
      <div className="outlet">
        {activeTab === 'tab1' && (
          <OverviewView
            startDate={startDate}
            endDate={endDate}
            prevStartDate={prevStartDate}
            prevEndDate={prevEndDate}
          />
        )}
        {activeTab === 'tab2' && (
          <FinanceView
            startDate={startDate}
            endDate={endDate}
            prevStartDate={prevStartDate}
            prevEndDate={prevEndDate}
          />
        )}
        {activeTab === 'tab3' && (
          <OperationsView
            startDate={startDate}
            endDate={endDate}
            prevStartDate={prevStartDate}
            prevEndDate={prevEndDate}
          />
        )}
        {activeTab === 'tab4' && (
          <ServerView startDate={startDate} endDate={endDate} />
        )}
        {activeTab === 'tab5' && (
          <GuestView startDate={startDate} endDate={endDate} />
        )}
        {activeTab === 'tab6' && <LiveMetricsDashboard />}
      </div>
    </div>
  )
}

export default Dashboard
