import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'

interface PercentageDetailProps {
  number: number
}

function PercentageDetail({ number }: PercentageDetailProps) {
  return (
    <div>
      {number < 0 ? (
        <div style={{ display: 'flex' }}>
          <TrendingDownIcon style={{ color: '#FFD700', marginRight: '4px' }} />
          <span>{Math.abs(Math.round(number))}% vs previous period</span>
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          <TrendingUpIcon style={{ color: 'green', marginRight: '4px' }} />
          <span>{Math.abs(Math.round(number))}% vs previous period</span>
        </div>
      )}
    </div>
  )
}

export default PercentageDetail
