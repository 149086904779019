import Button from 'components/Button/Index'
import Footer from 'components/Footer/Index'
import Header from 'components/Header/Index'
import Sidebar from 'components/Sidebar/Index'
import React from 'react'
import { Helmet } from 'react-helmet'

function Unauthorized() {
  return (
    <>
      <Helmet>
        <title>Error 401 | FasTab</title>
      </Helmet>
      <Sidebar />
      {/* wrapper start */}
      <section className="wrapper">
        {/* header */}
        <Header />
        {/* main-content start */}
        <section className="main-content error-message">
          <h1>401</h1>
          <h2>Authorization Required !</h2>
          <p>You do not have the access to the page you are looking for.</p>
          <Button
            variant="contained"
            isTypeLink
            to="/dashboard"
            color="primary"
            title="Back To Home"
          >
            Back To Home
          </Button>
        </section>
        {/* main-content end */}
        {/* footer */}
        <Footer />
      </section>
      {/* wrapper end */}
    </>
  )
}

export default Unauthorized
