import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IDeletePromocodeRequestDTO,
  INoOfVisit,
  INoOfVisitResponse,
  IPromocdeRequest,
  IPromocodeAddRequestDTO,
  IPromocodeListRequestDTO,
  IPromoListResponseDTO,
  IUpdatePromocodeRequestDTO,
} from 'interfaces/promocode'
import { ISearchResponse } from 'interfaces/restaurant'
import httpClient from './base-service'

const endPointBaseURL = `/Promocode`

const getPromocodeList = async (
  requestBody: IPromocodeListRequestDTO
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IPromoListResponseDTO[]>>>
> =>
  httpClient.post<
    IApiSuccessResponse<ISearchResponse<IPromoListResponseDTO[]>>
  >(`${endPointBaseURL}/get-promocode-list`, requestBody)

const addPromocode = async (
  requestBody: IPromocodeAddRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-promocode`,
    requestBody
  )

const deletePromocode = async (
  requestBody: IDeletePromocodeRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/delete-promocode`,
    requestBody
  )

const activeInactivePromocode = async (
  requestBody: IUpdatePromocodeRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-promocode-status`,
    requestBody
  )

const getGuestByNumberOfVisit = async (
  requestBody: INoOfVisit
): Promise<AxiosResponse<IApiSuccessResponse<INoOfVisitResponse>>> =>
  httpClient.post<IApiSuccessResponse<INoOfVisitResponse>>(
    `${endPointBaseURL}/get-login-list-by-number-of-visit`,
    requestBody
  )
const getPromocodeById = async (
  requestBody: IPromocdeRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPromocodeAddRequestDTO>>> =>
  httpClient.post<IApiSuccessResponse<IPromocodeAddRequestDTO>>(
    `${endPointBaseURL}/get-promocode`,
    requestBody
  )

export default {
  getPromocodeList,
  addPromocode,
  deletePromocode,
  activeInactivePromocode,
  getGuestByNumberOfVisit,
  getPromocodeById,
}
