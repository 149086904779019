import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import restuaranReducer from './features/restaurant/restaurant'
import userReducer from './features/user/user'
import menuReducer from './features/menu/menu'
import loadingReducer from './features/loader/loader'
import establishmentReducer from './features/establishment/establishment'
import tabReducer from './features/tabpayload/tabpayload'

const rootReducer = combineReducers({
  restaurant: restuaranReducer,
  user: userReducer,
  isLoading: loadingReducer,
  menu: menuReducer,
  establishment: establishmentReducer,
  payloadState: tabReducer,
})

const persistConfig = {
  key: 'main-root',
  storage,
  blacklist: ['isLoading'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
})

export const persistor = persistStore(store)
