/* eslint-disable react/jsx-filename-extension */
import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { TrayWhite, UserWhite } from 'assets/images'
import { IDashboardFinanceDetailsResponse } from 'interfaces/reporting'
import { useSelector } from 'react-redux'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import {
  selectedEstablishment,
  establishmentList,
} from 'features/establishment/establishment'
import moment from 'moment'
import reportingService from 'services/reporting.service'
import CalculateChange from './ChangeCalculator'
import PercentageDetail from './PercentageDetail'
import NoData from './NoData'
import StatDetail from './StatDetail'
import formatCurrency from './formatters'

interface OverviewProps {
  startDate: Date
  endDate: Date
  prevStartDate: Date
  prevEndDate: Date
}

function FinanceView({
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
}: OverviewProps) {
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedCurrentEstablishment = useSelector(selectedEstablishment)
  const allEstablishmentList = useSelector(establishmentList)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [reportingData, setReportingData] =
    useState<IDashboardFinanceDetailsResponse[]>()
  const [reportingPrevDailyData, setReportingPrevDailyData] =
    useState<IDashboardFinanceDetailsResponse[]>()

  const getData = useCallback(
    async (
      restaurantId: number,
      establishmentId: number,
      startingDate: Date | null,
      endingDate: Date | null,
      prevStartingDate: Date | null,
      prevEndingDate: Date | null
    ) => {
      await Promise.all([
        await reportingService.getFinanceInfo({
          restaurantId,
          establishmentId:
            establishmentId > 0
              ? [establishmentId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          startDate: moment(startingDate)
            .set('date', startingDate.getDate())
            .set('month', startingDate.getMonth())
            .toISOString()
            .split('T')[0],
          endDate: moment(endingDate)
            .set('date', endingDate.getDate() - 1)
            .toISOString()
            .split('T')[0],
        }),
        await reportingService.getFinanceInfo({
          restaurantId,
          establishmentId:
            establishmentId > 0
              ? [establishmentId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          startDate: prevStartingDate?.toISOString().split('T')[0],
          endDate: moment(prevEndingDate)
            .set('date', prevEndingDate.getDate() - 1)
            .toISOString()
            .split('T')[0],
        }),
      ])
        .then(([data, prevData]) => {
          setReportingData(data.data.data)
          setReportingPrevDailyData(prevData.data.data)
        })
        .then(() => {
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedRestaurantId,
      selectedCurrentEstablishment,
      startDate,
      endDate,
      prevStartDate,
      prevEndDate,
    ]
  )

  useEffect(() => {
    setIsLoading(true)
    getData(
      selectedRestaurantId,
      selectedCurrentEstablishment,
      startDate,
      endDate,
      prevStartDate,
      prevEndDate
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRestaurantId,
    selectedCurrentEstablishment,
    startDate,
    endDate,
    prevStartDate,
    prevEndDate,
  ])

  if (isLoading) {
    return <></>
  }

  function calculateAverage(dataArray, property1, property2) {
    let totalProp1 = 0
    let totalProp2 = 0
    dataArray?.forEach((item) => {
      if (item[property1]) {
        totalProp1 += item[property1]
      }
    })

    dataArray?.forEach((item) => {
      if (item[property2]) {
        totalProp2 += item[property2]
      }
    })
    if (totalProp2 === 0) {
      return 0
    }
    const avg = totalProp1 / totalProp2
    return avg
  }

  function calculateTotal(dataArray, property) {
    let total = 0
    dataArray?.forEach((item) => {
      if (item[property]) {
        total += item[property]
      }
    })
    return total
  }

  return (
    <div className="Tabs">
      <Grid container spacing={6}>
        <Grid item lg={4} sm={4} xs={6}>
          <div className="card stats" data-aos="fade-up">
            <StatDetail
              title="Earnings Per Minute"
              description={
                <p className="stat-description">
                  This is the total earnings per minute of guest time spent at
                  your restaurant over the time period selected. This allows you
                  to understand how efficiently your restaurant is operating
                </p>
              }
              content={
                <>
                  {reportingData ? (
                    <>
                      <p>
                        {formatCurrency(
                          calculateAverage(
                            reportingData,
                            'tabTotalSum',
                            'tabTimeMinsSum'
                          )
                        )}
                      </p>
                      <small>&nbsp;</small>
                      <PercentageDetail
                        number={CalculateChange(
                          calculateAverage(
                            reportingData,
                            'tabTotalSum',
                            'tabTimeMinsSum'
                          ),
                          calculateAverage(
                            reportingPrevDailyData,
                            'tabTotalSum',
                            'tabTimeMinsSum'
                          )
                        )}
                      />
                      <hr />
                      <h4>Previous Period</h4>
                      <h6>
                        {formatCurrency(
                          calculateAverage(
                            reportingPrevDailyData,
                            'tabTotalSum',
                            'tabTimeMinsSum'
                          )
                        )}
                      </h6>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={TrayWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>

        <Grid item lg={4} sm={4} xs={6}>
          <div className="card stats" data-aos="fade-up">
            <StatDetail
              title="Average Tab Value"
              description={
                <p className="stat-description">
                  This is the average amount of money guests spent on each tab
                  over the period of time selected. This allows you to
                  understand, on average, how much your guests order every time
                  they sit down at your restaurant
                </p>
              }
              content={
                <>
                  {reportingData ? (
                    <>
                      <p>
                        {formatCurrency(
                          calculateAverage(
                            reportingData,
                            'tabTotalSum',
                            'tabCount'
                          )
                        )}
                      </p>
                      <small>&nbsp;</small>
                      <PercentageDetail
                        number={CalculateChange(
                          calculateAverage(
                            reportingData,
                            'tabTotalSum',
                            'tabCount'
                          ),
                          calculateAverage(
                            reportingPrevDailyData,
                            'tabTotalSum',
                            'tabCount'
                          )
                        )}
                      />
                      <hr />
                      <h4>Previous Period</h4>
                      <h6>
                        {formatCurrency(
                          calculateAverage(
                            reportingPrevDailyData,
                            'tabTotalSum',
                            'tabCount'
                          )
                        )}
                      </h6>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={TrayWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>

        <Grid item lg={4} sm={4} xs={6}>
          <div className="card stats" data-aos="fade-up" data-aos-delay="100">
            <StatDetail
              title="Total Tabs"
              description={
                <p className="stat-description">
                  This is the number of tabs completed over the time period
                  selected. This allows you to understand how many tables were
                  completed, indicating table volume
                </p>
              }
              content={
                <>
                  {reportingData ? (
                    <>
                      <p>{calculateTotal(reportingData, 'tabCount')}</p>
                      <small>&nbsp;</small>
                      <PercentageDetail
                        number={CalculateChange(
                          calculateTotal(reportingData, 'tabCount'),
                          calculateTotal(reportingPrevDailyData, 'tabCount')
                        )}
                      />
                      <hr />
                      <h4>Previous Period</h4>
                      <h6>
                        {calculateTotal(reportingPrevDailyData, 'tabCount')}
                      </h6>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={UserWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>

        <Grid item lg={4} sm={4} xs={6}>
          <div className="card stats" data-aos="fade-up" data-aos-delay="100">
            <StatDetail
              title="Average Tab Value"
              description={
                <p className="stat-description">
                  This is the average tab value for you best, middle, and worst
                  3rd of tabs, over the time period selected. This illustrates
                  the distribution of tab amounts at your restaurant
                </p>
              }
              content={
                <>
                  {reportingData ? (
                    <>
                      <p>
                        {formatCurrency(
                          calculateAverage(
                            reportingData,
                            'tabTotalTop',
                            'tabCountTop'
                          )
                        )}
                        <small>
                          <PercentageDetail
                            number={CalculateChange(
                              calculateAverage(
                                reportingData,
                                'tabTotalTop',
                                'tabCountTop'
                              ),
                              calculateAverage(
                                reportingPrevDailyData,
                                'tabTotalTop',
                                'tabCountTop'
                              )
                            )}
                          />
                        </small>
                        <small>Top 3rd</small>
                      </p>
                      <p>
                        {formatCurrency(
                          calculateAverage(
                            reportingData,
                            'tabTotalMiddle',
                            'tabCountMiddle'
                          )
                        )}
                        <small>
                          <PercentageDetail
                            number={CalculateChange(
                              calculateAverage(
                                reportingData,
                                'tabTotalMiddle',
                                'tabCountMiddle'
                              ),
                              calculateAverage(
                                reportingPrevDailyData,
                                'tabTotalMiddle',
                                'tabCountMiddle'
                              )
                            )}
                          />
                        </small>
                        <small>Middle 3rd</small>
                      </p>
                      <p>
                        {formatCurrency(
                          calculateAverage(
                            reportingData,
                            'tabTotalBottom',
                            'tabCountBottom'
                          )
                        )}
                        <small>
                          <PercentageDetail
                            number={CalculateChange(
                              calculateAverage(
                                reportingData,
                                'tabTotalBottom',
                                'tabCountBottom'
                              ),
                              calculateAverage(
                                reportingPrevDailyData,
                                'tabTotalBottom',
                                'tabCountBottom'
                              )
                            )}
                          />
                        </small>
                        <small>Bottom 3rd</small>
                      </p>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={UserWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>

        <Grid item lg={4} sm={4} xs={6}>
          <div className="card stats" data-aos="fade-up" data-aos-delay="100">
            <StatDetail
              title="FasTab ROI"
              comingSoon
              description={<p className="stat-description">Coming Soon</p>}
              content={
                <>
                  {reportingData ? (
                    <>
                      <p>{calculateTotal(reportingData, 'tabCount')}</p>
                      <small>&nbsp;</small>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={UserWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos-dis" />
              <div className="stat-ind-fastab-dis" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default FinanceView
