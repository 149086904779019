import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import AddEditUserDialog from 'components/AddEditUserDialog/Index'
import Button from 'components/Button/Index'
import Select from 'components/Select/Index'
import React, { useCallback, useEffect, useState } from 'react'
import userService from 'services/user.service'
import { useFormik } from 'formik'
import { IUserAddUpdateRequest } from 'interfaces/user'
import { toast } from 'react-toastify'
import { resolveHTTP } from 'utility/helper'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  isFromRestaurant: boolean
  isFromLocation: boolean
  restaurantId?: number
  locationId?: number[]
  restaurantIdForEstUser?: number
  closeDialog?: (event: boolean) => void
}

function ExistingUserDialog(props: dialogProps) {
  const {
    open,
    onClose,
    isFromRestaurant,
    isFromLocation,
    restaurantId,
    locationId,
    restaurantIdForEstUser,
    closeDialog,
  } = props
  const [userList, setUserList] = useState([])
  const [result, setResult] = useState([])

  const [openAddEditUserDialog, setOpenAddEditUserDialog] = useState(false)
  const closeAddEditUserDialog = () => {
    setOpenAddEditUserDialog(false)
  }
  const handleClose = () => {
    setUserList([])
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.setFieldValue('loginId', 0)
    closeDialog(false)
  }
  const handleSave = async (formdata: IUserAddUpdateRequest) => {
    const [data] = await resolveHTTP(userService.addExistingUser(formdata))
    if (data) {
      toast.success('User added successfully')
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }
    handleClose()
  }
  // eslint-disable-next-line no-unused-expressions

  const formik = useFormik<IUserAddUpdateRequest>({
    initialValues: {
      loginId: 0,
      restaurantId: 0,
      firstName: '',
      lastName: '',
      isActive: true,
      loginName: '',
      isLockedOut: false,
      roleId: null,
      phoneNumber: '',
      establishmentId: [],
      loginTypeId: 2,
    },
    onSubmit: (values) => handleSave(values),
  })
  const getRestaurantUsers = useCallback(
    async () => {
      const response = await userService.getRestaurantUsers()
      setResult(response.data.data.data)
      const userListWithID = response.data.data.data.map((user) => {
        return {
          value: user.loginId,
          key: `${user.firstName} ${user.lastName}`,
        }
      })
      setUserList(userListWithID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const getLocationUsers = useCallback(
    async () => {
      const response = await userService.getLocationUsers()
      setResult(response.data.data.data)
      const userListWithID = response.data.data.data.map((user) => {
        return {
          value: user.loginId,
          key: `${user.firstName} ${user.lastName}`,
        }
      })
      setUserList(userListWithID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const handleUserSelection = (value: number) => {
    const user = result.find((e) => e.loginId === value)
    formik.setFieldValue('firstName', user.firstName)
    formik.setFieldValue('lastName', user.lastName)
    formik.setFieldValue('loginName', user.loginName)
    formik.setFieldValue('phoneNumber', user.phoneNumber)
    formik.setFieldValue('restaurantId', restaurantId || restaurantIdForEstUser)
    formik.setFieldValue('roleId', user.roleId)
    formik.setFieldValue('establishmentId', locationId)
    formik.setFieldValue('isActive', user.isActive)
    formik.setFieldValue('isLockedOut', user.isLockedOut)
  }
  useEffect(() => {
    if (isFromRestaurant) {
      getRestaurantUsers()
    }
    if (isFromLocation) {
      getLocationUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      <Dialog
        className="primary-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
          onClose(e)
          handleClose()
        }}
      >
        <DialogTitle>
          <h5>Existing User</h5>
          <Button
            variant="text"
            title="Close"
            onClick={(e) => {
              onClose(e)
              handleClose()
            }}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent className="pb-0">
            <div className="form-group">
              <Select
                label="Users"
                items={userList}
                formikValue={formik.values?.loginId}
                handleSelectValue={(value) => {
                  formik.setFieldValue('loginId', value)
                  handleUserSelection(value)
                }}

                // handleSelectValue={(value) => {
                //   formik.setFieldValue('restaurantId', value)
                // }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="btn-list">
              <Button
                variant="contained"
                color="primary"
                title="Add"
                type="submit"
              >
                Add
              </Button>
              <Button
                onClick={onClose}
                title="Cancel"
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
      <AddEditUserDialog
        open={openAddEditUserDialog}
        onClose={closeAddEditUserDialog}
        closeDialog={closeAddEditUserDialog}
        restId={restaurantId}
        estId={locationId}
        isFromRestaurant={isFromRestaurant}
        isFromLocation={isFromLocation}
        restaurantIdForEstUser={restaurantIdForEstUser}
      />
    </>
  )
}

export default ExistingUserDialog
