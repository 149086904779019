import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import { ISearchResponse } from 'interfaces/qrcode'
import {
  IGuestListDetails,
  IItemDetails,
  IPaymentDetails,
  ITabDetailsResponse,
  ITabIdRequest,
  ITabsCsvRequest,
  ItabsSearchRequest,
  ItabsSearchResponse,
} from 'interfaces/tab'
import { ICsvDownload } from 'interfaces/restaurant'
import httpClient from './base-service'

const endPointBaseURL = `/Tabs`

const getAllTabs = async (
  requestBody: ItabsSearchRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<ItabsSearchResponse[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<ItabsSearchResponse[]>>>(
    `${endPointBaseURL}/get-tabs-list`,
    requestBody
  )

// download csv
const downloadCsv = async (
  requestBody: ITabsCsvRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICsvDownload>>> =>
  httpClient.post<IApiSuccessResponse<ICsvDownload>>(
    `${endPointBaseURL}/create-csv-file-for-tabs`,
    requestBody
  )

const getTabDetailsData = async (
  requestBody: ITabIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<ITabDetailsResponse>>> =>
  httpClient.post<IApiSuccessResponse<ITabDetailsResponse>>(
    `${endPointBaseURL}/get-tab-details`,
    requestBody
  )
const getGuestForTabDetails = async (
  requestBody: ITabIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<IGuestListDetails[]>>> =>
  httpClient.post<IApiSuccessResponse<IGuestListDetails[]>>(
    `${endPointBaseURL}/get-guest-for-tab-details`,
    requestBody
  )

const getItemForTabDetails = async (
  requestBody: ITabIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<IItemDetails[]>>> =>
  httpClient.post<IApiSuccessResponse<IItemDetails[]>>(
    `${endPointBaseURL}/get-item-for-tab-details`,
    requestBody
  )
const getPaymentForTabDetails = async (
  requestBody: ITabIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPaymentDetails[]>>> =>
  httpClient.post<IApiSuccessResponse<IPaymentDetails[]>>(
    `${endPointBaseURL}/get-payment-for-tab-details`,
    requestBody
  )

export default {
  getAllTabs,
  downloadCsv,
  getTabDetailsData,
  getGuestForTabDetails,
  getItemForTabDetails,
  getPaymentForTabDetails,
}
