export class JWTToken {
  aud: string

  exp: number

  UserId: string

  RoleId: string

  FirstName: string

  LastName: string

  Email: string

  ProfilePictureName: string

  PreffredLanguage: string

  constructor(data?: JWTToken) {
    this.aud = data ? data.aud : ''
    this.exp = data ? data.exp : 0
    this.UserId = data ? data.UserId : ''
    this.RoleId = data ? data.RoleId : ''

    this.FirstName = data ? data.FirstName : ''
    this.LastName = data ? data.LastName : ''
    this.Email = data ? data.Email : ''
    this.ProfilePictureName = data ? data.ProfilePictureName : ''
    this.PreffredLanguage = data ? data.PreffredLanguage : ''
  }
}
