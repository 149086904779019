import { InputAdornment, TextField } from '@mui/material'
import Checkbox from 'components/Checkbox/Index'
import React, { useEffect, useState } from 'react'

interface ITextFieldWithCheckbox {
  name?: string
  value?: string
  checked?: boolean
  onTextChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCheckedChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function TextFieldWithCheckbox(props: ITextFieldWithCheckbox) {
  const { name, value, checked, onTextChange, onCheckedChange } = props

  const [innerValue, setInnerValue] = useState(value)
  const [tableSelected, setTableSelected] = useState(checked)

  useEffect(() => {
    setTableSelected(checked)
  }, [checked])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInnerValue(e.target.value)
    if (onTextChange) {
      onTextChange(e)
    }
  }
  const handleCheckboxState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableSelected(event.target.checked)
    if (onCheckedChange) {
      onCheckedChange(event)
    }
  }
  return (
    <>
      <TextField
        variant="outlined"
        value={innerValue}
        name={name}
        onChange={handleChange}
        disabled={!tableSelected}
        className="no-label with-select"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Checkbox
                onChange={handleCheckboxState}
                checked={tableSelected}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}

export default TextFieldWithCheckbox
