import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDateFns'
import { TextField } from '@mui/material'
import { Calendar } from 'assets/images'
import React from 'react'
import { formatISO } from 'date-fns'

interface props {
  datePickerlabel: string
  onChange?: (date: string) => void
  value?: Date | null
  setFilterSelectedDate?: React.Dispatch<Date | null>
  isPromoCodePage?: boolean
  minimumDate?: Date
  maximumDate?: Date
  error?: boolean
  helperText?: string
  disabled?: boolean
}

function Datepicker({
  datePickerlabel,
  onChange,
  value,
  setFilterSelectedDate,
  isPromoCodePage,
  minimumDate,
  maximumDate,
  error,
  helperText,
  disabled,
}: props) {
  // const [values, setValue] = React.useState<Date | null>(null)

  const handleChange = (newValue: Date | null) => {
    // setValue(newValue)
    setFilterSelectedDate(newValue)
    try {
      const date = formatISO(newValue, { representation: 'date' })
      onChange(date)
      // eslint-disable-next-line no-empty
    } catch (err) {
      console.log(isPromoCodePage, err)
    }
  }

  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DesktopDatePicker
          label={datePickerlabel}
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={handleChange}
          minDate={minimumDate}
          maxDate={maximumDate}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              // helperText="Invalid Date"
              variant="outlined"
              {...params}
              error={error}
              helperText={helperText}
            />
          )}
          components={{
            OpenPickerIcon: () => {
              return <img src={Calendar} alt="Calendar" />
            },
          }}
        />
      </LocalizationProvider>
    </>
  )
}

export default Datepicker
