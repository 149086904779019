import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IQRCodesTable,
  IParameterEstablishmentId,
  IQrSearchRequest,
  IqrSearchResponse,
  ISearchResponse,
  ITableSectionResponse,
  ITableSectionRequest,
  ITableSectionSearchRequest,
  IQRCodesTableRequest,
  IQRCodesTableArrayRequest,
  IQRCodesPdfRequest,
  IQRCodeImageRequest,
  IQRCodeBase64Request,
  IEnableDisablePayment,
  IQRCodesCsvRequest,
  IQRCodeData,
} from 'interfaces/qrcode'
import { ICsvDownload, IRestaurantQR } from 'interfaces/restaurant'
import httpClient from './base-service'

const endPointBaseURL = `/Table`

const getAllQrCodes = async (
  requestBody: IQrSearchRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IqrSearchResponse[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IqrSearchResponse[]>>>(
    `${endPointBaseURL}/get-tables`,
    requestBody
  )

const addTables = async (
  requestBody: IQRCodesTable[]
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-update-tables`,
    requestBody
  )

const getTablesByEstablishmentId = async (
  requestBody: IParameterEstablishmentId
): Promise<AxiosResponse<IApiSuccessResponse<IQRCodesTable[]>>> =>
  httpClient.post<IApiSuccessResponse<IQRCodesTable[]>>(
    `${endPointBaseURL}/get-tables-by-establishment-id`,
    requestBody
  )

const getAllSectionsByEstablishmentId = async (
  requestBody: ITableSectionSearchRequest
): Promise<AxiosResponse<IApiSuccessResponse<ITableSectionResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<ITableSectionResponse[]>>(
    `${endPointBaseURL}/get-table-sections-by-establishment`,
    requestBody
  )

const ceateTableSection = async (
  requestBody: ITableSectionRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/create-table-section`,
    requestBody
  )
const deleteTable = async (
  requestBody: IQRCodesTableRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.delete<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/delete-table`,
    {
      data: requestBody,
      hideLoader: false,
    }
  )

const bulkDeleteTables = async (
  requestBody: IQRCodesTableArrayRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.delete<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/bulk-delete-table`,
    {
      data: requestBody,
      hideLoader: false,
    }
  )
const getQrCodesPdf = async (
  requestBody: IQRCodesPdfRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(`/Pdf/generate-pdf`, requestBody)

// download csv
const downloadCsv = async (
  requestBody: IQRCodesCsvRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICsvDownload>>> =>
  httpClient.post<IApiSuccessResponse<ICsvDownload>>(
    `${endPointBaseURL}/create-csv-file-for-qrcode`,
    requestBody
  )

const getBulkQrCodes = async (
  requestBody: IQRCodeImageRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `/QRCode/generate-zip-file`,
    requestBody
  )

const getRestaurantLogo = async (
  requestBody: IQRCodeBase64Request
): Promise<AxiosResponse<IApiSuccessResponse<IRestaurantQR>>> =>
  httpClient.post<IApiSuccessResponse<IRestaurantQR>>(
    `/QRCode/get-restaurant-logo`,
    requestBody
  )

const getSingleQrCode = async (
  requestBody: IQRCodeImageRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `/QRCode/generate-single-file`,
    requestBody
  )
const getQrCodeBytes = async (
  requestBody: IQRCodeBase64Request
): Promise<AxiosResponse<IApiSuccessResponse<IQRCodeData>>> =>
  httpClient.post<IApiSuccessResponse<IQRCodeData>>(
    `/QRCode/get-qr-base-64`,
    requestBody
  )
const EnableDisablePayments = async (
  requestBody: IEnableDisablePayment
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-table-payment-status`,
    requestBody
  )

export default {
  getAllQrCodes,
  addTables,
  getTablesByEstablishmentId,
  getAllSectionsByEstablishmentId,
  ceateTableSection,
  deleteTable,
  bulkDeleteTables,
  getQrCodesPdf,
  downloadCsv,
  getBulkQrCodes,
  getSingleQrCode,
  getQrCodeBytes,
  EnableDisablePayments,
  getRestaurantLogo,
}
