/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
import { Collapse, Grid, Tooltip } from '@mui/material'
import Aos from 'aos'
import React, { useCallback, useEffect, useState } from 'react'
import {
  AddUserBold,
  CopyText,
  Edit,
  ExportNew,
  FilterNew,
  MenuClone,
  PlusWhite,
  RestaurantMenuLarge,
  ServiceDown,
  ServiceUp,
} from 'assets/images'
import Button from 'components/Button/Index'
import Textfield from 'components/Textfield/Index'
import CloneMenuDialog from 'components/CloneMenuDialog/Index'
import AlertDialog from 'components/AlertDialog/Index'
import { IDatagrid } from 'interfaces/datagrid'
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import restaurantService from 'services/restaurant.service'
import FasTabGrid from 'components/FasTabGrid/FasTabGrid'
import FasTabGridActionButton from 'components/FasTabGrid/FasTabGridActionButton'
import { IEstablishmentSearchResponse } from 'interfaces/establishment'
import Layout from 'components/Layout/Layout'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectedEstablishment } from 'features/establishment/establishment'
import {
  selectedRestaurant,
  setSelectedRestuarant,
} from 'features/restaurant/restaurant'
import establishmentService from 'services/establishment.service'
import { toast } from 'react-toastify'

Aos.init({
  duration: 600,
  once: true,
})

function Establishments() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [resId] = useState(
    (location.state as IEstablishmentSearchResponse)?.restaurantId
  )

  const [filterToggle, setFilterToggle] = useState(false)
  const [establishmentLists, setEstablishmentList] = useState<
    IEstablishmentSearchResponse[]
  >([])
  const [filterAddress, setFilterAddress] = useState('')

  // const [filterSelectedPostalCode, setFilterSelectedPostalCode] = useState('')
  const [totalRecords, setTotalRecords] = useState(0)
  const [locationIdForUser, setLocationIdForUser] = useState([])
  const [restaurantIdForEstUser, setRestaurantIdForEstUser] = useState(0)
  const [fromlocationId, setFromLocationId] = useState(0)
  const [fromRestaurantId, setFromRestaurantId] = useState(0)
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
  })
  // const establishmnets = useSelector(establishmentList)
  const selectedRestaurantId = useSelector(selectedRestaurant)

  const selectedEstablishmentId = useSelector(selectedEstablishment)
  // clone-menu Dialog
  const [openCloneMenuDialog, setOpenCloneMenuDialog] = useState(false)
  const closeCloneMenuDialog = () => {
    setOpenCloneMenuDialog(false)
  }

  // alert Dialog
  const [openAlertDialog, setOpenAlertDialog] = useState(false)
  const closeAlertDialog = () => {
    setOpenAlertDialog(false)
  }

  const getEstablishmentData = (id: number) => {
    const currentEstablishment = establishmentLists.find(
      (d) => d.establishmentId === id
    )
    return {
      establishmentId: currentEstablishment.establishmentId,
      restaurantId: currentEstablishment.restaurantId,
      fromAddRestaurant: false,
    }
  }
  const getRestaurantID = (id: number) => {
    const currentEstablishment = establishmentLists.find(
      (d) => d.establishmentId === id
    )
    return currentEstablishment.restaurantId
  }

  const getToolTipMessage = (healthMessage: string) => {
    const items = healthMessage?.split(',')
    let html = ''
    for (let i = 0; i < items?.length; i += 1) {
      html += '\t<li> 	&#149 ' + items[i]?.trim() + '</li>\n'
    }
    return html
  }

  const getEstablishmentDataForMenu = (id: number) => {
    const currentEstablishment = establishmentLists.find(
      (d) => d.establishmentId === id
    )

    return {
      establishmentId: currentEstablishment.establishmentId,
      restaurantId: currentEstablishment.restaurantId,
      establishmentName: currentEstablishment.establishmentName,
      fromEstablishment: true,
    }
  }

  const getEstablishmentColDef = (): GridColDef[] => {
    return [
      {
        field: 'isHealthy',
        maxWidth: 50,
        headerName: '',
        flex: 1,
        minWidth: 50,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          return params.row.healthMessage ? (
            <Tooltip
              title={
                <ul
                  dangerouslySetInnerHTML={{
                    __html: getToolTipMessage(params.row.healthMessage),
                  }}
                />
              }
              arrow
            >
              {params.value ? (
                <img src={ServiceUp} alt="ServiceUp" />
              ) : (
                <img src={ServiceDown} alt="ServiceDown" />
              )}
            </Tooltip>
          ) : params.value ? (
            <img src={ServiceUp} alt="ServiceUp" />
          ) : (
            <img src={ServiceDown} alt="ServiceDown" />
          )
        },
      },
      {
        field: 'restaurantName',
        headerName: 'Restaurant Name',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'establishmentName',
        headerName: 'Location',
        flex: 1,
        minWidth: 160,
      },
      {
        field: 'posName',
        headerName: 'POS Name',
        flex: 1,
        minWidth: 160,
      },
      {
        field: 'address',
        headerName: 'Address',
        flex: 1,
        minWidth: 280,
      },
      {
        field: 'cityName',
        headerName: 'City',
        flex: 1,
        minWidth: 120,
      },
      {
        field: 'postalCode',
        headerName: 'Postal Code',
        flex: 1,
        minWidth: 120,
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        flex: 1,
        minWidth: 140,
      },
      {
        field: 'isInternalReview',
        headerName: 'Internal Review',
        renderCell: (params: GridRenderCellParams<boolean>) => {
          const success =
            params.value && params.row.isShowGoogleReview ? 'success' : ''
          const secondary =
            !params.value || !params.row.isShowGoogleReview ? 'default' : ''
          return (
            <span
              className={`badge 
          ${success}
          ${secondary}
          `}
            >
              {params.value && params.row.isShowGoogleReview
                ? 'Active'
                : 'In active'}
            </span>
          )
        },
        flex: 1,
        minWidth: 110,
      },
      {
        field: 'isSubscriptionActive',
        headerName: 'Subscription',
        renderCell: (params: GridRenderCellParams<boolean>) => {
          const success =
            params.value && params.row.isSubscriptionActive ? 'success' : ''
          const secondary =
            !params.value || !params.row.isSubscriptionActive ? 'default' : ''
          return (
            <span
              className={`badge 
          ${success}
          ${secondary}
          `}
            >
              {params.value && params.row.isSubscriptionActive
                ? 'Active'
                : 'In active'}
            </span>
          )
        },
        flex: 1,
        minWidth: 110,
      },
      {
        align: 'center',
        headerAlign: 'center',
        field: 'establishmentId',
        disableColumnMenu: true,
        headerName: 'Action',
        sortable: false,
        renderCell: (d: GridRenderCellParams<number>) => {
          return (
            <div className="action-buttons">
              <div className="action-buttons">
                <FasTabGridActionButton
                  title="Edit"
                  link="/locations/edit"
                  icon={Edit}
                  state={getEstablishmentData(d.value)}
                />
                <FasTabGridActionButton
                  title="Add Menu"
                  icon={RestaurantMenuLarge}
                  link="/menu"
                  state={getEstablishmentDataForMenu(d.value)}
                />
                <FasTabGridActionButton
                  title="Add User"
                  icon={AddUserBold}
                  onClick={() => {
                    setOpenAlertDialog(true)
                    setLocationIdForUser([+d.value])
                    setFromLocationId(+d.value)
                    setFromRestaurantId(getRestaurantID(+d.value))
                    setRestaurantIdForEstUser(getRestaurantID(+d.value))
                  }}
                />

                {d.row.isSubscriptionActive && (
                  <Button
                    className="icon-btn small rounded light-bordered"
                    variant="text"
                    color="inherit"
                    title="Copy Link"
                    onClick={() => {
                      navigator.clipboard.writeText(d.row.subscriptionURL)
                      toast.success('Link Copied.')
                    }}
                  >
                    <img src={CopyText} alt="Copy Link" />
                  </Button>
                )}
                <FasTabGridActionButton
                  title="Clone Menu"
                  onClick={() => {
                    setFromLocationId(+d.value)
                    setFromRestaurantId(getRestaurantID(+d.value))
                    setOpenCloneMenuDialog(true)
                  }}
                  icon={MenuClone}
                />
              </div>
            </div>
          )
        },
        flex: 1,
        minWidth: 200,
      },
    ]
  }

  const getEstablishmentList = useCallback(
    async (
      pageNo: number,
      pageSize: number,
      sortOrder: GridSortModel,
      address: string
    ) => {
      await establishmentService.CheckLocationHealths()
      const data = await establishmentService.getFilteredEstablishments({
        pageNo,
        pageSize,
        sortGridModels: sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
        restaurantId: selectedRestaurantId,
        address,
      })
      if (
        selectedEstablishmentId !== null &&
        // selectedRestaurantId !== null &&
        selectedEstablishmentId !== 0
      ) {
        let myArray = []
        myArray = data.data.data.data.filter(
          (ar) => ar.establishmentId === selectedEstablishmentId
        )
        setEstablishmentList(myArray)
      } else {
        setEstablishmentList(data.data.data.data)
      }
      // if (totalRecords === 0) {
      setTotalRecords(data.data.data.totalRecords)
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId, selectedEstablishmentId]
  )
  useEffect(() => {
    if (
      selectedRestaurantId === null ||
      selectedRestaurantId === 0 ||
      resId === null ||
      resId === 0
    ) {
      if (resId !== 0 && resId !== undefined) {
        dispatch(
          setSelectedRestuarant({
            selectedRestaurant: resId,
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getEstablishmentList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      filterAddress
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataGridOptions.pageNumber,
    dataGridOptions.pageSize,
    dataGridOptions.sortOrder,
    filterAddress,
    selectedEstablishmentId,
    selectedRestaurantId,
  ])

  // function to get all restaurants from database for filter dropdown
  useEffect(() => {
    async function fetchFilterRestaurantList() {
      let pageNo = 1
      const pageSize = 10
      let data = await restaurantService.getAllResturants({
        pageNo,
        pageSize,
        sortGridModels: [
          {
            field: 'name',
            sort: 1,
          },
        ],
      })
      let restaurantData = data.data.data.data
      // eslint-disable-next-line @typescript-eslint/no-shadow
      let { totalRecords } = data.data.data
      if (totalRecords > pageSize) {
        while (totalRecords >= 0) {
          pageNo += 1
          // eslint-disable-next-line no-await-in-loop
          data = await restaurantService.getAllResturants({
            pageNo,
            pageSize,
            sortGridModels: [
              {
                field: 'name',
                sort: 1,
              },
            ],
          })
          restaurantData = restaurantData.concat(data.data.data.data)
          totalRecords -= pageSize
        }
      }
    }
    if (filterToggle) fetchFilterRestaurantList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterToggle])

  const onFilterApply = async () => {
    const data = await establishmentService.getAllEstablishments({
      pageNo: dataGridOptions.pageNumber,
      pageSize: dataGridOptions.pageSize,
      sortGridModels: dataGridOptions.sortOrder.map((d) => {
        return {
          field: d.field,
          sort: d.sort === 'asc' ? 1 : 0,
        }
      }),
      address: filterAddress,
      cityName: '',
    })
    setEstablishmentList(data.data.data.data)
    if (totalRecords === 0) {
      setTotalRecords(data.data.data.totalRecords)
    }
  }

  const onFilterClear = () => {
    // setFilterToggle(false)
    setFilterAddress('')
    getEstablishmentList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      ''
    )
  }

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  const filterAddressChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFilterAddress(e.target.value)
  }

  // csv download logic
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exportCSVFile = (
    headers: {
      Name: string // remove commas to avoid errors
      EmailAddress: string
      WebsiteLink: string
      CreatedDate: string
    },
    csv:
      | string
      | Blob
      | import('../../interfaces/restaurant').ICsvDownload
      | ArrayBufferView
      | ArrayBuffer,
    fileTitle: string
  ) => {
    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

    const blob = new Blob([csv as BlobPart], {
      type: 'text/csv;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const downloadFile = async () => {
    const data = await establishmentService.downloadCsv({
      address: filterAddress,
      establishmentId: selectedEstablishmentId,
      restaurantId: selectedRestaurantId,
    })

    const headers = {
      Name: 'Name'.replace(/,/g, ''), // remove commas to avoid errors
      EmailAddress: 'Email'.replace(/,/g, ''),
      WebsiteLink: 'Website'.replace(/,/g, ''),
      CreatedDate: 'Date'.replace(/,/g, ''),
    }
    const fileTitle = 'Location'
    exportCSVFile(headers, data.data.data, fileTitle)
  }

  return (
    <>
      <Layout title="Locations | FasTab">
        {/* page-top start */}
        <div className="page-top">
          <h3 className="page-title">Locations</h3>
          <div className="right">
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Export"
              size="small"
              className="btn-h-40"
              onClick={() => downloadFile()}
            >
              <img src={ExportNew} alt="File" />
              <span>Export</span>
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Filter"
              size="small"
              className="btn-h-40"
              onClick={() => {
                setFilterToggle(!filterToggle)
              }}
            >
              <img src={FilterNew} alt="File" />
              <span>Filter</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              title="Add New"
              size="small"
              isTypeLink
              to="/locations/add"
              linkState={{
                fromAddRestaurant: false,
                restaurantId: null,
              }}
            >
              <img src={PlusWhite} alt="ADD" />
              <span>Add New</span>
            </Button>
          </div>
          {/* page-top end */}
        </div>
        {/* filter-panel start */}
        <Collapse in={filterToggle}>
          <div className="filter-panel">
            <div className="card">
              <Grid container spacing={5}>
                <Grid item lg={3} sm={6} xs={12}>
                  <div className="form-group">
                    <Textfield
                      label="Address"
                      variant="outlined"
                      value={filterAddress}
                      onChange={(e) => filterAddressChange(e)}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="btn-list">
                <Button
                  variant="contained"
                  color="primary"
                  title="Apply"
                  onClick={onFilterApply}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  title="Clear All"
                  onClick={onFilterClear}
                >
                  Clear All
                </Button>
              </div>
            </div>
          </div>
        </Collapse>
        {/* filter-panel end */}

        <div className="card mb-30 p-0" data-aos="fade-up">
          {/* data-table start */}
          <FasTabGrid
            uniqueId="establishmentId"
            columnsData={getEstablishmentColDef()}
            gridData={establishmentLists}
            totalRecords={totalRecords}
            onGridPageChange={onPageChange}
            onGridPageSizeChange={onPageSizeChange}
            onGridSortChange={onSortChange}
          />
        </div>
        {/* data-table end */}
      </Layout>
      {/* clone-menu dialog */}
      <CloneMenuDialog
        open={openCloneMenuDialog}
        onClose={closeCloneMenuDialog}
        fromLocationId={fromlocationId}
        fromRestaurantId={fromRestaurantId}
        closeDialog={closeCloneMenuDialog}
      />

      {/* user-alert-dialog */}
      <AlertDialog
        onClose={closeAlertDialog}
        open={openAlertDialog}
        locationId={locationIdForUser}
        restaurantIdForEstUser={restaurantIdForEstUser}
        isFromLocation
        isFromRestaurant={false}
      />
    </>
  )
}

export default Establishments
