import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import {
  GridRenderCellParams,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import Aos from 'aos'
import {
  AlertImage,
  Close,
  Delete,
  FilterNew,
  IcDisablePromoCode,
  IcEnablePromoCode,
  PlusWhite,
  View,
} from 'assets/images'
import AddPromoCode from 'components/AddPromoCodeDialog'
import Button from 'components/Button/Index'
import DataTable from 'components/DataTable/Index'
import Layout from 'components/Layout/Layout'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import CryptoJS from 'crypto-js'
import { selectedEstablishment } from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IDatagrid } from 'interfaces/datagrid'
import {
  IPromoListResponseDTO,
  IPromocodeListRequestDTO,
} from 'interfaces/promocode'
import { useCallback, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import promocodeService from 'services/promocode.service'
import { ROLE_SECRET_KEY } from 'utility/constants'
import PromoCodeDetails from './PromocodeDetails'

Aos.init({
  duration: 600,
  once: true,
})

function PromoCode() {
  const [filterToggle, setFilterToggle] = useState(false)
  const [promocodeList, setpromocodeList] = useState([])
  const [promocodeName, setPromocodeName] = useState('')
  const [totalRecords, setTotalRecords] = useState(0)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedCurrentEstablishment = useSelector(selectedEstablishment)
  const [openAddPromoDialog, setopenAddPromoDialog] = useState(false)
  const [displayAddMenuConfirmDialog, setdisplayAddMenuConfirmDialog] =
    useState(false)
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false)
  const [deletePromoCodeId, setDeletePromoCodeId] = useState(null)
  const [isRestEstShow, setRestEst] = useState(false)
  const [promoType, setPromoType] = useState(0)
  const closeAddPromoDialog = () => {
    setopenAddPromoDialog(false)
  }

  const FILTER_PROMOCODE_STATUS = { FASTAB: 1, RESTAURANT: 2, ALL: 0 }
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
  const IsAdmin =
    Number(
      CryptoJS.AES.decrypt(
        localStorage.getItem('roleId'),
        ROLE_SECRET_KEY
      )?.toString(CryptoJS.enc.Utf8)
    ) === 1
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
  })
  const [promoCodeDetailsId, setPromoCodeDetailsId] = useState(false)
  const [restName, setRestName] = useState(false)
  const [discountUsageAmount, setDiscountUsageAmount] = useState(false)
  const [discountUsageNumber, setDiscountUsageNumber] = useState(false)
  const [openPromoDetailDialog, setOpenPromoDetailDialog] = useState(false)
  const closeOpenPromoDialog = () => {
    setOpenPromoDetailDialog(false)
  }
  const [activeInactiveText, setActiveInactiveText] = useState('')
  const [isActiveInactiveShow, setIsActiveInactiveShow] = useState(false)
  const getPromocodeList = useCallback(
    async (
      pageNo: number,
      pageSize: number,
      sortOrder: GridSortModel,
      selectedestablishmentId: number,
      restId: number
    ) => {
      const response = await promocodeService.getPromocodeList({
        restaurantId: restId,
        establishmentId: selectedestablishmentId,
        promocodeName,
        promoType,
        pageSize,
        pageNo,
        sortGridModels: sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
      })

      const promocodeListWithID: IPromoListResponseDTO[] =
        response.data.data.data.map((promo, index) => {
          return { id: index + 1, ...promo }
        })
      setpromocodeList(promocodeListWithID)
      setTotalRecords(response.data.data.totalRecords)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId, selectedCurrentEstablishment]
  )
  const generatePayload = () => {
    const payload: IPromocodeListRequestDTO = {
      pageNo: dataGridOptions.pageNumber,
      pageSize: dataGridOptions.pageSize,
      sortGridModels: dataGridOptions.sortOrder.map((d) => {
        return {
          field: d.field,
          sort: d.sort === 'asc' ? 1 : 0,
        }
      }),
      establishmentId: selectedCurrentEstablishment,
      promocodeName,
      promoType,
      restaurantId: selectedRestaurantId,
    }

    return payload
  }
  useEffect(() => {
    getPromocodeList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      selectedCurrentEstablishment,
      selectedRestaurantId
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataGridOptions,
    selectedRestaurantId,
    selectedCurrentEstablishment,
    selectedRestaurantId,
  ])

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  const onFilterApply = async () => {
    const payload = generatePayload()
    payload.pageNo = 1
    const data = await promocodeService.getPromocodeList(payload)
    const promocodeListWithID: IPromoListResponseDTO[] =
      data.data.data.data.map((promo, index) => {
        return { id: index + 1, ...promo }
      })
    setpromocodeList(promocodeListWithID)
    setTotalRecords(data.data.data.totalRecords)
  }

  const onFilterClear = () => {
    setPromocodeName('')
    setPromoType(0)
    getPromocodeList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      selectedCurrentEstablishment,
      selectedRestaurantId
    )
  }
  const filterPromoCodeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPromocodeName(e.target.value)
  }

  const onCloseMenuConfirmDialog = () => {
    setdisplayAddMenuConfirmDialog(false)
    return displayAddMenuConfirmDialog
  }

  const handleCancelClickForAddMenuConfirmDialog = () => {
    setdisplayAddMenuConfirmDialog(false)
  }

  const updatePromoList = () => {
    getPromocodeList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      selectedCurrentEstablishment,
      selectedRestaurantId
    )
  }
  const deletePromoCode = async () => {
    const data = await promocodeService.deletePromocode({
      promocodeId: deletePromoCodeId,
      isDeleted: true,
    })
    if (data) {
      toast.success('Promo code deleted successfully')
    }
    onFilterApply()
  }

  const onSelectionModelChange = (selectionModelData: GridSelectionModel) => {
    setSelectionModel(selectionModelData)
  }

  const handleActiveInactiveClick = async () => {
    const data = await promocodeService.activeInactivePromocode({
      promocodeId: selectionModel.map((d) => Number(d)),
      isActive: activeInactiveText === 'active',
    })
    if (data) {
      toast.success('Status updated successfully.')
    }
    onFilterApply()
  }

  return (
    <>
      <Layout title="Promo Code | FasTab">
        {/* main-content start */}
        <section className="main-content">
          {/* page-top start */}
          <div className="page-top">
            <h3 className="page-title">Promo Code</h3>

            <div className="right">
              <Button
                variant="contained"
                color="primary"
                title="Active Promo Code"
                size="small"
                className="btn-h-40"
                disabled={selectionModel.length === 0}
                onClick={() => {
                  setActiveInactiveText('active')
                  setIsActiveInactiveShow(true)
                }}
              >
                <img src={IcEnablePromoCode} alt="Active" />
                <span>Active Promo Code</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                title="Inactive  Promo Code"
                size="small"
                className="btn-h-40"
                disabled={selectionModel.length === 0}
                onClick={() => {
                  setActiveInactiveText('Inactive')
                  setIsActiveInactiveShow(true)
                }}
              >
                <img src={IcDisablePromoCode} alt="InActive" />
                <span>Deactivate Promo Code</span>
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                title="Filter"
                size="small"
                className="btn-h-40"
                onClick={() => {
                  setFilterToggle(!filterToggle)
                }}
              >
                <img src={FilterNew} alt="Filter" />
                <span>Filter</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                title="New Promo Code"
                size="small"
                to="/promocode/add"
                isTypeLink
                onClick={() => {
                  console.log(IsAdmin)
                  setRestEst(false)
                  setopenAddPromoDialog(true)
                  setdisplayAddMenuConfirmDialog(false)
                  // if (IsAdmin) {
                  //   // setdisplayAddMenuConfirmDialog(true)
                  // } else {
                  //   setRestEst(true)
                  //   setopenAddPromoDialog(true)
                  // }
                }}
              >
                <img src={PlusWhite} alt="ADD" />
                <span>New Promo Code</span>
              </Button>
            </div>
          </div>
          {/* page-top end */}
          {/* filter-panel start */}
          <Collapse in={filterToggle}>
            <div className="filter-panel">
              <div className="card">
                <Grid container spacing={5}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        label="Promo Code Name"
                        type="text"
                        variant="outlined"
                        value={promocodeName.toUpperCase()}
                        onChange={(e) => filterPromoCodeChange(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Promo Code Type"
                        items={[
                          {
                            key: 'All',
                            value: FILTER_PROMOCODE_STATUS.ALL,
                          },
                          {
                            key: 'FasTab',
                            value: FILTER_PROMOCODE_STATUS.FASTAB,
                          },
                          {
                            key: 'Restaurant',
                            value: FILTER_PROMOCODE_STATUS.RESTAURANT,
                          },
                        ]}
                        handleSelectValue={(item) => {
                          setPromoType(item)
                        }}
                        formikValue={promoType}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="btn-list">
                  <Button
                    variant="contained"
                    color="primary"
                    title="Apply"
                    onClick={onFilterApply}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    title="Clear All"
                    onClick={onFilterClear}
                  >
                    Clear All
                  </Button>
                </div>
              </div>
            </div>
          </Collapse>
          {/* filter-panel end */}

          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="promocodeId"
              checkboxSelection
              onSelectionModelChange={onSelectionModelChange}
              columnsData={[
                {
                  field: 'restaurantName',
                  headerName: 'Restaurant Name',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <span>
                        {params.value === '' ? 'All Restaurants' : params.value}
                      </span>
                    )
                  },
                  flex: 1,
                  minWidth: 180,
                },
                {
                  field: 'promocodeName',
                  headerName: 'Promocode Name',
                  flex: 1,
                  minWidth: 180,
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return <span> {params.value.trim().toUpperCase()} </span>
                  },
                },
                {
                  field: 'promocodePercentage',
                  headerName: 'Promocode Percentage(%)',
                  flex: 1,
                  minWidth: 220,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return (
                      <span> {params.value ? `${params.value}%` : '-'}</span>
                    )
                  },
                },
                {
                  field: 'promocodeAmount',
                  headerName: 'Promocode Amount($)',
                  flex: 1,
                  minWidth: 200,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return (
                      <span>{params.value ? `$${params.value}` : '-'}</span>
                    )
                  },
                },
                {
                  field: 'maximumDiscountUses',
                  headerName: 'Max Discount Uses($)',
                  flex: 1,
                  minWidth: 180,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return (
                      <span>{params.value ? `$${params.value}` : '-'}</span>
                    )
                  },
                },

                {
                  field: 'isRestaurantPromoCode',
                  headerName: 'Promo Code Type',
                  renderCell: (params: GridRenderCellParams<boolean>) => {
                    return <span>{params.value ? 'Restaurant' : 'FasTab'}</span>
                  },
                  flex: 1,
                  minWidth: 180,
                },
                {
                  field: 'startDate',
                  headerName: 'Start Date',
                  renderCell: (params: GridRenderCellParams<Date>) => {
                    if (params.value) {
                      return <Moment format="MM/DD/YYYY">{params.value}</Moment>
                    }
                    return <span>-</span>
                  },
                  flex: 1,
                  minWidth: 120,
                },
                {
                  field: 'endDate',
                  headerName: 'Expire Date',
                  renderCell: (params: GridRenderCellParams<Date>) => {
                    if (params.value) {
                      return <Moment format="MM/DD/YYYY">{params.value}</Moment>
                    }
                    return <span>-</span>
                  },
                  flex: 1,
                  minWidth: 120,
                },
                {
                  field: 'isActive',
                  headerName: 'Status',
                  renderCell: (params: GridRenderCellParams<boolean>) => {
                    const success = params.value ? 'success' : ''
                    const secondary = !params.value ? 'default' : ''
                    return (
                      <span
                        className={`badge 
                    ${success}
                    ${secondary}
                    `}
                      >
                        {params.value ? 'Active' : 'In active'}
                      </span>
                    )
                  },
                  flex: 1,
                  minWidth: 120,
                },
                {
                  field: 'maximumDiscountUsedAmount',
                  headerName: 'Discount usage alert',
                  align: 'center',
                  renderCell: (params: GridRenderCellParams<number>) => {
                    if (
                      params.value > 0 &&
                      params.row.notificationThreshold &&
                      params.row.notificationThreshold > 0 &&
                      params.row.notificationThreshold <= params.value
                    ) {
                      return (
                        <>
                          <img
                            src={AlertImage}
                            alt=""
                            title={`$${params.value.toFixed(2)}`}
                          />
                        </>
                      )
                    }
                    return <span>{`$${params.value.toFixed(2) ?? 0}`}</span>
                  },
                  flex: 1,
                  minWidth: 180,
                },
                {
                  align: 'center',
                  headerAlign: 'center',
                  field: 'action',
                  disableColumnMenu: true,
                  headerName: 'Action',
                  sortable: false,
                  renderCell: (d: GridRenderCellParams<number>) => {
                    return (
                      <div className="action-buttons">
                        <Button
                          className="icon-btn light-bordered small rounded"
                          title="View"
                          variant="text"
                          color="inherit"
                          onClick={() => {
                            setPromoCodeDetailsId(d.row.promocodeId)
                            setRestName(d.row.restaurantName)
                            setDiscountUsageAmount(
                              d.row.maximumDiscountUsedAmount
                            )
                            setDiscountUsageNumber(
                              d.row.maxNoOfDiscountUsesNumber
                            )
                            setOpenPromoDetailDialog(true)
                          }}
                        >
                          <img src={View} alt="View" />
                        </Button>
                        <Button
                          className="icon-btn light-bordered small rounded"
                          title="Delete"
                          variant="text"
                          color="inherit"
                          onClick={() => {
                            setDeletePromoCodeId(d.row.promocodeId)
                            setOpenDeleteConfirmDialog(true)
                          }}
                        >
                          <img src={Delete} alt="Delete" />
                        </Button>
                      </div>
                    )
                  },
                  flex: 1,
                  minWidth: 120,
                },
              ]}
              rowsData={promocodeList}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortChange}
              totalRecords={totalRecords}
            />
          </div>
          {isActiveInactiveShow && (
            <Dialog
              className="primary-dialog alert-dialog"
              maxWidth="xs"
              fullWidth
              open={isActiveInactiveShow}
            >
              <DialogTitle>
                <Button
                  variant="text"
                  title="Close"
                  onClick={() => {
                    setIsActiveInactiveShow(false)
                  }}
                  className="icon-btn rounded ml-auto"
                  color="inherit"
                >
                  <img src={Close} alt="Close" />
                </Button>
              </DialogTitle>
              <DialogContent className="pb-0">
                <p>Are you sure want to {activeInactiveText} promo codes?</p>
              </DialogContent>
              <DialogActions>
                <div className="btn-list">
                  <Button
                    variant="contained"
                    color="primary"
                    title="OK"
                    onClick={() => {
                      setIsActiveInactiveShow(false)
                      handleActiveInactiveClick()
                    }}
                  >
                    OK
                  </Button>
                  <Button
                    title="Cancel"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setIsActiveInactiveShow(false)
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          )}
          {openDeleteConfirmDialog && (
            <Dialog
              className="primary-dialog alert-dialog"
              maxWidth="xs"
              fullWidth
              open={openDeleteConfirmDialog}
            >
              <DialogTitle>
                <Button
                  variant="text"
                  title="Close"
                  onClick={() => {
                    setOpenDeleteConfirmDialog(false)
                  }}
                  className="icon-btn rounded ml-auto"
                  color="inherit"
                >
                  <img src={Close} alt="Close" />
                </Button>
              </DialogTitle>
              <DialogContent className="pb-0">
                <p>Are you sure want to delete promocode?</p>
              </DialogContent>
              <DialogActions>
                <div className="btn-list">
                  <Button
                    variant="contained"
                    color="primary"
                    title="Yes"
                    onClick={() => {
                      setOpenDeleteConfirmDialog(false)
                      deletePromoCode()
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    title="No"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setOpenDeleteConfirmDialog(false)
                    }}
                  >
                    <span>No</span>
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          )}
          {displayAddMenuConfirmDialog && (
            <Dialog
              className="primary-dialog"
              onClose={onCloseMenuConfirmDialog}
              maxWidth="xs"
              fullWidth
              open={displayAddMenuConfirmDialog}
            >
              <DialogTitle>
                <h6>Which type of promocode you want to create</h6>
                <Button
                  variant="text"
                  title="Close"
                  onClick={handleCancelClickForAddMenuConfirmDialog}
                  color="inherit"
                >
                  <img src={Close} alt="Close" />
                </Button>
              </DialogTitle>
              <DialogContent className="pb-0">
                <div className="split-options">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setRestEst(false)
                      setopenAddPromoDialog(true)
                      setdisplayAddMenuConfirmDialog(false)
                    }}
                    title="FasTab"
                    to="/promocode/add"
                    isTypeLink
                  >
                    <span>FasTab</span>
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setRestEst(true)
                      setopenAddPromoDialog(true)
                      setdisplayAddMenuConfirmDialog(false)
                    }}
                    title="Restaurant"
                  >
                    <span>Restaurant</span>
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          )}
          {/* data-table end */}
        </section>
        <></>
        {/* main-content end */}
      </Layout>
      <AddPromoCode
        open={openAddPromoDialog}
        onClose={closeAddPromoDialog}
        closeDialog={closeAddPromoDialog}
        isRestEstShow={isRestEstShow}
        updatePromocodeList={updatePromoList}
      />
      <PromoCodeDetails
        open={openPromoDetailDialog}
        onClose={closeOpenPromoDialog}
        closeDialog={closeOpenPromoDialog}
        promoId={Number(promoCodeDetailsId)}
        restaurantName={String(restName)}
        discountUsageAmount={Number(discountUsageAmount)}
        discountUsageNumber={Number(discountUsageNumber)}
      />
    </>
  )
}

export default PromoCode
