import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import { ICsvDownload } from 'interfaces/restaurant'
import {
  IDisbursement,
  IDisbursementCsvRequest,
  IDisbursementFileHistoryRequest,
  IDisbursementFileRequest,
  IDisbursementFileResponse,
  IDisbursementRequest,
  IDisbursementStatusResponse,
  IMarkDisbursementRequest,
  IPayout,
  IPayoutRequest,
  ISearchResponse,
  IMonthlyStatementRequest,
  IMonthlyStatementsResponse,
} from '../interfaces/disbursement'
import httpClient from './base-service'

const endPointBaseURL = `/Disbursement`
// /get-restaurant-disbursement-status
const getDisbursmentList = async (
  requestBody: IDisbursementRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IDisbursement[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IDisbursement[]>>>(
    `${endPointBaseURL}/get-disbursement-details`,
    requestBody
  )

const getAllDisbursementStatus = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IDisbursementStatusResponse>>
> =>
  httpClient.get<IApiSuccessResponse<IDisbursementStatusResponse>>(
    `${endPointBaseURL}/get-restaurant-disbursement-status`
  )

const downloadCsv = async (
  requestBody: IDisbursementCsvRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICsvDownload>>> =>
  httpClient.post<IApiSuccessResponse<ICsvDownload>>(
    `${endPointBaseURL}/download-disbursement-details`,
    requestBody
  )

const generateDisbursementFile = async (
  requestBody: IDisbursementFileRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICsvDownload>>> =>
  httpClient.post<IApiSuccessResponse<ICsvDownload>>(
    `${endPointBaseURL}/get-disbursement-file-details`,
    requestBody
  )

const getDefaultNoteForPayment = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IDisbursementFileResponse>>
> =>
  httpClient.get<IApiSuccessResponse<IDisbursementFileResponse>>(
    `${endPointBaseURL}/get-default-note-for-payment`
  )

const saveFileDownloadHistory = async (
  requestBody: IDisbursementFileHistoryRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-disbursement-file-history`,
    requestBody
  )

const markAsDisbursed = async (
  requestBody: IMarkDisbursementRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/mark-as-disbursed-payment`,
    requestBody
  )

const getPayoutList = async (
  requestBody: IPayoutRequest
): Promise<AxiosResponse<IApiSuccessResponse<ISearchResponse<IPayout[]>>>> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IPayout[]>>>(
    `${endPointBaseURL}/get-restaurant-view-payout-list`,
    requestBody
  )

const getMonthlyStatementList = async (
  requestBody: IMonthlyStatementRequest
): Promise<
  AxiosResponse<
    IApiSuccessResponse<ISearchResponse<IMonthlyStatementsResponse[]>>
  >
> =>
  httpClient.post<
    IApiSuccessResponse<ISearchResponse<IMonthlyStatementsResponse[]>>
  >(`/Common/get-monthly-statement-details`, requestBody)

export default {
  getDisbursmentList,
  getAllDisbursementStatus,
  downloadCsv,
  generateDisbursementFile,
  getDefaultNoteForPayment,
  saveFileDownloadHistory,
  markAsDisbursed,
  getPayoutList,
  getMonthlyStatementList,
}
