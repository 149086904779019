import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  ISendCodeToPhone,
  ITwoFactorResponse,
  IVerifyCodeFromPhone,
} from 'interfaces/two-factor'
import httpClient from './base-service'

const endPointBaseURL = '/Account'

const sendCodeOnPhone = async (
  phoneNumber: ISendCodeToPhone
): Promise<AxiosResponse<IApiSuccessResponse<ITwoFactorResponse>>> =>
  httpClient.post<IApiSuccessResponse<ITwoFactorResponse>>(
    `${endPointBaseURL}/send-otp-to-phone`,
    phoneNumber
  )

const verifyCodeSentOnPhone = async (
  data: IVerifyCodeFromPhone
): Promise<AxiosResponse<IApiSuccessResponse<ITwoFactorResponse>>> =>
  httpClient.post<IApiSuccessResponse<ITwoFactorResponse>>(
    `${endPointBaseURL}/verify-otp-sent-to-phone`,
    data
  )

const verifyEmail = async (
  data: string
): Promise<AxiosResponse<IApiSuccessResponse<ITwoFactorResponse>>> =>
  httpClient.post<IApiSuccessResponse<ITwoFactorResponse>>(
    `${endPointBaseURL}/send-verification-email`,
    {
      email: data,
    }
  )

export default {
  sendCodeOnPhone,
  verifyCodeSentOnPhone,
  verifyEmail,
}
