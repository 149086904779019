import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IUserListRequest,
  IUserListResponse,
  ISearchResponse,
  IUserAddUpdateRequest,
  IUserIdRequest,
  IUserCsvRequest,
} from 'interfaces/user'
import { ICsvDownload } from 'interfaces/restaurant'
import httpClient from './base-service'

const endPointBaseURL = `/User`
const endPointBaseURLRoles = `/Account`

const getUserList = async (
  requestBody: IUserListRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IUserListResponse[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IUserListResponse[]>>>(
    `${endPointBaseURL}/get-user-list`,
    requestBody
  )

const addUser = async (
  requestBody: IUserAddUpdateRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-user`,
    requestBody
  )

const updateUser = async (
  requestBody: IUserAddUpdateRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-user`,
    requestBody
  )

const deleteUser = async (
  requestBody: IUserIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/delete-user`,
    requestBody
  )

const getRoleList = async (): Promise<AxiosResponse> =>
  httpClient.get(`${endPointBaseURLRoles}/get-roles`)

const getUserById = async (
  requestBody: IUserIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<IUserAddUpdateRequest>>> =>
  httpClient.post<IApiSuccessResponse<IUserAddUpdateRequest>>(
    `${endPointBaseURL}/get-user-data-by-id`,
    requestBody
  )
const getRestaurantUsers = async (): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IUserListResponse[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IUserListResponse[]>>>(
    `${endPointBaseURL}/get-restaurant-users`
  )
const getLocationUsers = async (): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IUserListResponse[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IUserListResponse[]>>>(
    `${endPointBaseURL}/get-location-users`
  )
const addExistingUser = async (
  requestBody: IUserAddUpdateRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-existing-user`,
    requestBody
  )

// download csv
const downloadCsv = async (
  requestBody: IUserCsvRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICsvDownload>>> =>
  httpClient.post<IApiSuccessResponse<ICsvDownload>>(
    `${endPointBaseURL}/create-csv-file-for-user`,
    requestBody
  )

export default {
  getUserList,
  addUser,
  updateUser,
  deleteUser,
  getRoleList,
  getUserById,
  getRestaurantUsers,
  getLocationUsers,
  addExistingUser,
  downloadCsv,
}
