import { IMenuDetailData } from 'interfaces/menu'
import React, { useCallback, useEffect, useState } from 'react'
import menuService from 'services/menu.service'

interface PdfViewerProps {
  menuId?: number
  tabIndex?: number
  currentTab?: number
}

function PdfViewer(props: PdfViewerProps) {
  const { menuId, tabIndex, currentTab } = props
  const [menu, setMenu] = useState<IMenuDetailData>(null)

  const getMenuById = useCallback(
    async (currentMenuId: number) => {
      if (menu) {
        return
      }
      try {
        const data = await menuService.geMenubyMenuId({
          menuId: currentMenuId,
          includeAllFields: false,
        })
        setMenu(data.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (currentTab === tabIndex) {
      getMenuById(menuId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  return (
    menu &&
    menu.menuPdfPath && (
      <embed
        title="pdf"
        width="300"
        height="600"
        src={`${menu?.menuPdfPath}#toolbar=0&navpanes=0&scrollbar=0`}
      />
    )
  )
}

export default PdfViewer
