/* eslint-disable react/jsx-filename-extension */
import { Grid } from '@mui/material'
import Rating from '@mui/material/Rating'
import { TrayWhite, UserWhite } from 'assets/images'
import {
  selectedEstablishment,
  establishmentList,
} from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IDashboardOverViewResponse } from 'interfaces/reporting'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import reportingService from 'services/reporting.service'
import moment from 'moment'
import formatCurrency from './formatters'
import CalculateChange from './ChangeCalculator'
import NoData from './NoData'
import StatDetail from './StatDetail'
import PercentageDetail from './PercentageDetail'
import LineChart from './LineChart'

interface OverviewProps {
  startDate: Date
  endDate: Date
  prevStartDate: Date
  prevEndDate: Date
}

function OverviewView({
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
}: OverviewProps) {
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedCurrentEstablishment = useSelector(selectedEstablishment)
  const allEstablishmentList = useSelector(establishmentList)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [reportingData, setReportingData] =
    useState<IDashboardOverViewResponse>()
  const [reportingPrevDailyData, setReportingPrevDailyData] =
    useState<IDashboardOverViewResponse>()

  const getData = useCallback(
    async (
      restaurantId: number,
      establishmentId: number,
      startingDate: Date | null,
      endingDate: Date | null,
      prevStartingDate: Date | null,
      prevEndingDate: Date | null
    ) => {
      await Promise.all([
        await reportingService.getDashboardInfo({
          restaurantId,
          establishmentId:
            establishmentId > 0
              ? [establishmentId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          startDate: moment(startingDate)
            .set('date', startingDate.getDate())
            .set('month', startingDate.getMonth())
            .toISOString()
            .split('T')[0],
          endDate: moment(endingDate)
            .set('date', endingDate.getDate() - 1)
            .toISOString()
            .split('T')[0],
        }),
        await reportingService.getDashboardInfo({
          restaurantId,
          establishmentId:
            establishmentId > 0
              ? [establishmentId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          startDate: prevStartingDate?.toISOString().split('T')[0],
          endDate: moment(prevEndingDate)
            .set('date', prevEndingDate.getDate() - 1)
            .toISOString()
            .split('T')[0],
        }),
      ])
        .then(([data, prevDailyData]) => {
          setReportingData(data.data.data)
          setReportingPrevDailyData(prevDailyData.data.data)
        })
        .then(() => {
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedRestaurantId,
      selectedCurrentEstablishment,
      startDate,
      endDate,
      prevStartDate,
      prevEndDate,
    ]
  )

  useEffect(() => {
    setIsLoading(true)
    getData(
      selectedRestaurantId,
      selectedCurrentEstablishment,
      startDate,
      endDate,
      prevStartDate,
      prevEndDate
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRestaurantId,
    selectedCurrentEstablishment,
    startDate,
    endDate,
    prevStartDate,
    prevEndDate,
  ])

  if (isLoading) {
    return <></>
  }

  function calculateTotal(dataArray, property) {
    let total = 0
    dataArray?.forEach((item) => {
      if (item[property]) {
        total += item[property]
      }
    })
    return total
  }

  function dataSet(dataArray, property1, property2, period) {
    let strtDate
    let enDate

    if (period === 'current') {
      strtDate = moment(startDate)
        .set('date', startDate.getDate())
        .format('MMM D')
      enDate = moment(endDate).format('MMM D')
    } else if (period === 'previous') {
      strtDate = moment(prevStartDate).format('MMM D')
      enDate = moment(prevEndDate).format('MMM D')
    }

    const dataByDay = dataArray.reduce((acc, item) => {
      const date = new Date(item[property1])
      const month = moment(date).format('MMM')
      const day = date.getDate()
      const dayOfMonth = `${month} ${day}`

      if (!acc[dayOfMonth]) {
        acc[dayOfMonth] = 0
      }

      acc[dayOfMonth] += item[property2]
      return acc
    }, {})

    const sDate = moment(strtDate, 'MMM D')
    const eDate = moment(enDate, 'MMM D')

    const filledData = []
    const currentDate = sDate.clone()

    while (currentDate.isSameOrBefore(eDate, 'day')) {
      const formattedDate = currentDate.format('MMM D')
      filledData.push({
        x: formattedDate,
        y: dataByDay[formattedDate] || 0,
      })
      currentDate.add(1, 'day')
    }

    return filledData
  }

  function calculateAverage(dataArray, property1, property2) {
    let totalProp1 = 0
    let totalProp2 = 0
    dataArray?.forEach((item) => {
      if (item[property1]) {
        totalProp1 += item[property1]
      }
    })

    dataArray?.forEach((item) => {
      if (item[property2]) {
        totalProp2 += item[property2]
      }
    })

    if (totalProp2 === 0) {
      return 0
    }

    const avg = totalProp1 / totalProp2
    return avg
  }

  const categorizedTopPerformers = {}
  reportingData?.topPerformers?.forEach((obj) => {
    if (!categorizedTopPerformers[obj.performerCategory]) {
      categorizedTopPerformers[obj.performerCategory] = []
    }

    categorizedTopPerformers[obj.performerCategory].push(obj)
  })

  const mostRepeatedValues = {}

  Object.keys(categorizedTopPerformers).forEach((category) => {
    const categoryArray = categorizedTopPerformers[category].map(
      (obj) => obj.topAll
    )
    const counts = {}

    categoryArray.forEach((value) => {
      counts[value] = (counts[value] || 0) + 1
    })

    const mostRepeated = Object.keys(counts).reduce((a, b) =>
      counts[a] > counts[b] ? a : b
    )
    mostRepeatedValues[category] = mostRepeated
  })

  const singleSetOfCategories = Object.keys(mostRepeatedValues).map(
    (category) => ({
      category,
      value: mostRepeatedValues[category],
    })
  )

  return (
    <div className="Tabs">
      <Grid container spacing={6}>
        <Grid item xl={4} lg={6} sm={12}>
          <div className="card stats" data-aos="fade-up">
            <StatDetail
              title="Total Earnings"
              description={
                <p className="stat-description">
                  This is your total $ earned through transactions completed
                  during the selected time period. This allows you to know how
                  much you&rsquo;re selling
                </p>
              }
              content={
                <>
                  {reportingData?.overviewDetails ? (
                    <>
                      <p>
                        {formatCurrency(
                          calculateTotal(
                            reportingData.overviewDetails,
                            'totalEarnings'
                          )
                        )}
                      </p>
                      <small>&nbsp;</small>
                      <PercentageDetail
                        number={CalculateChange(
                          calculateTotal(
                            reportingData.overviewDetails,
                            'totalEarnings'
                          ),
                          calculateTotal(
                            reportingPrevDailyData.overviewDetails,
                            'totalEarnings'
                          )
                        )}
                      />
                      <hr />
                      <h4>Previous Period</h4>
                      <h6>
                        {formatCurrency(
                          calculateTotal(
                            reportingPrevDailyData.overviewDetails,
                            'totalEarnings'
                          )
                        )}
                      </h6>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />

            <div className="oval">
              <img src={TrayWhite} alt="Tray" />
            </div>

            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>
        <Grid item xl={4} lg={6} sm={12}>
          <div className="card stats" data-aos="fade-up">
            <StatDetail
              title="Total Tabs"
              description={
                <p className="stat-description">
                  This are the Total Tabs at your restaurant over the time
                  period selected. This allows you to see how many tabs were
                  added to get the total earnings.
                </p>
              }
              content={
                <>
                  {reportingData?.overviewDetails ? (
                    <>
                      <p>
                        {calculateTotal(
                          reportingData.overviewDetails,
                          'totalTabs'
                        )}{' '}
                        Tabs
                      </p>
                      <small>&nbsp;</small>
                      <PercentageDetail
                        number={CalculateChange(
                          calculateTotal(
                            reportingData.overviewDetails,
                            'totalTabs'
                          ),
                          calculateTotal(
                            reportingPrevDailyData.overviewDetails,
                            'totalTabs'
                          )
                        )}
                      />
                      <hr />
                      <h4>Previous Period</h4>
                      <h6>
                        {calculateTotal(
                          reportingPrevDailyData.overviewDetails,
                          'totalTabs'
                        )}{' '}
                        Tabs
                      </h6>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={TrayWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>
        <Grid item xl={4} lg={6} sm={12}>
          <div className="card stats" data-aos="fade-up" data-aos-delay="100">
            <StatDetail
              title="Avg FasTab Review Rating"
              description={
                <p className="stat-description">
                  This is the average FasTab reviews given over the selected
                  period of times. This allows you to see how guests rated their
                  expierence when dining with you
                </p>
              }
              content={
                <>
                  {reportingData?.overviewDetails ? (
                    <>
                      <p>
                        {calculateAverage(
                          reportingData.overviewDetails,
                          'starRatingSum',
                          'totalRatings'
                        )?.toFixed(1)}{' '}
                        <Rating
                          name="read-only"
                          value={
                            reportingData?.overviewDetails
                              ? Number(
                                  calculateAverage(
                                    reportingData.overviewDetails,
                                    'starRatingSum',
                                    'totalRatings'
                                  )?.toFixed(1)
                                )
                              : 0
                          }
                          precision={0.1}
                          size="large"
                          readOnly
                        />
                      </p>
                      <small>
                        {reportingData?.overviewDetails
                          ? calculateTotal(
                              reportingData.overviewDetails,
                              'totalRatings'
                            )
                          : 0}{' '}
                        ratings
                      </small>
                      <PercentageDetail
                        number={CalculateChange(
                          calculateAverage(
                            reportingData.overviewDetails,
                            'starRatingSum',
                            'totalRatings'
                          ),
                          calculateAverage(
                            reportingPrevDailyData.overviewDetails,
                            'starRatingSum',
                            'totalRatings'
                          )
                        )}
                      />
                      <hr />
                      <h4>Previous Period</h4>
                      <h6>
                        {calculateAverage(
                          reportingPrevDailyData.overviewDetails,
                          'starRatingSum',
                          'totalRatings'
                        )?.toFixed(1)}{' '}
                        <Rating
                          name="read-only"
                          value={
                            reportingPrevDailyData?.overviewDetails
                              ? Number(
                                  calculateAverage(
                                    reportingPrevDailyData.overviewDetails,
                                    'starRatingSum',
                                    'totalRatings'
                                  )?.toFixed(1)
                                )
                              : 0
                          }
                          precision={0.1}
                          size="small"
                          readOnly
                        />
                      </h6>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={UserWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos-dis" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>
        <Grid item xl={4} lg={6} sm={12}>
          <div className="card stats" data-aos="fade-up" data-aos-delay="100">
            <StatDetail
              title="Average Guest Check Size"
              description={
                <p className="stat-description">
                  This is the $ amount guests spent per cheque during the time
                  periods selected. This is a measure of how much you are making
                  per transaction.
                </p>
              }
              content={
                <>
                  {reportingData?.overviewDetails ? (
                    <>
                      <p>
                        $
                        {calculateAverage(
                          reportingData.overviewDetails,
                          'totalEarnings',
                          'totalTransactions'
                        )?.toFixed(1)}
                      </p>
                      <small>&nbsp;</small>
                      <PercentageDetail
                        number={CalculateChange(
                          calculateAverage(
                            reportingData.overviewDetails,
                            'totalEarnings',
                            'totalTransactions'
                          ),
                          calculateAverage(
                            reportingPrevDailyData.overviewDetails,
                            'totalEarnings',
                            'totalTransactions'
                          )
                        )}
                      />
                      <hr />
                      <h4>Previous Period</h4>
                      <h6>
                        $
                        {calculateAverage(
                          reportingPrevDailyData.overviewDetails,
                          'totalEarnings',
                          'totalTransactions'
                        )?.toFixed(1)}
                      </h6>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={UserWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>
        <Grid item xl={4} lg={6} sm={12}>
          <div className="card stats" data-aos="fade-up" data-aos-delay="100">
            <StatDetail
              title="Avg Turnover Time"
              description={
                <p className="stat-description">
                  This is the average time it takes to turnover a tab in your
                  restaurant. This allows you to understand how much time guests
                  spend at the table when dining with you
                </p>
              }
              content={
                <>
                  {reportingData?.overviewDetails ? (
                    <>
                      <p>
                        {calculateAverage(
                          reportingData.overviewDetails,
                          'tabTimeMins',
                          'totalValidTurnoverTabs'
                        )?.toFixed(1)}{' '}
                        min
                      </p>
                      <small>&nbsp;</small>
                      <PercentageDetail
                        number={CalculateChange(
                          calculateAverage(
                            reportingData.overviewDetails,
                            'tabTimeMins',
                            'totalValidTurnoverTabs'
                          ),
                          calculateAverage(
                            reportingPrevDailyData.overviewDetails,
                            'tabTimeMins',
                            'totalValidTurnoverTabs'
                          )
                        )}
                      />
                      <hr />
                      <h4>Previous Period</h4>
                      <h6>
                        {calculateAverage(
                          reportingPrevDailyData.overviewDetails,
                          'tabTimeMins',
                          'totalValidTurnoverTabs'
                        )?.toFixed(1)}{' '}
                        min
                      </h6>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={UserWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>
        <Grid item xl={4} lg={6} sm={12}>
          <div className="card stats" data-aos="fade-up" data-aos-delay="100">
            <StatDetail
              title="Top Performers"
              description={
                <p className="stat-description">
                  These are the top performers shown for your selected time
                  period, by total earnings. This allows you to have a good
                  understanding of the areas, people, and menu items in your
                  restaurant that are performing the best
                </p>
              }
              content={
                <>
                  {reportingData?.topPerformers &&
                  reportingData?.topPerformers.length > 0 &&
                  selectedCurrentEstablishment > 0 ? (
                    <table>
                      <tbody>
                        {singleSetOfCategories?.map((item) => (
                          <tr key={item.category}>
                            <td>{item.category}</td>
                            <td>{item.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={UserWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>
        <Grid item xl={8} lg={16} sm={12} style={{ position: 'relative' }}>
          <div className="card">
            {reportingData?.overviewDetails ||
            reportingPrevDailyData?.overviewDetails ? (
              <LineChart
                currentMonth={dataSet(
                  reportingData.overviewDetails,
                  'tabDate',
                  'totalEarnings',
                  'current'
                )}
                prevMonth={dataSet(
                  reportingPrevDailyData.overviewDetails,
                  'tabDate',
                  'totalEarnings',
                  'previous'
                )}
                currentDates={`${startDate.toLocaleDateString('default', {
                  month: 'short',
                })} ${startDate.getDate()} to 
                    ${endDate.toLocaleDateString('default', {
                      month: 'short',
                    })} ${endDate.getDate()}`}
                prevDates={`${moment(prevStartDate).format('MMM')} ${moment(
                  prevStartDate
                ).format('D')} to 
                    ${moment(prevEndDate).format('MMM')}
                     ${moment(prevEndDate).format('D')}`}
              />
            ) : (
              <NoData />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default OverviewView
