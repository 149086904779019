/* eslint-disable @typescript-eslint/no-use-before-define */
import { Tab, Tabs } from '@mui/material'
import MenuTab from 'components/MenuTab/Index'
import PdfViewer from 'components/PdfViewer/Index'
import { currentEstablishment } from 'features/establishment/establishment'
import { menuList } from 'features/menu/menu'
import { IMenuSearchResponse } from 'interfaces/menu'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function MobileMenuPreview() {
  const menus = useSelector(menuList)
  const [menuListData, setMenuList] = useState<IMenuSearchResponse[]>([])
  const establishmentData = useSelector(currentEstablishment)
  const [value, setValue] = React.useState(0)
  useEffect(() => {
    setMenuList(menus.filter((data) => data.isActive === true))
    if (value >= menus.filter((data) => data.isActive === true).length) {
      setValue(0)
    }
  }, [menus, value])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <div className="mobile-menu-preview">
        <div className="restaurant-info">
          <strong>{establishmentData?.restaurantName}</strong>
          <span>{establishmentData?.establishmentName}</span>
        </div>
        <div className="inner">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="primary-tabs"
            variant="scrollable"
            scrollButtons={false}
          >
            {menuListData.map((element) => {
              return <Tab key={element.menuId} label={element.name} />
            })}
          </Tabs>
          {menuListData.map((element, index) => {
            return element.isPdfMenu ? (
              <TabPanel value={value} key={element.menuId} index={index}>
                <PdfViewer
                  currentTab={value}
                  tabIndex={index}
                  menuId={element.menuId}
                />
              </TabPanel>
            ) : (
              element.isActive === true && (
                <TabPanel value={value} key={element.menuId} index={index}>
                  <MenuTab
                    currentTab={value}
                    tabIndex={index}
                    menuId={element.menuId}
                  />
                </TabPanel>
              )
            )
          })}
        </div>
      </div>
    </>
  )
}

export default MobileMenuPreview
