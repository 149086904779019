/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Textfield from 'components/Textfield/Index'
import React, { useState, useEffect } from 'react'
import menuService from 'services/menu.service'
import { IMenuSearchResponse } from 'interfaces/menu'
import { useDispatch, useSelector } from 'react-redux'
import { addMenu, updateMenu } from 'features/menu/menu'
import { selectedEstablishment } from 'features/establishment/establishment'
import { toast } from 'react-toastify'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  menu?: Array<IMenuSearchResponse>
  isEdit?: boolean
  CurrentEstablishmentId?: number
  closeDialog?: (event: boolean) => void
  index?: number
}

function AddEditMenuDialog(props: dialogProps) {
  const {
    open,
    onClose,
    menu,
    isEdit,
    CurrentEstablishmentId,
    closeDialog,
    index,
  } = props
  const dispatch = useDispatch()
  const selectedEstablishmentCurrent = useSelector(selectedEstablishment)
  const [nameError, setNameError] = useState(false)
  const [lengthError, setLengthError] = useState(false)
  const [menuName, setMenuName] = useState('')
  const [menuData, setMenu] = useState<IMenuSearchResponse>()
  const [data, setData] = useState({
    name: '',
    establishmentId: CurrentEstablishmentId,
    position: null,
  })

  useEffect(() => {
    const initialData = data
    initialData.establishmentId = CurrentEstablishmentId
    setData(initialData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CurrentEstablishmentId])

  useEffect(() => {
    setNameError(false)
    setLengthError(false)
    if (open) {
      if (isEdit) {
        setMenu(menu[index])
        setMenuName(menu[index].name)
      } else {
        setMenu(null)
        setMenuName('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, open])

  const SaveData = async () => {
    try {
      if (menuName === '') {
        setNameError(true)
        return
      }
      if (menuName.length > 100) {
        setLengthError(true)
        return
      }
      if (!isEdit) {
        let getPosition
        if (menu.length > 0) {
          getPosition = menu?.reduce((prev, current) =>
            +prev.position > +current.position ? prev : current
          )
        }
        data.name = menuData.name
        data.position = getPosition ? getPosition.position + 1 : 1
        const response = await menuService.creatMenu(data)
        toast.success('Menu added successfully.')
        dispatch(addMenu({ menu: response.data.data }))
        setNameError(false)
        setLengthError(false)
        closeDialog(false)
      } else {
        setMenu((previous) => ({
          ...previous,
          establishmentId: selectedEstablishmentCurrent,
        }))
        const resp = await menuService.updateMenu(menuData)
        toast.success(resp.data.data)
        dispatch(updateMenu({ menu: menuData }))
        setNameError(false)
        setLengthError(false)
        closeDialog(false)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
    setData({
      name: '',
      establishmentId: CurrentEstablishmentId,
      position: null,
    })
  }

  return (
    <>
      <Dialog
        className="primary-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <h5>Menu</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div className="form-group">
            <Textfield
              variant="outlined"
              label="Menu Name"
              value={menuName}
              onChange={(e) => {
                setMenuName(e.target.value)
                setMenu({ ...menuData, name: e.target.value })
              }}
              error={!!nameError || !!lengthError}
              helperText={
                nameError
                  ? 'Menuname is required'
                  : lengthError
                  ? 'Menu name length should be less than 100'
                  : ''
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title={isEdit ? 'Save' : 'Add'}
              onClick={SaveData}
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
            <Button
              onClick={onClose}
              title="Cancel"
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddEditMenuDialog
