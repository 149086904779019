import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import { styled } from '@mui/material/styles'
import { DialogOptions } from 'enums/Common'
import React from 'react'

const CustomDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid #DDD',
  },
}))

interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose?: React.MouseEventHandler<Element>
}
const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Button
          variant="text"
          title="Close"
          onClick={(e) => {
            onClose(e)
          }}
          className="icon-btn rounded ml-auto"
          color="inherit"
        >
          <img src={Close} alt="Close" />
        </Button>
      ) : null}
    </DialogTitle>
  )
}

interface dialogProps {
  open: boolean
  message: string
  idFieldValue?: number
  title?: string
  callBack: (reason: DialogOptions, idFieldValue?: number) => void
  onClose?: React.MouseEventHandler<Element>
}

function DeleteConfirmationDialog(props: dialogProps) {
  const {
    open,
    message,
    idFieldValue,
    title = 'Delete Confirmation',
    callBack,
    onClose,
  } = props

  return (
    <>
      <CustomDialog
        className="primary-dialog alert-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={(e) => {
            if (onClose) {
              onClose(e)
            }
            if (callBack) {
              callBack(DialogOptions.Cancel)
            }
          }}
        >
          {title}
        </CustomDialogTitle>
        <DialogContent className="pb-0">
          <p>{message}</p>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Yes"
              onClick={(e) => {
                if (onClose) {
                  onClose(e)
                }
                if (callBack) {
                  callBack(DialogOptions.Yes, idFieldValue)
                }
              }}
            >
              Yes
            </Button>
            <Button
              title="No"
              variant="outlined"
              color="primary"
              onClick={(e) => {
                if (onClose) {
                  onClose(e)
                }
                if (callBack) {
                  callBack(DialogOptions.No)
                }
              }}
            >
              <span>No</span>
            </Button>
          </div>
        </DialogActions>
      </CustomDialog>
    </>
  )
}

export default DeleteConfirmationDialog
