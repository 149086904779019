import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { BASE_URL } from 'configs'
import { HttpStatusCodes } from 'enums/http-status-codes'
import { toast } from 'react-toastify'
import { setIsLoading } from 'features/loader/loader'
import { store } from 'store'
// eslint-disable-next-line import/no-cycle
import authService from './auth.service'

declare module 'axios' {
  export interface AxiosRequestConfig {
    hideLoader: boolean
  }
}

axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    let isTokenRequired = true
    if (
      config.url.includes('/Account') ||
      config.url.includes('/authenticate')
    ) {
      isTokenRequired = false
    }
    if (isTokenRequired === true) {
      const token = await authService.getAuthorizationToken()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }
    if (config.url) {
      config.url = BASE_URL + config.url
    }

    if (config.url) {
      config.headers['Cache-Control'] =
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
      config.headers.Pragma = 'no-cache'
      config.headers.Expires = '0'
    }

    if (!config.hideLoader) {
      store.dispatch(setIsLoading(true))
    }

    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    store.dispatch(setIsLoading(false))
    return response
  },
  (error: AxiosError) => {
    store.dispatch(setIsLoading(false))
    // toast message
    let errorMessage = 'An error occured'
    if (error.response.status === 401 || error.response.status === 403) {
      if (!error.response?.request?.responseURL.includes('/tender_types')) {
        localStorage.clear()
      }
    }
    if (error.response?.data?.responseException?.exceptionMessage) {
      errorMessage =
        error.response?.data?.responseException?.exceptionMessage.message !==
        undefined
          ? error.response?.data?.responseException?.exceptionMessage.message
          : error.response?.data?.responseException?.exceptionMessage
    }
    if (error.response?.request?.responseURL.includes('/tender_types')) {
      errorMessage = 'Failed to get tender type.'
    }
    toast.error(errorMessage, {
      toastId: 'error',
    })

    // handle errors
    switch (error.response?.status) {
      case HttpStatusCodes.Forbidden:
        setTimeout(
          // eslint-disable-next-line no-return-assign
          () =>
            (window.location.href =
              // eslint-disable-next-line prefer-template
              window.location.protocol + '//' + window.location.host),
          5000
        )

        break
      case HttpStatusCodes.InternalServerError:
      case HttpStatusCodes.ServiceUnavailable:
        break
      default:
    }

    // hideLoader()
    return Promise.reject(error)
  }
)

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
}
