import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IEstablishmentId,
  IGlobalFilterRequest,
  IGoogleRatingResponse,
  IGuestListRequest,
  IGuestListResponse,
  IGuestRequest,
  IRatingResponse,
  IRestaurantNames,
  IReviewUpdateRequest,
  IUpdateInternalReviewRequest,
} from 'interfaces/guest'
import { ICsvDownload } from 'interfaces/restaurant'
import { ISearchResponse } from 'interfaces/user'
import httpClient from './base-service'

const endPointBaseURL = `/Guest`
const getGuestList = async (
  requestBody: IGuestListRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IGuestListResponse[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IGuestListResponse[]>>>(
    `${endPointBaseURL}/get-guest-list`,
    requestBody
  )

const getFastabReview = async (
  requestBody: IGlobalFilterRequest
): Promise<AxiosResponse<IApiSuccessResponse<IRatingResponse>>> =>
  httpClient.post<IApiSuccessResponse<IRatingResponse>>(
    `${endPointBaseURL}/get-fastab-review`,
    requestBody
  )

const getGuestRestaurant = async (
  requestBody: IGuestRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IRestaurantNames[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IRestaurantNames[]>>>(
    `${endPointBaseURL}/get-guest-restaurant`,
    requestBody
  )

const getGuestReview = async (
  requestBody: IGuestRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IRatingResponse[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IRatingResponse[]>>>(
    `${endPointBaseURL}/get-guest-review-rating`,
    requestBody
  )

const downloadRatingCsv = async (
  requestBody: IGuestRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICsvDownload>>> =>
  httpClient.post<IApiSuccessResponse<ICsvDownload>>(
    `${endPointBaseURL}/create-csv-for-review-rating`,
    requestBody
  )

const downloadGuestCSV = async (): Promise<
  AxiosResponse<IApiSuccessResponse<ICsvDownload>>
> =>
  httpClient.get<IApiSuccessResponse<ICsvDownload>>(
    `${endPointBaseURL}/get-guest-csv`
  )

const updateReview = async (
  requestBody: IReviewUpdateRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-review`,
    requestBody
  )

const getGoogleReview = async (
  requestBody: IEstablishmentId
): Promise<AxiosResponse<IApiSuccessResponse<IGoogleRatingResponse>>> =>
  httpClient.post<IApiSuccessResponse<IGoogleRatingResponse>>(
    `/common/get-fastab-google-review`,
    requestBody
  )

const updateInternalReview = async (
  requestBody: IUpdateInternalReviewRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `/Establishment/update-internal-review`,
    requestBody
  )

export default {
  getGuestList,
  getFastabReview,
  getGuestRestaurant,
  getGuestReview,
  downloadRatingCsv,
  downloadGuestCSV,
  updateReview,
  getGoogleReview,
  updateInternalReview,
}
