/* eslint-disable react/jsx-filename-extension */
import { Grid } from '@mui/material'
// import Select from 'components/Select/Index'
import Rating from '@mui/material/Rating'
import { TrayWhite, UserWhite } from 'assets/images'

function GuestView() {
  return (
    <div className="Tabs">
      {/* <div className="filter-panel">
        <div className="card">
          <Grid container spacing={5}>
            <Grid item lg={3} sm={6} xs={12}>
              <div className="form-group">
                <Select
                  label="Select Period"
                  items={[
                    {
                      key: 'All',
                      value: 0,
                    },
                    {
                      key: 'Breakfast',
                      value: 1,
                    },
                    {
                      key: 'Lunch',
                      value: 2,
                    },
                    {
                      key: 'Dinner',
                      value: 3,
                    },
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div> */}
      <Grid container spacing={6}>
        <Grid item lg={6} sm={6} xs={12}>
          <div className="card stats" data-aos="fade-up">
            <div className="stats-info">
              <small>Average Dollar Spend</small>
              <p>600</p>
            </div>
            <div className="oval">
              <img src={TrayWhite} alt="Tray" />
            </div>
          </div>
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <div className="card stats" data-aos="fade-up" data-aos-delay="100">
            <div className="stats-info">
              <small>Average Guest Rating</small>
              <p>
                4.2
                <Rating
                  name="read-only"
                  value={4.2}
                  precision={0.1}
                  size="large"
                  readOnly
                />
              </p>
            </div>
            <div className="oval">
              <img src={UserWhite} alt="Tray" />
            </div>
          </div>
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <div className="card stats" data-aos="fade-up">
            <div className="stats-info">
              <small>Guest Spend per Minute</small>
              <p>$3.40/min</p>
            </div>
            <div className="oval">
              <img src={TrayWhite} alt="Tray" />
            </div>
          </div>
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <div className="card stats" data-aos="fade-up">
            <div className="stats-info">
              <small>Guest Time</small>
              <p>45%</p>
            </div>
            <div className="oval">
              <img src={TrayWhite} alt="Tray" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default GuestView
