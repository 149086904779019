import React from 'react'
import ApexCharts from 'apexcharts'
import CalculateChange from './ChangeCalculator'

interface DataPoint {
  x: string
  y: number
}

interface LineChartProps {
  currentMonth: DataPoint[]
  prevMonth: DataPoint[]
  currentDates: string
  prevDates: string
}

class LineChart extends React.Component<LineChartProps> {
  componentDidMount() {
    const { currentMonth, prevMonth, currentDates, prevDates } = this.props

    const options = {
      series: [
        {
          name: `Current Period`,
          data: currentMonth.map((item) => item?.y),
        },
        {
          name: `Previous Period`,
          data: prevMonth.map((item) => item?.y),
        },
      ],
      chart: {
        height: 370,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        zoom: { enabled: false },
        toolbar: {
          show: false,
        },
      },
      colors: ['#00A8FC', '#A0A0A0'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      title: {
        text: `Current Period (${currentDates}) and Previous Period (${prevDates})`,
        align: 'left',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: 'Poppins, sans-serif',
          color: '#000000',
        },
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      legend: {
        tooltipHoverFormatter(val, opts) {
          return `${val} - $${
            opts.w.globals?.series[opts?.seriesIndex][opts?.dataPointIndex]
          }`
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: currentMonth.map((item) => item?.x),
        tickPlacement: 'on',
        title: {
          text: 'Days of Month',
          style: {
            fontSize: '14px',
            fontFamily: 'Poppins, sans-serif',
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '14px',
            fontFamily: 'Poppins, sans-serif',
          },
        },
        title: {
          text: 'Total Earnings in $',
          style: {
            fontSize: '14px',
            fontFamily: 'Poppins, sans-serif',
          },
        },
      },
      tooltip: {
        shared: true,
        x: {
          show: true,
          formatter: (val, { dataPointIndex }) => {
            const current = currentMonth[dataPointIndex]?.y
            const prev = prevMonth[dataPointIndex]?.y

            const change = CalculateChange(current, prev)

            let changeText = ''
            let changeStyle = ''

            if (change > 0) {
              changeText = `+${change.toFixed(1)}%`
              changeStyle =
                'background-color: green; color: white; padding: 4px 8px; border-radius: 4px;'
            } else if (change < 0) {
              changeText = `${change.toFixed(1)}%`
              changeStyle =
                'background-color: #FFD700; color: black; padding: 4px 8px; border-radius: 4px;'
            } else if (prev === 0 && current !== 0) {
              changeText = '+100.0%'
              changeStyle =
                'background-color: green; color: white; padding: 4px 8px; border-radius: 4px;'
            } else {
              changeText = 'No Change'
              changeStyle = 'padding: 4px 8px; border-radius: 4px;'
            }

            return `
              <div style="${changeStyle}">
                ${changeText}
              </div>
            `
          },
        },
        y: [
          {
            title: {
              formatter: (val, { dataPointIndex }) => {
                const currentDate = currentMonth[dataPointIndex]?.x
                return `${currentDate} ($)`
              },
            },
          },
          {
            title: {
              formatter: (val, { dataPointIndex }) => {
                const currentDate = prevMonth[dataPointIndex]?.x
                return `${currentDate} ($)`
              },
            },
          },
        ],
      },
    }

    const chart = new ApexCharts(document.querySelector('#chart'), options)
    chart.render()
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart" id="chart" />
        </div>
      </div>
    )
  }
}

export default LineChart
