/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Switch from 'components/Switch/Index'
import Textfield from 'components/Textfield/Index'
import {
  addMenuCategory,
  menuList,
  updateMenuCategory,
} from 'features/menu/menu'
import {
  IMenuCategorySearchResponse,
  IMenuSearchResponse,
} from 'interfaces/menu'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import menuService from 'services/menu.service'

interface dialogProps {
  open: boolean
  menuId: number
  isEdit?: boolean
  totalCategory: IMenuCategorySearchResponse[]
  categoryId?: number
  editPosition?: number
  editVisibility?: boolean
  closeDialog?: (event: boolean) => void
  editName?: string
  getNewCatList?: () => void
  menu?: IMenuSearchResponse[]
}

function AddEditCategoryDialog(props: dialogProps) {
  const {
    open,
    menuId,
    isEdit,
    totalCategory,
    categoryId,
    editPosition,
    editVisibility,
    closeDialog,
    editName,
    getNewCatList,
    menu,
  } = props
  const dispatch = useDispatch()
  const [categoryName, setCategoryName] = useState('')
  const [currentName, setCurrentName] = useState(editName)
  const [visibility, setvisibility] = useState(true)
  const [nameError, setNameError] = useState(false)
  const [lengthError, setLengthError] = useState(false)
  const menus = useSelector(menuList)

  useEffect(() => {
    setCurrentName(editName)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editName])

  const handleNameCahnge = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (isEdit) {
      setCurrentName(e.target.value)
    } else {
      setCategoryName(e.target.value)
    }
    {
      e.target.value && setNameError(false)
    }
  }

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setvisibility(e.target.checked)
  }

  const SaveCategory = async () => {
    const menuIndex = menus.findIndex((d) => d.menuId === menuId)
    if (!menus[menuIndex]?.isPdfMenu && !menus[menuIndex]?.menuPdfPath) {
      let currentAddedCategory
      if (isEdit) {
        if (currentName.length > 100) {
          setLengthError(true)
        }
        if (currentName !== '') {
          await menuService.updateCategoryMenu({
            menuId,
            name: currentName,
            position: editPosition,
            isActive: editVisibility,
            menuCategoryId: categoryId,
          })
          toast.success('Category updated succssfully')
          setNameError(false)
          setLengthError(false)
          setCurrentName(editName)
          getNewCatList()
          closeDialog(false)
        } else {
          setNameError(true)
        }
      } else if (categoryName !== '') {
        let getPosition
        if (totalCategory.length > 0) {
          getPosition = totalCategory?.reduce((prev, current) =>
            +prev.position > +current.position ? prev : current
          )
        }
        if (categoryName.length > 100) {
          setLengthError(true)
          return
        }
        currentAddedCategory = await menuService.creatCategoryMenu({
          menuId,
          name: categoryName,
          position: getPosition ? getPosition.position + 1 : 1,
          isActive: visibility,
        })
        toast.success('toast added successfully')
        setNameError(false)
        setLengthError(false)
        setCategoryName('')
        getNewCatList()
        closeDialog(false)
      } else {
        setNameError(true)
      }
      const currentMenuIndex = menu.findIndex((d) => d.menuId === menuId)
      // eslint-disable-next-line prefer-const
      let currentMenuData = menu[currentMenuIndex]
      const categoryIndex = currentMenuData.menuCategories?.findIndex(
        (d) => d.menuCategoryId === categoryId
      )
      if (!isEdit) {
        dispatch(
          addMenuCategory({
            menuId,
            categoryIndex,
            categoryDetails: {
              menuCategoryId: currentAddedCategory.data.data.menuCategoryId,
              menuId,
              menuItem: [],
              menuSubCategory: [],
              isActive: visibility,
              postion: totalCategory.length + 1,
              name: categoryName,
              parentCategoryId: null,
            },
          })
        )
      }

      if (isEdit) {
        dispatch(
          updateMenuCategory({
            menuId,
            categoryIndex,
            isActive: editVisibility,
            categoryName: currentName || categoryName,
          })
        )
      }
    } else {
      toast.error(
        'There is already a file uploaded for this menu. Please remove it for adding menu category'
      )
    }
  }

  const closeDialogBox = () => {
    setNameError(false)
    setLengthError(false)
    closeDialog(false)
  }

  return (
    <>
      <Dialog
        className="primary-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={closeDialogBox}
      >
        <DialogTitle>
          <h5>Category</h5>
          <Button
            variant="text"
            title="Close"
            onClick={closeDialogBox}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <Textfield
              variant="outlined"
              onChange={(e) => handleNameCahnge(e)}
              label="Category Name"
              defaultValue={isEdit ? editName : ''}
              error={nameError || lengthError}
              helperText={
                nameError
                  ? 'Category name is required'
                  : lengthError
                  ? 'Category Name should be less than 100'
                  : ' '
              }
            />
          </div>
          {!isEdit && (
            <Switch
              label="Visibility"
              placement="start"
              defaultChecked
              onChange={(e) => handleSwitchChange(e)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title={isEdit ? 'Save' : 'Add'}
              onClick={SaveCategory}
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
            <Button
              onClick={closeDialogBox}
              title="Cancel"
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddEditCategoryDialog
