import Header from 'components/Header/Index'
import Sidebar from 'components/Sidebar/Index'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Footer from 'components/Footer/Index'
import { HelmetProvider } from 'react-helmet-async'
import restaurantService from 'services/restaurant.service'
import { toast } from 'react-toastify'
import establishmentService from 'services/establishment.service'
import Loader from 'components/Loader/Index'

interface ILayout {
  title?: string
  children: JSX.Element[]
}

const Layout = ({ title, children }: ILayout) => {
  const [restaurantList, setRestaurantList] = useState([])
  const [establishmentList, setEstablishmentList] = useState([])
  const [restaurantLoaded, setRestaurantLoaded] = useState(false)
  const [establishmentLoaded, setEstablishmentLoaded] = useState(false)

  useEffect(() => {
    async function getRestaurantList(pageNo: number, pageSize: number) {
      const data = await restaurantService.getAllResturants({
        pageNo,
        pageSize,
        sortGridModels: [
          {
            field: 'name',
            sort: 0,
          },
        ],
      })
      setRestaurantList(data.data.data.data)
      setRestaurantLoaded(true)
    }
    getRestaurantList(1, 0)
    async function getEstablishmentList() {
      try {
        const data = await establishmentService.getAllEstablishments({
          pageNo: 1,
          pageSize: 0,
          sortGridModels: [{ field: 'restaurantName', sort: 0 }],
          restaurantId: 0,
        })

        setEstablishmentList(data.data.data.data)
        setEstablishmentLoaded(true)
      } catch (e) {
        if (localStorage.length !== 0) {
          toast.error('Failed to retrieve establishment list.')
        }
      }
    }
    getEstablishmentList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return restaurantLoaded && establishmentLoaded ? (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
      <Sidebar
        takeEstablishmentList={establishmentList}
        takeRestaurantList={restaurantList}
      />
      {/* wrapper start */}
      <section className="wrapper">
        {/* header */}
        <Header
          takeEstablishmentList={establishmentList}
          takeRestaurantList={restaurantList}
        />
        {/* main-content start */}
        <section className="main-content">{children}</section>
        {/* footer */}
        <Footer />
      </section>
      {/* wrapper end */}
    </>
  ) : (
    <>
      <Loader />
    </>
  )
}

export default Layout
