import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import { IAzureStorageDetail } from 'interfaces/azure-storage-details'
import httpClient from './base-service'

// const getAzureDetails = () => {
//   return fetch(`http://localhost:49262/api/Blob/get_azure_sas_token`)
//     .then((response) => response.json())
//     .then((response) => response)
//     .catch((error) => {
//       console.error(error)
//       throw Error(error)
//     })
// }
const endPointBaseURL = `/Blob`

const getAzureDetails = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IAzureStorageDetail>>
> =>
  httpClient.get<IApiSuccessResponse<IAzureStorageDetail>>(
    `${endPointBaseURL}/get_azure_sas_token`
  )

const getLogoFromAzure = async (
  filepath: string
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.get<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/get-blob-url`,
    {
      params: {
        path: filepath,
      },
      hideLoader: false,
    }
  )

const createBlobInContainer = async (
  containerClient: ContainerClient,
  file: File,
  fileName: string
) => {
  const blobClient = containerClient.getBlockBlobClient(fileName)

  const options = { blobHTTPHeaders: { blobContentType: file.type } }

  try {
    await blobClient.uploadData(file, options)
  } catch {
    toast.error('Error while uploading file')
  }
}

const deleteFileInBlobContainer = async (
  containerClient: ContainerClient,
  fileName: string
) => {
  console.log(containerClient)
  const blobClient = containerClient.getBlockBlobClient(fileName)

  try {
    console.log(blobClient)
    await blobClient.deleteIfExists({ deleteSnapshots: 'include' })
  } catch (e) {
    console.log(e)
    toast.error('Error while deleting file')
  }
}

const uploadFileToBlob = async (
  file: File,
  fileName: string,
  containerName: string,
  azureStorageAccountName: string,
  sasToken?: string
): Promise<void> => {
  let blobService
  // eslint-disable-next-line no-unused-expressions
  sasToken
    ? (blobService = new BlobServiceClient(
        `https://${azureStorageAccountName}.blob.core.windows.net/?${sasToken}`
      ))
    : (blobService = new BlobServiceClient(
        `https://${azureStorageAccountName}.blob.core.windows.net/`
      ))

  const containerClient: ContainerClient =
    blobService.getContainerClient(containerName)

  await createBlobInContainer(containerClient, file, fileName)
}

const deleteFile = async (
  fileName: string,
  containerName: string,
  azureStorageAccountName: string,
  sasToken?: string
): Promise<void> => {
  let blobService
  // eslint-disable-next-line no-unused-expressions
  sasToken
    ? (blobService = new BlobServiceClient(
        `https://${azureStorageAccountName}.blob.core.windows.net/?${sasToken}`
      ))
    : (blobService = new BlobServiceClient(
        `https://${azureStorageAccountName}.blob.core.windows.net/`
      ))

  const containerClient: ContainerClient =
    blobService.getContainerClient(containerName)

  await deleteFileInBlobContainer(containerClient, fileName)
}

export default {
  getAzureDetails,
  uploadFileToBlob,
  getLogoFromAzure,
  deleteFile,
}
