export const LoginArtwork = require('./login-artwork.svg').default
export const Logo = require('./logo.png')
export const Checked = require('./ic-checked.svg').default
export const Unchecked = require('./ic-unchecked.svg').default
export const Bill = require('./ic-bill.svg').default
export const BillActive = require('./ic-bill-active.svg').default
export const ChartWhite = require('./ic-chart-white.svg').default
export const Down = require('./ic-down.svg').default
export const FoodDelivery = require('./ic-food-delivery.svg').default
export const FoodDeliveryActive =
  require('./ic-food-delivery-active.svg').default
export const HelpIcon = require('./ic-help-icon.svg').default
export const Home = require('./ic-home.svg').default
export const HomeActive = require('./ic-home-active.svg').default
export const Insight = require('./ic-insight.svg').default
export const InsightActive = require('./ic-insight-active.svg').default
export const MenuIcon = require('./ic-menu-icon.svg').default
export const MoneyBagWhite = require('./ic-money-bag-white.svg').default
export const MoneyBag = require('./ic-money-bag.svg').default
export const MoneyBagActive = require('./ic-money-bag-active.svg').default
export const Notification = require('./ic-notification.svg').default
export const Payment = require('./ic-payment.svg').default
export const PaymentActive = require('./ic-payment-active.svg').default
export const QRCode = require('./ic-QRcode.svg').default
export const QRCodeActive = require('./ic-QRcode-active.svg').default
export const Report = require('./ic-report.svg').default
export const ReportActive = require('./ic-report-active.svg').default
export const RestaurantMenu = require('./ic-restaurant-menu.svg').default
export const RestaurantMenuActive =
  require('./ic-restaurant-menu-active.svg').default
export const Setting = require('./ic-setting.svg').default
export const SettingActive = require('./ic-setting-active.svg').default
export const TrayWhite = require('./ic-tray-white.svg').default
export const UserFill = require('./ic-user-fill.svg').default
export const UserWhite = require('./ic-user-white.svg').default
export const User = require('./ic-user.svg').default
export const UserActive = require('./ic-user-active.svg').default
export const Chart1 = require('./chart1.png')
export const Chart2 = require('./chart2.png')
export const Chart3 = require('./chart3.png')
export const Chart4 = require('./chart4.png')
export const UsermenuDownarrow = require('./ic-usermenu-downarrow.svg').default
export const PlusWhite = require('./ic-plus-white.svg').default
export const CsvFile = require('./ic-csv-file.svg').default
export const Filter = require('./ic-filter.svg').default
export const Calendar = require('./ic-calendar.svg').default
export const FileUploadIcon = require('./ic-file-upload.svg').default
export const Move = require('./ic-move.svg').default
export const Edit = require('./ic-edit.svg').default
export const Delete = require('./ic-delete.svg').default
export const List = require('./ic-list.svg').default
export const MobileFrame = require('./mobile-frame.png')
export const More = require('./ic-more.svg').default
export const Close = require('./ic-close.svg').default
export const PDF = require('./ic-pdf.svg').default
export const DeleteGrey = require('./ic-delete-grey.svg').default
export const AddSmall = require('./ic-add-small.svg').default
export const SortDescending = require('./ic-sort-desc.svg').default
export const SortAscending = require('./ic-sort-asc.svg').default
export const MoreSmall = require('./ic-more-small.svg').default
export const AddUser = require('./ic-add-user.svg').default
export const BackArrow = require('./ic-back-arrow.svg').default
export const RestaurantLogo = require('./restaurant-logo.png')
export const View = require('./ic-view.svg').default
export const Download = require('./ic-download.svg').default
export const DownloadDark = require('./ic-download-dark.svg').default
export const DownloadWhite = require('./ic-download-white.svg').default
export const MobileLogo = require('./mobile-logo.png')
export const Barcode = require('./barcode.png')
export const BarcodeSmall = require('./barcode-small.png')
export const Establishments = require('./ic-establishments.svg').default
export const Location = require('./ic-location.svg').default
export const Guest = require('./ic-guest.svg').default
export const GuestActive = require('./ic-guest-active.svg').default
export const AddUserBold = require('./ic-add-user-bold.svg').default
export const MenuClone = require('./ic-menu-clone.svg').default
export const Warning = require('./ic-warning.svg').default
export const AddPrimary = require('./ic-plus-primary.svg').default
export const Search = require('./ic-search.svg').default
export const CircularText = require('./circular-text.svg').default
export const ArrowBlue = require('./ic-arrow-blue.svg').default
export const ArrowBlueSmall = require('./ic-arrow-blue-small.svg').default
export const RestaurantMenuLarge =
  require('./ic-restaurant-menu-large.svg').default
export const EstablishmentsActive =
  require('./ic-establishments-active.svg').default
