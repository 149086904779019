import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

const LiveLineChart = ({ xData, yData, date }) => {
  const series = [
    {
      name: 'Today',
      data: xData?.map((point) => point.y),
    },
    {
      name: date,
      data: yData?.map((point) => point.y),
    },
  ]

  const options: ApexOptions = {
    chart: {
      zoom: { enabled: true },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: true,
      width: 3,
      curve: 'smooth',
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    colors: ['#00A8FC', '#A0A0A0'],
    legend: {
      position: 'bottom',
      offsetY: 0,
      offsetX: 0,
      tooltipHoverFormatter(val, opts) {
        return `${val} - $${
          opts.w.globals?.series[opts?.seriesIndex][opts?.dataPointIndex] || 0
        }`
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      labels: {
        show: true,
      },
      categories: yData?.map((point) => point.x),
      tickAmount: 8,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => `$${val?.toFixed(2) || 0}`,
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      style: {
        fontSize: '12px',
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
  }

  if (window.innerWidth < 786) {
    options.legend.offsetX = 50
    options.legend.offsetY = 70
  }

  return (
    <div className="app">
      <div className="row">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={300}
        />
      </div>
    </div>
  )
}
export default LiveLineChart
