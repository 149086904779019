/* eslint-disable no-unused-vars */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { resolveHTTP } from 'utility/helper'
import { PASSWORD_REGEX } from 'utility/constants'
import { IResetPassword } from 'interfaces/login'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import authService from 'services/auth.service'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  closeDialog?: (event: boolean) => void
  // closeDialog?: (event: boolean) => void
}
function ResetPasswordDialog(props: dialogProps) {
  const { open, onClose, closeDialog } = props
  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false)
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        oldPassword: yup.string().required('Old Password is required'),
        newPassword: yup
          .string()
          .required('New Password is required')
          .matches(
            PASSWORD_REGEX,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
          ),
        confirmNewPassword: yup
          .string()
          .required('Confirm New Password is required')
          .oneOf([yup.ref('newPassword')], 'Passwords do not match'),
      }),
    []
  )

  const handleSave = async (formdata: IResetPassword) => {
    formdata.loginId = Number(localStorage.getItem('loginId'))
    const [data] = await resolveHTTP(authService.resetPassword(formdata))
    if (data) {
      toast.success('Password updated successfully ')
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      formik.resetForm()
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      formik.resetForm()
    }
    closeDialog(false)
    // eslint-disable-next-line no-unused-expressions
  }
  const formik = useFormik<IResetPassword>({
    initialValues: {
      loginId: 0,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema,
    onSubmit: (values) => handleSave(values),
  })

  const handleClose = () => {
    formik.resetForm()
  }
  useEffect(() => {
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfPassword = () => {
    setShowConfPassword(!showConfPassword)
  }

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <>
      <Dialog
        className="primary-dialog user-dialog"
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <h5 className="bold">Change Password</h5>
          <Button
            variant="text"
            title="Close"
            onClick={(e) => {
              onClose(e)
              handleClose()
            }}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        {/* dialog-content start */}
        <form onSubmit={formik.handleSubmit}>
          <DialogContent className="pb-0">
            <div className="form-group">
              <TextField
                variant="outlined"
                type={showOldPassword ? 'text' : 'password'}
                label="Old Password"
                name="oldPassword"
                value={formik.values?.oldPassword}
                onChange={formik.handleChange}
                error={
                  !!formik.errors.oldPassword && formik.touched.oldPassword
                }
                helperText={
                  !!formik.errors.oldPassword && formik.touched.oldPassword
                    ? formik.errors.oldPassword
                    : ''
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group">
              <TextField
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                label="New Password"
                name="newPassword"
                value={formik.values?.newPassword}
                onChange={formik.handleChange}
                error={
                  !!formik.errors.newPassword && formik.touched.newPassword
                }
                helperText={
                  !!formik.errors.newPassword && formik.touched.newPassword
                    ? formik.errors.newPassword
                    : ''
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group">
              <TextField
                variant="outlined"
                type={showConfPassword ? 'text' : 'password'}
                label="Confirm New Password"
                name="confirmNewPassword"
                value={formik.values?.confirmNewPassword}
                onChange={formik.handleChange}
                error={
                  !!formik.errors.confirmNewPassword &&
                  formik.touched.confirmNewPassword
                }
                helperText={
                  !!formik.errors.confirmNewPassword &&
                  formik.touched.confirmNewPassword
                    ? formik.errors.confirmNewPassword
                    : ''
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </DialogContent>
          {/* dialog-content end */}
          <DialogActions>
            <div className="btn-list">
              <Button
                variant="contained"
                color="primary"
                title="Change password"
                type="submit"
              >
                Change Password
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default ResetPasswordDialog
