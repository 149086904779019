import * as signalR from '@microsoft/signalr'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { Close } from 'assets/images'
import Checkbox from 'components/Checkbox/Index'
import ReactSelect, { Option } from 'components/ReactSelect/Index'
import Select from 'components/Select/Index'
import Switch from 'components/Switch/Index'
import TextFieldWithCheckbox from 'components/TextfieldWithCheckbox/Index'
import { DialogOptions } from 'enums/Common'
import {
  IEstablishmentSearchResponse,
  IPOSEstablishment,
} from 'interfaces/establishment'
import { RootObject } from 'interfaces/omnivore'
import { IQRCodesTable, ITableSectionResponse } from 'interfaces/qrcode'
import { IRestuarantSearchResponse } from 'interfaces/restaurant'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import establishmentService from 'services/establishment.service'
import { getTablesFromPOS } from 'services/omnivore-service'
import QRCodesTableServices from 'services/qr.service'
import restaurantService from 'services/restaurant.service'
import {
  PIXELPOINT_POS_ID,
  SIGNAL_R_HUB,
  SQUIRREL_POS_ID,
  TRAY_POS_ID,
} from 'utility/constants'

interface dialogProps {
  open: boolean
  onClose?: (
    event: React.MouseEvent<HTMLElement>,
    option?: DialogOptions
  ) => void
  editTableId?: number
}

interface IQRCodesTableVM {
  tableId: number
  name: string
  establishmentId: number
  posTableId: number
  isActive: boolean
  landingPageUrl: string
  tableSectionId: number
  checked: boolean
}

function AddEditTableDialog(props: dialogProps) {
  const { open, onClose, editTableId = 0 } = props
  const [tables, setTables] = useState<IQRCodesTableVM[] | undefined>([])
  const [fetchFromPOS, setFetchFromPOS] = useState(false)
  const [restaurantDetailList, setRestaurantList] = useState([])
  const [establishmentList, setEstablishmentList] = useState([
    {
      key: 'No Options',
      value: -1,
    },
  ])
  const [tableSectionList, setTableSectionList] = useState<Option[]>([])
  const [restaurantId, setRestaurantId] = useState<number>(0)
  const [establishmentId, setEstablishmentId] = useState<number>(0)
  const [sectionId, setSectionId] = useState<string>('0')

  const onFormSubmit = useCallback(
    async (
      qrTables: IQRCodesTableVM[],
      qrTableEstId: number,
      qrTableSectionId: number
    ) => {
      const finalTables = qrTables
        .filter(
          (table) =>
            table.checked &&
            table.name &&
            ((table.tableSectionId === qrTableSectionId && table.tableId > 0) ||
              table.tableId === 0)
        )
        .map((table) => {
          return {
            tableId: table.tableId,
            name: table.name,
            establishmentId: qrTableEstId,
            posTableId: table.posTableId,
            isActive: table.isActive,
            landingPageUrl: 'Landing Page URL',
            tableSectionId: qrTableSectionId,
          }
        })
      const deleteTableIds = qrTables
        .filter((table) => table.tableId > 0 && !table.checked)
        .map((table) => table.tableId)

      if (finalTables.length === 0 && deleteTableIds.length === 0) {
        toast.info('No tables are updated')
        return false
      }
      await QRCodesTableServices.addTables(finalTables)

      if (deleteTableIds.length > 0) {
        await QRCodesTableServices.bulkDeleteTables({
          tableIds: deleteTableIds,
        })
      }

      toast.success('Data Saved Successfully')
      return true
    },
    []
  )

  const clearForm = () => {
    setSectionId(null)
    setEstablishmentId(null)
    setRestaurantId(null)
    setTableSectionList([])
    setEstablishmentList([
      {
        key: 'No Options',
        value: -1,
      },
    ])
    setTables([])
  }

  // getting list of restraunts
  const getRestaurantList = useCallback(
    async () => {
      try {
        const data = await restaurantService.getAllResturants({
          pageNo: 1,
          pageSize: 0,
          sortGridModels: [{ field: 'name', sort: 1 }],
        })
        const restaurants = data.data.data.data.map(
          (rest: IRestuarantSearchResponse) => ({
            key: rest.name,
            value: rest.restaurantId,
          })
        )
        setRestaurantList(restaurants)
      } catch (e) {
        if (localStorage.length !== 0) {
          toast.error('Failed to retrieve restaurant list.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getStaticData = (
    dbTables: IQRCodesTableVM[],
    estId: number,
    noOfTables = 50
  ) => {
    const posTables: Array<IQRCodesTableVM> = []
    const tableTemplate: IQRCodesTableVM = {
      tableId: 0,
      establishmentId: estId || 0,
      landingPageUrl: null,
      tableSectionId: 0,
      isActive: true,
      checked: false,
      name: '',
      posTableId: 0,
    }
    for (let index = dbTables.length + 1; index <= noOfTables; index += 1) {
      posTables.push({
        ...tableTemplate,
        name: `Table ${index}`,
        posTableId: index,
      })
    }
    return posTables
  }
  const getConfiguration = async (estId: number) => {
    const config = await establishmentService.getConfigurations({
      establishmentId: estId,
    })
    const configObj: { [ApiKey: string]: string; locationId: string } = {
      locationId: '',
    }
    config.data.data.forEach((cnf: IPOSEstablishment) => {
      configObj[cnf.key.replace('-', '')] = cnf.value
    })
    return configObj
  }

  const mergeTables = (
    posTables: Array<IQRCodesTableVM>,
    dbTables: Array<IQRCodesTableVM>
  ) => {
    const newTables = posTables.filter(
      (posTable: IQRCodesTableVM) =>
        dbTables.find(
          (table: IQRCodesTableVM) => table.posTableId === posTable.posTableId
        ) === undefined
    )
    const mergedTables = [...dbTables, ...newTables]
    setTables(mergedTables)
  }
  const hasNumber = (tableName) => {
    return /\d/.test(tableName)
  }
  const getTableName = (tableName, tableNumber) => {
    if (
      tableNumber !== null &&
      tableNumber !== undefined &&
      tableNumber !== '' &&
      !hasNumber(tableName)
    ) {
      return `${tableName} ${tableNumber}`
    }
    return tableName
  }
  const getFasTabAgentPosTables = async (
    dbTables: Array<IQRCodesTableVM>,
    estId: number
  ) => {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_SERVER}/${SIGNAL_R_HUB.HUB_NAME}`)
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect([0, 1000])
      .build()

    hubConnection
      .start()
      .then(async () => {
        // Once started, invokes the sendConnectionId in our ChatHub inside our ASP.NET Core application.
        if (hubConnection.connectionId) {
          let isDataSynced = false
          hubConnection
            .invoke(
              SIGNAL_R_HUB.REQUEST_LIST_OF_TABLES_METHOD,
              estId.toString()
            )
            .then(async () => {
              // eslint-disable-next-line no-console
              setTimeout(() => {
                if (isDataSynced) return
                mergeTables([], dbTables)
              }, 5000)
            })
          hubConnection.on(
            SIGNAL_R_HUB.ON_RECIEVE_TABLE_LIST,
            async (response) => {
              const rootObject: RootObject = JSON.parse(response) as RootObject
              // eslint-disable-next-line no-underscore-dangle
              const posTables = rootObject._embedded.tables.map((table) => {
                return {
                  tableId: 0,
                  name: table.name
                    ? getTableName(table.name, table.number)
                    : `Table ${table.id}`,
                  establishmentId: establishmentId || 0,
                  landingPageUrl: '',
                  tableSectionId: 0,
                  posTableId: Number(table.id),
                  isActive: true,
                  checked: false,
                }
              })
              isDataSynced = true
              mergeTables(posTables, dbTables)
            }
          )
        }
      })
      .catch(() => {
        mergeTables([], dbTables)
      })
  }
  const getTrayPOS = async (
    dbTables: Array<IQRCodesTableVM>,
    estId: number
  ) => {
    let posTables: Array<IQRCodesTableVM> = []
    if (estId && estId > 0) {
      const response = await establishmentService.getTRAYTables({
        establishmentId: estId,
      })
      const rootObject: RootObject = response.data.data
      // eslint-disable-next-line no-underscore-dangle
      posTables = rootObject._embedded.tables.map((table) => {
        return {
          tableId: 0,
          name: getTableName(table.name, table.number),
          establishmentId: establishmentId || 0,
          landingPageUrl: '',
          tableSectionId: 0,
          posTableId: Number(table.id),
          isActive: table.available,
          checked: false,
        }
      })
    } else {
      posTables = getStaticData(dbTables, estId, 50)
    }
    mergeTables(posTables, dbTables)
  }

  const getOmnivoreTables = async (
    dbTables: Array<IQRCodesTableVM>,
    estId: number
  ) => {
    const configObj = await getConfiguration(estId)
    let posTables: Array<IQRCodesTableVM> = []
    if (configObj.locationId && configObj.ApiKey) {
      const response = await getTablesFromPOS(
        configObj.ApiKey,
        configObj.locationId
      )
      const rootObject: RootObject = response.data
      // eslint-disable-next-line no-underscore-dangle
      posTables = rootObject._embedded.tables.map((table) => {
        return {
          tableId: 0,
          name: getTableName(table.name, table.number),
          establishmentId: establishmentId || 0,
          landingPageUrl: '',
          tableSectionId: 0,
          posTableId: Number(table.id),
          isActive: table.available,
          checked: false,
        }
      })
    } else {
      posTables = getStaticData(dbTables, estId, 50)
    }
    mergeTables(posTables, dbTables)
  }
  const getPOSTables = async (
    dbTables: IQRCodesTableVM[],
    estId: number
    // currRestId: number
  ) => {
    // console.log(availableRestaurantList, currRestId)
    // const selectedRestaurantDetail = availableRestaurantList.find(
    //   (d) => d.restaurantId === currRestId
    // )
    const estDetails = await establishmentService.getEstablishmentById({
      establishmentId: estId,
    })
    if (
      estDetails.data.data.posId === PIXELPOINT_POS_ID ||
      estDetails.data.data.posId === SQUIRREL_POS_ID
    ) {
      await getFasTabAgentPosTables(dbTables, estId)
    } else if (estDetails.data.data.posId === TRAY_POS_ID) {
      getTrayPOS(dbTables, estId)
    } else {
      await getOmnivoreTables(dbTables, estId)
    }
  }
  const getTables = useCallback(
    async (estId: number, usedb: boolean) => {
      const data = await QRCodesTableServices.getTablesByEstablishmentId({
        establishmentId: estId,
      })
      try {
        const qrTables = data.data.data.map((table: IQRCodesTable) => ({
          tableId: table.tableId,
          name: table.name,
          establishmentId: table.establishmentId || 0,
          posTableId: table.posTableId || 0,
          isActive: table.isActive,
          landingPageUrl: table.landingPageUrl,
          tableSectionId: table.tableSectionId || 0,
          checked: true,
        }))
        if (usedb) {
          getPOSTables(qrTables, estId)
        } else {
          mergeTables([], qrTables)
        }
      } catch (e) {
        if (localStorage.length !== 0) {
          toast.error(data.data.message)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getEstablishmentList = useCallback(
    async (restId: number) => {
      try {
        const data = await establishmentService.getAllEstablishments({
          pageNo: 1,
          pageSize: 20000,
          sortGridModels: [{ field: 'restaurantName', sort: 0 }],
          restaurantId: restId,
        })
        const establishments = data.data.data.data.map(
          (est: IEstablishmentSearchResponse) => ({
            key: est.establishmentName,
            value: est.establishmentId,
          })
        )
        setEstablishmentList([
          {
            key: 'Select',
            value: -1,
          },
          ...establishments,
        ])
        if (establishments.length > 0) {
          setEstablishmentId(-1)
        } else {
          setEstablishmentList([
            {
              key: 'No Options',
              value: -1,
            },
          ])
        }
      } catch (e) {
        if (localStorage.length !== 0) {
          toast.error('Failed to retrieve establishment list.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getAllSections = useCallback(
    async (estId: number, selectedText?: string) => {
      try {
        const data = await QRCodesTableServices.getAllSectionsByEstablishmentId(
          {
            establishmentId: estId,
          }
        )
        const tableSections: Option[] = data.data.data.map(
          (est: ITableSectionResponse) => ({
            label: est.tableSectionName,
            value: est.tableSectionId.toString(),
          })
        )
        setTableSectionList([
          {
            label: 'Select',
            value: '-1',
          },
          ...tableSections,
        ])
        if (selectedText) {
          const selectedSectionId = tableSections.find(
            (t) => t.label === selectedText
          )?.value
          setSectionId(selectedSectionId)
        } else {
          setSectionId('-1')
        }
      } catch (e) {
        if (localStorage.length !== 0) {
          toast.error('Failed to retrieve table sections.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const createSection = (tableSectionName: string) => {
    const tempSections: Option[] = [
      ...tableSectionList,
      {
        label: tableSectionName,
        value: '-1',
      },
    ]
    setTableSectionList(tempSections)
    setSectionId('-1')
    QRCodesTableServices.ceateTableSection({
      tableSectionName,
      establishmentId,
    }).then(() => {
      getAllSections(establishmentId, tableSectionName)
    })
  }

  const onCheckAll = (checked: boolean) => {
    const checkedTable = tables.map((table) => {
      return { ...table, checked }
    })
    setTables(checkedTable)
  }

  useEffect(() => {
    getRestaurantList()
  }, [getRestaurantList])

  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    option: DialogOptions = DialogOptions.No
  ) => {
    clearForm()
    onClose(event, option)
  }

  const renderTable = (table: IQRCodesTableVM, index: number) => {
    let bReturn = false
    const tableSectionId = Number(sectionId)
    if (table.tableId === 0) {
      // If table already saved
      bReturn = true
    } else if (tableSectionId > 0) {
      // if section is selected
      if (table.tableSectionId === tableSectionId) {
        bReturn = true
      }
    } else if (table.tableSectionId > 0) {
      bReturn = false
    } else {
      bReturn = true
    }

    if (bReturn) {
      return (
        <Grid item lg={3} sm={4} xs={6} key={`tbl${index}`}>
          <div className="form-group">
            <TextFieldWithCheckbox
              name={`tables.${index}.name`}
              value={table.name}
              onTextChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const qrTables = [...tables]
                qrTables[index].name = event.target.value
              }}
              onCheckedChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const qrTables = [...tables]
                qrTables[index].checked = event.target.checked
                setTables(qrTables)
              }}
              checked={table.checked}
            />
          </div>
        </Grid>
      )
    }
    return null
  }

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFetchFromPOS(e.target.checked)
    if (establishmentId) {
      getTables(establishmentId, e.target.checked)
    }
  }

  return (
    <>
      <Dialog
        className="primary-dialog xxl table-list-dialog"
        fullWidth
        open={open}
        onClose={(event: React.MouseEvent<HTMLElement>) => {
          handleClose(event)
          setFetchFromPOS(false)
        }}
      >
        <DialogTitle>
          <h5 className="bold">Add/Update Table</h5>
          <Button
            variant="text"
            title="Close"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              handleClose(event)
              setFetchFromPOS(false)
            }}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        {/* dialog-content start */}
        <DialogContent>
          <Grid container spacing={5}>
            <Grid item md={4} sm={6} xs={12}>
              <div className="form-group">
                <Select
                  label="Restaurant"
                  items={restaurantDetailList}
                  formikValue={restaurantId}
                  handleSelectValue={(item) => {
                    setRestaurantId(item)
                    getEstablishmentList(item)
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div className="form-group">
                <Select
                  label="Location"
                  items={establishmentList}
                  formikValue={establishmentId}
                  handleSelectValue={(item) => {
                    setTables([])
                    setEstablishmentId(item)
                    getTables(item, fetchFromPOS)
                    getAllSections(item)
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div className="form-group">
                <ReactSelect
                  label="Sections"
                  placeholderValue=" "
                  items={tableSectionList}
                  value={sectionId}
                  handleSelectValue={(newValue: Option) => {
                    setSectionId(newValue.value)
                  }}
                  disabled={establishmentId === 0}
                  handleCreate={createSection}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              {editTableId === 0 ? (
                <Checkbox
                  classes="select-all"
                  label="All Table"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onCheckAll(event.target.checked)
                  }
                />
              ) : (
                ''
              )}
              <Switch
                className="table-retrive-switch"
                label="Retrive table from POS"
                placement="start"
                onChange={(e) => handleSwitchChange(e)}
              />
            </Grid>
          </Grid>
          {/* table-list start */}
          <div className="table-list">
            <Grid container spacing={5}>
              {
                /* Here we need to put a condition to filter the tables based on sectionId */
                tables.map(renderTable)
              }
            </Grid>
          </div>
          {/* table-list end */}
        </DialogContent>
        {/* dialog-content end */}
        <DialogActions>
          <div className="btn-list">
            {/* <input type="submit" value="Save" /> */}
            <Button
              variant="contained"
              color="primary"
              title="Save"
              type="submit"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                onFormSubmit(
                  tables,
                  establishmentId > 0 ? establishmentId : 0,
                  Number(sectionId) > 0 ? Number(sectionId) : 0
                ).then((value) => {
                  if (value) {
                    handleClose(event, DialogOptions.Yes)
                    setFetchFromPOS(false)
                  }
                })
              }}
            >
              Save
            </Button>
            <Button
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                handleClose(event)
                setFetchFromPOS(false)
              }}
              title="Cancel"
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddEditTableDialog
