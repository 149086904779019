import Aos from 'aos'
import { PlusWhite } from 'assets/images'
import AddEditMenuDialog from 'components/AddEditMenuDialog/Index'
import Button from 'components/Button/Index'
import Layout from 'components/Layout/Layout'
import MenuList from 'components/MenuList/Index'
import MobileMenuPreview from 'components/MobileMenuPreview/Index'
import {
  currentEstablishment,
  establishmentList,
  selectedEstablishment,
  setCurrentEstablishment,
  setSelectedEstablishment,
} from 'features/establishment/establishment'
import { menuList, setMenus } from 'features/menu/menu'
import {
  selectedRestaurant,
  setSelectedRestuarant,
} from 'features/restaurant/restaurant'
import { IMenutState } from 'interfaces/menu'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import menuService from 'services/menu.service'

Aos.init({
  duration: 600,
  once: true,
})

function Menu() {
  const location = useLocation()
  const dispatch = useDispatch()
  const establishments = useSelector(establishmentList)

  // add-edit-menu-dialog
  const [openAddEditMenuDialog, setOpenAddEditMenuDialog] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedCurrentEstablishment = useSelector(selectedEstablishment)

  const menuLists = useSelector(menuList)
  const [establishmentId] = useState(
    (location.state as IMenutState)?.establishmentId
  )
  const [restaurantId] = useState((location.state as IMenutState)?.restaurantId)

  const establishmentData = useSelector(currentEstablishment)

  useEffect(() => {
    if (
      selectedCurrentEstablishment === null ||
      selectedCurrentEstablishment === 0 ||
      establishmentId === null ||
      establishmentId === 0
    ) {
      if (establishmentId !== 0 && establishmentId !== undefined) {
        dispatch(
          setSelectedEstablishment({
            selectedEstablishment: establishmentId,
          })
        )
        dispatch(
          setSelectedRestuarant({
            selectedRestaurant: restaurantId,
          })
        )
      }
    } else if (
      establishmentId !== null &&
      establishmentId !== 0 &&
      establishmentId !== undefined
    ) {
      dispatch(
        setSelectedEstablishment({
          selectedEstablishment: establishmentId,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getMenuLists = useCallback(
    async () => {
      try {
        const data = await menuService.getAllMenu({
          establishmentId: selectedCurrentEstablishment,
        })

        dispatch(
          setMenus({
            menuList: data.data.data.sort((a, b) => {
              return a.position - b.position
            }),
          })
        )
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCurrentEstablishment]
  )

  useEffect(() => {
    if (selectedCurrentEstablishment) {
      getMenuLists()
    }

    const currentEstablishments = establishments.find(
      (d) => d.establishmentId === selectedCurrentEstablishment
    )
    dispatch(
      setCurrentEstablishment({
        currentEstablishment: currentEstablishments,
      })
    )
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrentEstablishment, selectedRestaurantId])

  const closeAddEditMenuDialog = () => {
    setOpenAddEditMenuDialog(false)
    // getMenuLists()
  }

  return (
    <>
      <Layout title="Menu | FasTab">
        <section
          className={
            selectedCurrentEstablishment !== 0 &&
            selectedCurrentEstablishment !== null
              ? 'main-content'
              : 'main-content no-data'
          }
          // data-aos="fade-up"
        >
          {selectedCurrentEstablishment !== 0 &&
          selectedCurrentEstablishment !== null ? (
            <>
              {/* page-top start */}
              <div className="page-top">
                <h3 className="page-title">Menu</h3>
                <div className="right">
                  <Button
                    variant="contained"
                    color="primary"
                    title="New Menu"
                    size="small"
                    onClick={() => {
                      setIsEdit(false)
                      setOpenAddEditMenuDialog(true)
                    }}
                  >
                    <img src={PlusWhite} alt="ADD" />
                    <span>New Menu</span>
                  </Button>
                </div>
              </div>
              {/* page-top end */}
              {/* menu-wrapper start */}
              <div className="card menu-wrapper">
                <div className="menu-content">
                  <div className="left">
                    <h6>{establishmentData?.establishmentName}</h6>
                    <MenuList
                      menu={menuLists}
                      establishmentId={selectedCurrentEstablishment}
                    />
                  </div>
                  <div className="right">
                    <MobileMenuPreview />
                  </div>
                </div>
              </div>
              {/* menu-wrapper end */}
            </>
          ) : (
            <p>Please Select Restaurant and Location to view menu.</p>
          )}
        </section>
        <></>
      </Layout>
      {/* add-edit-menu-dialog */}
      <AddEditMenuDialog
        open={openAddEditMenuDialog}
        onClose={closeAddEditMenuDialog}
        menu={menuLists}
        isEdit={isEdit}
        CurrentEstablishmentId={selectedCurrentEstablishment}
        closeDialog={closeAddEditMenuDialog}
      />
      {/* add-edit-menu-dialog */}
    </>
  )
}

export default Menu
