import Aos from 'aos'
import React, { useCallback, useEffect, useState } from 'react'
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import { Edit, PlusWhite } from 'assets/images'
import Button from 'components/Button/Index'
import FasTabGrid from 'components/FasTabGrid/FasTabGrid'
import FasTabGridActionButton from 'components/FasTabGrid/FasTabGridActionButton'
import AlertDialog from 'components/AlertDialog/Index'
import { IDatagrid } from 'interfaces/datagrid'
import Layout from 'components/Layout/Layout'
import posService from 'services/pos.service'

Aos.init({
  duration: 600,
  once: true,
})

function Pos() {
  const [posList, setPosList] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
  })
  const [openAlertDialog, setOpenAlertDialog] = useState(false)
  const closeAlertDialog = () => {
    setOpenAlertDialog(false)
  }

  const getPosListColumnDef = (): GridColDef[] => {
    return [
      {
        field: 'posName',
        headerName: 'Pos Name',
        flex: 1,
        minWidth: 160,
      },
      {
        field: 'splitByItem',
        headerName: 'Split by Item',
        flex: 1,
        minWidth: 160,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          return <span>{params.value ? 'Yes' : 'No'}</span>
        },
      },
      {
        field: 'splitBySeat',
        headerName: 'Split by Seat',
        flex: 1,
        minWidth: 160,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          return <span>{params.value ? 'Yes' : 'No'}</span>
        },
      },
      {
        field: 'splitByPayInFull',
        headerName: 'Pay in Full',
        flex: 1,
        minWidth: 160,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          return <span>{params.value ? 'Yes' : 'No'}</span>
        },
      },
      {
        field: 'splitByGuest',
        headerName: 'Split by Guest',
        flex: 1,
        minWidth: 160,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          return <span>{params.value ? 'Yes' : 'No'}</span>
        },
      },
      {
        align: 'center',
        headerAlign: 'center',
        field: 'posId',
        disableColumnMenu: true,
        headerName: 'Action',
        sortable: false,
        renderCell: (d: GridRenderCellParams<number>) => {
          return (
            <div className="action-buttons">
              <FasTabGridActionButton
                title="Edit"
                link="/pos/edit"
                icon={Edit}
                state={{
                  posId: +d.value,
                }}
              />
            </div>
          )
        },
        flex: 1,
        minWidth: 200,
      },
    ]
  }

  const getPosList = useCallback(
    async (pageNo: number, pageSize: number, sortOrder: GridSortModel) => {
      const data = await posService.getAllPos({
        pageNo,
        pageSize,
        sortGridModels: sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
      })
      setPosList(data.data.data.data)
      // if (totalRecords === 0) {
      setTotalRecords(data.data.data.totalRecords)
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    getPosList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions])
  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  return (
    <>
      <Layout title="Pos List | FasTab">
        {/* page-top start */}
        <div className="page-top">
          <h3 className="page-title">Pos List</h3>
          <div className="right">
            <Button
              variant="contained"
              color="primary"
              title="Add New"
              size="small"
              isTypeLink
              to="/pos/add"
            >
              <img src={PlusWhite} alt="ADD" />
              <span>Add New</span>
            </Button>
          </div>
        </div>

        <div className="card mb-30 p-0" data-aos="fade-up">
          {/* data-table start */}
          <FasTabGrid
            uniqueId="posId"
            columnsData={getPosListColumnDef()}
            gridData={posList}
            totalRecords={totalRecords}
            onGridPageChange={onPageChange}
            onGridPageSizeChange={onPageSizeChange}
            onGridSortChange={onSortChange}
          />
        </div>
        {/* data-table end */}
      </Layout>
      {/* wrapper end */}

      {/* user-alert-dialog */}
      <AlertDialog onClose={closeAlertDialog} open={openAlertDialog} />
    </>
  )
}

export default Pos
