import { Collapse, Grid } from '@mui/material'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import Aos from 'aos'
import { BackArrow, CsvFile, Filter, View } from 'assets/images'
import Sidebar from 'components/Sidebar/Index'
import Header from 'components/Header/Index'
import Button from 'components/Button/Index'
import Textfield from 'components/Textfield/Index'
import Datepicker from 'components/Datepicker/Index'
import Select from 'components/Select/Index'
import DataTable from 'components/DataTable/Index'
import Footer from 'components/Footer/Index'
import PaymentDetailsDialog from 'components/PaymentDetailsDialog/Index'

Aos.init({
  duration: 600,
  once: true,
})

function PaymentsByOrder() {
  const [filterToggle, setFilterToggle] = useState(false)

  const [openPaymentDetailsDialog, setOpenPaymentDetailsDialog] =
    useState(false)
  const closePaymentDetailsDialog = () => {
    setOpenPaymentDetailsDialog(false)
  }

  return (
    <>
      <Helmet>
        <title>Payments | FasTab</title>
      </Helmet>
      <Sidebar />
      {/* wrapper start */}
      <section className="wrapper">
        {/* header */}
        <Header />
        {/* main-content start */}
        <section className="main-content">
          {/* page-top start */}
          <div className="page-top">
            <h3 className="page-title">Payments</h3>
            <div className="right">
              <Button
                variant="text"
                color="inherit"
                disableFocusRipple
                title="CSV File"
                className="icon-btn"
              >
                <img src={CsvFile} alt="File" />
              </Button>
              <Button
                variant="text"
                color="inherit"
                title="Filter"
                disableFocusRipple
                className="icon-btn"
                onClick={() => {
                  setFilterToggle(!filterToggle)
                }}
              >
                <img src={Filter} alt="Filter" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                title="Back"
                isTypeLink
                to="/tabs"
                size="small"
              >
                <img src={BackArrow} alt="Back" />
                <span>Back</span>
              </Button>
            </div>
          </div>
          {/* page-top end */}
          {/* filter-panel start */}
          <Collapse in={filterToggle}>
            <div className="filter-panel">
              <div className="card">
                <Grid container spacing={5}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield label="Order ID" variant="outlined" />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Datepicker datePickerlabel="Date" />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Select Payment Type"
                        items={[
                          {
                            key: 'Restaurant 1',
                            value: 1,
                          },
                          {
                            key: 'Restaurant 2',
                            value: 2,
                          },
                          {
                            key: 'Restaurant 3',
                            value: 3,
                          },
                        ]}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Select Status"
                        items={[
                          {
                            key: 'Completed',
                            value: 1,
                          },
                          {
                            key: 'Pending',
                            value: 2,
                          },
                          {
                            key: 'In Progress',
                            value: 3,
                          },
                        ]}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="btn-list">
                  <Button variant="contained" color="primary" title="Apply">
                    Apply
                  </Button>
                  <Button variant="outlined" color="primary" title="Clear All">
                    Clear All
                  </Button>
                </div>
              </div>
            </div>
          </Collapse>
          {/* filter-panel end */}

          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="id"
              topPaginatiom
              columnsData={[
                {
                  field: 'orderId',
                  headerName: 'Order ID',
                  flex: 1,
                  minWidth: 100,
                },
                {
                  field: 'dateTime',
                  headerName: 'Date & Time',
                  flex: 1,
                  minWidth: 160,
                },
                {
                  field: 'paymentType',
                  headerName: 'Payment Type',
                  flex: 1,
                  minWidth: 130,
                },
                {
                  field: 'cardType',
                  headerName: 'Card Type',
                  flex: 1,
                  minWidth: 120,
                },
                {
                  field: 'transactionId',
                  headerName: 'Transaction Id',
                  flex: 1,
                  minWidth: 130,
                },
                {
                  field: 'amount',
                  headerName: 'Amount',
                  flex: 1,
                  minWidth: 90,
                },
                {
                  field: 'tip',
                  headerName: 'Tip',
                  flex: 1,
                  minWidth: 80,
                },
                {
                  field: 'status',
                  align: 'center',
                  headerAlign: 'center',
                  headerName: 'Status',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    const success =
                      params.value === 'Active' ||
                      params.value === 'Open' ||
                      params.value === 'Success'
                        ? 'success'
                        : ''
                    const secondary =
                      params.value === 'Inactive' || params.value === 'Closed'
                        ? 'default'
                        : ''
                    const danger = params.value === 'Failed' ? 'danger' : ''
                    return (
                      <span
                        className={`badge 
                      ${success}
                      ${secondary}
                      ${danger}
                      `}
                      >
                        {params.value}
                      </span>
                    )
                  },
                  flex: 1,
                  minWidth: 120,
                },
                {
                  align: 'center',
                  headerAlign: 'center',
                  field: 'action',
                  disableColumnMenu: true,
                  headerName: 'Action',
                  sortable: false,
                  renderCell: () => {
                    return (
                      <Button
                        className="icon-btn no-border rounded"
                        variant="text"
                        color="inherit"
                        onClick={() => {
                          setOpenPaymentDetailsDialog(true)
                        }}
                      >
                        <img src={View} alt="More" />
                      </Button>
                    )
                  },
                  flex: 1,
                  minWidth: 100,
                },
              ]}
              rowsData={[
                {
                  id: 1,
                  orderId: '12345',
                  dateTime: '5/19/12 | 10:30 am',
                  paymentType: 'Credit',
                  cardType: 'Mastercard',
                  transactionId: '4',
                  amount: '$14.00',
                  tip: '$14.00',
                  status: 'Success',
                },
                {
                  id: 2,
                  orderId: '12345',
                  dateTime: '5/19/12 | 10:30 am',
                  paymentType: 'Gpay',
                  cardType: null,
                  transactionId: '3',
                  amount: '$14.00',
                  tip: '$14.00',
                  status: 'Failed',
                },
              ]}
            />
          </div>
          {/* data-table end */}
        </section>
        {/* main-content end */}
        {/* footer */}
        <Footer />
      </section>
      {/* wrapper end */}
      <PaymentDetailsDialog
        open={openPaymentDetailsDialog}
        onClose={closePaymentDetailsDialog}
      />
    </>
  )
}

export default PaymentsByOrder
