import { Collapse, Grid } from '@mui/material'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro'
import Aos from 'aos'
import { Edit, ExportNew, FilterNew, PlusWhite } from 'assets/images'
import AddEditUserDialog from 'components/AddEditUserDialog/Index'
import Button from 'components/Button/Index'
import DataTable from 'components/DataTable/Index'
import Layout from 'components/Layout/Layout'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import CryptoJS from 'crypto-js'
import { selectedEstablishment } from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IDatagrid } from 'interfaces/datagrid'
import { IUserListRequest, IUserListResponse } from 'interfaces/user'
import React, { useCallback, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import userService from 'services/user.service'
import { ROLE_SECRET_KEY } from 'utility/constants'

Aos.init({
  duration: 600,
  once: true,
})

function Users() {
  const FILTER_STATUS = { INACTIVE: 2, ACTIVE: 3, DEFAULT: 0 }
  const [filterToggle, setFilterToggle] = useState(false)
  const [userList, setUserList] = useState([])
  const [filterEmail, setFilterEmail] = useState('')
  const [roleList, setRoleList] = useState([])
  const [editUserData, setEditUserData] = useState<IUserListResponse>()
  const [totalRecords, setTotalRecords] = useState(0)
  const [editMode, setEditMode] = useState(false)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedCurrentEstablishment = useSelector(selectedEstablishment)
  const [userFilterSelectedStatus, setuserFilterSelectedStatus] = useState(
    FILTER_STATUS.DEFAULT
  )
  const IsAdmin = localStorage.getItem('roleId')
    ? Number(
        CryptoJS.AES.decrypt(
          localStorage.getItem('roleId'),
          ROLE_SECRET_KEY
        )?.toString(CryptoJS.enc.Utf8)
      ) === 1
    : false
  const [userFilterSelectedRole, setuserFilterSelectedRole] = useState(0)
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
  })
  const getRoleList = useCallback(
    async () => {
      try {
        const data = await userService.getRoleList()
        const result = data.data.data
        const filtered = result.filter(
          (obj: { roleId: number }) =>
            obj.roleId === 3 ||
            obj.roleId === 4 ||
            obj.roleId === 6 ||
            obj.roleId === 7 ||
            obj.roleId === 1 ||
            obj.roleId === 2
        )
        setRoleList(filtered)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    getRoleList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getUserList = useCallback(
    async (
      pageNo: number,
      pageSize: number,
      sortOrder: GridSortModel,
      selectedStatus: number,
      selectedestablishmentId: number,
      selectedRole: number,
      restId: number,
      email: string
    ) => {
      const response = await userService.getUserList({
        pageNo,
        pageSize,
        sortBy: 'string',
        sortGridModels: sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
        restaurantId: restId === null ? 0 : restId,
        establishmentId:
          selectedestablishmentId === null ? 0 : selectedestablishmentId,
        status:
          selectedStatus === 0
            ? null
            : selectedStatus !== FILTER_STATUS.INACTIVE,
        roleId: selectedRole || 0,
        email,
        isGuestRequired: false,
      })

      const userListWithID: IUserListResponse[] = response.data.data.data.map(
        (user, index) => {
          return { id: index + 1, ...user }
        }
      )
      setUserList(userListWithID)
      setTotalRecords(response.data.data.totalRecords)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId, selectedCurrentEstablishment]
  )
  const generatePayload = () => {
    const payload: IUserListRequest = {
      pageNo: dataGridOptions.pageNumber,
      pageSize: dataGridOptions.pageSize,
      isGuestRequired: false,
      sortGridModels: dataGridOptions.sortOrder.map((d) => {
        return {
          field: d.field,
          sort: d.sort === 'asc' ? 1 : 0,
        }
      }),
    }
    payload.email = filterEmail
    payload.restaurantId =
      selectedRestaurantId === null ? 0 : selectedRestaurantId
    payload.establishmentId =
      selectedCurrentEstablishment === null ? 0 : selectedCurrentEstablishment
    if (userFilterSelectedStatus !== FILTER_STATUS.DEFAULT)
      payload.status = userFilterSelectedStatus !== FILTER_STATUS.INACTIVE
    if (userFilterSelectedRole !== 0) payload.roleId = userFilterSelectedRole
    return payload
  }
  useEffect(() => {
    getUserList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      userFilterSelectedStatus,
      selectedCurrentEstablishment,
      userFilterSelectedRole,
      selectedRestaurantId,
      filterEmail
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions, selectedRestaurantId, selectedCurrentEstablishment])

  const getNewUserList = () => {
    getUserList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      userFilterSelectedStatus,
      selectedCurrentEstablishment,
      userFilterSelectedRole,
      selectedRestaurantId,
      filterEmail
    )
  }

  // Add/Edit User Dialog
  const [openAddEditUserDialog, setOpenAddEditUserDialog] = useState(false)
  const closeAddEditUserDialog = () => {
    setOpenAddEditUserDialog(false)
  }

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  const onFilterApply = async () => {
    const payload = generatePayload()
    payload.pageNo = 1
    const data = await userService.getUserList(payload)
    setUserList(data.data.data.data)
    // if (totalRecords === 0) {
    setTotalRecords(data.data.data.totalRecords)
    // }
  }

  const onFilterClear = () => {
    setuserFilterSelectedStatus(FILTER_STATUS.DEFAULT)
    setFilterEmail('')
    setuserFilterSelectedRole(0)
    getUserList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      FILTER_STATUS.DEFAULT,
      0,
      0,
      selectedRestaurantId,
      ''
    )
  }
  const filterEmailChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFilterEmail(e.target.value)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exportCSVFile = (
    headers: {
      Name: string // remove commas to avoid errors
      EmailAddress: string
      WebsiteLink: string
      CreatedDate: string
    },
    csv:
      | string
      | Blob
      | import('../../interfaces/restaurant').ICsvDownload
      | ArrayBufferView
      | ArrayBuffer,
    fileTitle: string
  ) => {
    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

    const blob = new Blob([csv as BlobPart], {
      type: 'text/csv;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const downloadFile = async () => {
    const data = await userService.downloadCsv({
      email: filterEmail,
      roleId: userFilterSelectedRole !== 0 ? userFilterSelectedRole : null,
      isGuestRequired: false,
      status:
        userFilterSelectedStatus !== FILTER_STATUS.DEFAULT
          ? userFilterSelectedStatus !== FILTER_STATUS.INACTIVE
          : null,
      restaurantId: selectedRestaurantId,
      establishmentId: selectedCurrentEstablishment,
    })

    const headers = {
      Name: 'Name'.replace(/,/g, ''), // remove commas to avoid errors
      EmailAddress: 'Email'.replace(/,/g, ''),
      WebsiteLink: 'Website'.replace(/,/g, ''),
      CreatedDate: 'Date'.replace(/,/g, ''),
    }
    const fileTitle = 'Users'
    exportCSVFile(headers, data.data.data, fileTitle)
  }

  return (
    <>
      <Layout title="Users | FasTab">
        {/* main-content start */}
        <section className="main-content">
          {/* page-top start */}
          <div className="page-top">
            <h3 className="page-title">Users</h3>
            <div className="right">
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                title="Export"
                size="small"
                className="btn-h-40"
                onClick={() => downloadFile()}
              >
                <img src={ExportNew} alt="File" />
                <span>Export</span>
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                title="Filter"
                size="small"
                className="btn-h-40"
                onClick={() => {
                  setFilterToggle(!filterToggle)
                }}
              >
                <img src={FilterNew} alt="Filter" />
                <span>Filter</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                title="New User"
                size="small"
                onClick={() => {
                  setEditMode(false)
                  setOpenAddEditUserDialog(true)
                }}
              >
                <img src={PlusWhite} alt="ADD" />
                <span>New User</span>
              </Button>
            </div>
          </div>
          {/* page-top end */}
          {/* filter-panel start */}
          <Collapse in={filterToggle}>
            <div className="filter-panel">
              <div className="card">
                <Grid container spacing={5}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        label="Email"
                        type="email"
                        variant="outlined"
                        value={filterEmail}
                        onChange={(e) => filterEmailChange(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Select Role"
                        items={
                          roleList &&
                          roleList.map((d) => {
                            return {
                              key: d.displayValue,
                              value: d.roleId,
                            }
                          })
                        }
                        formikValue={userFilterSelectedRole}
                        handleSelectValue={(value) => {
                          setuserFilterSelectedRole(value)
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Select Status"
                        items={[
                          {
                            key: 'Inactive',
                            value: FILTER_STATUS.INACTIVE,
                          },
                          {
                            key: 'Active',
                            value: FILTER_STATUS.ACTIVE,
                          },
                        ]}
                        formikValue={userFilterSelectedStatus}
                        handleSelectValue={(value) => {
                          setuserFilterSelectedStatus(value)
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="btn-list">
                  <Button
                    variant="contained"
                    color="primary"
                    title="Apply"
                    onClick={onFilterApply}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    title="Clear All"
                    onClick={onFilterClear}
                  >
                    Clear All
                  </Button>
                </div>
              </div>
            </div>
          </Collapse>
          {/* filter-panel end */}

          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="loginId"
              columnsData={[
                {
                  field: 'loginName',
                  headerName: 'Email',
                  flex: 1,
                  minWidth: 250,
                },
                {
                  field: 'displayValue',
                  headerName: 'Role',
                  flex: 1,
                  minWidth: 100,
                },
                {
                  field: 'firstName',
                  headerName: 'First Name',
                  flex: 1,
                  minWidth: 110,
                },
                {
                  field: 'lastName',
                  headerName: 'Last Name',
                  flex: 1,
                  minWidth: 110,
                },
                {
                  field: 'isActive',
                  headerName: 'Status',
                  renderCell: (params: GridRenderCellParams<boolean>) => {
                    const success = params.value ? 'success' : ''
                    const secondary = !params.value ? 'default' : ''
                    return (
                      <span
                        className={`badge 
                    ${success}
                    ${secondary}
                    `}
                      >
                        {params.value ? 'Active' : 'In active'}
                      </span>
                    )
                  },
                  flex: 1,
                  minWidth: 120,
                },
                {
                  align: 'center',
                  field: 'lastActive',
                  headerName: 'Last Active',
                  renderCell: (params: GridRenderCellParams<Date>) => {
                    if (params.value) {
                      return (
                        <Moment format="MM/DD/YYYY hh:mm A">
                          {params.value}
                        </Moment>
                      )
                    }
                    return <span>-</span>
                  },
                  flex: 1,
                  minWidth: 180,
                  hide: !IsAdmin,
                },
                {
                  align: 'center',
                  headerAlign: 'center',
                  field: 'action',
                  disableColumnMenu: true,
                  headerName: 'Action',
                  sortable: false,
                  renderCell: (d: GridRenderCellParams<number>) => {
                    return (
                      <div className="action-buttons">
                        <Button
                          className="icon-btn light-bordered small rounded"
                          title="Edit"
                          variant="text"
                          color="inherit"
                          onClick={() => {
                            setEditMode(true)
                            setEditUserData(d.row)
                            setOpenAddEditUserDialog(true)
                          }}
                        >
                          <img src={Edit} alt="Edit" />
                        </Button>
                      </div>
                    )
                  },
                  flex: 1,
                  minWidth: 120,
                },
              ]}
              rowsData={userList}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortChange}
              totalRecords={totalRecords}
            />
          </div>
          {/* data-table end */}
        </section>
        <></>
        {/* main-content end */}
      </Layout>
      {/* wrapper end */}
      {/* add-edit-user-dialog */}
      <AddEditUserDialog
        open={openAddEditUserDialog}
        onClose={closeAddEditUserDialog}
        editMode={editMode}
        userData={editUserData}
        closeDialog={closeAddEditUserDialog}
        getNewList={getNewUserList}
      />
    </>
  )
}

export default Users
