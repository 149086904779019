import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import AddEditEstablishment from 'components/AddEditEstablishment/Index'
import AddEditPromoCode from 'components/AddEditPromoCode/Index'
import AddEditRestaurant from 'components/AddEditRestaurant/Index'
import Dashboard from 'pages/Dashboard/Index'
import Establishments from 'pages/Establishments/Index'
import Guests from 'pages/Guests/Index'
import Login from 'pages/Login/Index'
import Menu from 'pages/Menu/Index'
import Payments from 'pages/Payments/Index'
import PaymentsByOrder from 'pages/PaymentsByOrder/Index'
import QRCodes from 'pages/QRCode/Index'
import Restaurants from 'pages/Restaurants/Index'
import Tabs from 'pages/Tabs/Index'
import Users from 'pages/Users/Index'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { store } from 'store'
import './App.css'
// import React, { useEffect } from 'react'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import AddEditPos from 'components/AddEditPos/Index'
import Loader from 'components/Loader/Index'
import Error from 'pages/Error/Index'
import Pos from 'pages/Pos/Index'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import TwoFactor from 'services/two.factor'
import CreatePassword from 'components/CreatePassword/Index'
import OtpVerification from 'components/TwoFactor/Index'
import Disbursement from 'pages/Disbursement/Index'
import ForgotPassword from 'pages/ForgotPassword/Index'
import PayOut from 'pages/Payouts/Index'
import MonthlyStatements from 'pages/MonthlyStatements/Index'
import TabDetails from 'pages/TabDetails/Index'
import Unauthorized from 'pages/Unauthorized/Index'
import PrivateRoute from 'PrivateRoutes'
import { useEffect } from 'react'
import userService from 'services/user.service'
import Reviews from 'pages/Reviews/Index'
import authService from 'services/auth.service'
import PromoCode from 'pages/PromoCode/Index'

function App() {
  LicenseInfo.setLicenseKey(
    '954de76a4b35b116563e2838d309a8dcTz01MDQyNixFPTE2OTQxNzYwMzI2MjMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  )

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    spacing: [0, 4, 8, 12, 16, 20, 24],
  })

  window.onbeforeunload = () => {
    if (
      localStorage.getItem('loginId') &&
      Number(localStorage.getItem('loginId')) > 0
    ) {
      authService.updateLastActive({
        loginId: Number(localStorage.getItem('loginId')),
        isLastLoginTime: false,
      })
    }
    setTimeout(() => {
      console.log('')
    }, 100)
    localStorage.removeItem('lastActive')
    localStorage.removeItem('createdEndDate')
    localStorage.removeItem('weekStartDate')
    localStorage.removeItem('weekEndDate')
    localStorage.removeItem('payoutDate')
    localStorage.removeItem('fromLocationId')
    localStorage.removeItem('isfromPayment')
    localStorage.removeItem('createdDate')
    localStorage.removeItem('createdEndDate')
    localStorage.removeItem('payEdgeId')
    localStorage.removeItem('fromDisbursement')
    localStorage.removeItem('disRestaurantId')
    localStorage.removeItem('disbursementStatus')
    localStorage.removeItem('disStartDate')
    localStorage.removeItem('disEndDate')
    localStorage.removeItem('disbursementDate')
    localStorage.removeItem('fromPaymentTabId')
    localStorage.removeItem('fromGuestGuestId')
    localStorage.removeItem('fromTabs')
    localStorage.removeItem('orderId')
    localStorage.removeItem('dispayEdgeId')
    localStorage.removeItem('payoutPayEdgeOrderId')
    localStorage.removeItem('frmtabId')
    localStorage.removeItem('ShowDateCalender')
    localStorage.removeItem('serverReviewId')
    localStorage.removeItem('serverReviewDate')
    localStorage.removeItem('restaurantId')
  }
  const updateProfileHeader = async () => {
    if (localStorage.getItem('loginId')) {
      const data = await userService.getUserById({
        loginId: Number(localStorage.getItem('loginId')),
      })
      localStorage.setItem('firstName', data.data.data.firstName)
      localStorage.setItem('lastName', data.data.data.lastName)
      localStorage.setItem('ShowDateCalender', 'false')
    }
  }
  window.onload = () => {
    if (
      localStorage.getItem('loginId') &&
      Number(localStorage.getItem('loginId')) > 0
    ) {
      authService.updateLastActive({
        loginId: Number(localStorage.getItem('loginId')),
        isLastLoginTime: true,
      })
    }
  }
  useEffect(() => {
    updateProfileHeader()
    console.log('test')
  }, [])
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* basename="/design/fastab-admin" */}
        {/* "homepage": "https://web2.anasource.com/design/fastab-admin" */}

        {/* Loader */}
        <Loader />

        {/* Global Toast */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<PrivateRoute Component={Login} allowAll />}
            />
            <Route path="/two-factor" element={<OtpVerification />} />
            <Route
              path="/monthly-statements"
              element={<PrivateRoute Component={MonthlyStatements} allowAll />}
            />
            <Route path="/create-password" element={<CreatePassword />} />
            <Route
              path="/dashboard"
              element={<PrivateRoute Component={Dashboard} allowAll />}
            />
            <Route
              path="/restaurants"
              element={<PrivateRoute Component={Restaurants} />}
            />
            <Route
              path="/restaurants/add"
              element={<PrivateRoute Component={AddEditRestaurant} />}
            />
            <Route
              path="/restaurants/edit"
              element={<PrivateRoute Component={AddEditRestaurant} />}
            />
            <Route
              path="/menu"
              element={<PrivateRoute Component={Menu} allowAll />}
            />
            <Route
              path="/tabs"
              element={<PrivateRoute Component={Tabs} allowAll />}
            />
            <Route
              path="/reviews"
              element={<PrivateRoute Component={Reviews} allowAll />}
            />
            <Route
              path="/promocode"
              element={<PrivateRoute Component={PromoCode} />}
            />
            <Route
              path="/promocode/add"
              element={<PrivateRoute Component={AddEditPromoCode} />}
            />
            <Route
              path="/tab-detail"
              element={<PrivateRoute Component={TabDetails} allowAll />}
            />
            <Route
              path="/payments"
              element={<PrivateRoute Component={Payments} allowAll />}
            />
            <Route
              path="/payments/byOrder"
              element={<PrivateRoute Component={PaymentsByOrder} />}
            />
            <Route
              path="/qr-codes"
              element={<PrivateRoute Component={QRCodes} />}
            />
            <Route path="/users" element={<PrivateRoute Component={Users} />} />
            <Route
              path="/locations"
              element={<PrivateRoute Component={Establishments} />}
            />
            <Route
              path="/restaurant/locations"
              element={<PrivateRoute Component={Establishments} />}
            />
            <Route
              path="/locations/edit"
              element={<PrivateRoute Component={AddEditEstablishment} />}
            />
            <Route
              path="/locations/add"
              element={<PrivateRoute Component={AddEditEstablishment} />}
            />
            <Route
              path="/pos/add"
              element={<PrivateRoute Component={AddEditPos} />}
            />
            <Route
              path="/pos/edit"
              element={<PrivateRoute Component={AddEditPos} />}
            />
            <Route path="/pos" element={<PrivateRoute Component={Pos} />} />
            <Route
              path="/guests"
              element={<PrivateRoute Component={Guests} />}
            />
            <Route
              path="/restaurants/locations/add"
              element={<PrivateRoute Component={AddEditEstablishment} />}
            />
            <Route
              path="/disbursement"
              element={<PrivateRoute Component={Disbursement} />}
            />
            <Route
              path="/payouts"
              element={<PrivateRoute Component={PayOut} allowAll />}
            />
            <Route
              path="/tab-details"
              element={<PrivateRoute Component={TabDetails} allowAll />}
            />
            <Route path="*" element={<Error />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route
              path="/forgot-password"
              element={
                <PrivateRoute
                  Component={ForgotPassword}
                  isNotAllowedAfterLogin
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  )
}

export default App
