function CalculateChange(currentMonthValue, lastMonthValue) {
  if (lastMonthValue === 0) {
    return 0
  }
  const changePercentage =
    ((currentMonthValue - lastMonthValue) / Math.abs(lastMonthValue)) * 100

  return changePercentage
}

export default CalculateChange
