import { Grid } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import DataTable from 'components/DataTable/Index'
import {
  selectedEstablishment,
  establishmentList,
} from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IServerPillerResponse } from 'interfaces/restaurant'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import reportingService from 'services/reporting.service'

interface ServerProps {
  startDate: Date
  endDate: Date
}

function ServerView({ startDate, endDate }: ServerProps) {
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedCurrentEstablishment = useSelector(selectedEstablishment)
  const allEstablishmentList = useSelector(establishmentList)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [serverPillerData, setServerData] = useState([])
  const getData = useCallback(
    async (
      restaurantId: number,
      establishmentId: number,
      startingDate: Date | null,
      endingDate: Date | null
    ) => {
      const pillerData = await reportingService.GetServerPillerInfo({
        restaurantId,
        establishmentId:
          establishmentId > 0
            ? [establishmentId]
            : allEstablishmentList
                .filter((x) => x.restaurantId === selectedRestaurantId)
                .map((x) => x.establishmentId),
        startDate: startingDate?.toJSON(),
        endDate: endingDate?.toJSON(),
      })
      const listPillerData: IServerPillerResponse[] = pillerData.data.data.map(
        (user, index) => {
          return { id: index + 1, ...user }
        }
      )
      setServerData(listPillerData)
      setIsLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId, selectedCurrentEstablishment, startDate, endDate]
  )

  useEffect(() => {
    setIsLoading(true)
    getData(
      selectedRestaurantId,
      selectedCurrentEstablishment,
      startDate,
      endDate
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, selectedCurrentEstablishment, startDate, endDate])

  if (isLoading) {
    return <></>
  }

  return (
    <div className="Tabs">
      <Grid container spacing={6}>
        <Grid item lg={12} sm={12} xs={12}>
          <h3 className="page-title nav">Server Pillar</h3>
          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="id"
              columnsData={[
                {
                  field: 'restuarantName',
                  headerName: 'Restaurant Name',
                  flex: 1,
                  minWidth: 220,
                  align: 'center',
                },
                {
                  field: 'establishmentName',
                  headerName: 'Establishment Name',
                  flex: 1,
                  minWidth: 150,
                  align: 'center',
                },
                {
                  field: 'serverName',
                  headerName: 'Server Name',
                  flex: 1,
                  minWidth: 110,
                  align: 'center',
                },
                // {
                //   field: 'totalTabCount',
                //   headerName: 'Total Tab Count',
                //   flex: 1,
                //   minWidth: 110,
                //   align: 'center',
                //   renderCell: (params: GridRenderCellParams<number>) => {
                //     return (
                //       <span>{`${Math.round(params.value).toFixed(2)} `}</span>
                //     )
                //   },
                // },
                {
                  field: 'avgTabTotal',
                  headerName: 'Average Tab Total',
                  flex: 1,
                  minWidth: 120,
                  align: 'center',
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return (
                      <span>{`$${Math.round(params.value).toFixed(2)} `}</span>
                    )
                  },
                },
                {
                  field: 'tipPercent',
                  headerName: 'Tip Percentage',
                  flex: 1,
                  minWidth: 120,
                  align: 'center',
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return (
                      <span>{`${Math.round(params.value * 100).toFixed(
                        2
                      )}%`}</span>
                    )
                  },
                },
                // {
                //   field: 'tabTotalSum',
                //   headerName: 'Tab Total Sum',
                //   flex: 1,
                //   minWidth: 120,
                //   align: 'center',
                //   renderCell: (params: GridRenderCellParams<number>) => {
                //     return (
                //       <span>{`$${Math.round(params.value).toFixed(2)} `}</span>
                //     )
                //   },
                // },
                // {
                //   field: 'avgTabTurnOverMins',
                //   headerName: 'Average Tab Turnover mins',
                //   flex: 1,
                //   minWidth: 120,
                //   align: 'center',
                //   renderCell: (params: GridRenderCellParams<number>) => {
                //     return (
                //       <span>{`${Math.round(params.value).toFixed(2)}`}</span>
                //     )
                //   },
                // },
                {
                  field: 'avgStarRating',
                  headerName: 'Average star rating',
                  flex: 1,
                  minWidth: 110,
                  align: 'center',
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return (
                      <span>{`${Math.round(params.value).toFixed(2)}`}</span>
                    )
                  },
                },
                {
                  field: 'sfrRating',
                  headerName: 'SFR Rating',
                  flex: 1,
                  minWidth: 100,
                  align: 'center',
                  renderCell: (params: GridRenderCellParams<number>) => {
                    const pillerBgColor = params.value
                      ? `piller-bg-${Math.round(params.value).toFixed()}`
                      : ``
                    return (
                      <span
                        className={` 
                        ${pillerBgColor}  padding-inherit               
                    `}
                      >
                        {params.value.toFixed(1)}
                      </span>
                    )
                  },
                },
              ]}
              rowsData={serverPillerData}
              sortingMode
              bottomPagination
              classes="show-resize"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ServerView
