import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import httpClient from './base-service'
import {
  IMenuSearchResponse,
  IMenutList,
  IMenuUpdate,
  IMenuCategorySearchResponse,
  ICategoryMenuUpdate,
  ICategoryItemUpdate,
  IMenuGetRequest,
  IMenuDetailData,
} from '../interfaces/menu'

const endPointBaseURL = `/Menu`

const getAllMenu = async (
  requestBody: IMenutList
): Promise<AxiosResponse<IApiSuccessResponse<IMenuSearchResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<IMenuSearchResponse[]>>(
    `${endPointBaseURL}/get-menu`,
    requestBody
  )

// edit menu
const updateMenu = async (
  requestBody: IMenuUpdate
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-menu?id=${requestBody.menuId}`,
    requestBody
  )

// add menu
const creatMenu = async (
  requestBody: IMenuUpdate
): Promise<AxiosResponse<IApiSuccessResponse<IMenuUpdate>>> =>
  httpClient.post<IApiSuccessResponse<IMenuUpdate>>(
    `${endPointBaseURL}/add-menu`,
    requestBody
  )

const deleteMenu = async (
  requestBody: ICategoryMenuUpdate
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.delete<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/delete-menu`,
    {
      headers: {},
      data: requestBody,
      hideLoader: false,
    }
  )

const ReorderMenu = async (
  requestBody: Array<number>
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/reorder-menu`,
    {
      positionIds: requestBody,
    }
  )

// inactiveMenu

// edit menu
const InactiveMenu = async (
  requestBody: IMenuUpdate
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/active-inactive-menu`,
    {
      menuId: requestBody.menuId,
      isActive: requestBody.isActive,
    }
  )

const getMenuCategoryById = async (
  requestBody: number
): Promise<AxiosResponse<IApiSuccessResponse<IMenuCategorySearchResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<IMenuCategorySearchResponse[]>>(
    `/MenuCategory/get-categories-by-menu-id`,
    {
      menuId: requestBody,
    }
  )

// add Category menu
const creatCategoryMenu = async (
  requestBody: ICategoryMenuUpdate
): Promise<AxiosResponse<IApiSuccessResponse<ICategoryMenuUpdate>>> =>
  httpClient.post<IApiSuccessResponse<ICategoryMenuUpdate>>(
    `/MenuCategory/add-menu-category`,
    requestBody
  )

const ReorderMenuCategory = async (
  requestBody: Array<number>
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `/MenuCategory/reorder-menu-category`,
    {
      positionIds: requestBody,
    }
  )

const deleteCategoryMenu = async (
  requestBody: number
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.delete<IApiSuccessResponse<string>>(
    `/MenuCategory/delete-menu-category`,
    {
      headers: {},
      data: {
        menuCategoryId: requestBody,
      },
      hideLoader: false,
    }
  )

// edit menu
const updateCategoryMenu = async (
  requestBody: ICategoryMenuUpdate
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `/MenuCategory/update-menu-category`,
    requestBody
  )

// getMenucat
const geMenubyMenuId = async (
  requestBody: IMenuGetRequest
): Promise<AxiosResponse<IApiSuccessResponse<IMenuDetailData>>> =>
  httpClient.post<IApiSuccessResponse<IMenuDetailData>>(
    `${endPointBaseURL}/get-menu-by-id`,
    {
      menuId: requestBody.menuId,
      includeAllFields: requestBody.includeAllFields,
    }
  )

// menu items

const getItemByCatId = async (
  requestBody: number
): Promise<AxiosResponse<IApiSuccessResponse<ICategoryItemUpdate[]>>> =>
  httpClient.post<IApiSuccessResponse<ICategoryItemUpdate[]>>(
    `/MenuItem/get-items-by-category-id`,
    {
      categoryId: requestBody,
    }
  )

const creatCatItem = async (
  requestBody: ICategoryItemUpdate
): Promise<AxiosResponse<IApiSuccessResponse<ICategoryItemUpdate>>> =>
  httpClient.post<IApiSuccessResponse<ICategoryItemUpdate>>(
    `/MenuItem/add-menu-item`,
    requestBody
  )

const ReorderItems = async (
  requestBody: Array<number>
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(`/MenuItem/reorder-menu-item`, {
    positionIds: requestBody,
  })

const deleteItemMenu = async (
  requestBody: number
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.delete<IApiSuccessResponse<string>>(`/MenuItem/delete-menu-item`, {
    headers: {},
    data: {
      menuItemId: requestBody,
    },
    hideLoader: false,
  })

// edit menu item
const updateItemMenu = async (
  requestBody: ICategoryItemUpdate
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `/MenuItem/update-menu-item`,
    requestBody
  )

const getItembyItemID = async (
  requestBody: ICategoryItemUpdate
): Promise<AxiosResponse<IApiSuccessResponse<ICategoryItemUpdate>>> =>
  httpClient.post<IApiSuccessResponse<ICategoryItemUpdate>>(
    `/MenuItem/get-menu-item`,
    requestBody
  )

export default {
  getAllMenu,
  updateMenu,
  creatMenu,
  deleteMenu,
  ReorderMenu,
  getMenuCategoryById,
  creatCategoryMenu,
  ReorderMenuCategory,
  deleteCategoryMenu,
  updateCategoryMenu,
  InactiveMenu,
  geMenubyMenuId,
  getItemByCatId,
  creatCatItem,
  ReorderItems,
  deleteItemMenu,
  updateItemMenu,
  getItembyItemID,
}
