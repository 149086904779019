import React from 'react'
import Button from '../Button/Index'

interface IFasTabGridActionButton {
  className?: string
  title: string
  link?: string
  icon?: string
  state?: object
  onClick?: () => void
}
const FasTabGridActionButton = ({
  className,
  title,
  link,
  icon,
  onClick,
  state,
}: IFasTabGridActionButton) => {
  return (
    <Button
      className={`icon-btn small light-bordered rounded ${className}`}
      variant="text"
      title={title}
      color="inherit"
      isTypeLink
      to={link}
      onClick={onClick}
      linkState={state}
    >
      <img src={icon} alt={title} />
    </Button>
  )
}
export default FasTabGridActionButton
