import { Collapse, Grid } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import Aos from 'aos'
import {
  AddUserBold,
  Edit,
  ExportNew,
  FilterNew,
  Location,
  PlusWhite,
} from 'assets/images'
import AlertDialog from 'components/AlertDialog/Index'
import Button from 'components/Button/Index'
import Datepicker from 'components/Datepicker/Index'
import FasTabGrid from 'components/FasTabGrid/FasTabGrid'
import FasTabGridActionButton from 'components/FasTabGrid/FasTabGridActionButton'
import Layout from 'components/Layout/Layout'
import Select from 'components/Select/Index'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IDatagrid } from 'interfaces/datagrid'
import { IRestuarantSearchRequest } from 'interfaces/restaurant'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux/es/exports'
import restaurantService from 'services/restaurant.service'

Aos.init({
  duration: 600,
  once: true,
})

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, filename: string) => void
  }
}

function Restaurants() {
  const FILTER_STATUS = { INACTIVE: 2, ACTIVE: 3, DEFAULT: 0 }
  const [filterToggle, setFilterToggle] = useState(false)
  const [restaurantList, setRestaurantList] = useState([])
  const [restaurantIdForUser, setRestaurantIdForUser] = useState(0)
  const [restaurantFilterSelectedDate, setRestaurantFilterSelectedDate] =
    useState(null)

  const [restaurantFilterSelectedStatus, setRestaurantFilterSelectedStatus] =
    useState(FILTER_STATUS.DEFAULT)
  const [totalRecords, setTotalRecords] = useState(0)
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
  })
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const [openAlertDialog, setOpenAlertDialog] = useState(false)
  const closeAlertDialog = () => {
    setOpenAlertDialog(false)
  }

  const getEstablishmentDataForMenu = (id: number) => {
    const currentRestaurant = restaurantList.find((d) => d.restaurantId === id)

    return {
      restaurantId: currentRestaurant.restaurantId,
      fromRestaurant: true,
    }
  }

  const getRestaurantListColumnDef = (): GridColDef[] => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 160,
      },
      {
        field: 'createdDate',
        headerName: 'Date',
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<string>) => {
          return <span>{new Date(params.value).toDateString()}</span>
        },
      },
      {
        field: 'isActive',
        headerName: 'Status',
        renderCell: (params: GridRenderCellParams<boolean>) => {
          const success = params.value ? 'success' : ''
          const secondary = !params.value ? 'default' : ''
          return (
            <span
              className={`badge 
          ${success}
          ${secondary}
          `}
            >
              {params.value ? 'Active' : 'In active'}
            </span>
          )
        },
        flex: 1,
        minWidth: 120,
      },
      {
        align: 'center',
        headerAlign: 'center',
        field: 'restaurantId',
        disableColumnMenu: true,
        headerName: 'Action',
        sortable: false,
        renderCell: (d: GridRenderCellParams<number>) => {
          return (
            <div className="action-buttons">
              <FasTabGridActionButton
                title="Edit"
                link="/restaurants/edit"
                icon={Edit}
                state={{
                  restaurantId: +d.value,
                }}
              />
              <FasTabGridActionButton
                title="Add User"
                icon={AddUserBold}
                onClick={() => {
                  setOpenAlertDialog(true)
                  setRestaurantIdForUser(+d.value)
                }}
              />
              <FasTabGridActionButton
                title="Add Location"
                link="/locations"
                icon={Location}
                state={getEstablishmentDataForMenu(d.value)}
              />
            </div>
          )
        },
        flex: 1,
        minWidth: 200,
      },
    ]
  }

  const generatePayload = () => {
    const payload: IRestuarantSearchRequest = {
      pageNo: dataGridOptions.pageNumber,
      pageSize: dataGridOptions.pageSize,
      sortGridModels: dataGridOptions.sortOrder.map((d) => {
        return {
          field: d.field,
          sort: d.sort === 'asc' ? 1 : 0,
        }
      }),
    }
    payload.restaurantId = selectedRestaurantId
    if (restaurantFilterSelectedDate !== '')
      payload.date = restaurantFilterSelectedDate
    if (restaurantFilterSelectedStatus !== FILTER_STATUS.DEFAULT)
      payload.status = restaurantFilterSelectedStatus !== FILTER_STATUS.INACTIVE
    return payload
  }

  const getRestaurantList = useCallback(
    async (
      pageNo: number,
      pageSize: number,
      sortOrder: GridSortModel,
      selectedDate: Date | null,
      selectedStatus: number | null
    ) => {
      const data = await restaurantService.getFilteredResturants({
        pageNo,
        pageSize,
        sortGridModels: sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
        restaurantId: selectedRestaurantId,
        date: selectedDate?.toISOString(),
        // eslint-disable-next-line no-constant-condition
        status:
          selectedStatus === 0
            ? null
            : selectedStatus !== FILTER_STATUS.INACTIVE,
      })
      setRestaurantList(data.data.data.data)
      setTotalRecords(data.data.data.totalRecords)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId]
  )

  useEffect(() => {
    getRestaurantList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      restaurantFilterSelectedDate,
      restaurantFilterSelectedStatus
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions, selectedRestaurantId])

  useEffect(() => {
    async function restaurantListFilter() {
      let pageNo = 1
      const pageSize = 10
      let data = await restaurantService.getAllResturants({
        pageNo,
        pageSize,
        sortGridModels: [
          {
            field: 'name',
            sort: 1,
          },
        ],
      })
      let restaurantData = data.data.data.data
      // eslint-disable-next-line @typescript-eslint/no-shadow
      let { totalRecords } = data.data.data
      if (totalRecords > pageSize) {
        while (totalRecords >= 0) {
          pageNo += 1
          // eslint-disable-next-line no-await-in-loop
          data = await restaurantService.getAllResturants({
            pageNo,
            pageSize,
            sortGridModels: [
              {
                field: 'name',
                sort: 1,
              },
            ],
          })
          restaurantData = restaurantData.concat(data.data.data.data)
          totalRecords -= pageSize
        }
      }
    }
    if (filterToggle) restaurantListFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterToggle, totalRecords])

  const onFilterApply = async () => {
    const payload = generatePayload()
    const data = await restaurantService.getAllResturants(payload)
    setRestaurantList(data.data.data.data)
    setTotalRecords(data.data.data.totalRecords)
  }

  const onFilterClear = () => {
    setRestaurantFilterSelectedDate(null)
    setRestaurantFilterSelectedStatus(FILTER_STATUS.DEFAULT)
    getRestaurantList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      null,
      0
    )
  }

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exportCSVFile = (
    headers: {
      Name: string // remove commas to avoid errors
      CreatedDate: string
    },
    csv:
      | string
      | Blob
      | import('../../interfaces/restaurant').ICsvDownload
      | ArrayBufferView
      | ArrayBuffer,
    fileTitle: string
  ) => {
    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

    const blob = new Blob([csv as BlobPart], {
      type: 'text/csv;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const downloadFile = async () => {
    const data = await restaurantService.downloadCsv({
      status:
        restaurantFilterSelectedStatus !== FILTER_STATUS.DEFAULT
          ? restaurantFilterSelectedStatus !== FILTER_STATUS.INACTIVE
          : null,
      date:
        restaurantFilterSelectedDate !== ''
          ? restaurantFilterSelectedDate
          : null,
      restaurantId: selectedRestaurantId,
    })

    const headers = {
      Name: 'Name'.replace(/,/g, ''), // remove commas to avoid errors
      CreatedDate: 'Date'.replace(/,/g, ''),
    }
    const fileTitle = 'Restaurants'
    exportCSVFile(headers, data.data.data, fileTitle)
  }

  // convert date to local time
  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    )
    return newDate
  }

  return (
    <>
      <Layout title="Restaurants | FasTab">
        {/* page-top start */}
        <div className="page-top">
          <h3 className="page-title">Restaurants</h3>
          <div className="right">
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Export"
              size="small"
              className="btn-h-40"
              onClick={() => downloadFile()}
            >
              <img src={ExportNew} alt="File" />
              <span>Export</span>
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Filter"
              size="small"
              className="btn-h-40"
              onClick={() => {
                setFilterToggle(!filterToggle)
              }}
            >
              <img src={FilterNew} alt="File" />
              <span>Filter</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              title="Add New"
              size="small"
              isTypeLink
              to="/restaurants/add"
            >
              <img src={PlusWhite} alt="ADD" />
              <span>Add New</span>
            </Button>
          </div>
        </div>
        {/* page-top end */}
        {/* filter-panel start */}
        <Collapse in={filterToggle}>
          <div className="filter-panel">
            <div className="card">
              <Grid container spacing={5}>
                <Grid item lg={4} sm={6} xs={12}>
                  <div className="form-group">
                    <Datepicker
                      datePickerlabel="Date"
                      value={restaurantFilterSelectedDate}
                      onChange={(date) => {
                        setRestaurantFilterSelectedDate(
                          convertUTCDateToLocalDate(date).toISOString()
                        )
                      }}
                      setFilterSelectedDate={setRestaurantFilterSelectedDate}
                    />
                  </div>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <div className="form-group">
                    <Select
                      label="Select Status"
                      items={[
                        {
                          key: 'All',
                          value: FILTER_STATUS.DEFAULT,
                        },
                        {
                          key: 'Inactive',
                          value: FILTER_STATUS.INACTIVE,
                        },
                        {
                          key: 'Active',
                          value: FILTER_STATUS.ACTIVE,
                        },
                      ]}
                      formikValue={restaurantFilterSelectedStatus}
                      handleSelectValue={(value) => {
                        setRestaurantFilterSelectedStatus(value)
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="btn-list">
                <Button
                  variant="contained"
                  color="primary"
                  title="Apply"
                  onClick={onFilterApply}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  title="Clear All"
                  onClick={onFilterClear}
                >
                  Clear All
                </Button>
              </div>
            </div>
          </div>
        </Collapse>
        {/* filter-panel end */}

        <div className="card mb-30 p-0" data-aos="fade-up">
          {/* data-table start */}
          <FasTabGrid
            uniqueId="restaurantId"
            columnsData={getRestaurantListColumnDef()}
            gridData={restaurantList}
            totalRecords={totalRecords}
            onGridPageChange={onPageChange}
            onGridPageSizeChange={onPageSizeChange}
            onGridSortChange={onSortChange}
          />
        </div>
        {/* data-table end */}
      </Layout>
      {/* wrapper end */}

      {/* user-alert-dialog */}
      <AlertDialog
        onClose={closeAlertDialog}
        open={openAlertDialog}
        restaurantId={restaurantIdForUser}
        isFromRestaurant
        isFromLocation={false}
      />
    </>
  )
}

export default Restaurants
