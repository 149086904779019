import React, { useMemo, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import Textfield from 'components/Textfield/Index'
import Button from 'components/Button/Index'
import authService from 'services/auth.service'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { EMAIL_REGEX } from 'utility/constants'
import { IForgotPassword } from 'interfaces/login'
import { Logo } from 'assets/images'

function ForgotPassword() {
  const navigate = useNavigate()

  const initialData: IForgotPassword = {
    email: '',
  }
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required('Email is required')
          .matches(EMAIL_REGEX, 'Email is not valid'),
      }),
    []
  )

  const handleSave = useCallback(
    async (data: IForgotPassword) => {
      const result = await authService.forgotPassword(data.email)
      if (result) {
        toast.success('Email sent successfully')
        navigate('/')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [navigate]
  )

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSave(values)
    },
  })

  return (
    <>
      <Helmet>
        <title>Forgot Password | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="login-wrapper">
        <section className="left">
          <h1 data-aos="fade-up">Dine the way</h1>
          <p data-aos="fade-up" data-aos-delay="100">
            You want!
          </p>
        </section>
        <section className="right">
          {/* login-box start */}
          <div className="login-box" data-aos="fade-up" data-aos-delay="200">
            <img src={Logo} alt="FasTab" />
            <small>Welcome</small>
            <h4>Forgot Password</h4>
            <h6>
              Enter your register email address, we will send you the link to
              reset your password.
            </h6>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <Textfield
                  label="Email"
                  variant="outlined"
                  type="text"
                  name="email"
                  error={!!formik.errors.email && formik.touched.email}
                  helperText={
                    !!formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : ''
                  }
                  value={formik.values?.email}
                  onChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </div>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                title="Continue"
                className="forgot-password-continue"
              >
                <span>Continue</span>
              </Button>
            </form>
            <Link
              to="/"
              state={{ isFromLogin: true }}
              className="primary-link"
              title="Back To Login"
            >
              Back To Login
            </Link>
          </div>
          {/* login-box end */}
        </section>
      </section>

      {/* wrapper end */}
    </>
  )
}

export default ForgotPassword
