/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import authService from 'services/auth.service'
import FaqDialog from 'components/FAQdialog/Index'

function Footer() {
  const [faqDialog, setOpenFaqDialog] = useState(false)
  const [url, setUrl] = useState('')
  const getFaqUrl = async () => {
    const data = await authService.getFaqUrl()
    setUrl(data.data.data.privacyPolicyUrl)
  }

  return (
    <>
      <footer className="footer">
        &copy; 2022 FasTab. All rights reserved.{' '}
        <Link
          to="#"
          onClick={() => {
            getFaqUrl()
            setOpenFaqDialog(true)
          }}
          title="Privacy policy"
          className="primary-link"
        >
          Privacy policy
        </Link>
      </footer>
      <FaqDialog
        open={faqDialog}
        url={url}
        onClose={() => setOpenFaqDialog(false)}
      />
    </>
  )
}

export default Footer
