import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { IPromocdeRequest } from 'interfaces/promocode'
import { useCallback, useEffect, useState } from 'react'
import promocodeService from 'services/promocode.service'
import moment from 'moment'
import { Close } from 'assets/images'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  closeDialog?: (event: boolean) => void
  promoId: number
  restaurantName: string
  discountUsageAmount: number
  discountUsageNumber: number
}

function PromoCodeDetails(props: dialogProps) {
  const {
    open,
    onClose,
    closeDialog,
    promoId,
    restaurantName,
    discountUsageAmount,
    discountUsageNumber,
  } = props
  const [promo, setPromo] = useState(null)

  const getPromoDetails = useCallback(
    async (payload: IPromocdeRequest) => {
      try {
        const response = await promocodeService.getPromocodeById(payload)
        setPromo(response.data.data)
      } catch (error) {
        console.error(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    if (promoId > 0) {
      getPromoDetails({ promocodeId: promoId })
    }
  }, [promoId, getPromoDetails])

  let content

  switch (promo?.customerEligibility) {
    case 'specific_customer':
      content = `Applicable exclusively to customers who have visited ${
        promo?.minNoOfVisit || 0
      } times`
      break
    case 'loggedinuser':
      content = 'Applicable exclusively to logged-in customers'
      break
    default:
      content = 'Applicable to all customers'
  }
  return (
    <>
      {open && (
        <Dialog
          className="primary-dialog user-dialog"
          maxWidth="sm"
          fullWidth
          open={open}
          onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
            onClose(e)
            closeDialog(false)
          }}
        >
          <DialogTitle>
            <h5 className="bold">Promo Code Details</h5>
            <Button
              variant="text"
              title="Close"
              onClick={(e) => {
                onClose(e)
              }}
              className="icon-btn rounded"
              color="inherit"
            >
              <img src={Close} alt="Close" />
            </Button>
          </DialogTitle>
          {/* dialog-content start */}
          <div className="right" style={{ marginTop: '10px' }}>
            <Grid className="card" container spacing={2}>
              <Grid item md={12} xs={12}>
                <Grid item md={8} xs={12}>
                  <div>
                    <Typography variant="h5" fontWeight="bold">
                      Summary
                    </Typography>
                  </div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div>
                    <Typography variant="h6">
                      <span className="text-blue">
                        {promo?.codeName.trim().toUpperCase()}
                      </span>
                    </Typography>
                  </div>
                </Grid>
                <hr />
                <Grid item md={12} xs={12}>
                  <div className="promo-list-details">
                    <Typography variant="h6">Details</Typography>
                    <List
                      sx={{
                        listStyleType: 'disc',
                        pl: 2,
                        '& .MuiListItem-root': {
                          display: 'list-item',
                        },
                      }}
                    >
                      {promo?.isRestaurantSpecific ? (
                        <ListItem>
                          {`Promo Code is applicable at `}
                          <span className="text-blue">{restaurantName}</span>
                        </ListItem>
                      ) : (
                        <ListItem>
                          Promo Code is applicable at{` `}
                          <span className="text-blue">all restaurants</span>
                        </ListItem>
                      )}
                      {promo?.isFixedAmountPromoCode ? (
                        <ListItem>
                          <span className="text-blue">
                            {`$${promo?.promocodeAmount ?? 0} `}
                          </span>
                          off entire order
                        </ListItem>
                      ) : (
                        <ListItem>
                          <span className="text-blue">
                            {promo?.promocodePercentage ?? 0}%
                          </span>
                          {` off entire order`}
                        </ListItem>
                      )}
                      {promo?.isMinimumOrderRequirement ? (
                        <ListItem>
                          {`Minimum purchase of `}{' '}
                          <span className="text-blue">
                            ${promo?.minOrderAmount ?? 0}
                          </span>
                        </ListItem>
                      ) : (
                        <ListItem>No minimum purchase requirement</ListItem>
                      )}
                      <ListItem>
                        <span className="text-blue">{content}</span>
                      </ListItem>
                      {/* <ListItem>
                        Promo code will be auto-applied after
                        <span className="text-blue">
                          {` `}
                          {promo?.autoApplyAfterPayment ?? 0}{' '}
                        </span>
                        {` payments`}
                      </ListItem> */}
                      <ListItem>
                        {`Maximum discount usage amount is `}
                        <span className="text-blue">
                          ${promo?.maxDiscountUses ?? 0}
                        </span>
                      </ListItem>
                      <ListItem>
                        Maximum number of discount usage is
                        <span className="text-blue">
                          {` `}
                          {promo?.maxNoOfDiscountUses ?? 0}{' '}
                        </span>
                        times
                      </ListItem>
                      <ListItem>
                        Applicable to use{` `}
                        <span className="text-blue">
                          {promo?.maxLimitForUser ?? 0}
                        </span>
                        {` time per customer (only applicable if promo code is customer specific)`}
                      </ListItem>
                      <ListItem>
                        FasTab will get notified after{` `}
                        <span className="text-blue">
                          ${promo?.maxLimitForNotify ?? 0}
                        </span>
                        {` has been spent on this promo code`}
                      </ListItem>
                      <ListItem>
                        {`Promo code will be valid from `}
                        <span className="text-blue">
                          {moment(promo?.startDate).format('DD MMMM YYYY')}
                        </span>
                        {` to `}
                        <span className="text-blue">
                          {moment(promo?.expireDate).format('DD MMMM YYYY')}
                        </span>
                        {` payments`}
                      </ListItem>
                    </List>
                  </div>
                </Grid>
                <hr />
                <Grid item md={12} xs={12}>
                  <div className="promo-list-details">
                    <Typography variant="h6">Description</Typography>
                    <List>
                      <ListItem>
                        <span className="text-blue">{`${
                          promo?.description.trim() || 'No description'
                        }`}</span>
                      </ListItem>
                    </List>
                  </div>
                </Grid>
                <hr />
                <Grid item md={12} xs={12}>
                  <div className="promo-list-details">
                    <Typography variant="h6">Performance</Typography>
                    <List>
                      {promo?.isActive ? (
                        <ListItem>
                          Discount is&nbsp;
                          <span className="text-blue">Active</span>
                        </ListItem>
                      ) : (
                        <ListItem>
                          Discount is&nbsp;
                          <span className="text-blue">not active</span>
                        </ListItem>
                      )}
                      <ListItem>
                        <span>
                          This code has been applied&nbsp;
                          <span className="text-blue">
                            {discountUsageNumber}
                          </span>
                          &nbsp;times, totaling&nbsp;
                          <span className="text-blue">
                            ${discountUsageAmount?.toFixed(2)}
                          </span>
                          &nbsp;in discount usage until now
                        </span>
                      </ListItem>
                    </List>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      )}
    </>
  )
}
export default PromoCodeDetails
