// [pos]
import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IPosIdRequest,
  IPosRequest,
  IPosResponse,
  ISearchRequest,
  ISearchResponse,
} from 'interfaces/pos'
import httpClient from './base-service'

const endPointBaseURL = `/PosList`

const getAllPos = async (
  requestBody: ISearchRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IPosResponse[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IPosResponse[]>>>(
    `${endPointBaseURL}/get-pos-list`,
    requestBody
  )
const getPosById = async (
  requestBody: IPosIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPosResponse>>> =>
  httpClient.post<IApiSuccessResponse<IPosResponse>>(
    `${endPointBaseURL}/get-pos-detail-by-id`,
    requestBody
  )

const AddPos = async (
  requestBody: IPosRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPosResponse>>> =>
  httpClient.post<IApiSuccessResponse<IPosResponse>>(
    `${endPointBaseURL}/add-pos`,
    requestBody
  )
const updatePos = async (
  requestBody: IPosRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPosResponse>>> =>
  httpClient.put<IApiSuccessResponse<IPosResponse>>(
    `${endPointBaseURL}/update-pos`,
    requestBody
  )
const deletePos = async (
  requestBody: IPosIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/delete-pos`,
    requestBody
  )
export default {
  getAllPos,
  deletePos,
  updatePos,
  AddPos,
  getPosById,
}
