// ColorExtractor.js
import React, { useState, useRef } from 'react'
import { ColorExtractor } from 'react-color-extractor'

const ColorExtractorComponent = ({
  imageUrl,
  onDominantColorChange,
  onSecondaryColorChange,
}) => {
  const [dominantColor, setDominantColor] = useState(null)
  const [secondaryColor, setSecondaryColor] = useState(null)
  const imgRef = useRef(null)

  const sortColorsByFrequency = (colorPalette) => {
    const colorCounts = {}
    colorPalette.forEach((color) => {
      if (colorCounts[color]) {
        colorCounts[color] += 1
      } else {
        colorCounts[color] = 1
      }
    })

    const sortedColors = Object.keys(colorCounts).sort(
      (a, b) => colorCounts[b] - colorCounts[a]
    )

    return sortedColors.slice(0, 2) // Get the top two most frequent colors
  }

  const handleColors = (colorPalette) => {
    const sortedColors = sortColorsByFrequency(colorPalette)
    setDominantColor(sortedColors[0])
    setSecondaryColor(sortedColors[1])
    onDominantColorChange(sortedColors[0])
    onSecondaryColorChange(sortedColors[1])
  }

  return (
    <div>
      <img
        ref={imgRef}
        src={imageUrl}
        alt="Color Extractor"
        crossOrigin="anonymous"
        style={{ display: 'none' }}
      />
      <ColorExtractor getColors={(colors) => handleColors(colors)}>
        <img src={imageUrl} alt="Color Extractor" style={{ display: 'none' }} />
      </ColorExtractor>
      <div style={{ width: '300px', margin: '10px' }}>
        {dominantColor && (
          <div>
            <span>
              Select <span style={{ fontWeight: 900 }}>Scan To</span> Text
              Color:{' '}
            </span>
            <input
              type="color"
              value={dominantColor}
              id="dominantColor"
              name="dominantColor"
              onChange={(cc) => {
                const newColor = cc.target.value
                setDominantColor(newColor)
                onDominantColorChange(newColor)
              }}
            />
          </div>
        )}
        {secondaryColor && (
          <div>
            <span>
              Select <span style={{ fontWeight: 900 }}>Pay Tab</span> Text
              Color:{' '}
            </span>
            <input
              type="color"
              value={secondaryColor}
              id="secondaryColor"
              name="secondaryColor"
              onChange={(cc) => {
                const newColor = cc.target.value
                setSecondaryColor(newColor)
                onSecondaryColorChange(newColor)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ColorExtractorComponent
