import { Container, TextField, InputAdornment, IconButton } from '@mui/material'
import Button from 'components/Button/Index'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { PASSWORD_REGEX } from 'utility/constants'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ICreatePassword } from 'interfaces/user'
import authService from 'services/auth.service'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { MobileLogo } from 'assets/images'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function CreatePassword() {
  const searchParameters = new URLSearchParams(
    window.location.search.split('?')[1]
  )
  const token = searchParameters.get('token')
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .required('Password is required')
          .matches(
            PASSWORD_REGEX,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
          ),
        confirmNewPassword: yup
          .string()
          .required('Confirm Password is required')
          .oneOf([yup.ref('password')], 'Passwords do not match'),
      }),
    []
  )

  const handleSave = async (formdata: ICreatePassword) => {
    formdata.token = token
    const data = await authService.createPassword(formdata)
    if (data) {
      toast.success('Password Created successfully')
      navigate('/')
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      formik.resetForm()
    } else {
      toast.error('Something went wrong')
    }
    // eslint-disable-next-line no-unused-expressions
  }
  const formik = useFormik<ICreatePassword>({
    initialValues: {
      token: '',
      password: '',
      confirmNewPassword: '',
    },
    validationSchema,
    onSubmit: (values) => handleSave(values),
  })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfPassword = () => {
    setShowConfPassword(!showConfPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (!token) {
      navigate(-1)
    }
  }, [navigate, token])
  return (
    <>
      <Helmet>
        <title>Create Password | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="formm-wrapper">
        <Container maxWidth="sm">
          {/* create-password start */}
          <div className="card bordered create-password">
            <img src={MobileLogo} alt="FasTab" className="from-mobile-logo" />
            <div className="sm-card">
              <h4 className="form-h4">Create New Password</h4>
              {/* <h6>
                Your new password must be different from previous used
                passwords.
              </h6> */}
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <div className="form-group">
                    <TextField
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                      name="password"
                      value={formik.values?.password}
                      onChange={formik.handleChange}
                      error={
                        !!formik.errors.password && formik.touched.password
                      }
                      helperText={
                        !!formik.errors.password && formik.touched.password
                          ? formik.errors.password
                          : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      variant="outlined"
                      type={showConfPassword ? 'text' : 'password'}
                      label="Confirm password"
                      name="confirmNewPassword"
                      value={formik.values?.confirmNewPassword}
                      onChange={formik.handleChange}
                      error={
                        !!formik.errors.confirmNewPassword &&
                        formik.touched.confirmNewPassword
                      }
                      helperText={
                        !!formik.errors.confirmNewPassword &&
                        formik.touched.confirmNewPassword
                          ? formik.errors.confirmNewPassword
                          : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showConfPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <Button
                    color="primary"
                    variant="contained"
                    title="Create Password"
                    type="submit"
                  >
                    <span>Create Password</span>
                  </Button>
                </div>
              </form>
            </div>
          </div>
          {/* create-password end */}
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default CreatePassword
