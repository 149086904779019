import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import { useFormik } from 'formik'
import { resolveHTTP } from 'utility/helper'
import { IUserAddUpdateRequest, IUserListResponse } from 'interfaces/user'
import React, { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from 'utility/constants'
import * as yup from 'yup'
import userService from 'services/user.service'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  guestData?: IUserListResponse
}

function AddEditGuestDialog(props: dialogProps) {
  const { open, onClose, guestData } = props
  const STATUS = { INACTIVE: 2, ACTIVE: 3, DEFAULT: 0 }
  function onSubmit() {
    toast.success('Data saved successfully')
  }
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        loginName: yup
          .string()
          .required('Email is required')
          .matches(EMAIL_REGEX, 'Email is not valid'),
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        phoneNumber: yup
          .string()
          .required('Phone number is required')
          .matches(PHONE_NUMBER_REGEX, 'Phone number is not valid'),
      }),

    []
  )
  const handleSave = async (formdata: IUserAddUpdateRequest) => {
    const [data] = await resolveHTTP(userService.addUser(formdata))
    if (data) {
      toast.success('Updated added successfully')
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      formik.resetForm()
    }

    // eslint-disable-next-line no-unused-expressions
  }
  const formik = useFormik<IUserAddUpdateRequest>({
    initialValues: {
      loginId: 0,
      restaurantId: 0,
      firstName: '',
      lastName: '',
      isActive: true,
      loginName: '',
      isLockedOut: false,
      roleId: null,
      phoneNumber: '',
      establishmentId: [],
      loginTypeId: 2,
    },
    validationSchema,
    onSubmit: (values) => handleSave(values),
  })
  const setFieldValues = () => {
    if (guestData) {
      formik.setFieldValue('firstName', guestData.firstName)
      formik.setFieldValue('lastName', guestData.lastName)
      formik.setFieldValue('loginName', guestData.loginName)
      formik.setFieldValue('isActive', guestData.isActive)
      formik.setFieldValue('isLockedOut', guestData.isLockedOut)
    }
  }

  useEffect(() => {
    if (open) setFieldValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  return (
    <>
      <Dialog
        className="primary-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <h5 className="bold">New Guest</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        {/* dialog-content start */}
        <DialogContent className="pb-0">
          <div className="form-group">
            <Textfield
              variant="outlined"
              type="email"
              label="Email"
              name="loginName"
              value={formik.values?.loginName}
              onChange={formik.handleChange}
              error={!!formik.errors.loginName && formik.touched.loginName}
              helperText={
                !!formik.errors.loginName && formik.touched.loginName
                  ? formik.errors.loginName
                  : ''
              }
              handleBlur={formik.handleBlur}
            />
          </div>

          <div className="form-group">
            <Textfield
              variant="outlined"
              type="text"
              label="First Name"
              name="firstName"
              value={formik.values?.firstName}
              onChange={formik.handleChange}
              error={!!formik.errors.firstName && formik.touched.firstName}
              helperText={
                !!formik.errors.firstName && formik.touched.firstName
                  ? formik.errors.firstName
                  : ''
              }
              handleBlur={formik.handleBlur}
            />
          </div>
          <div className="form-group">
            <Textfield
              variant="outlined"
              type="text"
              label="Last Name"
              name="lastName"
              value={formik.values?.lastName}
              onChange={formik.handleChange}
              error={!!formik.errors.lastName && formik.touched.lastName}
              helperText={
                !!formik.errors.lastName && formik.touched.lastName
                  ? formik.errors.lastName
                  : ''
              }
              handleBlur={formik.handleBlur}
            />
          </div>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <div className="form-group">
                <Select
                  label="Status"
                  items={[
                    {
                      key: 'Active',
                      value: STATUS.ACTIVE,
                    },
                    {
                      key: 'Inactive',
                      value: STATUS.INACTIVE,
                    },
                  ]}
                  formikValue={formik.values.isActive === true ? 3 : 2}
                  handleSelectValue={(value) => {
                    formik.setFieldValue('isActive', value === STATUS.ACTIVE)
                  }}
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className="form-group">
                <Select
                  label="Account Locked"
                  items={[
                    {
                      key: 'Yes',
                      value: 1,
                    },
                    {
                      key: 'No',
                      value: 2,
                    },
                  ]}
                  formikValue={formik.values.isLockedOut ? 1 : 2}
                  handleSelectValue={(value) => {
                    formik.setFieldValue('isLockedOut', value === 1)
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        {/* dialog-content end */}
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Add"
              isTypeLink
              to="/users"
              onClick={(e) => {
                onSubmit()
                onClose(e)
              }}
            >
              Add
            </Button>
            <Button
              onClick={onClose}
              title="Cancel"
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddEditGuestDialog
