import { createSlice } from '@reduxjs/toolkit'
import { IMenuSearchResponse } from 'interfaces/menu'

const menuSlice = createSlice({
  name: 'menu',
  initialState: { menuList: [] },
  reducers: {
    setMenus: (state, action) => {
      const { menuList } = action.payload
      state.menuList = menuList
    },
    setMenuCategory: (state, action) => {
      const { menuCategory, menuId } = action.payload
      const index = state.menuList.findIndex((d) => d.menuId === menuId)
      state.menuList[index].menuCategories = menuCategory
      state.menuList = [...state.menuList]
    },
    setMenuItem: (state, action) => {
      const { menuItem, menuId } = action.payload

      const index = state.menuList.findIndex((d) => d.menuId === menuId)
      const currentMenuData = state.menuList[index]
      const categoryIndex = currentMenuData.menuCategories.findIndex(
        (d) => d.menuCategoryId === menuItem[0].menuCategoryId
      )
      state.menuList[index].menuCategories[categoryIndex].menuItems = menuItem
      state.menuList = [...state.menuList]
    },
    addMenu: (state, action) => {
      const { menu } = action.payload
      state.menuList = [...state.menuList, menu]
    },
    addMenuCategory: (state, action) => {
      const { categoryDetails, categoryIndex, subCategoryIndex, menuId } =
        action.payload
      const index = state.menuList.findIndex((d) => d.menuId === menuId)
      if (subCategoryIndex !== null && subCategoryIndex !== undefined) {
        const selectedCategory =
          state.menuList[index].menuCategories[categoryIndex].menuSubCategory
        const newCategory = [...selectedCategory, categoryDetails]
        state.menuList[index].menuCategories[categoryIndex].menuSubCategory =
          newCategory
        state.menuList = [...state.menuList]
      } else {
        const menuList = [...state.menuList]
        const menuListInd = state.menuList[0]
        console.log(menuList)
        console.log(menuListInd)
        const selectedCategory = state?.menuList[index]?.menuCategories
        let newCategory
        if (selectedCategory && selectedCategory.length > 0) {
          newCategory = [...selectedCategory, categoryDetails]
        } else {
          newCategory = [categoryDetails]
        }
        state.menuList[index].menuCategories = newCategory
        state.menuList = [...state.menuList]
      }
    },
    addMenuItem: (state, action) => {
      const { menuid, categoryItemDetails, categoryIndex, subCategoryIndex } =
        action.payload
      const index = state.menuList.findIndex((d) => d.menuId === menuid)
      let selectedCategoryItem
      if (
        subCategoryIndex !== null &&
        subCategoryIndex !== undefined &&
        subCategoryIndex !== -1
      ) {
        selectedCategoryItem =
          state.menuList[index].menuCategories[categoryIndex].menuSubCategory[
            subCategoryIndex
          ].menuItems
        let newCategoryItem
        if (selectedCategoryItem && selectedCategoryItem.length > 0) {
          newCategoryItem = [...selectedCategoryItem, categoryItemDetails]
        } else {
          newCategoryItem = [categoryItemDetails]
        }
        state.menuList[index].menuCategories[categoryIndex].menuSubCategory[
          subCategoryIndex
        ].menuItems = newCategoryItem
      } else {
        selectedCategoryItem =
          state.menuList[index]?.menuCategories[categoryIndex]?.menuItems
        let newCategoryItem
        if (selectedCategoryItem && selectedCategoryItem.length > 0) {
          newCategoryItem = [...selectedCategoryItem, categoryItemDetails]
        } else {
          newCategoryItem = [categoryItemDetails]
        }
        state.menuList[index].menuCategories[categoryIndex].menuItems =
          newCategoryItem
      }

      state.menuList = [...state.menuList]
    },
    updateMenu: (state, action) => {
      const { menu } = action.payload
      const index = state.menuList.findIndex((d) => d.menuId === menu.menuId)
      state.menuList[index] = menu
      state.menuList = [...state.menuList]
    },
    deleteMenu: (state, action) => {
      const { menuId } = action.payload
      const index = state.menuList.findIndex((d) => d.menuId === menuId)
      state.menuList.splice(index, 1)
      state.menuList = [...state.menuList]
    },
    deleteMenuCategory: (state, action) => {
      const { menuId, categoryIndex, subCategoryIndex } = action.payload
      console.log(categoryIndex)
      const index = state.menuList.findIndex((d) => d.menuId === menuId)
      if (
        subCategoryIndex !== null &&
        subCategoryIndex !== undefined &&
        subCategoryIndex !== -1
      ) {
        state.menuList[index]?.menuCategories[
          categoryIndex
        ].menuSubCategory?.splice(subCategoryIndex, 1)
        if (
          state.menuList[index].menuCategories[categoryIndex]
            .menuSubCategory === null
        ) {
          state.menuList[index].menuCategories[categoryIndex].menuSubCategory =
            []
        }
        state.menuList = [...state.menuList]
      } else {
        state.menuList[index]?.menuCategories?.splice(categoryIndex, 1)
        if (state.menuList[index].menuCategories === null) {
          state.menuList[index].menuCategories = []
        }
        state.menuList = [...state.menuList]
      }
    },
    deleteMenuItem: (state, action) => {
      const { menuId, categoryIndex, subCategoryIndex, itemIndex } =
        action.payload
      const index = state.menuList.findIndex((d) => d.menuId === menuId)
      if (
        subCategoryIndex !== null &&
        subCategoryIndex !== undefined &&
        subCategoryIndex !== -1
      ) {
        state.menuList[index].menuCategories[categoryIndex].menuSubCategory[
          subCategoryIndex
        ].menuItems.splice(itemIndex, 1)
        state.menuList = [...state.menuList]
      } else {
        state.menuList[index].menuCategories[categoryIndex].menuItems.splice(
          itemIndex,
          1
        )
        state.menuList = [...state.menuList]
      }
      // if (subCategoryIndex) {
      //   state.menuList[index]?.menuCategories[
      //     categoryIndex
      //   ].menuSubCategory?.splice(subCategoryIndex, 1)
      //   if (
      //     state.menuList[index].menuCategories[categoryIndex]
      //       .menuSubCategory === null
      //   ) {
      //     state.menuList[index].menuCategories[categoryIndex].menuSubCategory =
      //       []
      //   }
      //   state.menuList = [...state.menuList]
      // } else {
      //   state.menuList[index]?.menuCategories?.splice(categoryIndex, 1)
      //   if (state.menuList[index].menuCategories === null) {
      //     state.menuList[index].menuCategories = []
      //   }
      //   state.menuList = [...state.menuList]
      // }
    },
    updateMenuCategory: (state, action) => {
      const {
        menuId,
        categoryIndex,
        subCategoryIndex,
        isActive,
        categoryName,
        position,
      } = action.payload
      const index = state.menuList.findIndex((d) => d.menuId === menuId)
      if (
        subCategoryIndex !== null &&
        subCategoryIndex !== undefined &&
        subCategoryIndex !== -1
      ) {
        state.menuList[index].menuCategories[categoryIndex].menuSubCategory[
          subCategoryIndex
        ].isActive = isActive
        state.menuList[index].menuCategories[categoryIndex].menuSubCategory[
          subCategoryIndex
        ].name = categoryName
        state.menuList[index].menuCategories[categoryIndex].menuSubCategory[
          subCategoryIndex
        ].position = position
        state.menuList = [...state.menuList]
      } else {
        state.menuList[index].menuCategories[categoryIndex].isActive = isActive
        state.menuList[index].menuCategories[categoryIndex].name = categoryName
        state.menuList[index].menuCategories[categoryIndex].position = position
        state.menuList = [...state.menuList]
      }
    },
    updateMenuItem: (state, action) => {
      const {
        menuid,
        itemIndex,
        isActive,
        categoryIndex,
        subCategoryIndex,
        itemName,
        price,
        description,
      } = action.payload
      const index = state.menuList.findIndex((d) => d.menuId === menuid)
      if (
        subCategoryIndex !== null &&
        subCategoryIndex !== undefined &&
        subCategoryIndex !== -1
      ) {
        state.menuList[index].menuCategories[categoryIndex].menuSubCategory[
          subCategoryIndex
        ].menuItems[itemIndex].isActive = isActive
        state.menuList[index].menuCategories[categoryIndex].menuSubCategory[
          subCategoryIndex
        ].menuItems[itemIndex].name = itemName
        state.menuList[index].menuCategories[categoryIndex].menuSubCategory[
          subCategoryIndex
        ].menuItems[itemIndex].description = description
        state.menuList[index].menuCategories[categoryIndex].menuSubCategory[
          subCategoryIndex
        ].menuItems[itemIndex].price = price
        state.menuList = [...state.menuList]
      } else {
        state.menuList[index].menuCategories[categoryIndex].menuItems[
          itemIndex
        ].isActive = isActive
        state.menuList[index].menuCategories[categoryIndex].menuItems[
          itemIndex
        ].name = itemName
        state.menuList[index].menuCategories[categoryIndex].menuItems[
          itemIndex
        ].description = description
        state.menuList[index].menuCategories[categoryIndex].menuItems[
          itemIndex
        ].price = price
        state.menuList = [...state.menuList]
      }
    },
  },
})

export const {
  setMenus,
  addMenu,
  addMenuCategory,
  addMenuItem,
  updateMenu,
  deleteMenu,
  updateMenuCategory,
  updateMenuItem,
  deleteMenuCategory,
  deleteMenuItem,
  setMenuCategory,
  setMenuItem,
} = menuSlice.actions

export default menuSlice.reducer

export const menuList = (state: {
  menu: { menuList: IMenuSearchResponse[] }
}) => state.menu.menuList
