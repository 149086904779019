import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IManualRefundRequestDTO,
  IMonthlyStatementRequestDTO,
  IMultiPaymentsSearchRequest,
  IPaymentDetailRequest,
  IPaymentDetailResponse,
  IPaymentsSearchRequest,
  IPaymentsSearchResponse,
} from 'interfaces/payments'
import { ISearchResponse } from 'interfaces/qrcode'
import { ICsvDownload } from 'interfaces/restaurant'
import { IPaymentCsvRequest } from 'interfaces/tab'
import httpClient from './base-service'

const endPointBaseURL = `/Payment`

const getAllPayments = async (
  requestBody: IPaymentsSearchRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IPaymentsSearchResponse[]>>>
> =>
  httpClient.post<
    IApiSuccessResponse<ISearchResponse<IPaymentsSearchResponse[]>>
  >(`${endPointBaseURL}/get-payments-list`, requestBody)

const downloadCsv = async (
  requestBody: IPaymentCsvRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICsvDownload>>> =>
  httpClient.post<IApiSuccessResponse<ICsvDownload>>(
    `${endPointBaseURL}/create-csv-file-for-payment`,
    requestBody
  )
const getMultiPaymentsList = async (
  requestBody: IMultiPaymentsSearchRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IPaymentsSearchResponse[]>>>
> =>
  httpClient.post<
    IApiSuccessResponse<ISearchResponse<IPaymentsSearchResponse[]>>
  >(`${endPointBaseURL}/get-multi-check-payments-list`, requestBody)

const getPaymentDetail = async (
  requestBody: IPaymentDetailRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPaymentDetailResponse>>> =>
  httpClient.post<IApiSuccessResponse<IPaymentDetailResponse>>(
    `${endPointBaseURL}/get-payments-action-details`,
    requestBody
  )

const getPaymentDetailByOrder = async (
  requestBody: IPaymentDetailRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPaymentDetailResponse>>> =>
  httpClient.post<IApiSuccessResponse<IPaymentDetailResponse>>(
    `${endPointBaseURL}/get-multi-check-payments-action-details`,
    requestBody
  )

const getMonthlyReportDetails = async (
  requestBody: IMonthlyStatementRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/get-monthly-report`,
    requestBody
  )

const addManualRefund = async (
  requestBody: IManualRefundRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-manual-refund`,
    requestBody
  )

export default {
  getAllPayments,
  downloadCsv,
  getMultiPaymentsList,
  getPaymentDetail,
  getPaymentDetailByOrder,
  getMonthlyReportDetails,
  addManualRefund,
}
