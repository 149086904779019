/* eslint-disable no-nested-ternary */
import { Collapse, Grid } from '@mui/material'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro'
import Aos from 'aos'
import { ExportNew, FilterNew } from 'assets/images'
import AddEditGuestDialog from 'components/AddEditGuestDialog/Index'
import Button from 'components/Button/Index'
import DataTable from 'components/DataTable/Index'
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/Index'
import Layout from 'components/Layout/Layout'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import { DialogOptions } from 'enums/Common'
import { selectedEstablishment } from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IDatagrid } from 'interfaces/datagrid'
import { IGuestListRequest, IGuestListResponse } from 'interfaces/guest'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import guestService from 'services/guest.service'
import twoFactor from 'services/two.factor'
import userService from 'services/user.service'
import GuestRestaurant from './GuestRestaurant'
import GuestReview from './GuestReview'

Aos.init({
  duration: 600,
  once: true,
})

function Guests() {
  const FILTER_STATUS = { REGISTERED: 3, GUEST: 2, DEFAULT: 0 }
  const [filterToggle, setFilterToggle] = useState(false)
  const [guestList, setGuestList] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [filterEmail, setFilterEmail] = useState('')
  const [filterName, setFilteName] = useState('')
  const [userFilterSelectedStatus, setuserFilterSelectedStatus] = useState(
    FILTER_STATUS.DEFAULT
  )
  // const [guestData, setGuestData] = useState<IUserListResponse>()
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [loginIdForDelete, setLoginIdForDelete] = useState(0)
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'firstname',
        sort: 'asc',
      },
    ],
  })
  // Add/Edit User Dialog
  const [openAddEditGuestDialog, setOpenAddEditGuestDialog] = useState(false)
  const [guestId, setGuestId] = useState(0)
  const [restaurantBox, setRestaurantBox] = useState(false)
  const [reviewlistBox, setReviewListBox] = useState(false)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const closeAddEditGuestDialog = () => {
    setOpenAddEditGuestDialog(false)
  }
  const filterEmailChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFilterEmail(e.target.value)
  }
  const getGuestList = useCallback(
    async (
      pageNo: number,
      pageSize: number,
      sortOrder: GridSortModel,
      selectedStatus?: number,
      email?: string
    ) => {
      const response = await guestService.getGuestList({
        pageNo,
        pageSize,
        sortBy: 'string',
        sortGridModels: sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
        isRegistered:
          selectedStatus === 0
            ? null
            : selectedStatus !== FILTER_STATUS.REGISTERED,
        loginName: email,
        guestName: filterName,
      })
      const guestListWithID: IGuestListResponse[] = response.data.data.data.map(
        (user, index) => {
          return { id: index + 1, ...user }
        }
      )
      setGuestList(guestListWithID)
      if (totalRecords === 0) {
        setTotalRecords(response.data.data.totalRecords)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const toggleDeleteConfirmationDialog = useCallback(
    async (isOpen: boolean, isSubmit = true, id: number) => {
      setOpenDeleteConfirmation(isOpen)
      setLoginIdForDelete(id)

      if (isSubmit && !isOpen && id > 0) {
        try {
          await userService.deleteUser({
            loginId: id,
          })
          toast.success('Guest deleted successfully.')
        } catch (error) {
          toast.error('Something wrong with deleting the records.')
        }
      }
      getGuestList(
        dataGridOptions.pageNumber,
        dataGridOptions.pageSize,
        dataGridOptions.sortOrder,
        FILTER_STATUS.DEFAULT,
        ''
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const generatePayload = () => {
    const payload: IGuestListRequest = {
      pageNo: dataGridOptions.pageNumber,
      pageSize: dataGridOptions.pageSize,
      sortGridModels: dataGridOptions.sortOrder.map((d) => {
        return {
          field: d.field,
          sort: d.sort === 'asc' ? 1 : 0,
        }
      }),
      guestName: filterName,
    }
    payload.loginName = filterEmail
    if (userFilterSelectedStatus !== FILTER_STATUS.DEFAULT) {
      payload.isRegistered =
        userFilterSelectedStatus !== FILTER_STATUS.REGISTERED
    }
    payload.guestName = filterName
    return payload
  }
  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  const onFilterApply = async () => {
    const payload = generatePayload()
    const data = await guestService.getGuestList(payload)
    setGuestList(data.data.data.data)
    setTotalRecords(data.data.data.totalRecords)
  }

  const onFilterClear = () => {
    setuserFilterSelectedStatus(FILTER_STATUS.DEFAULT)
    setFilterEmail('')
    getGuestList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      FILTER_STATUS.DEFAULT,
      ''
    )
  }
  const openReviewDetails = (value: number) => {
    setGuestId(value)
    setReviewListBox(true)
  }
  const openRestaurantDetails = (value: number) => {
    setGuestId(value)
    setRestaurantBox(true)
  }
  const closeRestaurantBox = () => {
    setRestaurantBox(false)
  }
  const closeReviewBox = () => {
    setReviewListBox(false)
  }
  useEffect(() => {
    getGuestList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      userFilterSelectedStatus,
      filterEmail
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exportCSVFile = (
    headers: {
      Name: string // remove commas to avoid errors
      EmailAddress: string
      WebsiteLink: string
      CreatedDate: string
    },
    csv:
      | string
      | Blob
      | import('../../interfaces/restaurant').ICsvDownload
      | ArrayBufferView
      | ArrayBuffer,
    fileTitle: string
  ) => {
    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

    const blob = new Blob([csv as BlobPart], {
      type: 'text/csv;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const downloadFile = async () => {
    const data = await guestService.downloadGuestCSV()
    console.log(selectedRestaurantId)
    console.log(selectedEstablishmentId)
    const headers = {
      Name: 'Name'.replace(/,/g, ''), // remove commas to avoid errors
      EmailAddress: 'Email'.replace(/,/g, ''),
      WebsiteLink: 'Website'.replace(/,/g, ''),
      CreatedDate: 'Date'.replace(/,/g, ''),
    }
    const fileTitle = 'Guests'
    exportCSVFile(headers, data.data.data, fileTitle)
  }

  const sendEmail = async (mail) => {
    await twoFactor.verifyEmail(mail)
  }

  return (
    <>
      <Layout title="Guests | FasTab">
        {/* main-content start */}
        <section className="main-content">
          {/* page-top start */}
          <div className="page-top">
            <h3 className="page-title">Guests</h3>
            <div className="right">
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                size="small"
                title="Export"
                className="btn-h-40"
                onClick={() => downloadFile()}
              >
                <img src={ExportNew} alt="File" />
                <span>Export</span>
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                size="small"
                title="Filter"
                className="btn-h-40"
                onClick={() => {
                  setFilterToggle(!filterToggle)
                }}
              >
                <img src={FilterNew} alt="Filter" />
                <span>Filter</span>
              </Button>
            </div>
          </div>
          {/* page-top end */}
          {/* filter-panel start */}
          <Collapse in={filterToggle}>
            <div className="filter-panel">
              <div className="card">
                <Grid container spacing={5}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        label="Email"
                        type="email"
                        variant="outlined"
                        value={filterEmail}
                        onChange={(e) => filterEmailChange(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        label="Guest Name"
                        type="text"
                        variant="outlined"
                        value={filterName}
                        onChange={(e) => setFilteName(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Type"
                        items={[
                          {
                            key: 'Guest',
                            value: FILTER_STATUS.REGISTERED,
                          },
                          {
                            key: 'Registered',
                            value: FILTER_STATUS.GUEST,
                          },
                          {
                            key: 'All',
                            value: FILTER_STATUS.DEFAULT,
                          },
                        ]}
                        formikValue={userFilterSelectedStatus}
                        handleSelectValue={(value) => {
                          setuserFilterSelectedStatus(value)
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="btn-list">
                  <Button
                    variant="contained"
                    color="primary"
                    title="Apply"
                    onClick={onFilterApply}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    title="Clear All"
                    onClick={onFilterClear}
                  >
                    Clear All
                  </Button>
                </div>
              </div>
            </div>
          </Collapse>
          {/* filter-panel end */}

          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="guestId"
              columnsData={[
                {
                  field: 'loginName',
                  headerName: 'Email',
                  flex: 1,
                  minWidth: 250,
                },
                {
                  field: 'firstName',
                  headerName: 'First Name',
                  flex: 1,
                  minWidth: 160,
                },
                {
                  field: 'lastName',
                  headerName: 'Last Name',
                  flex: 1,
                  minWidth: 160,
                },
                {
                  field: 'totalTabs',
                  headerName: 'Total Tabs',
                  flex: 1,
                  minWidth: 110,
                  cellClassName: 'text-justify-center',
                  renderCell: (params: GridRenderCellParams<number>) => {
                    if (params.value === 0) {
                      return <span>0</span>
                    }
                    return (
                      <Link
                        to="/tabs"
                        onClick={() =>
                          localStorage.setItem(
                            'fromGuestGuestId',
                            params.row.guestId
                          )
                        }
                        style={{
                          color: '#008cff',
                          display: 'block',
                          textAlign: 'center',
                        }}
                        target="_blank"
                      >
                        {params.value}
                      </Link>
                    )
                  },
                },
                {
                  field: 'totalPayment',
                  headerName: 'Dollars Spent',
                  flex: 1,
                  minWidth: 160,
                  cellClassName: 'text-justify-center',
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return `$${(
                      Math.round(Number(params.value) * 100) / 100
                    ).toFixed(2)}`
                  },
                },
                {
                  field: 'averageGuestSpend',
                  headerName: 'Average Guest Spend',
                  flex: 1,
                  minWidth: 160,
                  cellClassName: 'text-justify-center',
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return `$${(
                      Math.round(Number(params.value) * 100) / 100
                    ).toFixed(2)}`
                  },
                },
                {
                  field: 'totalReview',
                  headerName: 'Reviews',
                  flex: 1,
                  minWidth: 110,
                  cellClassName: 'text-justify-center',
                  renderCell: (d: GridRenderCellParams<number>) => {
                    if (d.value === 0) {
                      return <span>0</span>
                    }
                    return (
                      <Button
                        className="icon-btn no-border rounded"
                        variant="text"
                        color="primary"
                        onClick={() => {
                          openReviewDetails(d.row.guestId)
                        }}
                      >
                        {d.value}
                      </Button>
                    )
                  },
                },
                {
                  field: 'totalRestaurant',
                  headerName: 'Restaurants Visited',
                  flex: 1,
                  minWidth: 160,
                  cellClassName: 'text-justify-center',
                  renderCell: (d: GridRenderCellParams<number>) => {
                    if (d.value === 0) {
                      return <span>0</span>
                    }
                    return (
                      <Button
                        className="icon-btn no-border rounded"
                        variant="text"
                        color="primary"
                        onClick={() => {
                          openRestaurantDetails(d.row.guestId)
                        }}
                      >
                        {d.value}
                      </Button>
                    )
                  },
                },
                {
                  field: 'isRegistered',
                  align: 'center',
                  headerAlign: 'center',
                  headerName: 'Type',
                  renderCell: (params: GridRenderCellParams<boolean>) => {
                    const success = params.value ? 'text-green' : ''
                    const secondary = !params.value ? 'text-blue' : ''
                    return (
                      <span
                        className={` 
                    ${success}
                    ${secondary}
                    `}
                      >
                        {params.value === false ? 'Guest' : 'Registered Guest'}
                      </span>
                    )
                  },
                  flex: 1,
                  minWidth: 120,
                },
                {
                  field: 'isVerified',
                  align: 'center',
                  headerAlign: 'center',
                  headerName: 'Status',
                  renderCell: (params: GridRenderCellParams<boolean>) => {
                    const success = params.value ? 'text-green' : ''
                    const secondary = !params.value ? 'text-blue' : ''
                    return (
                      <span
                        className={` 
                    ${success}
                    ${secondary}
                    `}
                      >
                        {params.row.isRegistered ? (
                          params.value === false ? (
                            <Button
                              variant="outlined"
                              color="inherit"
                              disableFocusRipple
                              size="small"
                              title="Verify"
                              className="btn-h-40"
                              onClick={() => sendEmail(params.row.loginName)}
                            >
                              <span>Verify</span>
                            </Button>
                          ) : (
                            'Verified'
                          )
                        ) : (
                          'N/A'
                        )}
                      </span>
                    )
                  },
                  flex: 1,
                  minWidth: 120,
                },
              ]}
              rowsData={guestList}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortChange}
              totalRecords={totalRecords}
            />
          </div>
          {/* data-table end */}
        </section>
        <></>
        {/* main-content end */}
      </Layout>
      {/* wrapper end */}
      {/* add-edit-user-dialog */}
      <AddEditGuestDialog
        open={openAddEditGuestDialog}
        onClose={closeAddEditGuestDialog}
        // guestData={guestData}
      />
      <DeleteConfirmationDialog
        open={openDeleteConfirmation}
        idFieldValue={loginIdForDelete}
        message="Are you sure you want to delete this Guest?"
        callBack={(reason: DialogOptions, idFieldValue: number) => {
          toggleDeleteConfirmationDialog(
            false,
            reason === DialogOptions.Yes,
            idFieldValue
          )
        }}
      />
      <GuestRestaurant
        open={restaurantBox}
        onClose={closeRestaurantBox}
        guestId={guestId}
      />
      <GuestReview
        open={reviewlistBox}
        onClose={closeReviewBox}
        guestId={guestId}
      />
    </>
  )
}

export default Guests
