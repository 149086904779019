import { createSlice } from '@reduxjs/toolkit'

const restaurantSlice = createSlice({
  name: 'user',
  initialState: { userName: 'User' },
  reducers: {
    setUserName: (state, action) => {
      const { userName } = action.payload
      state.userName = userName
    },
  },
})

export const { setUserName } = restaurantSlice.actions
export const username = (state: { user: { userName: string } }) =>
  state.user.userName
export default restaurantSlice.reducer
