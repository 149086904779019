import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import Aos from 'aos'
import { BackArrow } from 'assets/images'
import Button from 'components/Button/Index'
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/Index'
import Layout from 'components/Layout/Layout'
import Textfield from 'components/Textfield/Index'
import { DialogOptions } from 'enums/Common'
import { useFormik } from 'formik'
import { IPosRequest, IPosState } from 'interfaces/pos'
import { IPos } from 'interfaces/restaurant'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// import { Helmet } from 'react-helmet'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import posService from 'services/pos.service'
import restaurantService from 'services/restaurant.service'
import { resolveHTTP } from 'utility/helper'
import * as yup from 'yup'

Aos.init({
  duration: 600,
  once: true,
})

function AddEditPos() {
  const location = useLocation()
  const [currentID] = useState(
    location.state ? (location.state as IPosState).posId : null
  )
  const navigate = useNavigate()
  const [isEditMode] = useState(Number.isInteger(currentID))
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [posIdForDelete, setPosIdForDelete] = useState(0)
  const [posList, setPosList] = useState<IPos[]>([])

  const handleSave = async (formData: IPosRequest) => {
    if (isEditMode) {
      formData.posId = Number(currentID)
      const [data] = await resolveHTTP(posService.updatePos(formData))
      if (data) {
        toast.success('Pos updated successfully')
        navigate('/pos')
      }
    } else {
      const [data] = await resolveHTTP(posService.AddPos(formData))
      if (data) {
        toast.success('Pos added successfully')
        navigate('/pos')
      }
    }
  }

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        posName: yup.string().required('Pos Name is required'),
      }),
    []
  )

  const formik = useFormik<IPosRequest>({
    initialValues: {
      posName: '',
      posId: 0,
      splitByGuest: false,
      splitBySeat: false,
      splitByItem: false,
      splitByPayInFull: false,
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => handleSave(values),
  })
  const toggleDeleteConfirmationDialog = useCallback(
    async (isOpen: boolean, isSubmit = false, id: number) => {
      setOpenDeleteConfirmation(isOpen)
      setPosIdForDelete(id)
      if (isSubmit && !isOpen && id > 0) {
        const [result] = await resolveHTTP(
          posService.deletePos({
            posId: Number(currentID),
          })
        )
        if (result) {
          toast.success('Pos deleted successfully')
          navigate('/pos')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getPosData = async () => {
    if (posList && posList.length === 0) {
      const data = await restaurantService.getPosList()
      setPosList(data.data.data)
    }
  }
  const getPosDetail = useCallback(
    async (posId: number) => {
      try {
        const data = await posService.getPosById({
          posId,
        })
        const posDetailData = data.data.data
        formik.setFieldValue('posName', posDetailData.posName)
        formik.setFieldValue('splitByGuest', posDetailData.splitByGuest)
        formik.setFieldValue('splitByItem', posDetailData.splitByItem)
        formik.setFieldValue('splitByPayInFull', posDetailData.splitByPayInFull)
        formik.setFieldValue('splitBySeat', posDetailData.splitBySeat)
      } catch (e) {
        console.log(e)
      }
    },
    [formik]
  )

  useEffect(() => {
    if (isEditMode) {
      getPosDetail(Number(currentID))
    }
    getPosData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Layout title={isEditMode ? 'Edit  Pos | FasTab' : 'New Pos | FasTab'}>
        {/* page-top start */}
        <div className="page-top">
          <h3 className="page-title">{isEditMode ? 'Edit' : 'New'} Pos</h3>
          <div className="right">
            <Button
              variant="contained"
              color="primary"
              title="Back"
              isTypeLink
              to="/pos"
              size="small"
            >
              <img src={BackArrow} alt="Back" />
              <span>Back</span>
            </Button>
          </div>
        </div>
        {/* page-top end */}
        <div className="card mb-30" data-aos="fade-up">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={6}>
              <Grid item lg={8} xs={12}>
                <Grid container spacing={6}>
                  <Grid item sm={12} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        name="posName"
                        onChange={formik.handleChange}
                        value={formik.values?.posName}
                        error={
                          !!formik.errors.posName && formik.touched.posName
                        }
                        helperText={
                          !!formik.errors.posName && formik.touched.posName
                            ? formik.errors.posName
                            : ''
                        }
                        label="Pos Name"
                        handleBlur={formik.handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <div className="form-group">
                      <FormControlLabel
                        name="splitByItem"
                        control={
                          <Checkbox
                            color="primary"
                            checked={formik.values.splitByItem}
                            onChange={formik.handleChange}
                          />
                        }
                        label={
                          <span style={{ color: '#737373' }}>
                            Split By Item
                          </span>
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <div className="form-group">
                      <FormControlLabel
                        name="splitByGuest"
                        control={
                          <Checkbox
                            color="primary"
                            checked={formik.values.splitByGuest}
                            onChange={formik.handleChange}
                          />
                        }
                        label={
                          <span style={{ color: '#737373' }}>
                            Split By Guest
                          </span>
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <div className="form-group">
                      <FormControlLabel
                        name="splitBySeat"
                        control={
                          <Checkbox
                            color="primary"
                            checked={formik.values.splitBySeat}
                            onChange={formik.handleChange}
                          />
                        }
                        label={
                          <span style={{ color: '#737373' }}>
                            Split By Seat
                          </span>
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <div className="form-group">
                      <FormControlLabel
                        name="splitByPayInFull"
                        control={
                          <Checkbox
                            color="primary"
                            checked={formik.values.splitByPayInFull}
                            onChange={formik.handleChange}
                          />
                        }
                        label={
                          <span style={{ color: '#737373' }}>Pay In Full</span>
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className="btn-list">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                title="Save"
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="primary"
                isTypeLink
                to="/pos"
                title="Cancel"
              >
                Cancel
              </Button>
              {isEditMode && (
                <Button
                  variant="outlined"
                  color="primary"
                  isTypeLink
                  onClick={() =>
                    toggleDeleteConfirmationDialog(
                      true,
                      false,
                      Number(currentID)
                    )
                  }
                  title="Delete"
                >
                  Delete
                </Button>
              )}
            </div>
          </form>
        </div>
      </Layout>
      <DeleteConfirmationDialog
        open={openDeleteConfirmation}
        idFieldValue={posIdForDelete}
        message="Are you sure you want to delete this Pos?"
        callBack={(reason: DialogOptions, idFieldValue: number) => {
          toggleDeleteConfirmationDialog(
            false,
            reason === DialogOptions.Yes,
            idFieldValue
          )
        }}
      />
      {/* wrapper end */}
    </>
  )
}

export default AddEditPos
