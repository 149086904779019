/* eslint-disable react/jsx-no-bind */

import React, { useCallback, useEffect, useState } from 'react'
import { CircularProgress, Grid, Link, Rating } from '@mui/material'
import reportingService from 'services/reporting.service'
import { Revenue, Review, StarRating, Table } from 'assets/images'
import Datepicker from 'components/Datepicker/Index'
import Select from 'components/Select/Index'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import {
  selectedEstablishment,
  establishmentList,
} from 'features/establishment/establishment'
import { useSelector } from 'react-redux'
import {
  ILMTodaysReviewsResponse,
  ILMServerActionResponse,
  ILMLastHourEarningsResponse,
  ILMTodayFifteenMinsResponse,
  ILMAllTimeResponse,
  ILMTodaysEarningResponse,
} from 'interfaces/reporting'
import moment from 'moment'
import { sub } from 'date-fns'
import BarGraph from './BarGraph'
import DonutChart from './DonutPieChart'
import LiveLineChart from './LiveLineChart'
import formatCurrency from './formatters'

function LiveMetricsDashboard() {
  const [todaysEarning, setTodaysEarning] = useState<ILMTodaysEarningResponse>()
  const [lastHourEarnings, setLastHourEarnings] =
    useState<ILMLastHourEarningsResponse[]>()
  const [todaysReview, setTodaysReview] = useState<ILMTodaysReviewsResponse>()
  const [serverActions, setServerActions] =
    useState<ILMServerActionResponse[]>()
  const [serverMinutesFilter, setServerMinutesFilter] = useState(15)
  const [activeTables, setActiveTables] = useState<ILMServerActionResponse[]>()
  const [todayFifteenMins, setTodayFifteenMins] =
    useState<ILMTodayFifteenMinsResponse[]>()
  const [allTimeEarnings, setAllTimeEarnings] = useState<ILMAllTimeResponse[]>()
  const [tableMinutesFilter, setTableMinutesFilter] = useState(15)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const allEstablishmentList = useSelector(establishmentList)
  const [selectedDate, setSelectedDate] = useState(sub(new Date(), { days: 1 }))
  const [todaysEarningLoader, setTodaysEarningLoader] = useState(false)
  const [lastHourEarningsLoader, setLastHourEarningsLoader] = useState(false)
  const [todaysReviewLoader, setTodaysReviewLoader] = useState(false)
  const [serverActionsLoader, setServerActionsLoader] = useState(false)
  const [activeTablesLoader, setActiveTablesLoader] = useState(false)
  const [todayFifteenMinsLoader, setTodayFifteenMinsLoader] = useState(false)
  const [allTimeEarningsLoader, setAllTimeEarningsLoader] = useState(false)

  const intervalTime = parseInt(
    process.env.REACT_APP_LIVE_METRICS_AUTO_LOAD_INTERVAL,
    10
  )
  const [isAutomaticReload, setIsAutomaticReload] = useState(false)

  const getTodaysEarning = async (id, estId, isReload) => {
    try {
      setTodaysEarningLoader(true)
      const data = await reportingService.getLMTodaysEarning(
        {
          restaurantId: id,
          establishmentId:
            estId > 0
              ? [estId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
        },
        isReload
      )
      setTodaysEarning(data.data.data)
      setTodaysEarningLoader(false)
    } catch (e) {
      console.log(e)
    }
  }

  const getLastHourEarnings = async (id, estId, isReload) => {
    try {
      setLastHourEarningsLoader(true)
      const data = await reportingService.getLMLastHourEarnings(
        {
          restaurantId: id,
          establishmentId:
            estId > 0
              ? [estId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
        },
        isReload
      )
      setLastHourEarnings(data.data.data)
      setLastHourEarningsLoader(false)
    } catch (e) {
      console.log(e)
    }
  }

  const getTodaysReviews = async (id, estId, isReload) => {
    try {
      setTodaysReviewLoader(true)
      const data = await reportingService.getLMTodaysReviews(
        {
          restaurantId: id,
          establishmentId:
            estId > 0
              ? [estId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
        },
        isReload
      )
      setTodaysReview(data.data.data)
      setTodaysReviewLoader(false)
    } catch (e) {
      console.log(e)
    }
  }

  const getServerActions = async (id, estId, mins, isReload) => {
    try {
      setServerActionsLoader(true)
      const data = await reportingService.getLMServerActions(
        {
          restaurantId: id,
          establishmentId:
            estId > 0
              ? [estId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          minutes: mins,
        },
        isReload
      )
      setServerActions(data.data.data)
      setServerActionsLoader(false)
    } catch (e) {
      console.log(e)
    }
  }

  const getActiveTables = async (id, estId, mins, isReload) => {
    try {
      setActiveTablesLoader(true)
      const data = await reportingService.getLMServerActions(
        {
          restaurantId: id,
          establishmentId:
            estId > 0
              ? [estId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          minutes: mins,
        },
        isReload
      )
      const uniqueIds = new Set()
      const uniqueData = data.data.data?.filter((item) => {
        if (!uniqueIds.has(item.tableId)) {
          uniqueIds.add(item.tableId)
          return true
        }
        return false
      })
      setActiveTables(uniqueData.filter((i) => i.isClosedAction === 0))
      setActiveTablesLoader(false)
    } catch (e) {
      console.log(e)
    }
  }

  const getTodayFifteenMinsEarnings = useCallback(
    async (id, estId, isReload) => {
      try {
        setTodayFifteenMinsLoader(true)
        const data = await reportingService.getLMTodayFifteenMinsEarnings(
          {
            restaurantId: id,
            establishmentId:
              estId > 0
                ? [estId]
                : allEstablishmentList
                    .filter((x) => x.restaurantId === selectedRestaurantId)
                    .map((x) => x.establishmentId),
          },
          isReload
        )
        setTodayFifteenMins(data.data.data)
        setTodayFifteenMinsLoader(false)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getAllTimeEarnings = useCallback(async (id, estId, date, isReload) => {
    try {
      setAllTimeEarningsLoader(true)
      const data = await reportingService.getLMAllTimeEarnings(
        {
          restaurantId: id,
          establishmentId:
            estId > 0
              ? [estId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          fasTabDateLocal: moment(date).format('YYYY-MM-DD'),
        },
        isReload
      )
      setAllTimeEarnings(data.data.data)
      setAllTimeEarningsLoader(false)
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await getTodayFifteenMinsEarnings(
        selectedRestaurantId,
        selectedEstablishmentId,
        isAutomaticReload
      )
    }
    fetchData()

    const interval = setInterval(fetchData, intervalTime)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, selectedEstablishmentId, intervalTime])

  useEffect(() => {
    const fetchData = async () => {
      await getAllTimeEarnings(
        selectedRestaurantId,
        selectedEstablishmentId,
        selectedDate,
        isAutomaticReload
      )
    }
    fetchData()

    const interval = setInterval(fetchData, intervalTime)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRestaurantId,
    selectedEstablishmentId,
    selectedDate,
    intervalTime,
  ])

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        getTodaysEarning(
          selectedRestaurantId,
          selectedEstablishmentId,
          isAutomaticReload
        ),
        getLastHourEarnings(
          selectedRestaurantId,
          selectedEstablishmentId,
          isAutomaticReload
        ),
        getTodaysReviews(
          selectedRestaurantId,
          selectedEstablishmentId,
          isAutomaticReload
        ),
      ])
    }

    fetchData()

    const interval = setInterval(fetchData, intervalTime)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, selectedEstablishmentId, intervalTime])

  useEffect(() => {
    const fetchData = async () => {
      await getServerActions(
        selectedRestaurantId,
        selectedEstablishmentId,
        serverMinutesFilter,
        isAutomaticReload
      )
    }
    fetchData()

    const interval = setInterval(fetchData, intervalTime)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRestaurantId,
    selectedEstablishmentId,
    serverMinutesFilter,
    intervalTime,
  ])

  useEffect(() => {
    const fetchData = async () => {
      await getActiveTables(
        selectedRestaurantId,
        selectedEstablishmentId,
        tableMinutesFilter,
        isAutomaticReload
      )
    }
    fetchData()
    setIsAutomaticReload(true)
    const interval = setInterval(fetchData, intervalTime)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRestaurantId,
    selectedEstablishmentId,
    tableMinutesFilter,
    intervalTime,
  ])

  const handleServerSelectChange = (event) => {
    setServerMinutesFilter(event)
  }

  const handleTableSelectChange = (event) => {
    setTableMinutesFilter(event)
  }
  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    )
    return newDate
  }
  const handleDateChange = (date) => {
    setSelectedDate(convertUTCDateToLocalDate(date))
  }
  const disableFutureDates = sub(new Date(), { days: 1 })

  const serverActionList = [
    { key: 'Last 15 Minute', value: 15 },

    { key: 'Last 30 Minute', value: 30 },

    { key: 'Last 45 Minute', value: 45 },

    { key: 'Last 1 Hour', value: 60 },

    { key: 'Last 2 Hours', value: 120 },

    { key: 'Last 5 Hours', value: 300 },
  ]

  const setLocalStorageParams = (tabIdforDetailRequest?) => {
    localStorage.setItem('fromDashboard', 'true')
    localStorage.setItem('tabIdforDetailRequest', tabIdforDetailRequest)
    localStorage.setItem(
      'restaurantIdForDetailRequest',
      selectedRestaurantId.toString()
    )
  }

  function hourlyDataSet(dataArray, property1, property2, dates) {
    if (!dataArray || !Array.isArray(dataArray) || dataArray.length === 0) {
      dataArray = [] // Return an empty array if dataset is undefined or empty
    }
    const start = moment(dates)
      .hour(5)
      .startOf('hour')
      .format('YYYY-MM-DD HH:mm')
    const end = moment(start)
      .add(1, 'day')
      .startOf('day')
      .hour(4)
      .minute(45)
      .format('YYYY-MM-DD HH:mm')

    const dataByHour = dataArray?.reduce((acc, item) => {
      const date = new Date(item[property1])
      const formattedDate = moment(date).format('hh:mm A')

      if (!acc[formattedDate]) {
        acc[formattedDate] = 0
      }

      acc[formattedDate] += item[property2]
      return acc
    }, {})

    const sDate = moment(start, 'YYYY-MM-DD HH:mm')
    const eDate = moment(end, 'YYYY-MM-DD HH:mm')

    const filledData = []
    const currentDate = sDate?.clone()

    while (currentDate?.isSameOrBefore(eDate, 'minute')) {
      const formattedDate = currentDate?.format('hh:mm A')
      filledData.push({
        x: formattedDate,
        y: dataByHour[formattedDate]?.toFixed(1) || 0,
      })
      currentDate?.add(15, 'minutes')
    }

    return filledData
  }

  return (
    <Grid container spacing={5}>
      <Grid item xl={4} lg={6} xs={12}>
        {/* total-earning start */}

        <div className="card total-earning">
          <div className="oval">
            <div className="oval-bg">
              <img src={Revenue} alt="Revenue" />
            </div>
            <div className="fade one" />
            <div className="fade two" />
            <div className="fade three" />
          </div>

          <div className="details">
            <span>Total Earnings</span>
            {todaysEarningLoader ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '10px',
                  paddingBottom: '30px',
                  height: 'inherit',
                }}
              >
                <CircularProgress size={24} thickness={4} />
              </div>
            ) : (
              <>
                <p>{formatCurrency(todaysEarning?.totalEarningsSum) || 0}</p>
                <span style={{ fontSize: '0.95em' }}>
                  {todaysEarning?.totalTabs || 0} Tabs
                </span>
              </>
            )}
          </div>
        </div>

        {/* total-earning end */}
      </Grid>

      <Grid item xl={4} lg={6} xs={12}>
        {/* card start */}
        <div className="card chart mb-20" style={{ position: 'relative' }}>
          <div className="card-header" style={{ marginBottom: 0 }}>
            <h5>Total Sales</h5>
            <div className="form-group">
              <Datepicker
                datePickerlabel="Compared To"
                onChange={handleDateChange}
                value={selectedDate}
                maximumDate={disableFutureDates}
                setFilterSelectedDate={setSelectedDate}
              />
            </div>
          </div>
          {todayFifteenMinsLoader || allTimeEarningsLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '30px',
                height: 'inherit',
              }}
            >
              <CircularProgress size={24} thickness={4} />
            </div>
          ) : (
            <LiveLineChart
              xData={hourlyDataSet(
                todayFifteenMins,
                'dateLocal',
                'totalEarningsSum',
                new Date()
              )}
              yData={hourlyDataSet(
                allTimeEarnings,
                'createdDateLocal',
                'totalEarningsSum',
                selectedDate
              )}
              date={moment(selectedDate).format('YY-MMM-DD')}
            />
          )}
        </div>

        {/* card end */}
      </Grid>

      <Grid item xl={4} xs={12}>
        {/* card start */}
        <div className="card chart mb-20" style={{ position: 'relative' }}>
          <h5 style={{ paddingBottom: '0px' }}>Total Sales in Last Hour</h5>
          {lastHourEarningsLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '10px',
                paddingBottom: '30px',
                height: 'inherit',
              }}
            >
              <CircularProgress size={24} thickness={4} />
            </div>
          ) : (
            <BarGraph
              x={lastHourEarnings?.map((item) => `${item?.period} Mins Ago`)}
              y={lastHourEarnings?.map((item) => item?.periodSales)}
            />
          )}
        </div>
        {/* card end */}
      </Grid>

      <Grid item xs={12}>
        {/* current reviews start */}
        <div className="current-reviews mb-20 card">
          <div className="card-header">
            <h5>Current Reviews</h5>
            <Link href="/reviews" title="View Details" className="primary-link">
              View Details
            </Link>
          </div>

          <div className="card-body">
            {/* left start */}
            <div className="left">
              <div className="total-reviews">
                <div className="label">
                  <img src={StarRating} alt="Star Rating" />
                  <span>Avg Star Rating</span>
                </div>

                {todaysReviewLoader ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: '10px',
                      paddingBottom: '30px',
                      height: 'inherit',
                    }}
                  >
                    <CircularProgress size={24} thickness={4} />
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p>{todaysReview?.avgStarRating.toFixed(1) || 0} </p>{' '}
                    <p style={{ marginTop: '5px', marginLeft: '5px' }}>
                      <Rating
                        name="read-only"
                        value={todaysReview?.avgStarRating || 0}
                        precision={0.1}
                        size="medium"
                        readOnly
                      />
                    </p>
                  </div>
                )}
              </div>
              <div className="total-reviews">
                <div className="label">
                  <img src={Review} alt="Review" />
                  <span>Total Reviews</span>
                </div>
                {todaysReviewLoader ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: '10px',
                      paddingBottom: '30px',
                      height: 'inherit',
                    }}
                  >
                    <CircularProgress size={24} thickness={4} />
                  </div>
                ) : (
                  <p>{todaysReview?.ratingsCount || 0}</p>
                )}
              </div>
            </div>

            {/* left end */}

            {/* right start */}

            <div className="right">
              {/* review category start */}
              {todaysReviewLoader ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '10%',
                    paddingBottom: '30px',
                    height: 'inherit',
                  }}
                >
                  <CircularProgress size={24} thickness={4} />
                </div>
              ) : (
                <>
                  <Grid container spacing={{ xl: 5, xs: 4 }}>
                    <Grid
                      item
                      lg={2}
                      md={3}
                      sm={4}
                      xs={6}
                      style={{ position: 'relative' }}
                    >
                      <div className="review-category">
                        <DonutChart
                          x={todaysReview?.goodServiceCount}
                          y={todaysReview?.badServiceCount}
                        />
                        <span>Service</span>
                      </div>
                      {/* review category end */}
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      md={3}
                      sm={4}
                      xs={6}
                      style={{ position: 'relative' }}
                    >
                      {/* review category start */}
                      <div className="review-category">
                        <DonutChart
                          x={todaysReview?.goodPaymentCount}
                          y={todaysReview?.badPaymentCount}
                        />
                        <span>FasTab Payment</span>
                      </div>

                      {/* review category end */}
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      md={3}
                      sm={4}
                      xs={6}
                      style={{ position: 'relative' }}
                    >
                      {/* review category start */}
                      <div className="review-category">
                        <DonutChart
                          x={todaysReview?.goodValueCount}
                          y={todaysReview?.badValueCount}
                        />
                        <span>Value</span>
                      </div>
                      {/* review category end */}
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      md={3}
                      sm={4}
                      xs={6}
                      style={{ position: 'relative' }}
                    >
                      {/* review category start */}
                      <div className="review-category">
                        <DonutChart
                          x={todaysReview?.goodWaittimeCount}
                          y={todaysReview?.badWaittimeCount}
                        />
                        <span>Wait Time</span>
                      </div>

                      {/* review category end */}
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      md={3}
                      sm={4}
                      xs={6}
                      style={{ position: 'relative' }}
                    >
                      {/* review category start */}

                      <div className="review-category">
                        <DonutChart
                          x={todaysReview?.goodFoodCount}
                          y={todaysReview?.badFoodCount}
                        />
                        <span>Food</span>
                      </div>

                      {/* review category end */}
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      md={3}
                      sm={4}
                      xs={6}
                      style={{ position: 'relative' }}
                    >
                      {/* review category start */}
                      <div className="review-category">
                        <DonutChart
                          x={todaysReview?.goodAtmosphereCount}
                          y={todaysReview?.badAtmosphereCount}
                        />
                        <span>Atmosphere</span>
                      </div>

                      {/* review category end */}
                    </Grid>
                  </Grid>

                  <div className="legends">
                    <div className="legend positive">
                      <div className="oval" />
                      <span>Positive Reviews</span>
                    </div>

                    <div className="legend negative">
                      <div className="oval" />
                      <span>Negative Reviews</span>
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* right end */}
          </div>
        </div>

        {/* current reviews end */}
      </Grid>

      <Grid item lg={6} xs={12}>
        <div className="card mb-20 items-list">
          <div className="card-header">
            <h5>List of Working Servers</h5>
            <div className="filter">
              <span>
                Filter:{' '}
                <span style={{ color: '#008cff', fontWeight: 600 }}>
                  {
                    serverActionList.find(
                      (item) => item.value === serverMinutesFilter
                    ).key
                  }
                </span>
              </span>
              <div className="form-group">
                <Select
                  items={serverActionList}
                  isTotalEarning
                  handleSelectValue={handleServerSelectChange}
                />
              </div>
            </div>
          </div>
          {serverActionsLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '10px',
                paddingBottom: '30px',
                height: 'inherit',
              }}
            >
              <CircularProgress size={24} thickness={4} />
            </div>
          ) : (
            <ul
              className="list"
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              {serverActions?.length > 0 ? (
                serverActions?.map((item) => {
                  return (
                    <li className="item">
                      <span className="details">
                        <strong>
                          {item?.serverName !== ''
                            ? item?.serverName
                            : 'Name Unavailable'}
                        </strong>
                        {item.isClosedAction ? (
                          <span>
                            {`${
                              item?.serverName ?? 'Name Unavailabe'
                            } closed Table 
                          ${item?.tableName
                            .toLowerCase()
                            .replace('table', '')
                            .trim()}`}
                          </span>
                        ) : (
                          <span>
                            {`${
                              item?.serverName ?? 'Name Unavailabe'
                            } is serving
                            on Table 
                            ${item?.tableName
                              .toLowerCase()
                              .replace('table', '')
                              .trim()}`}
                          </span>
                        )}
                      </span>
                      <small>
                        {`${item?.actionTimeLocal?.split(' ')[1]} ${
                          item?.actionTimeLocal?.split(' ')[2]
                        }`}
                        <Link
                          href="/tab-details"
                          title="View Details"
                          className="primary-link"
                          onClick={() => setLocalStorageParams(item?.tabId)}
                        >
                          Go To Tab
                        </Link>
                      </small>
                    </li>
                  )
                })
              ) : (
                <li className="item">
                  <span className="details">
                    <strong>No server actions available</strong>
                  </span>
                </li>
              )}
            </ul>
          )}
        </div>
      </Grid>

      <Grid item lg={6} xs={12}>
        <div className="card mb-20 items-list">
          <div className="card-header">
            <h5>List of Active Tables</h5>
            <div className="filter">
              <span>
                Filter:{' '}
                <span style={{ color: '#008cff', fontWeight: 600 }}>
                  {
                    serverActionList.find(
                      (item) => item.value === tableMinutesFilter
                    ).key
                  }
                </span>
              </span>
              <div className="form-group">
                <Select
                  items={serverActionList}
                  isTotalEarning
                  handleSelectValue={handleTableSelectChange}
                />
              </div>
            </div>
          </div>
          {activeTablesLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '10px',
                paddingBottom: '30px',
                height: 'inherit',
              }}
            >
              <CircularProgress size={24} thickness={4} />
            </div>
          ) : (
            <ul
              className="list"
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              {activeTables?.length > 0 ? (
                activeTables?.map((item) => {
                  return (
                    <li className="item">
                      <span className="details with-icon">
                        <span className="oval">
                          <img src={Table} alt="Table" />
                        </span>
                        <strong>
                          Table No.{' '}
                          {item?.tableName
                            .toLowerCase()
                            .replace('table', '')
                            .trim()}
                        </strong>
                      </span>
                      <small>{`${item?.actionTimeLocal?.split(' ')[1]} ${
                        item?.actionTimeLocal?.split(' ')[2]
                      }`}</small>
                    </li>
                  )
                })
              ) : (
                <li className="item">
                  <span className="details with-icon">
                    <span className="oval">
                      <img src={Table} alt="Table" />
                    </span>
                    <strong>No active tables</strong>
                  </span>
                </li>
              )}
            </ul>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default LiveMetricsDashboard
