/* eslint-disable react/jsx-filename-extension */
import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { TrayWhite, UserWhite } from 'assets/images'
import { IDashboardOperationsResponse } from 'interfaces/reporting'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import {
  selectedEstablishment,
  establishmentList,
} from 'features/establishment/establishment'
import reportingService from 'services/reporting.service'
import CalculateChange from './ChangeCalculator'
import PercentageDetail from './PercentageDetail'
import NoData from './NoData'
import StatDetail from './StatDetail'
import formatCurrency from './formatters'

interface OverviewProps {
  startDate: Date
  endDate: Date
  prevStartDate: Date
  prevEndDate: Date
}

function OperationsView({
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
}: OverviewProps) {
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedCurrentEstablishment = useSelector(selectedEstablishment)
  const allEstablishmentList = useSelector(establishmentList)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [reportingData, setReportingData] =
    useState<IDashboardOperationsResponse>()
  const [reportingPrevDailyData, setReportingPrevDailyData] =
    useState<IDashboardOperationsResponse>()

  const getData = useCallback(
    async (
      restaurantId: number,
      establishmentId: number,
      startingDate: Date | null,
      endingDate: Date | null,
      prevStartingDate: Date | null,
      prevEndingDate: Date | null
    ) => {
      await Promise.all([
        await reportingService.getOperationsInfo({
          restaurantId,
          establishmentId:
            establishmentId > 0
              ? [establishmentId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          startDate: moment(startingDate)
            .set('date', startingDate.getDate())
            .set('month', startingDate.getMonth())
            .toISOString()
            .split('T')[0],
          endDate: moment(endingDate)
            .set('date', endingDate.getDate() - 1)
            .toISOString()
            .split('T')[0],
        }),
        await reportingService.getOperationsInfo({
          restaurantId,
          establishmentId:
            establishmentId > 0
              ? [establishmentId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          startDate: prevStartingDate?.toISOString().split('T')[0],
          endDate: moment(prevEndingDate)
            .set('date', prevEndingDate.getDate() - 1)
            .toISOString()
            .split('T')[0],
        }),
      ])
        .then(([data, prevData]) => {
          setReportingData(data.data.data)
          setReportingPrevDailyData(prevData.data.data)
        })
        .then(() => {
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedRestaurantId,
      selectedCurrentEstablishment,
      startDate,
      endDate,
      prevStartDate,
      prevEndDate,
    ]
  )

  useEffect(() => {
    setIsLoading(true)
    getData(
      selectedRestaurantId,
      selectedCurrentEstablishment,
      startDate,
      endDate,
      prevStartDate,
      prevEndDate
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRestaurantId,
    selectedCurrentEstablishment,
    startDate,
    endDate,
    prevStartDate,
    prevEndDate,
  ])

  if (isLoading) {
    return <></>
  }

  function calculateAverage(dataArray, property1, property2) {
    let totalProp1 = 0
    let totalProp2 = 0
    dataArray?.forEach((item) => {
      if (item[property1]) {
        totalProp1 += item[property1]
      }
    })

    dataArray?.forEach((item) => {
      if (item[property2]) {
        totalProp2 += item[property2]
      }
    })
    if (totalProp2 === 0) {
      return 0
    }
    const avg = totalProp1 / totalProp2
    return avg
  }

  function calculateAverages(dataArray, property1, property2, property3) {
    let totalProp1 = 0
    let totalProp2 = 0
    let totalProp3 = 0
    dataArray?.forEach((item) => {
      if (item[property1]) {
        totalProp1 += item[property1]
      }
    })

    dataArray?.forEach((item) => {
      if (item[property2]) {
        totalProp2 += item[property2]
      }
    })

    dataArray?.forEach((item) => {
      if (item[property3]) {
        totalProp3 += item[property3]
      }
    })
    if (totalProp2 === 0 || totalProp3 === 0) {
      return 0
    }
    const avg = totalProp1 / (totalProp2 * totalProp3)
    return avg
  }

  const categorizedTopPerformers = {}
  reportingData?.topPerformers?.forEach((obj) => {
    if (!categorizedTopPerformers[obj.performerCategory]) {
      categorizedTopPerformers[obj.performerCategory] = []
    }

    categorizedTopPerformers[obj.performerCategory].push(obj)
  })

  const mostRepeatedValues = {}

  Object.keys(categorizedTopPerformers).forEach((category) => {
    const categoryArray = categorizedTopPerformers[category].map(
      (obj) => obj.topAll
    )
    const counts = {}

    categoryArray.forEach((value) => {
      counts[value] = (counts[value] || 0) + 1
    })

    const mostRepeated = Object.keys(counts).reduce((a, b) =>
      counts[a] > counts[b] ? a : b
    )
    mostRepeatedValues[category] = mostRepeated
  })

  const singleSetOfCategories = Object.keys(mostRepeatedValues).map(
    (category) => ({
      category,
      value: mostRepeatedValues[category],
    })
  )

  return (
    <div className="Tabs">
      <Grid container spacing={6}>
        <Grid item lg={4} sm={4} xs={6}>
          <div className="card stats" data-aos="fade-up" data-aos-delay="100">
            <StatDetail
              title="Top Performers"
              description={
                <p className="stat-description">
                  These are the top performers shown for your selected time
                  period, by total earnings. This allows you to have a good
                  understanding of the areas, people, and menu items in your
                  restaurant that are performing the best
                </p>
              }
              content={
                <>
                  {reportingData?.topPerformers &&
                  reportingData?.topPerformers.length > 0 &&
                  selectedCurrentEstablishment > 0 ? (
                    <table>
                      <tbody>
                        {singleSetOfCategories?.map((item) => (
                          <tr key={item.category}>
                            <td>{item.category}</td>
                            <td>{item.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={UserWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>
        <Grid item lg={4} sm={4} xs={6}>
          <div className="card stats" data-aos="fade-up">
            <StatDetail
              title="Average Tip %"
              description={
                <p className="stat-description">
                  This is the average tip percentage servers received during the
                  period of selected time. This indicates how well your guests
                  are tipping your servers
                </p>
              }
              content={
                <>
                  {reportingData?.operationsDetails ? (
                    <>
                      <p>
                        {(
                          calculateAverage(
                            reportingData.operationsDetails,
                            'tipTotalSum',
                            'tabTotalSum'
                          ) * 100
                        )?.toFixed(1)}
                        %
                      </p>
                      <small>&nbsp;</small>
                      <PercentageDetail
                        number={CalculateChange(
                          calculateAverage(
                            reportingData.operationsDetails,
                            'tipTotalSum',
                            'tabTotalSum'
                          ),
                          calculateAverage(
                            reportingPrevDailyData.operationsDetails,
                            'tipTotalSum',
                            'tabTotalSum'
                          )
                        )}
                      />
                      <hr />
                      <h4>Previous Period</h4>
                      <h6>
                        {(
                          calculateAverage(
                            reportingPrevDailyData.operationsDetails,
                            'tipTotalSum',
                            'tabTotalSum'
                          ) * 100
                        )?.toFixed(1)}
                        %
                      </h6>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={TrayWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>
        <Grid item lg={4} sm={4} xs={6}>
          <div className="card stats" data-aos="fade-up" data-aos-delay="100">
            <StatDetail
              title="Earnings Per Min Per Staff"
              description={
                <p className="stat-description">
                  This is the total earnings per minute of guest time, per
                  server on the tabs, spent at your restaurant over the time
                  period selected. This allows you to understand how efficiently
                  your restaurant is operating
                </p>
              }
              content={
                <>
                  {reportingData?.operationsDetails ? (
                    <>
                      <p>
                        {formatCurrency(
                          calculateAverages(
                            reportingData.operationsDetails,
                            'tabTotalSum',
                            'tabTimeMinsSum',
                            'serverCount'
                          )
                        )}
                      </p>
                      <small>&nbsp;</small>
                      <PercentageDetail
                        number={CalculateChange(
                          calculateAverages(
                            reportingData.operationsDetails,
                            'tabTotalSum',
                            'tabTimeMinsSum',
                            'serverCount'
                          ),
                          calculateAverages(
                            reportingPrevDailyData.operationsDetails,
                            'tabTotalSum',
                            'tabTimeMinsSum',
                            'serverCount'
                          )
                        )}
                      />
                      <hr />
                      <h4>Previous Period</h4>
                      <h6>
                        {formatCurrency(
                          calculateAverages(
                            reportingPrevDailyData.operationsDetails,
                            'tabTotalSum',
                            'tabTimeMinsSum',
                            'serverCount'
                          )
                        )}
                      </h6>
                    </>
                  ) : (
                    <NoData />
                  )}
                </>
              }
            />
            <div className="oval">
              <img src={UserWhite} alt="Tray" />
            </div>
            <div className="dataTypes">
              <div className="stat-ind-pos" />
              <div className="stat-ind-fastab" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default OperationsView
