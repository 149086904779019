import { AxiosResponse } from 'axios'
import { JWTToken } from 'interfaces/JWT-Token'
// import { JWTToken } from 'interfaces/JWT-Token'
import { ICreatePassword } from 'interfaces/user'
// import jwtDecode from 'jwt-decode'
import jwtDecode from 'jwt-decode'
import { IApiSuccessResponse } from '../interfaces/api-success-response'
import {
  ILoginResponse,
  ILoginWithEmail,
  IResetPassword,
  ISetLoginDetails,
  ICaptchaResponse,
  IFaqUrlResponse,
  ILastActiveRequest,
  IGoogleLoginKeyResponse,
  ISocialLoginStatus,
  IAccessTokenRequestDTO,
  IGoogleLocationResponse,
  IGoogleReviewRequestDTO,
  IGoogleReviewResponse,
  IGoogleSecretKeyResponse,
  IRefreshTokenRequestDTO,
  IAccessTokenResponse,
} from '../interfaces/login'
// eslint-disable-next-line import/no-cycle
import httpClient from './base-service'

const endPointBaseURL = `/Common`
const accountendPointURL = `/Account`
const userendPointURL = `/User`

const authToken = {
  TOKEN: 'loginAuthToken',
  REFRESH_TOKEN: 'RefreshToken',
}

const login = async (
  requestBody: ILoginWithEmail
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse>>> =>
  httpClient.post<IApiSuccessResponse<ILoginResponse>>(
    `${endPointBaseURL}/authenticate`,
    requestBody
  )

const setLoginDetails = async (
  auth: ISetLoginDetails,
  rememberMe?: boolean
) => {
  localStorage.setItem(authToken.TOKEN, auth.token)
  localStorage.setItem('firstName', auth.firstName)
  localStorage.setItem('lastName', auth.lastName)
  localStorage.setItem('loginId', auth.loginId.toString())
  localStorage.setItem('lastActive', auth.lastActive.toString())
  if (rememberMe) {
    localStorage.setItem(authToken.REFRESH_TOKEN, auth.refreshToken)
  }
}

const getDecodedToken = (token: string): JWTToken => {
  try {
    const decodedValue = jwtDecode(token)
    return new JWTToken(decodedValue as JWTToken)
  } catch {
    return new JWTToken()
  }
}

const isTokenExpired = (token: string): boolean => {
  const expireTime = getDecodedToken(token).exp
  const currentTime = new Date().getTime() / 1000
  if (expireTime) {
    return currentTime > expireTime
  }
  return false
}
const refreshToken = async (
  reToken: string
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse>>> =>
  httpClient.get<IApiSuccessResponse<ILoginResponse>>(
    `/Account/refresh-token?refreshToken=${reToken}`
  )
const getAuthorizationToken = async (): Promise<string> => {
  const token = localStorage.getItem(authToken.TOKEN)
  if (isTokenExpired(token)) {
    const refresh = localStorage.getItem(authToken.REFRESH_TOKEN)
    if (refresh) {
      const { data } = await refreshToken(refresh)
      return data.data.token
    }
    return null
  }
  return token
}
const resetPassword = async (
  requestBody: IResetPassword
): Promise<AxiosResponse<IApiSuccessResponse<String>>> =>
  httpClient.post<IApiSuccessResponse<String>>(
    `${accountendPointURL}/Reset-password`,
    requestBody
  )
const createPassword = async (
  requestBody: ICreatePassword
): Promise<AxiosResponse<IApiSuccessResponse<String>>> =>
  httpClient.post<IApiSuccessResponse<String>>(
    `${userendPointURL}/create-password`,
    requestBody
  )
const forgotPassword = async (
  requestBody: string
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse>>> =>
  httpClient.get<IApiSuccessResponse<ILoginResponse>>(
    `${accountendPointURL}/forgot-admin-password?email=${requestBody}`
  )

// get Captcha
const getCaptcha = async (): Promise<
  AxiosResponse<IApiSuccessResponse<ICaptchaResponse>>
> =>
  httpClient.get<IApiSuccessResponse<ICaptchaResponse>>(
    `${endPointBaseURL}/get-captcha-details-admin`
  )

const getFaqUrl = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IFaqUrlResponse>>
> => httpClient.get<IApiSuccessResponse<IFaqUrlResponse>>(`/Common/get-faq-url`)

const updateLastActive = (
  requestBody: ILastActiveRequest
): Promise<AxiosResponse<IApiSuccessResponse<String>>> =>
  httpClient.post<IApiSuccessResponse<String>>(
    `${endPointBaseURL}/update-last-active`,
    requestBody
  )

const getGoogleLoginKey = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IGoogleLoginKeyResponse>>
> =>
  httpClient.get<IApiSuccessResponse<IGoogleLoginKeyResponse>>(
    `/Common/get-google-client-id`
  )
const getGoogleSecretKey = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IGoogleSecretKeyResponse>>
> =>
  httpClient.get<IApiSuccessResponse<IGoogleSecretKeyResponse>>(
    `/Common/get-google-secret-id`
  )
const getSocialLoginStatus = async (): Promise<
  AxiosResponse<IApiSuccessResponse<ISocialLoginStatus>>
> =>
  httpClient.get<IApiSuccessResponse<ISocialLoginStatus>>(
    `/Common/get-social-login-status`
  )

const getGoogleLocation = async (
  requestBody: IAccessTokenRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<IGoogleLocationResponse>>> =>
  httpClient.post<IApiSuccessResponse<IGoogleLocationResponse>>(
    `/Common/get-location-list-data-from-api`,
    requestBody
  )

const getGoogleReview = async (
  requestBody: IGoogleReviewRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<IGoogleReviewResponse>>> =>
  httpClient.post<IApiSuccessResponse<IGoogleReviewResponse>>(
    `/Common/get-google-reviews`,
    requestBody
  )

const replyGoogleReview = async (
  requestBody: IGoogleReviewRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `/Common/google-review-response`,
    requestBody
  )

const getAccessTokenByRefreshToken = async (
  requestBody: IRefreshTokenRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<IAccessTokenResponse>>> =>
  httpClient.post<IApiSuccessResponse<IAccessTokenResponse>>(
    `/Common/get-access-token-by-refresh-token`,
    requestBody
  )

export default {
  getAuthorizationToken,
  login,
  setLoginDetails,
  resetPassword,
  createPassword,
  forgotPassword,
  isTokenExpired,
  getCaptcha,
  getFaqUrl,
  updateLastActive,
  getGoogleLoginKey,
  getSocialLoginStatus,
  getGoogleLocation,
  getGoogleReview,
  replyGoogleReview,
  getGoogleSecretKey,
  getAccessTokenByRefreshToken,
}
