import { Menu, MenuItem } from '@mui/material'
import { Delete, Edit, List, More, Move } from 'assets/images'
import AddEditMenuDialog from 'components/AddEditMenuDialog/Index'
import Button from 'components/Button/Index'
import MenuItemsDialog from 'components/MenuItemsDialog/Index'
import Switch from 'components/Switch/Index'
import React, { useCallback, useEffect, useState } from 'react'
import menuService from 'services/menu.service'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { IMenuSearchResponse } from 'interfaces/menu'
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/Index'
import { DialogOptions } from 'enums/Common'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { deleteMenu, menuList, setMenus } from 'features/menu/menu'

interface dialogProps {
  establishmentId?: number
  menu?: IMenuSearchResponse[]
}

function MenuList(props: dialogProps) {
  // eslint-disable @typescript-eslint/no-unused-vars /
  // add-edit-menu-dialog
  const dispatch = useDispatch()
  const [openAddEditMenuDialog, setOpenAddEditMenuDialog] = useState(false)
  const [menuId, setMenuId] = useState(0)
  const [cIndex, setIndex] = useState(0)

  const [CEstablishmentId] = useState(props)

  const [menuLists, setMenuList] = useState<IMenuSearchResponse[]>([])
  const items = useSelector(menuList)

  useEffect(() => {
    setMenuList(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  const closeAddEditMenuDialog = () => {
    setOpenAddEditMenuDialog(false)
  }

  const handleOnDragEnd = async (result: DropResult) => {
    if (!result.destination) return
    const newItems = Array.from(menuLists)
    const [reorderedItem] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, reorderedItem)
    const sortedArray = newItems.map((a) => a.menuId)
    dispatch(setMenus({ menuList: newItems }))
    // ReorderMenu
    await menuService.ReorderMenu(sortedArray)
  }

  // action-menu
  const [actionAnchorEl, setActionAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const openActionMenu = Boolean(actionAnchorEl)
  const actionMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget)
  }
  const actionMenuClose = () => {
    setActionAnchorEl(null)
  }

  // menu-items-dialog
  const [openMenuItemsDialog, setOpenMenuItemsDialog] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const closeMenuItemsDialog = () => {
    setOpenMenuItemsDialog(false)
  }
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [tableIdForDelete, setTableIdForDelete] = useState(0)

  const toggleDeleteConfirmationDialog = useCallback(
    async (isOpen: boolean, isSubmit = false, id: number) => {
      setOpenDeleteConfirmation(isOpen)
      setTableIdForDelete(id)
      if (isSubmit && !isOpen && id > 0) {
        try {
          await menuService.deleteMenu({
            menuId: id,
          })
          dispatch(deleteMenu({ menuId: id }))
          toast.success('Menu deleted successfully.')
        } catch (error) {
          toast.error('Something wrong with deleting the records.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleSwitchChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const newMenuArr = items.map((obj) => {
      if (obj.menuId === id) {
        return { ...obj, isActive: e.target.checked }
      }
      return obj
    })
    dispatch(setMenus({ menuList: newMenuArr }))

    await menuService.InactiveMenu({
      menuId: id,
      isActive: e.target.checked,
    })
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="menues">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="draggable-list"
            >
              {menuLists &&
                menuLists.map((element, index) => {
                  return (
                    <Draggable
                      key={element.position}
                      draggableId={element.position.toString()}
                      index={index}
                    >
                      {(providedDraggable, snapshot) => (
                        <li
                          className={`draggable-item ${
                            !element.isActive ? 'inactive' : ''
                          } ${snapshot.isDragging ? 'dragging' : ''}`}
                          {...providedDraggable.draggableProps}
                          ref={providedDraggable.innerRef}
                        >
                          <span
                            className="drag-handler"
                            {...providedDraggable.dragHandleProps}
                          >
                            <img src={Move} alt="Move" />
                          </span>
                          <span className="item-label wrap-content">
                            <span>{element.name}</span>
                            {/* <small>{element.price}</small> */}
                          </span>
                          <span className="right">
                            <Switch
                              onChange={(e) =>
                                handleSwitchChange(e, element.menuId)
                              }
                              defaultChecked={element.isActive}
                            />
                            <Button
                              variant="text"
                              title="Edit"
                              className="icon-btn rounded light-bordered"
                              color="inherit"
                              onClick={() => {
                                setMenuId(element.menuId)
                                setIndex(index)
                                setIsEdit(true)
                                setOpenAddEditMenuDialog(true)
                              }}
                            >
                              <img src={Edit} alt="Edit" />
                            </Button>
                            <Button
                              variant="text"
                              title="List"
                              className="icon-btn rounded light-bordered"
                              color="inherit"
                              onClick={() => {
                                setMenuId(element.menuId)
                                setOpenMenuItemsDialog(true)
                              }}
                            >
                              <img src={List} alt="List" />
                            </Button>
                            <Button
                              variant="text"
                              title="Delete"
                              className="icon-btn rounded light-bordered"
                              color="inherit"
                              to="/menu"
                              onClick={() =>
                                toggleDeleteConfirmationDialog(
                                  true,
                                  false,
                                  element.menuId
                                )
                              }
                            >
                              <img src={Delete} alt="Delete" />
                            </Button>
                            <Button
                              color="inherit"
                              variant="text"
                              className="icon-btn rounded light-bordered action-menu-btn"
                              onClick={actionMenuOpen}
                            >
                              <img src={More} alt="More" />
                            </Button>
                          </span>
                        </li>
                      )}
                    </Draggable>
                  )
                })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <Menu
        anchorEl={actionAnchorEl}
        open={openActionMenu}
        onClose={actionMenuClose}
        className="primary-menu action-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          title="Edit"
          onClick={() => {
            actionMenuClose()
            setOpenAddEditMenuDialog(true)
          }}
        >
          <img src={Edit} alt="Edit" />
          <span>Edit</span>
        </MenuItem>
        <MenuItem
          title="List"
          onClick={() => {
            actionMenuClose()
            setOpenMenuItemsDialog(true)
          }}
        >
          <img src={List} alt="List" />
          <span>List</span>
        </MenuItem>
        <MenuItem onClick={actionMenuClose} title="Delete">
          <img src={Delete} alt="List" />
          <span>Delete</span>
        </MenuItem>
      </Menu>

      <DeleteConfirmationDialog
        open={openDeleteConfirmation}
        idFieldValue={tableIdForDelete}
        message="Are you sure you want to delete this Menu?"
        callBack={(reason: DialogOptions, idFieldValue: number) => {
          toggleDeleteConfirmationDialog(
            false,
            reason === DialogOptions.Yes,
            idFieldValue
          )
        }}
      />

      {/* add-edit-menu-dialog */}
      <AddEditMenuDialog
        open={openAddEditMenuDialog}
        onClose={closeAddEditMenuDialog}
        index={cIndex}
        menu={menuLists}
        isEdit={isEdit}
        CurrentEstablishmentId={CEstablishmentId?.establishmentId}
        closeDialog={closeAddEditMenuDialog}
      />
      {/* menu-items-dialog */}
      <MenuItemsDialog
        open={openMenuItemsDialog}
        onClose={closeMenuItemsDialog}
        menuId={menuId}
        menu={items}
      />
    </>
  )
}

export default MenuList
