/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from 'react'
import {
  MenuItem,
  Button as MaterialButton,
  Menu,
  Divider,
} from '@mui/material'
import { Link } from 'react-router-dom'
import Button from 'components/Button/Index'
import {
  HelpIcon,
  MenuIcon,
  MobileLogo,
  UserFill,
  UsermenuDownarrow,
} from 'assets/images'
import Select from 'components/Select/Index'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
  setRestaurants,
  setSelectedRestuarant,
  selectedRestaurant,
} from 'features/restaurant/restaurant'
import {
  setEstablishments,
  setSelectedEstablishment,
  selectedEstablishment,
  setCurrentEstablishment,
} from 'features/establishment/establishment'
import ResetPasswordDialog from 'components/ResetPasswordDialog/Index'
import AddEditUserDialog from 'components/AddEditUserDialog/Index'
import authService from 'services/auth.service'
import Moment from 'react-moment'
import userService from 'services/user.service'
import { IRestuarantSearchResponse } from 'interfaces/restaurant'
import { IEstablishmentSearchResponse } from 'interfaces/establishment'
import restaurantService from 'services/restaurant.service'
import establishmentService from 'services/establishment.service'

interface IGetExistingData {
  takeRestaurantList?: IRestuarantSearchResponse[]
  takeEstablishmentList?: IEstablishmentSearchResponse[]
}

function Header({
  takeRestaurantList,
  takeEstablishmentList,
}: IGetExistingData) {
  const [restaurantList, setRestaurantList] = useState([])
  const [isFromMyProfile, setFromMyProfile] = useState(true)
  const [establishmentLists, setEstablishmentList] = useState([
    {
      key: 'All',
      value: 0,
    },
  ])
  const selectedRestraunt = useSelector(selectedRestaurant)
  const selectedEstablishmentCurrent = useSelector(selectedEstablishment)
  const [openAddEditUserDialog, setOpenAddEditUserDialog] = useState(false)
  const closeAddEditUserDialog = () => {
    setOpenAddEditUserDialog(false)
  }
  const [lastActive, setLastActive] = useState(null)
  const [selectedCurrentEstablishment, setCurrentSelectedEstablishment] =
    useState(selectedEstablishmentCurrent)

  const dispatch = useDispatch()

  const getEstablishmentList = useCallback(
    async () => {
      try {
        const useEstablishmentList =
          takeEstablishmentList === null || takeEstablishmentList === undefined
            ? (
                await establishmentService.getAllEstablishments({
                  pageNo: 1,
                  pageSize: 0,
                  sortGridModels: [{ field: 'restaurantName', sort: 0 }],
                  restaurantId: selectedRestraunt,
                })
              ).data.data.data
            : takeEstablishmentList
        if (selectedRestraunt !== null && selectedRestraunt !== 0) {
          const data = useEstablishmentList
            .filter((x) => x.restaurantId === selectedRestraunt)
            .map((e) => {
              return e
            })

          const list = data.map((e) => {
            return {
              key: e.establishmentName,
              value: e.establishmentId,
            }
          })
          list.splice(0, 0, {
            key: 'All',
            value: 0,
          })
          if (list.length === 2) {
            setSelectedEstablishment(list[1])
            dispatch(
              setSelectedEstablishment({ selectedEstablishment: list[1].value })
            )
          }
          setEstablishmentList(list)
        } else {
          const list = [
            {
              key: 'All',
              value: 0,
            },
          ]
          setEstablishmentList(list)
        }
        dispatch(setEstablishments({ establishmentList: useEstablishmentList }))
      } catch (e) {
        if (localStorage.length !== 0) {
          toast.error('Failed to retrieve establishment list.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestraunt]
  )

  // get all the restraunts
  const getRestaurantList = useCallback(
    async () => {
      const useRestaurantList =
        takeRestaurantList === null || takeRestaurantList === undefined
          ? (
              await restaurantService.getAllResturants({
                pageNo: 1,
                pageSize: 0,
                sortGridModels: [
                  {
                    field: 'name',
                    sort: 0,
                  },
                ],
              })
            ).data.data.data
          : takeRestaurantList
      const list = useRestaurantList.map((e) => {
        return {
          key: e.name,
          value: e.restaurantId,
        }
      })
      list.splice(0, 0, {
        key: 'All',
        value: 0,
      })
      if (list.length === 2) {
        setSelectedRestuarant(list[1])
        dispatch(setSelectedRestuarant({ selectedRestaurant: list[1].value }))
      }
      setRestaurantList(list)
      dispatch(setRestaurants({ restaurantList: useRestaurantList }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestraunt]
  )

  const getUserDetail = useCallback(
    async () => {
      const userData = await userService.getUserById({
        loginId: Number(localStorage.getItem('loginId')),
      })
      localStorage.setItem(
        'lastActive',
        userData.data.data.lastActive.toString()
      )
      setLastActive(userData.data.data.lastActive.toString())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    getEstablishmentList()
    setCurrentSelectedEstablishment(selectedEstablishmentCurrent)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestraunt, selectedEstablishmentCurrent])

  // const [notificationAnchorEl, setNotificationAnchorEl] =
  //   React.useState<null | HTMLElement>(null)
  // const openNotificationMenu = Boolean(notificationAnchorEl)
  // const notificationMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setNotificationAnchorEl(event.currentTarget)
  // }
  // const notificationMenuClose = () => {
  //   setNotificationAnchorEl(null)
  // }

  const [userAnchorEl, setUserAnchorEl] = React.useState<null | HTMLElement>(
    null
  )
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false)
  const openUserMenu = Boolean(userAnchorEl)
  const userMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserAnchorEl(event.currentTarget)
  }
  const userMenuClose = () => {
    setUserAnchorEl(null)
  }
  const sidebarToggle = () => {
    document.body.classList.toggle('sidebar-toggle')
  }
  const hideSidebar = () => {
    document.body.classList.remove('sidebar-toggle')
  }
  const closeResetPasswordDialog = () => {
    setResetPasswordDialogOpen(false)
  }

  useEffect(() => {
    getRestaurantList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('lastActive')) {
      getUserDetail()
    } else {
      setLastActive(localStorage.getItem('lastActive'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActive])
  const clearStorage = () => {
    authService.updateLastActive({
      loginId: Number(localStorage.getItem('loginId')),
      isLastLoginTime: false,
    })
    localStorage.clear()
  }
  const handleSelectValue = (value: number) => {
    dispatch(setSelectedEstablishment({ selectedEstablishment: null }))
    dispatch(
      setCurrentEstablishment({
        currentEstablishments: null,
      })
    )
    dispatch(setSelectedRestuarant({ selectedRestaurant: value }))
    setCurrentSelectedEstablishment(null)
  }

  // handleSelectLocationValue
  const handleSelectLocationValue = (value: number) => {
    setCurrentSelectedEstablishment(value)
    dispatch(setSelectedEstablishment({ selectedEstablishment: value }))
  }

  return (
    <>
      <div className="overlay">
        <button type="button" onClick={hideSidebar}>
          {' '}
        </button>
      </div>
      <header className="header">
        <div className="inner">
          <Button
            variant="text"
            onClick={sidebarToggle}
            className="toggle-menu"
            color="inherit"
          >
            <img src={MenuIcon} alt="Menu" />
          </Button>
          <Link to="/dashboard" className="mobile-logo">
            <img src={MobileLogo} alt="FasTab" />
          </Link>
          <div className="form-group">
            <Select
              label="All Restaurants"
              items={restaurantList}
              formikValue={selectedRestraunt}
              handleSelectValue={(value) => handleSelectValue(value)}
              searchAble
            />
          </div>
          <div className="form-group">
            <Select
              label="All Locations"
              items={establishmentLists}
              formikValue={selectedCurrentEstablishment}
              handleSelectValue={(value) => {
                handleSelectLocationValue(value)
              }}
              searchAble
            />
          </div>
          <div className="nav-right">
            <Button
              variant="text"
              color="inherit"
              className="btn-icon"
              title="Help"
              target="_top"
              rel="noopener noreferrer"
              href="mailto:partners@FasTabPayments.com"
            >
              <img src={HelpIcon} alt="Help" />
            </Button>
            <div className="notification-menu">
              {/* <MaterialButton
                color="inherit"
                onClick={notificationMenuOpen}
                className="btn-icon"
              >
                <img src={Notification} alt="Notification" />
              </MaterialButton>
              <Menu
                anchorEl={notificationAnchorEl}
                open={openNotificationMenu}
                onClose={notificationMenuClose}
                className="primary-menu"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={notificationMenuClose}>
                  Lorem ipsum dolor sit amet.
                </MenuItem>
                <MenuItem onClick={notificationMenuClose}>
                  Lorem ipsum dolor sit amet.
                </MenuItem>
                <MenuItem onClick={notificationMenuClose}>
                  Lorem ipsum dolor sit amet.
                </MenuItem>
              </Menu> */}
            </div>
            <div className="user-menu">
              <MaterialButton color="inherit" onClick={userMenuOpen}>
                <span className="user-icon">
                  <img src={UserFill} alt="user" />
                </span>
                <span className="user-info">
                  <small>Welcome,</small>
                  {`${localStorage.getItem('firstName')} ${localStorage.getItem(
                    'lastName'
                  )}`}
                </span>
                <img src={UsermenuDownarrow} alt="Down" />
              </MaterialButton>
              <Menu
                anchorEl={userAnchorEl}
                open={openUserMenu}
                onClose={userMenuClose}
                className="primary-menu user-menu-popup"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <span className="info-text">
                  <small>Welcome,</small>
                  {`${localStorage.getItem('firstName')} ${localStorage.getItem(
                    'lastName'
                  )}`}
                </span>
                <Divider />
                <MenuItem
                  onClick={() => {
                    userMenuClose()
                    setOpenAddEditUserDialog(true)
                    setFromMyProfile(true)
                  }}
                >
                  My Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    userMenuClose()
                    setResetPasswordDialogOpen(true)
                  }}
                >
                  Change Password
                </MenuItem>
                <MenuItem onClick={userMenuClose}>
                  <Link to="/" title="Logout" onClick={clearStorage}>
                    Logout
                  </Link>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </header>
      <div className="last-active-seen">
        <span className="last-seen-lable">Last Logged In: </span>
        <span className="last-seen-value">
          {lastActive && (
            <Moment format="MM/DD/YYYY hh:mm A">{lastActive}</Moment>
          )}
        </span>
      </div>
      <ResetPasswordDialog
        open={resetPasswordDialogOpen}
        onClose={closeResetPasswordDialog}
        closeDialog={closeResetPasswordDialog}
      />
      <AddEditUserDialog
        open={openAddEditUserDialog}
        onClose={closeAddEditUserDialog}
        closeDialog={closeAddEditUserDialog}
        isFromMyProfile={isFromMyProfile}
      />
    </>
  )
}

export default Header
