import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { menuList } from 'features/menu/menu'
import {
  ICategoryDetailData,
  IMenuDetailData,
  IMenuItem,
} from 'interfaces/menu'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface MenuTabProps {
  menuId?: number
  tabIndex?: number
  currentTab?: number
}

function MenuTab(props: MenuTabProps) {
  const menus = useSelector(menuList)
  const { menuId, tabIndex, currentTab } = props
  const [menu, setMenu] = useState<IMenuDetailData>(null)
  const [items, setItems] = React.useState<IMenuItem[]>([])
  const [categoryToDisplay, setCategoryToDisplay] = React.useState<
    ICategoryDetailData[]
  >([])
  const [alignment, setAlignment] = React.useState<number>(0)
  const getMenuById = useCallback(
    async (currentMenuId: number) => {
      try {
        const data = menus.find((d) => d.menuId === currentMenuId)
        const menuData = data
        setMenu(menuData)
        const categoryData =
          menuData &&
          menuData?.menuCategories?.length > 0 &&
          menuData.isActive === true
            ? menuData?.menuCategories
                ?.filter((d) => d.isActive === true && !d.isDeleted)
                .sort((a, b) => {
                  return a.position - b.position
                })
            : null
        const categoryId = categoryData ? categoryData[0]?.menuCategoryId : 0
        setAlignment(categoryId)
        setItems(
          categoryData && categoryData[0].isActive === true
            ? categoryData[0]?.menuItems
                ?.filter((i) => i.isActive === true)
                .sort((a, b) => {
                  return a.position - b.position
                })
            : []
        )
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menus]
  )

  useEffect(() => {
    if (currentTab === tabIndex) {
      getMenuById(menuId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, menus])
  const toggleChange = (
    event: React.MouseEvent<HTMLElement>,
    categoryId: number
  ) => {
    if (categoryId) {
      setAlignment(categoryId)
      const categoryData = menu.menuCategories.find(
        (d) => d.menuCategoryId === categoryId
      )
      const sortedCategoryArray =
        categoryData.menuSubCategory === null ||
        categoryData.menuSubCategory?.length === 0
          ? [...categoryData?.menuItems]
          : [...categoryData?.menuSubCategory]

      if (
        categoryData.menuSubCategory === null ||
        categoryData.menuSubCategory?.length === 0
      ) {
        setItems(
          categoryData
            ? sortedCategoryArray.sort((a, b) => {
                return a.position - b.position
              })
            : []
        )
      } else {
        setItems([])
      }
    }
  }
  useEffect(() => {
    const cat =
      menu &&
      menu?.menuCategories &&
      menu?.menuCategories
        ?.filter((data) => data.isActive === true && !data.isDeleted)
        .sort((a, b) => {
          return a.position - b.position
        })
    setCategoryToDisplay(
      menu &&
        menu.menuCategories
          ?.filter((data) => data.isActive === true)
          .sort((a, b) => {
            return a.position - b.position
          })
    )
    setAlignment(menu && cat && cat[0]?.menuCategoryId)
    setItems(
      cat && cat[0]?.isActive === true && cat[0]?.menuItems
        ? cat[0]?.menuItems
            ?.filter((i) => i.isActive === true)
            .sort((a, b) => {
              return a.position - b.position
            })
        : []
    )
  }, [menu, currentTab, menus])
  return (
    <>
      {menu && menu.menuCategories?.length > 0 && (
        <>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={toggleChange}
            className="secondary-tabs"
          >
            {categoryToDisplay?.map((toggleBtnData: ICategoryDetailData) => {
              return (
                toggleBtnData.isActive &&
                !toggleBtnData.isDeleted && (
                  <ToggleButton
                    key={toggleBtnData.menuCategoryId}
                    value={toggleBtnData.menuCategoryId}
                  >
                    {toggleBtnData.name}
                  </ToggleButton>
                )
              )
            })}
          </ToggleButtonGroup>

          <ul className="menu-item-list">
            {items?.map((item) => {
              return (
                item.isActive === true && (
                  <li key={item.menuItemId}>
                    <span className="item-details">
                      <strong>{item.name}</strong>
                      {item.description}
                    </span>
                    <span className="item-price">
                      ${item?.price?.toFixed(2)}
                    </span>
                  </li>
                )
              )
            })}
          </ul>
        </>
      )}
    </>
  )
}

export default MenuTab
