import { MenuItem, TextField } from '@mui/material'
import { Search } from 'assets/images'
import React, { useState } from 'react'

interface props {
  items: Array<{ key: number | string; value: number | string }>
  label?: string
  errorText?: string
  showError?: boolean
  placeholder?: boolean
  placeholderValue?: string
  disabled?: boolean
  searchAble?: boolean
  createAble?: boolean
  handleSelectValue?: (event: string) => void
  name?: string
  formikValue?: string
  isTotalEarning?: boolean
}

function SelectStringId({
  items,
  label,
  errorText,
  showError = false,
  placeholderValue,
  placeholder = false,
  disabled = false,
  searchAble = false,
  createAble = false,
  handleSelectValue,
  name,
  formikValue,
  isTotalEarning,
}: props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSelectValue(event.target.value)
  }

  const [searchText, setSearchText] = useState('')

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value)
  }
  const handleItemClick = () => {
    setSearchText('')
  }

  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      helperText={showError ? errorText : ''}
      onChange={handleChange}
      error={showError}
      disabled={disabled}
      value={formikValue || 0}
      select
      SelectProps={{
        name,
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 250,
              overflowY: 'auto',
            },
          },
          classes: {
            root: isTotalEarning ? 'primary-menu filter-menu' : 'primary-menu',
          },
        },
      }}
    >
      {placeholder && (
        <MenuItem disabled style={{ display: 'none' }} value={placeholderValue}>
          <span className="placeholder">{placeholderValue}</span>
        </MenuItem>
      )}
      {searchAble && (
        <MenuItem className="search-box" disableTouchRipple>
          <span>
            <input
              type="text"
              value={searchText}
              onChange={handleSearchInputChange}
              onKeyDown={(e) => e.stopPropagation()}
              placeholder="Search Here..."
            />
            <button type="button">
              <img src={Search} alt="Search" />
            </button>
          </span>
        </MenuItem>
      )}
      {items.map((element) => {
        return (
          <MenuItem
            key={element?.value}
            value={element?.value}
            onClick={handleItemClick}
            style={
              element?.key
                .toString()
                .toLowerCase()
                .includes(searchText?.toLowerCase()) ||
              element?.value === formikValue
                ? {}
                : { display: 'none' }
            }
          >
            <span
              style={
                element?.key
                  .toString()
                  .toLowerCase()
                  .includes(searchText?.toLowerCase()) ||
                element?.value === formikValue
                  ? {}
                  : { display: 'none' }
              }
              className="options"
            >
              {element?.key}
            </span>
          </MenuItem>
        )
      })}
      {createAble && (
        <MenuItem className="add-item">
          <span>Add</span> &quot;some section&quot;
        </MenuItem>
      )}
    </TextField>
  )
}

export default SelectStringId
