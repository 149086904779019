import { createSlice } from '@reduxjs/toolkit'
import { IRestuarantSearchResponse } from 'interfaces/restaurant'

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState: { restaurantList: [], selectedRestaurant: null },
  reducers: {
    setRestaurants: (state, action) => {
      const { restaurantList } = action.payload
      state.restaurantList = restaurantList
    },
    setSelectedRestuarant: (state, action) => {
      const { selectedRestaurant } = action.payload
      state.selectedRestaurant = selectedRestaurant
    },
  },
})

export const { setRestaurants, setSelectedRestuarant } = restaurantSlice.actions

export default restaurantSlice.reducer

export const restaurantList = (state: {
  restaurant: { restaurantList: IRestuarantSearchResponse[] }
}) => state.restaurant.restaurantList

export const selectedRestaurant = (state: {
  restaurant: { selectedRestaurant: number }
}) => state.restaurant.selectedRestaurant
