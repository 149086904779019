import { createSlice } from '@reduxjs/toolkit'
import { ItabsSearchRequest } from 'interfaces/tab'

const tabPayloadSlice = createSlice({
  name: 'payloadState',
  initialState: {
    tabpayloadData: {},
  },
  reducers: {
    setTabPayloadData: (state, action) => {
      state.tabpayloadData = action.payload
    },
  },
})

export const { setTabPayloadData } = tabPayloadSlice.actions
export default tabPayloadSlice.reducer
export const tabpayloadData = (state: {
  payloadState: { tabpayloadData: ItabsSearchRequest }
}) => state.payloadState.tabpayloadData
